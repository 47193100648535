import React from "react";

import EditInvoiceFormContainer from "../../containers/EditInvoiceFormContainer";
import { MainLayout } from "layouts";

const EditInvoicePage = (props) => (
  <MainLayout {...props}>
    <EditInvoiceFormContainer {...props} />
  </MainLayout>
)

export default EditInvoicePage;