


export const domesticCleaningOptions = [
  {
    label:"Standard (Just this once)",
    value:"standard_1",
    type: "standard",
    cost: 100,
    minDuratin: 60,
    perItemCost:17,
    perItemTime:30,
    addOnServices:17
  },{
    label:"Standard (Every Week)",
    value:"standard_2",
    type: "standard",
    cost: 100,
    minDuratin: 60,
    perItemCost:17,
    perItemTime:30,
    addOnServices:17
  },{
    label:"Standard (Every 2 Weeks)",
    value:"standard_3",
    type: "Standard",
    cost: 100,
    minDuratin: 60,
    perItemCost:17,
    perItemTime:30,
    addOnServices:17
  },{
    label:"Standard (Every Month)",
    value:"standard_4",
    type: "standard",
    cost: 100,
    minDuratin: 60,
    perItemCost:17,
    perItemTime:30,
    addOnServices:17
  },{
    label:"Deep (Just this once)",
    value:"deep_1",
    type: "deep",
    cost: 151,
    minDuratin: 90,
    perItemCost:34,
    perItemTime:30,
    addOnServices:17
  },{
    label:"Deep (Every 2 Weeks)",
    value:"deep_2",
    type: "deep",
    cost: 151,
    minDuratin: 90,
    perItemCost:34,
    perItemTime:30,
    addOnServices:17
  },{
    label:"Deep (Every Month)",
    value:"deep_3",
    type: "deep",
    cost: 151,
    minDuratin: 90,
    perItemCost:34,
    perItemTime:30,
    addOnServices:17
  },{
    label:"Move in/out (Just this once)",
    value:"move_in_out_1",
    type: "moveinout",
    cost: 168,
    minDuratin: 120,
    perItemCost:68,
    perItemTime:30,
    addOnServices:17
  },{
    label:"Move in/out (Every 2 Weeks)",
    value:"move_in_out_2",
    type: "moveinout",
    cost: 168,
    minDuratin: 120,
    perItemCost:68,
    perItemTime:30,
    addOnServices:17
  },{
    label:"Move in/out (Every Month)",
    value:"move_in_out_3",
    type: "moveinout",
    cost: 168,
    minDuratin: 120,
    perItemCost:68,
    perItemTime:30,
    addOnServices:17
  },{
    label:"Quick (Just this once)",
    value:"quick_1",
    type: "quick",
    cost: 49,
    minDuratin: 60,
    perItemCost:17,
    perItemTime:30,
    addOnServices:17
  }
];

export const commercialCleaningOptions = [
  {
    label:"Standard (Just this once)",
    value:"standard_1",
    type: "standard",
    cost: 120,
    minDuratin: 60,
    perItemCost:17,
    perItemTime:30,
    perSquareFootageRangeCost:30,
    perWindowsRangeCost:15

  },{
    label:"Standard (Every Week)",
    value:"standard_2",
    type: "standard",
    cost: 120,
    minDuratin: 60,
    perItemCost:17,
    perItemTime:30,
    perSquareFootageRangeCost:30,
    perWindowsRangeCost:15
  },{
    label:"Standard (Every 2 Weeks)",
    value:"standard_3",
    type: "Standard",
    cost: 120,
    minDuratin: 60,
    perItemCost:17,
    perItemTime:30,
    perSquareFootageRangeCost:30,
    perWindowsRangeCost:15
  },{
    label:"Standard (Every Month)",
    value:"standard_4",
    type: "standard",
    cost: 120,
    minDuratin: 60,
    perItemCost:17,
    perItemTime:30,
    perSquareFootageRangeCost:30,
    perWindowsRangeCost:15
  },{
    label:"Deep (Just this once)",
    value:"deep_1",
    type: "deep",
    cost: 151,
    minDuratin: 90,
    perItemCost:34,
    perItemTime:30,
    perSquareFootageRangeCost:30,
    perWindowsRangeCost:15
  },{
    label:"Deep (Every 2 Weeks)",
    value:"deep_2",
    type: "deep",
    cost: 151,
    minDuratin: 90,
    perItemCost:34,
    perItemTime:30,
    perSquareFootageRangeCost:30,
    perWindowsRangeCost:15
  },{
    label:"Deep (Every Month)",
    value:"deep_3",
    type: "deep",
    cost: 151,
    minDuratin: 90,
    perItemCost:34,
    perItemTime:30,
    addOnServices:17,
    perSquareFootageRangeCost:30,
    perWindowsRangeCost:15
  },{
    label:"Move in/out (Just this once)",
    value:"move_in_out_1",
    type: "moveinout",
    cost: 168,
    minDuratin: 120,
    perItemCost:68,
    perItemTime:30,
    addOnServices:17,
    perSquareFootageRangeCost:30,
    perWindowsRangeCost:15
  },{
    label:"Move in/out (Every 2 Weeks)",
    value:"move_in_out_2",
    type: "moveinout",
    cost: 168,
    minDuratin: 120,
    perItemCost:68,
    perItemTime:30,
    addOnServices:17,
    perSquareFootageRangeCost:30,
    perWindowsRangeCost:15
  },{
    label:"Move in/out (Every Month)",
    value:"move_in_out_3",
    type: "moveinout",
    cost: 168,
    minDuratin: 120,
    perItemCost:68,
    perItemTime:30,
    addOnServices:17,
    perSquareFootageRangeCost:30,
    perWindowsRangeCost:15
  },{
    label:"Quick (Just this once)",
    value:"quick_1",
    type: "quick",
    cost: 49,
    minDuratin: 60,
    perItemCost:17,
    perItemTime:30,
    addOnServices:17,
    perSquareFootageRangeCost:30,
    perWindowsRangeCost:15
  }
];

export const addOnServices = [
  {

    label:"Blinds",
    name:"blinds",
    quantity:1,
    value: 'blinds'
  },{
    label:"Fridge",
    name:"fridge",
    quantity:1,
    value: 'fridge'
  },{
    label:"Oven",
    name:"oven",
    quantity:1,
    value: 'oven'
  },{
    label:"Walls",
    name:"walls",
    quantity:1,
    value: 'walls'
  },{
    label:"Windows",
    name:"windows",
    quantity:1,
    value: 'windows'
  },{
    label:"Eco Friendly",
    name:"ecoFriendly",
    quantity:1,
    value: 'Eco Friendly'
  }
];

export const addOnServicesCommercial = [
  {
    name:"add_on_windows",
    label:"Windows",
  }
];

export const commercialPropertyTypes = [
  {
    label:"Office",
    value:"office"
  },
  {
    label:"Restaurant",
    value:"restaurant"
  },
  {
    label:"Retail",
    value:"retail"
  },
  {
    label:"Medical Facility",
    value:"medical_facility"
  },
  {
    label:"School",
    value:"school"
  },
  {
    label:"A place of worship",
    value:"place_of_worship"
  }
];

export const commercialPropertyOptions = [
  {
    id:"property-area-1500",
    label:"Upto to 1500 Square Feet",
    value:1500
  },
  {
    id:"property-area-3000",
    label:"Upto to 3000 Square Feet",
    value:3000
  },
  {
    id:"property-area-4500",
    label:"Upto to 4500 Square Feet",
    value:4500
  },
  {
    id:"property-area-6000",
    label:"Upto to 6000 Square Feet",
    value:6000
  },
  {
    id:"property-area-7500",
    label:"Upto to 7500 Square Feet",
    value:7500
  },
  {
    id:"property-area-9000",
    label:"Upto to 9000 Square Feet",
    value:9000
  }
];

export const domesticPropertyOptions = [
  {
    id:"property-area-1500",
    label:"Upto to 1500 Square Feet",
    value:1500
  },
  {
    id:"property-area-3000",
    label:"Upto to 3000 Square Feet",
    value:3000
  },
  {
    id:"property-area-4500",
    label:"Upto to 4500 Square Feet",
    value:4500
  },
  {
    id:"property-area-6000",
    label:"Upto to 6000 Square Feet",
    value:6000
  },
  {
    id:"property-area-7500",
    label:"Upto to 7500 Square Feet",
    value:7500
  },
  {
    id:"property-area-9000",
    label:"Upto to 9000 Square Feet",
    value:9000
  }
];

export const addOnServicesWindows = [
  {
    name:"windows",
    nameLabel:"windows",
    planType:1,
    valueName:"windowsRange",
    label:"1-5",
    value:"1-5",
    isCommercial:true
  },
  {
    name:"windows",
    nameLabel:"windows",
    valueName:"windowsRange",
    planType:2,
    label:"6-10",
    value:"6-10",
    isCommercial: true
  },
  {
    name:"windows",
    nameLabel:"windows",
    planType:3,
    valueName:"windowsRange",
    label:"11-15",
    value:"11-15",
    isCommercial: true
  },
  {
    name:"windows",
    planType: 4,
    nameLabel:"windows",
    valueName:"windowsRange",
    label:"16-20",
    value:"16-20",
    isCommercial: true
  }
];

export const domesticOnlyServices = [
  {
    label:"Bedrooms",
    name:"bedrooms"
  },
  {
    label:"Bathrooms",
    name:"bathrooms"
  }
];