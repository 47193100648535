import React from "react";

import TrackContainer from "containers/TrackContainer";
import { MainLayout } from "layouts";

const CalenderPage = (props) => (
  <MainLayout {...props}>
    <TrackContainer {...props} />
  </MainLayout>
)

export default CalenderPage;