import React, { Component } from "react";
import ReactEchartsCore from "echarts-for-react/lib/core";
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";


const options = {
  tooltip: {
      trigger: 'axis',
      axisPointer: {
          type: 'cross',
          crossStyle: {
              color: '#fff'
          }
      }
  },
  toolbox: {
      feature: {
          dataView: {show: true, readOnly: false},
          magicType: {show: true, type: ['line', 'bar']},
          restore: {show: true},
          saveAsImage: {show: true}
      }
  },
  legend: {
      data:[]
  },
  xAxis: [
      {
          type: 'category',
          data: [],
          axisPointer: {
              type: 'shadow'
          }
      }
  ],
  yAxis: [
      {
          type: 'value',
          min: 0,
          max: 250,
          interval: 50,
          axisLabel: {
              formatter: '{value} ml'
          }
      },
      {
       
      }
  ],
  series: [
      {
          
          type:'bar',
          data:[2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3]
      },
      {
          type:'line',
          yAxisIndex: 1,
          data:[2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
      }
  ]
};
class EChart extends Component {
  render() {
    return (
      <div>
        <ReactEchartsCore
          echarts={echarts}
          option={options}
          notMerge={true}
          lazyUpdate={true}
        //   theme={"theme_name"}
        //   onChartReady={this.onChartReadyCallback}
        //   onEvents={EventsDict}
          opts={{}} 
          />
      </div>
    );
  }
}

export default EChart;
