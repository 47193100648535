import React from "react";
import Dialog from "core/components/Dialog";
import Slide from "core/components/Slide";
import AccountForm from "../AccountForm";
import { withStyles } from "core/components/Styles";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" {...props} ref={ref} />);

const styles = theme => ({
  paper: {
    maxWidth: 957
  }
})
const AccountFormDialog = props => {
  const { classes, isOpen, handleClose, onPaymentDetailsAdded } = props;
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth
      maxWidth={false}
      classes={{
        paper: classes.paper
      }}
    >
      <AccountForm handleClose={handleClose} onPaymentDetailsAdded={onPaymentDetailsAdded}/>
    </Dialog>
  )
}

export default withStyles(styles)(AccountFormDialog);