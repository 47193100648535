import React from "react";
import { connect } from "react-redux";
import Dialog from "core/components/Dialog";
import DialogActions from "core/components/DialogActions";
import DialogContent from "core/components/DialogContent";
import DialogTitle from "core/components/DialogTitle";
import Grid from "core/components/Grid";
// import Button from "core/components/Button";
import {  
  AdvanceSelectCompanyProfile, 
  FormInputField,
  MaterialTextField, 
} from "../FormField";
import Loader from "../ProgressCircle"; 
import { required, matchPasswords, number, minLength, maxLength, email } from "../FormField/Validations";
import TimePicker from "../TimePicker";
import { Field, Form } from "redux-form";
import { withStyles } from "core/components/Styles";
import ZButton from "core/components/Button";
import ZTypography from "core/components/Typography";
import IconButton from "core/components/IconButton";
import { Pencil } from "mdi-material-ui";
import cn from "classnames";
import { isEmpty } from "lodash";
import { capitalizeAllFirstLetters } from "../../utils/capitalize";

const weekDaysOptions = [
  {
    label: "Monday",
    value: "monday"
  },
  {
    label: "Tuesday",
    value: "tuesday"
  },
  {
    label:"Wednesday",
    value:"wednesday"
  },
  {
   label:"Thursday",
   value:"thursday"
  },
  {
    label:"Friday",
    value:"friday"
  },
  {
    label:"Saturday",
    value:"saturday"
  },
  {
    label:"Sunday",
    value:"sunday"
  }
]

const minLength_10 = minLength(10);
const maxLength_10 = maxLength(10);

const styles = theme => ({
  textCenter: {
    textAlign: "center"
  },
  inputSelect:{
    paddingTop: 0,
		"& [class*=fieldWrapper]":{
      height: 46,
			"& > span":{
				right: 0,
				color: "#c4c4c4",
        "& img":{
          width: "1.3rem",
          height: "1.3rem",
          opacity: 0.7
        }
			},
			"& > input":{
        color: "#646464",
        fontWeight: 500,
        lineHeight: "21px",
				fontSize: 14,
				height: 46,
				border: "solid 1px #d8d8d8",
				background: "#ffffff",
				padding: "5px 18px", //"20px 56px 20px 20px",
				"&::placeholder ":{
					color: "#b7b7b7"
				}
			}
		},
  },
  cleaningTypeHeading: {
    marginRight: 0,
    marginTop: 0,
    marginBottom: 4,
    fontSize: 12,
    color: "#929292",
    lineHeight: "18px"
  },
  inputMarginTop_10:{
    marginTop: 10
  },	
  imageFrame:{
    border: "3px dotted #686868",
    height: "14.28rem",
    width: "14.28rem",
    margin: ".9rem 0 1rem 0",
    borderRadius: "8%"   
  },
  profileHolder:{
    position: "relative"
  },
	addImageText:{
    display: "inline",
    color: "#686868",
    fontSize: "1.28rem",
    textTransform: "uppercase",
    border: "1px dashed #aaa",
    padding: ".35rem 1.07rem",
    borderRadius: ".35rem",
  },
  editImageBtn:{
    position: "absolute",
    right: "0px",
    bottom: "10px",
    height: 30,
    width: 30,
    boxShadow: "4px 7px 14px 0 rgba(59, 39, 95, 0.21)",
    background:"#fff",
    borderRadius: "50%",
    "&:hover":{
      background:"#fff"
		}
	},
	pencilIcon:{
    color: "#fd9b5b",
    fontSize: 14
  },
  profileStyle: {
    height: "14.28rem",
    width: "14.28rem"
  }
});

const AddEmployeeModal = ({
  classes,
  editEmployee,
  isModalOpen,
  handleModal,
  handleSubmit,
  cancelForm,
  submitData,
  handleSave,
  initialValues,
  showDialogLoader,
  onPhotoUpload,
  formValues
}) => {
  const fullName = !isEmpty(formValues) ? capitalizeAllFirstLetters(`${formValues.firstName} ${formValues.lastName}`) : undefined;
  const profileImage = (formValues && formValues.profileImage) ? formValues.profileImage :  undefined;
   
  return (
    <Dialog
      open={isModalOpen}
      onClose={handleModal}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <Form onSubmit={handleSubmit(submitData(editEmployee ? "update" : "add"))}>
        <DialogTitle id="form-dialog-title" className={classes.textCenter}>
          <ZTypography color="secondary" gradient bold fontSize={25}>
            {editEmployee ? "Update Employee" : "Add Employee" } 
          </ZTypography>
        </DialogTitle>
        <DialogContent>

          {showDialogLoader &&  <Loader zIndex={999} position="fixed" />}
        
          <Grid container justify="center" spacing={4}>
            <Grid item onClick={onPhotoUpload} className={cn({ 
              [classes.imageFrame]: !profileImage, 
              [classes.profileHolder]: profileImage, 
            })} xs={3}>
              { 
                profileImage 	
                  ? <img src={`${process.env.REACT_APP_USER_FILES}${profileImage}`} className={classes.profileStyle} alt={ fullName } />
                  : <ZTypography classes={{ root: classes.addImageText }}>
                    + Click to Add Picture
                  </ZTypography>
              }    
              { editEmployee &&
                <IconButton size="small" className={classes.editImageBtn}>
                  <Pencil className={classes.pencilIcon}/>
                </IconButton>
              }
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}> 
                <FormInputField label="First Name"
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="Enter First Name"
                  component={MaterialTextField}
                  validate={[required]}
                  InputProps={{disableUnderline :true}}
                  setBorder  
                />
              </Grid>
              <Grid item xs={12} md={4}> 
                <FormInputField label="Last Name"
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter Last Name"
                  component={MaterialTextField}
                  validate={[required]}
                  InputProps={{disableUnderline :true}}
                  setBorder  
                />
              </Grid>
              <Grid item xs={12} md={4}> 
                <FormInputField label="Phone" 
                  id="phone"
                  name="phone"
                  placeholder="Enter Phone Number"
                  component={MaterialTextField}
                  validate={[required,number,minLength_10,maxLength_10]}
                  InputProps={{disableUnderline :true}}
                  setBorder  
                />
              </Grid>
              <Grid item xs={12} md={4}>  
                <FormInputField label="Email"
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter Email Address"
                  component={MaterialTextField}
                  validate={[required,email]}
                  InputProps={{disableUnderline :true}}
                  setBorder  
                />
              </Grid>
              <Grid item xs={12} md={4}> 
                <FormInputField label="Password"
                  name="password"
                  type="password"
                  id="password"
                  placeholder="Enter Password"
                  component={MaterialTextField}
                  validate={ editEmployee ? null : [required]}
                  InputProps={{disableUnderline :true}}
                  setBorder  
                />
              </Grid>
              <Grid item xs={12} md={4}> 
                <FormInputField label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirm Your Password"
                  component={MaterialTextField}
                  validate={!editEmployee ? [required, matchPasswords ] : [matchPasswords]}
                  InputProps={{disableUnderline :true}}
                  setBorder  
                /> 
              </Grid>
              <Grid item xs={12} md={12}>
                <FormInputField 
                  isMulti={true}
                  close={false}
                  label="Week Days"
                  id="workDays"
                  name="workDays"
                  component={AdvanceSelectCompanyProfile}
                  options={weekDaysOptions}
                  backgroundColor="transparent"
                  validate={[required]}
                  material
                  InputProps={{disableUnderline :true}}
                  closeMenuOnSelect={false} // To keep the select menu open
                  menuPlacement="top" // To open the list to top direction
                />
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.inputMarginTop_10}
              justify="space-between"
              spacing={3}
            >
              <Grid item xs={12} md={6} className={classes.inputSelect}>
                <p className={classes.cleaningTypeHeading}>
                  Job Shift Start Time
                </p>
                <Field
                  name="jobShiftStartTime"
                  component={TimePicker}
                  validate={[required]}
                />   
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputSelect}>
                <p className={classes.cleaningTypeHeading}>
                  Job Shift End Time
                </p>
                <Field
                  name="jobShiftEndTime"
                  component={TimePicker}
                  validate={[required]}
                />  
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ZButton type="submit" color="primary" gradient>
            {editEmployee ? "Update  Employee " : "Add Employee"} 
          </ZButton>
          <ZButton onClick={cancelForm}>
            Cancel
          </ZButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};


const mapStateToProps = (state) => ({
  showDialogLoader: state.app.showDialogLoader
})
const WithStylesAddEmployeeModal = withStyles(styles)(AddEmployeeModal)
export default connect(mapStateToProps)(WithStylesAddEmployeeModal) ;
