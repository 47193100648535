import React from "react";
import { Switch, Redirect } from "react-router-dom";
import PropsRoute from "./PropsRoute";
// Routes Components

// import SignupScene from "../containers/SignupContainer";
import Dashboard from "Pages/DashboardPage";
import JobsScene from "../Pages/JobsPage";
import EmployeeScene from "../Pages/EmployeesPage";
import InvoiceScene from "../containers/InvoiceContainer";
import MainScene from "../containers/MainPageContainer";
import LoginScene from "../containers/LoginContainer";
import EarningsPage from "../containers/EarningsContainer";
import CustomersPage from "Pages/CustomersPage";
import SettingsPage from "Pages/SettingsPage";
import TrackPage from "Pages/TrackPage";
import CalenderPage from "../Pages/CalenderPage";
import AddInvoicePage from '../Pages/AddInvoicePage';
import ListInvoicePage from '../Pages/ListInvoicePage';
import EditInvoicePage from '../Pages/EditInvoicePage';

const JobsSceneFC = (props)=> <JobsScene {...props}/>;
const InvoiceSceneFC = (props)=> <InvoiceScene {...props}/>;

const Routes = props => (
  <Switch>
    <PropsRoute path="/" component={MainScene} exact {...props}/>
    <PropsRoute path="/customers" component={CustomersPage} {...props} />
    <PropsRoute path="/earnings" component={EarningsPage} {...props} />
    <PropsRoute path="/dashboard" component={Dashboard} {...props}  />
    <PropsRoute path="/calender" component={CalenderPage} {...props} />
    <PropsRoute path="/jobs" component={JobsSceneFC} {...props} />
    <PropsRoute path="/employee" component={EmployeeScene} {...props} />
    <PropsRoute path="/invoice" component={InvoiceSceneFC} {...props} />
    <PropsRoute path='/login' component={LoginScene} {...props}/>
    {/* <PropsRoute path="/signup" component={SignupScene} {...props} /> */}
    <PropsRoute path="/settings" component={SettingsPage} {...props} />
    <PropsRoute path="/track" component={TrackPage} {...props} />

    <PropsRoute path="/invoices" exact component={ListInvoicePage} {...props} />
    <PropsRoute path="/invoices/add" exact component={AddInvoicePage} {...props} />
    <PropsRoute path="/invoices/:id" exact component={EditInvoicePage} {...props} />

    <Redirect to="/" />
  </Switch>
)
export default Routes;
