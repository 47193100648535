import React from "react";

import EmployeeDashboardContainer from "containers/EmployeeDashboardContainer";
import { MainLayout } from "layouts";

const EmployeePage = (props) => (
  <MainLayout {...props}>
    <EmployeeDashboardContainer {...props} />
  </MainLayout>
)

export default EmployeePage;