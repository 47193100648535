import React from 'react';
import { MainLayout } from "layouts";
import InvoiceFormContainer from '../../containers/InvoiceFormContainer';
const AddInvoicePage = (props) => {
  return (
    <MainLayout {...props}>
      <InvoiceFormContainer {...props} />
    </MainLayout>
  )
}


export default AddInvoicePage;