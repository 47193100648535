import React from "react";
import cn from "classnames";
import ReactMapboxGl, { Marker, Popup } from "react-mapbox-gl";
import Avatar from "core/components/Avatar";
import { withStyles } from "core/components/Styles";
import {Hidden} from "core/components";
import employeePlaceholder from "../../images/utils/employee-placeholder.png";
import EmployeeInfo from "./EmployeeInfo";
import { drawerWidth } from "../../theme/vars";
import ActiveEmployeeList from "./ActiveEmployeeList";

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAP_BOX_TOKEN
});

const styles = () => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex"
  },
  marker:{
    cursor:"pointer",
    width:50,
    height:50,
    background:"#986BD2",
    padding:5,
    borderRadius:"50%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  markerImage:{
    // height:"auto",
    // witdh:"auto"
    // height: 50,
    // width: 50,
    // borderRadius: "50%"
  },
  popup:{
    display:"none"
  },
  activePop:{
    display:"flex"
  },
  avatar:{
    height:40,
    width:40,
    background:"#fff"
  },
  popupDetails:{
    display:"flex"
  },
  trackWrapper:{
    // padding: 10,
    width:"100%",
    position:"relative"
  }
})









const Track  = (props) => {

   const { 
     classes, 
     center, 
     onEmpClick, 
     closePopup, 
     openPopup,
     popupCoords, 
     popupRef, 
     mapRef, 
     mapOffset,
     employeeLocations,
     currEmployee,
     onLoad,
     onDragEnd,
     currEmpId
    } = props;

    return (
      <div className={classes.root}>
        <div className={classes.trackWrapper} ref={mapRef} >
          <Map
            // eslint-disable-next-line
            style="mapbox://styles/zoklean/cjtju0l7700cj1fqb3m3wtii3"
            containerStyle={{
              width: window.innerWidth > 767 ? window.innerWidth - drawerWidth : window.innerWidth,
              height: window.innerHeight - mapOffset
            }}
            center={center}
            onClick={closePopup}
            // onRender={onReady}
            onStyleLoad={onLoad}
            onDragEnd={onDragEnd}
          >
              {
                employeeLocations.length > 0 && employeeLocations.map((e, i) => {
                  const { fname, lname, profileAvator } = e;
                  const profileLink = profileAvator ? `${process.env.REACT_APP_USER_FILES}${profileAvator}`
                    : employeePlaceholder
                  return (
                    <Marker
                      onClick={onEmpClick(e)}
                      coordinates={e.location}
                      anchor="bottom"
                      key={`empoloyee-loca-${i}`}
                      className={cn(classes.marker,"pulseIcon")}
                    >

                      <Avatar
                        className={cn(classes.avatar,"profile-btn-avatar img-fluid mr-2")}
                        image={profileLink}
                        name={`${fname} ${lname}`}
                        disableBorder
                      />

                    </Marker>
                  )
                })
              }

            <Popup
              coordinates={popupCoords}
              offset={{
                'bottom-left': [12, -60],  'bottom': [0, -60], 'bottom-right': [-12, -60]
              }}
              className={cn(classes.popup, openPopup ? classes.activePop : null)}
              tabIndex={-1}
              ref={popupRef}
              >
              {
                currEmployee ? <EmployeeInfo employee={currEmployee} /> : null
              }
            </Popup>
          </Map>


          {
            employeeLocations && employeeLocations.length > 0 &&
            <Hidden xsDown>
              <ActiveEmployeeList 
                employeeList={employeeLocations}
                onSelect={onEmpClick}
                currEmpId={currEmpId}
              />
            </Hidden>
          }
        </div>

    </div>
    )
 }



export default withStyles(styles)(Track);