import React from "react";
import FormControl from "core/components/FormControl";
import Grid from "core/components/Grid";
import Select from "core/components/Select";
import MenuItem from "core/components/MenuItem";
import InputLabel from "core/components/InputLabel";
import {withStyles} from "core/components/Styles"

const styles = {
  error: {
    color: "red",
    fontSize: 12,
  },
  warning: {
    color: "orange",
    fontSize: 12
  }
}


const MaterialSelectField = ({
  classes,
  label,
  input,
  options,
  name,
  meta
}) => {
  const onChange = item => {
    input.onChange(item);
  };
  return (
    <Grid>
      <FormControl fullWidth>
        <InputLabel htmlFor="age-simple">{label}</InputLabel>
        <Select
          fullWidth
          onChange={onChange}
          inputProps={{
            name: name,
          }}
          name={name}
          value={input.value}
        >
        {options && options.map((option, i)=> <MenuItem key={i} value={option.value}>{option.label}</MenuItem>)}
        </Select>
      </FormControl>

      {meta.touched &&
        ((meta.error && <span className={classes.error}>{meta.error}</span>) ||
          (meta.warning && <span className={classes.warning}>{meta.warning}</span>))}
    </Grid>
  );
};



export default withStyles(styles)(MaterialSelectField);