import React from 'react'
import { Grid, Card, CardContent } from "core/components";
import { withStyles } from "core/components/Styles";
import PlanChangeForm from "../PlanChangeForm";


import Joyride from "react-joyride";
import { paymentPlanSteps } from "../TourGuide/TourGuideSteps";

import {tooltipButtonColor} from "../../theme/colors";

const styles = () => ({
});

const Plan = ({
  classes,

  handleJoyrideCallback,
  getHelpers,
  runGuide

}) => {
  return (
    <Grid
    container
    alignItems="center"
    justify="center"
    className={classes.root}
    >
      <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={runGuide}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={paymentPlanSteps}
        styles={{
          options: {
            primaryColor: tooltipButtonColor  
          }
        }}
      />
      <Grid item xs={12}>
        <Card className={classes.card} elevation={1}>
          <CardContent id="choosePlan">
            <PlanChangeForm/>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Plan);
