import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "utils/http";
import { setMsgAndShow } from "../store/actions/popup";
import _ from "lodash";
import moment from "moment-timezone";

import Earnings from "../components/Earnings"

class EarningsContainer extends Component {
  state = {
    ordersEarningList:[],
    totalsObjArray:[],
    startDate:"",
    endDate: ""
  }

  componentDidUpdate(prevProps, nextProps) {
    if(JSON.stringify(prevProps.contractorInfo) !== JSON.stringify(this.props.contractorInfo)){
      this.addData();
    }
  }

 componentDidMount() {
    if(!_.isEmpty(this.props.contractorInfo.businessDetails)){
      this.addData();
    }
  }

  addData = async () => {
    const now = moment();
    const totalsObjArray = [];
    const contractorId = this.props.contractorInfo.businessDetails._id
    const startDate = now.startOf('isoWeek').format("YYYY-MM-DD")
    const endDate = now.endOf("isoWeek").format("YYYY-MM-DD")
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_earnings`,{
          params:{
            contractorId,
            startDate,
            endDate,
          }
        }
      );

      const { ordersEarningList, totalsObj } = data;
        
      totalsObjArray.push(totalsObj)
      this.setState({
        ordersEarningList,
        totalsObjArray,
        startDate,
        endDate
      })
    } catch (error) {
      console.error(error); // eslint-disable-line
      this.props.setMsgAndShow(error.response.data.msg);
    }
  }
  handleDateChange = (dateType) => (e) => {
    const state = {};
    state[dateType] = e.target.value;
    this.setState({
      ...state
    })
  }
  getData = async () => {
    const { startDate, endDate } = this.state;
    const totalsObjArray = [];
    const contractorId = this.props.contractorInfo.businessDetails._id
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_earnings`,{
          params:{
            contractorId,
            startDate,
            endDate,
          }
        }
      );

      const { ordersEarningList, totalsObj } = data;
      totalsObjArray.push(totalsObj)
      this.setState({
        ordersEarningList,
        totalsObjArray,
        startDate,
        endDate
      })
      // this.props.setMsgAndShow("Received Data")
    } catch (error) {
      this.props.setMsgAndShow(error.response.data.msg);
    }
  }
  render(){
    return (
      <Earnings 
        handleDateChange={this.handleDateChange}
        getData={this.getData}
        {...this.state} 
      />
    )
  }
}


const mapStateToProps = state => ({
  contractorInfo:state.contractor
});

const mapDispatchToProps = dispatch => ({
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg))
})

export default connect(mapStateToProps, mapDispatchToProps)(EarningsContainer);