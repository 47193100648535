import React, { PureComponent } from 'react'
import GetOTPModal from "./GetOTPModal";
import { connect } from "react-redux";
import { setMsgAndShow } from "../../store/actions/popup";
import isNumberKey from "../../utils/isNumberKey";
import axios from "../../utils/http"

class GetOTPModalContainer extends PureComponent {

  state = {
    code:"",
    showLoader:false,
    errorMsg:""
  }


  handleValueChange = (e) => {
    const val = e.target.value;
    const filteredVal = val && val.replace(/\D/g, '');

    if(filteredVal || filteredVal === ""){
      this.setState({
        code:filteredVal
      })
    }
  }

  closeModal = () => {
    this.props.closeModal();
    this.setState({
      code:"",
      errorMsg:""
    })
  }

  submitRequest = async () => {
    let popupMsg = "An error occurred while marking the job as complete";
    try {
      const val = this.state.code;
      const filteredVal = val && val.replace(/\D/g, '');

      if(!filteredVal){
        this.setState({
          errorMsg:"Required!"
        })
        return;
      }

      if(filteredVal.length < 4){
        this.setState({
          errorMsg:"OTP should be of 4 digits!"
        })
        return;
      }
      this.setState({
        showLoader:true,
      })
      const { 
        url,
        jobId,
        action,
        onCallSuccess,
      } = this.props;

      const { data } = await axios.post(`${url}${action}/${jobId}`,{
        code:this.state.code,
      }); 
      if(data && data.status === "OK"){
        if(onCallSuccess){
          onCallSuccess(data.job)
        }
        popupMsg =  data.message;
      }

      this.setState({
        showLoader:false,
      })
      this.closeModal();
      this.props.setMsgAndShow(popupMsg);
    } catch (error) {

      const { onCallFailure } = this.props;

      if(onCallFailure){
        onCallFailure(error);
      }
      const { response } = error;
      popupMsg = response && response.data ? response.data.message : popupMsg;
      this.setState({
        showLoader: false,
      })
      this.props.setMsgAndShow(popupMsg);
    }


  }


  onKeyDown = (e) => {
    const { errorMsg } = this.state;
    if((errorMsg !== "") && isNumberKey(e)){
      this.setState({
        errorMsg:""
      })
    }
  }
  render() {
    return (
      <GetOTPModal 
        {...this.state}
        {...this.props}
        closeModal={this.closeModal}
        handleValueChange={this.handleValueChange}
        submitRequest={this.submitRequest}
        onKeyDown={this.onKeyDown}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
})
export default connect(undefined,mapDispatchToProps)(GetOTPModalContainer);
