import { 
  SET_DETAILS, 
  SET_USER_INFO, 
  SET_ORDER_HISTORY, 
  UPDATE_USER_INFO,
  UPDATE_DETAILS,
  UPDATE_INVOICE_LOGO
} from "../types";
import _ from "lodash";

const defaultState = {
  userInfo:{},
  businessDetails:{},
  orderHistory:[]
}



const reducer = (state = defaultState, action) => {
    let finalState = {}

    switch(action.type){
      case SET_DETAILS:
        finalState = _.cloneDeep(state)
        finalState.userInfo = _.cloneDeep(action.payload[0])
        finalState.businessDetails = _.cloneDeep(action.payload[1])
        finalState.orderHistory = _.cloneDeep(action.payload[2])
        break;
      case SET_USER_INFO:
        finalState = _.cloneDeep(state)
        finalState.userInfo = _.cloneDeep(action.payload);
        break;
      case UPDATE_USER_INFO:
        finalState = _.cloneDeep(state);
        finalState.userInfo = {
          ...finalState.userInfo,
          ...action.payload
        }
        break;
      case UPDATE_DETAILS:
        finalState = _.cloneDeep(state);
        const updates = action.payload;
        for(const key in updates){
          finalState[key] = _.cloneDeep(updates[key])
        }
        break;
      case SET_ORDER_HISTORY:
        finalState = _.cloneDeep(state);
        finalState.orderHistory = _.cloneDeep(action.payload);
      break;
      case UPDATE_INVOICE_LOGO:
        finalState = _.cloneDeep(state);
        finalState.businessDetails.logo = action.payload;
        break;
      default: 
      finalState = state
    }

    return finalState
}



export default reducer;