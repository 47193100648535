function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 16.5,
    height: 16.5,
    viewBox: "0 0 16.5 16.5"
  }, props), React.createElement("g", {
    id: "prefix__iconfinder_Location_09_1530085",
    transform: "translate(-19.294 -18.507)"
  }, React.createElement("path", {
    id: "prefix__Rectangle_1",
    d: "M0 0H0.976V1.87H0z",
    className: "prefix__cls-1",
    "data-name": "Rectangle 1",
    transform: "translate(22.84 26.102)",
    style: {
      "fill": "#fff",
      "stroke": "#fff",
      "strokeWidth": ".5px"
    }
  }), React.createElement("path", {
    id: "prefix__Rectangle_2",
    d: "M0 0H0.976V1.87H0z",
    className: "prefix__cls-1",
    "data-name": "Rectangle 2",
    transform: "translate(22.84 29.842)",
    style: {
      "fill": "#fff",
      "stroke": "#fff",
      "strokeWidth": ".5px"
    }
  }), React.createElement("path", {
    id: "prefix__Rectangle_3",
    d: "M0 0H0.976V1.87H0z",
    className: "prefix__cls-1",
    "data-name": "Rectangle 3",
    transform: "translate(22.84 22.362)",
    style: {
      "fill": "#fff",
      "stroke": "#fff",
      "strokeWidth": ".5px"
    }
  }), React.createElement("path", {
    id: "prefix__Rectangle_4",
    d: "M0 0H0.976V1.87H0z",
    className: "prefix__cls-1",
    "data-name": "Rectangle 4",
    transform: "translate(25.836 29.842)",
    style: {
      "fill": "#fff",
      "stroke": "#fff",
      "strokeWidth": ".5px"
    }
  }), React.createElement("path", {
    id: "prefix__Rectangle_5",
    d: "M0 0H0.976V1.87H0z",
    className: "prefix__cls-1",
    "data-name": "Rectangle 5",
    transform: "translate(25.836 26.102)",
    style: {
      "fill": "#fff",
      "stroke": "#fff",
      "strokeWidth": ".5px"
    }
  }), React.createElement("path", {
    id: "prefix__Rectangle_6",
    d: "M0 0H0.976V1.87H0z",
    className: "prefix__cls-1",
    "data-name": "Rectangle 6",
    transform: "translate(25.836 22.362)",
    style: {
      "fill": "#fff",
      "stroke": "#fff",
      "strokeWidth": ".5px"
    }
  }), React.createElement("path", {
    id: "prefix__Rectangle_7",
    d: "M0 0H1.91V1.016H0z",
    className: "prefix__cls-1",
    "data-name": "Rectangle 7",
    transform: "translate(31.419 28.258)",
    style: {
      "fill": "#fff",
      "stroke": "#fff",
      "strokeWidth": ".5px"
    }
  }), React.createElement("path", {
    id: "prefix__Rectangle_8",
    d: "M0 0H1.91V1.016H0z",
    className: "prefix__cls-1",
    "data-name": "Rectangle 8",
    transform: "translate(31.419 30.751)",
    style: {
      "fill": "#fff",
      "stroke": "#fff",
      "strokeWidth": ".5px"
    }
  }), React.createElement("path", {
    id: "prefix__Path_9",
    d: "M30.109 34.757h3.5a1.976 1.976 0 0 0 1.933-2.011v-7.43h-5.433v-6.559H19.544v16h10.565zm4.458-8.426v6.415a.978.978 0 0 1-.957 1h-3.5v-7.417zm-14.047 7.41V19.773h8.612v13.968H20.52z",
    className: "prefix__cls-1",
    "data-name": "Path 9",
    style: {
      "fill": "#fff",
      "stroke": "#fff",
      "strokeWidth": ".5px"
    }
  })));
};

export default __webpack_public_path__ + "static/media/job_commercial_type.4bb8c397.svg";
export { SvgComponent as ReactComponent };