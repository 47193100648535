import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { reduxForm, clearSubmitErrors } from "redux-form";
import _ from "lodash";
import socket, { createRoom } from "socket"
import removeProp from "../utils/removeProp";
import Storage from "../utils/Storage";
import axios from 'utils/http';
import { capitalizeFirstLetterOnly } from "../utils/capitalize";
import { hideAlert } from "../store/actions/app";
import { setDetails } from "../store/actions/contractor";
import { setMsgAndShow } from "../store/actions/popup";
import { toggleBusinessModal, setFormData, closeBusinessModal } from "../store/actions/app";

import AddBusinessDetails, {
  BasicInfoForm,
  // PaymentDetails,
  ReviewInfo,
  AddServiceArea
} from "components/AddDetailsSettings";
import DropZone, { DropZoneDialog } from "../components/DropZone";
import states from "data/statesTitleCase.json";


import { withMixpanel } from "../utils/react-mixpanel";

const options = [
  {
    label: "Select a Type",
    value: ""
  },
  {
    label: "Commercial",
    value: "commercial"
  },
  {
    label: "Domestic",
    value: "domoestic"
  }
];


const stateObjOptions = states.map(state => {
  return {
    label: state.name,
    value: state.abbreviation
  };
});




// const makeObj = (data) => {
//   let responseObj = {};

//   if(!_.isEmpty(data)){
//       let { 
//         data:businessData, 
//         businessType, 
//         businessName, 
//         numberOfEmployees, 
//         businessWebsite, 
//         serviceArea,
//         businessAddress
//       } = data;

//       if(numberOfEmployees){
//         numberOfEmployees = {
//           label: numberOfEmployees,
//           value: numberOfEmployees
//         }
//       }



//     businessAddress.state = stateObjOptions.filter((s) => s.value === businessAddress.state)[0];

//     businessType = businessType.map((b) => ({
//       label: capitalizeFirstLetterOnly(b),
//       value: b
//     }))

//   responseObj = {
//       legalEntity: businessData.legalEntity,
//       businessType: businessType.length > 0 ? businessType : null,
//       businessName,
//       businessPhone: businessData.phone,
//       numberOfEmployees,
//       businessWebsite: businessWebsite ? businessWebsite : "",
//       businessRadius: serviceArea.radius,
//       businessAddress
//     }
//   }

//   return responseObj

// }
class AddBusinessDetailsContainer extends Component {
  state = {
    activeStep: 0,
    skipped: new Set(),
    load: false,
    isDropZoneOpen: false,
  };

  componentDidMount(){
    const { businessInfo, trackPageLoad } = this.props;
    
    if(!_.isEmpty(businessInfo)){
      this.setData(businessInfo)
    }
    
    trackPageLoad("Company_Profile_Settings");
  }


  componentDidUpdate(prevProps) {
    const { businessInfo: oldBusinessInfo }= prevProps;
    const { businessInfo } = this.props;
 
    if(JSON.stringify(oldBusinessInfo) !== JSON.stringify(businessInfo)){
      this.setData(businessInfo);
    }
  }

  componentWillUnmount(){
    this.props.setFormData(null);
  }

  setData = (businessInfo) => {
    const { setFormData } = this.props;
    
    let address; 
    let { 
        data:businessData, 
        businessType, 
        businessName, 
        numberOfEmployees, 
        businessWebsite, 
        serviceArea,
        businessAddress,
        businessEmail
      } = businessInfo;

      if(numberOfEmployees){
        numberOfEmployees = {
          label: numberOfEmployees,
          value: numberOfEmployees
        }
      }

    address = typeof businessAddress.state === "string" 
                ? businessAddress.state 
                : typeof businessAddress.state === "object" && businessAddress.state.value ;

    businessAddress.state = stateObjOptions.filter((s) => s.value === address)[0];

    if(Array.isArray(businessType)){
      businessType = businessType.map((b) => ({
        label: capitalizeFirstLetterOnly(b),
        value: b
      }))
    }

    
    setFormData({
      legalEntity: businessData && businessData.legalEntity ?  businessData.legalEntity : [],
      businessType: businessType && businessType.length > 0 ? businessType : null,
      businessName,
      businessPhone: businessData && businessData.phone,
      numberOfEmployees,
      businessWebsite: businessWebsite ? businessWebsite : "",
      businessRadius: serviceArea && serviceArea.radius,
      businessAddress,
      businessEmail: businessEmail ? businessEmail : ""
    })
  }


  isStepOptional = step => {
    return step === 1;
  };


  goToStart = () => {
    this.setState({
      activeStep: 0
    })
  }



  handleNext = () => {
    const { activeStep } = this.state;
    const steps = this.getSteps();

    if(!this.props.valid){
      this.props.submit("AddBusinessDetails")
      return;
    }

    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }

    if(activeStep === steps.length - 1){
      this.setState({
        activeStep: steps.length + 1
      })
      return;
    } 

    this.setState({
      activeStep: activeStep + 1,
      skipped
    });


  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1
    });
  };

  handleSkip = () => {
    const { activeStep } = this.state;
    this.setState(state => {
      const skipped = new Set(state.skipped.values());
      skipped.add(activeStep);
      return {
        activeStep: state.activeStep + 1,
        skipped
      };
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  isStepSkipped = (step) => {
    return this.state.skipped.has(step);
  }

  getSteps = () => {
    return ["Basic Info", "Service Details","Finish"];
  };
  
  setGeo = geo => {
    this.props.change('businessGeo', geo);
  };

  confirmData = async ()=> {

    const { 
      // closeBusinessDetailsModal,
      doNotCloseModal,
      trackApiEvent
    } = this.props;

    if(!this.props.valid){
      this.props.submit("AddBusinessDetails")
      return;
    }
    
    const formValues = _.cloneDeep(this.props.formValues);

    let { 
      // bankName,
      // bankAccountNumber,
      // beneficeryName,
      businessAddress,
      businessRadius,
      businessGeo,
      businessPhone,
      // bankRoutingNumber,
      legalEntity,
      businessType,
      numberOfEmployees
    } = formValues;


    businessType = businessType.map((t) => t.value);


    
    const newFormValues = removeProp(_.cloneDeep(formValues), [
      "bankName",
      "bankAccountNumber",
      "bankRoutingNumber",
      "beneficeryName",
      "accountType",
      "addressCity",
      "addressState",
      "addressStreet",
      "addressUnit",
      "addressZipcode",
      "businessRadius",
      "businessPhone",
      "businessGeo",
      "idType",
      "acceptTerms",
      "legalEntity",
      "businessType"
    ]);

    const businessDetailsObj = {
      ...newFormValues,
       businessAddress:{
        ...businessAddress,
        state: businessAddress.state.value
       },
       serviceArea: {
        radius: businessRadius,
        ...businessGeo
       },
       data:{
         phone: businessPhone,
         legalEntity,
       },
       businessType,
       numberOfEmployees: numberOfEmployees.value
    }

    
    try {

      this.setState({
        load: true
      })

     const { data } =  await axios.post(`${process.env.REACT_APP_API_PREFIX}contractor/add_details`,{...businessDetailsObj});


     if(data.status === "OK"){

      trackApiEvent("Add_Contractor_Details", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/add_details`, "OK", window.location.pathname);

      const { userInfo, businessDetails, orderHistory, message } = data;

      this.props.setMsgAndShow(message);
      this.props.setDetails(userInfo, businessDetails, orderHistory);
      Storage.set("contractorInfo",JSON.stringify({
        userInfo,
        businessDetails,
        orderHistory
      }))
      Storage.set("orderHistory", JSON.stringify(orderHistory));
      createRoom(businessDetails._id)
      socket.on('roomcreated', data => {
        console.info('room created', data, businessDetails._id); // eslint-disable-line
      })
      this.setState({
        load: false,
        isDropZoneOpen: true,
      })

      if(!doNotCloseModal){
        this.props.closeBusinessModal();
      }


      if(this.props.alert && this.props.alert.show ){
        this.props.hideAlert();
      }
      this.props.history.push("/dashboard");
      this.props.reset("AddBusinessDetails");
       
     } else {
      const { message } = data;
      
      trackApiEvent("Add_Contractor_Details", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/add_details`, message, window.location.pathname);

      this.props.setMsgAndShow(message ? message : "Error Occured");
      this.setState({
        load: false
      })
     }
    } catch (error) {
      const { response } = error;
      let message  = "Error Occured!";
      
      if(response && response.data){
        message = response.data.msg ? response.data.msg : "Error Occured!";
      }

      trackApiEvent("Add_Contractor_Details", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/add_details`, message, window.location.pathname);
      this.props.setMsgAndShow(message);

      this.setState({
        load: false
      });
    }
  }
  onSave = (obj, dispatch, props ) => {
  }

  handleDropZoneToggle = () => {
    const { closeBusinessDetailsModal } = this.props;
    this.setState({ isDropZoneOpen: !this.state.isDropZoneOpen });
    if(closeBusinessDetailsModal){
      closeBusinessDetailsModal()
    }
    // this.props.history.replace("/dashboard");
  };
  closeDropZone = () => {
    const { closeBusinessDetailsModal } = this.props;
    this.setState({ isDropZoneOpen: false });
    if(closeBusinessDetailsModal){
      closeBusinessDetailsModal()
    }
  };
  fileUploaded = res => {
    if (res && res.data) {
      this.props.setMsgAndShow("Upload was successful")
      this.closeDropZone();
    } else {
      this.closeDropZone();
    }
  };


  skipAll = () => {
    const { toggleBusinessModal, onBusinessModelClose } = this.props;

    if(onBusinessModelClose){
      onBusinessModelClose();
    } else {
      toggleBusinessModal();
    }
    
  }

  trackInput = (fieldname)=> (e)=> {
    const { trackInputOnBlur } = this.props;
    if(e.target.value){
      trackInputOnBlur(fieldname, e.target.value, window.location.pathname);
    }
  } 

  trackRadioButton = (fieldname)=> (e)=>{
    const { trackRadioInput } = this.props;
    const selectedValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    trackRadioInput(fieldname, "Company", selectedValue, window.location.pathname);
  }

  trackSelectedValue = (fieldname,value)=>  {
    const { trackInputOnBlur, trackMultiPickEvent } = this.props;
    if(value){
      if(Array.isArray(value)){
        if(value.length){
          const businessTypes = value.map(item => item.label);
          trackMultiPickEvent(fieldname, businessTypes, window.location.pathname);
        }
      }else{
        trackInputOnBlur(fieldname, value, window.location.pathname);
      }
    }
  }

  render() {
    const {formValues, userInfo, businessInfo } = this.props

    const serviceArea = {
      address: '',
      radius: 5 * 1609.344 // convert into miles
    }
    const detailsObj = { formValues, userInfo };

    if(formValues) {
      const { 
        businessAddress,
        businessRadius
      } = formValues;

      if(businessAddress && businessAddress.state){
        serviceArea.address = `${businessAddress.street}, ${businessAddress.city}, ${businessAddress.state.value}`
      }

      serviceArea.radius = (businessRadius ? businessRadius : 5) * 1609.344 // convert into miles
    }

    const stepsContent = [
      <BasicInfoForm 
        options={options} 
        openUploader={this.handleDropZoneToggle} 

        trackInput={this.trackInput}
        trackRadioInput={this.trackRadioButton}

        trackSelectedValue={this.trackSelectedValue}
      />,
      // <PaymentDetails formValues={this.props.formValues} />,
      <AddServiceArea 
        area={serviceArea} 
        setGeo={this.setGeo}
      />,
      <ReviewInfo 
        detailsObj={detailsObj} 
      />,
    ];


    const getStepContent = (step) => {
      return stepsContent[step];
    }





    return (
      <Fragment>
        <AddBusinessDetails
          handleSave={this.handleSave}
          getSteps={this.getSteps}
          isStepSkipped={this.isStepSkipped}
          options={options}
          handleNext={this.handleNext}
          handleBack={this.handleBack}
          getStepContent={getStepContent}
          confirmData={this.confirmData}
          onSave={this.onSave}
          changeIdType={this.changeIdType}
          skipAll={this.skipAll}
          {...this.state}
          {...this.props}
        />

        <DropZoneDialog
          isOpen={this.state.isDropZoneOpen}
          toggle={this.handleDropZoneToggle}
          title="Upload Business Logo"
        >
          <DropZone
            onUploaded={this.fileUploaded}
            onCancel={this.closeDropZone}
            url={`${process.env.REACT_APP_API_PREFIX}contractor/upload_image`}
            accept=".jpg,.png,.gif"
            formData={{
              businessId: businessInfo._id
            }}
          />
        </DropZoneDialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state)=>{
  let formValues = {};

  if(!_.isEmpty(state.form.AddBusinessDetails)){
    const { AddBusinessDetails } = state.form;
    if(!_.isEmpty(AddBusinessDetails.values)){
      formValues = AddBusinessDetails.values;
    }
  }

  return {
    formValues,
    userInfo: state.contractor.userInfo,
    businessInfo: state.contractor.businessDetails,
    initialValues: state.app.formData.data,
    alert:state.app.alert
  }
}


const mapDispatchToProps = (dispatch) => ({
  setDetails: (userInfo, businessDetails) => dispatch(setDetails(userInfo, businessDetails)),
  clearSubmitErrors,
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg)),
  toggleBusinessModal: () => dispatch(toggleBusinessModal()),
  setFormData: (payload) => dispatch(setFormData(payload)),
  closeBusinessModal: () => dispatch(closeBusinessModal()),
  hideAlert:() => dispatch(hideAlert())
});

const withReduxForm = reduxForm({
  form: "AddBusinessDetails",
  destroyOnUnmount: true,
  // initialValues:{
  //   idType:"businessId",
  //   accountType:"bank"
  // }
})(AddBusinessDetailsContainer);

const connectRedux = connect(mapStateToProps, mapDispatchToProps)(withReduxForm);

export default withMixpanel(connectRedux);

