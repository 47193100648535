import React from 'react'
import Image from "core/components/Image";
import { Grid } from "core/components";
import cn from "classnames";
import styles from "./plan.module.css";


const PremiumPlanItem = (props) => {
  const { changePlan, plan } = props;
  const isSelected = plan === "premium";
  return (
    <Grid>
      <Grid className={cn(styles["plan-card"],isSelected ? styles['plan-selected'] : "")}>
        <Grid className={styles["plan-card-header"]}>
          <Image src="assets/images/svg/premium_plan_icon.svg" alt="" />
          <p className="mt-1">premium</p>
        </Grid>


        <Grid 
          container
          direction="column"
          justify="space-between"
        >
          <Grid className={styles["plan-card-body"]}>
            <ul className={styles['margin-pad-0']}>
              <li className="mt-0">
                <Image src="assets/images/svg/red_circle_tick.svg" alt="" className="mr-2" />
                <span className={styles['ml-2']}>
                  $1 per 10 Jobs after 10 Jobs
                </span>
              </li>
              <li>
                <Image src="assets/images/svg/red_circle_tick.svg" alt="" className="mr-2" />
                <span className={styles['ml-2']}>
                  $1 per 10 Invoices after 10 Invoices
                </span>
              </li>
              <li>
                <Image src="assets/images/svg/red_circle_tick.svg" alt="" className="mr-2" />
                <span className={styles['ml-2']}>
                  $15 per Active Employee
                </span>
              </li>
              <li>
                <Image src="assets/images/svg/red_circle_tick.svg" alt="" className="mr-2" />
                <span className={styles['ml-2']}>
                  Also includes 90 days trail
                </span>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justify="space-between"
        >
          <Grid className={styles['plan-card-footer']}>
            <button type="button" className={cn(styles["bac-btn"], styles["plan-card-btn"])} onClick={changePlan("premium")}>
              {isSelected ? "Selected": "Select"}
            </button>

          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PremiumPlanItem
