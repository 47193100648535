import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import cn from "classnames";

const styles = theme => ({
  optionLabelStyle:{
    paddingTop: "3.57rem",
    fontSize: "1rem",
    color: "#6b6b6b"
  },
  bottomPadding:{
    paddingBottom: "2.78rem"
  }, 
  optionListContainer:{
    minHeight: "2.84rem",
    paddingBottom: "3.57rem"
  }
});

const Options = (props)=> {
  const { classes, children, optionLabel } = props;
  return(
    <Grid item>
      <div className={cn([classes.optionLabelStyle, classes.bottomPadding])}>
        {optionLabel}
      </div>
      <div className={classes.optionListContainer}>
        {children}
      </div>
    </Grid>
  )
}

export default withStyles(styles)(Options);