import React, { Component } from "react";
import Grid from "core/components/Grid";
import moment from "moment-timezone";
import { withStyles } from 'core/components/Styles';
import cn from "classnames";


const styles = theme => ({
  titleAndTime:{
    //fontFamily : "Poppins",
    fontSize: 8,
    //lineHeight: "16px",
    paddingLeft: 10,
    letterSpacing: "-0.165px"
  },
  eventAssigned:{
    //fontFamily: "Poppins",
    fontSize: 8,
    //lineHeight: "18px",
    paddingLeft: 5,
    letterSpacing: "-0.146667px"
  },
  greenColor:{
    color:"rgb(116, 186, 24)"
  },
  greenBullet: {
    backgroundColor:"rgb(116, 186, 24)"
  },
  blueBullet: {
    backgroundColor: "#346ff1"
  },
  bulletCaliber: {
    height: 6,
    width: 6,
    borderRadius: 18,
  }
  
  // timeRow:{
  //   marginTop: 2
  // },
  // time:{
  //   fontSize: 9,
  //   fontWeight: 500,
  //   fontStyle: "normal",
  //   fontStretch: "normal",
  //   lineHeight: 1,
  //   letterSpacing: -0.2,
  // } 
})
class Event extends Component {
  render(){
    const { event, classes } = this.props;
    return (
      <Grid container alignItems="center">
        <Grid item>
          <div className={cn(classes.bulletCaliber, event.isAssigned ? classes.greenBullet : classes.blueBullet)}></div>
        </Grid>
        <Grid item className={cn(classes.eventAssigned, event.isAssigned ? classes.greenColor: null)}>
          {event.isAssigned 
            ? <span className={classes.marginZero}>
                ASSIGNED
              </span> 
            : <span>
                NOT ASSIGNED
              </span>}
        </Grid>
        <Grid item className={cn(classes.titleAndTime, event.isAssigned ? classes.greenColor : null) }>
          Order ID:- {event.title}
        </Grid>
        <Grid className={cn(classes.titleAndTime, event.isAssigned ? classes.greenColor : null)}>
          Time:- {moment(event.start).format("hh:mm A")}
        </Grid>
      </Grid>
    )
  }
}

const StyledEvent = withStyles(styles)(Event);
export default (props)=> <StyledEvent {...props}/>