import React from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
} from "react-stripe-elements";
import withStyles from "react-jss";
import Image from "core/components/Image";

const styles = {
  field: {
    // border: "1px solid #4a4a4a",
    // borderRadius: "0.6rem",
    // background: "#fff",
    margin: "0.3rem 0 1.5rem 0",
    padding: "0.5rem 0",
    fontSize: "1.2rem",
    width: "100%",
    lineHeight: "normal",
    background: "#FFFFFF",
    // border: "1px solid #E0E0E0",
    // boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.128223)",
    // borderRadius: "8px",
    borderBottom:"1px solid #dadada",
    "& .StripeElement--invalid": {
      border: "1px solid #9e2146",
    }
  },
  label: {
    display: "block"
  },
  labelRow:{
    display:"flex",
    justifyContent:"space-between"
  },
  labelText:{
    color:"#4a4a4a"
  }
};


const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize: "18px",
        color: "#000",
        "::placeholder": {
          color: "#A3A3A3",
        },
        ...(padding ? {padding} : {}),
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};

const Error = ({text}) => (<p className="text-danger" style={{fontSize: "0.7em"}}>{text}</p>);

class CardForm extends React.Component {

  state = {
    error: {}
  }

  createToken = async (ev) => {
    this.setState({ error: {}});
    this.props.onSubmit();
    let stripe = await this.props.stripe.createToken();
    this.props.tokenCreated(stripe);
    if(stripe.error) this.setState({error: stripe.error});
  };

  render() {


    const {classes, error} = this.props;
    return (
      <div>
        <div className="input-row row flex-container">
          <div className="col-12 col-sm-12 col-md-12 card-input">
            <div className={classes.labelRow}>
              <span className={classes.labelText}>Card Number</span>
              <Image src="assets/images/cards.png" alt="" />
            </div>
            <CardNumberElement
              className={classes.field}
              {...createOptions(this.props.fontSize)}
              placeholder="**** **** **** 3765"
            />
            {error.code && error.code.indexOf("_number") > -1 && <Error text={error.message} />}
            
          </div>
        </div>
        <div className="input-row row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 card-input">
            <span className={classes.labelText}>Expiration date</span>
            <CardExpiryElement
              className={classes.field}
              {...createOptions(this.props.fontSize)}
            />
            {error.code && error.code.indexOf("_expiry") > -1 && <Error text={error.message} />}
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 card-input">
            <span className={classes.labelText}>CVV</span>
            <CardCVCElement
              className={classes.field}
              {...createOptions(this.props.fontSize)}
            />
            {error.code && error.code.indexOf("_cvc") > -1 && <Error text={error.message} />}
          </div>
          {/* <div className="col-12 col-sm-12 col-md-12 col-lg-4">
            <span className="label-txt">Postal code</span>
            <PostalCodeElement
              className={classes.field}
              {...createOptions(this.props.fontSize)}
            />
            {error.code && error.code.indexOf("_zip") > -1 && <Error text={error.message} />}
          </div> */}
        </div>
        
      </div>
    );
  }
}
export default withStyles(styles)(injectStripe(CardForm));