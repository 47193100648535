import React from "react";
import FormControlLabel from 'core/components/FormControlLabel';
import Checkbox from 'core/components/Checkbox';
import withStyles from "core/components/Styles/withStyles";


const styles = () => ({
  error: {
    color: "red",
    fontSize: 12,
  },
  warning: {
    color: "orange",
    fontSize: 12
  }
})
const MaterialCheckBox = (props) => {
  const { input, label, value, meta, classes, ...rest } = props;
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            {...input}
            value={value}
            {...rest}
          />
        }
        label={label}
      />
      <div className="help-block">
        {meta.touched &&
          ((meta.error && <span className={classes.error}>{meta.error}</span>) ||
            (meta.warning && <span className={classes.warning}>{meta.warning}</span>))}
        </div>
    </div>
    
  )
}


export default withStyles(styles)(MaterialCheckBox);