import React, { Component } from "react";
import { connect } from "react-redux";
import { JobChangesModal } from "../components/JobChangesModal";
import { closeChangesModal } from "../store/actions/jobChanges";
import { removeOrderFromOrdersWithChanges } from "../store/actions/app";
import { togglePageRefresh } from "../store/actions/app";
import { setMsgAndShow } from "../store/actions/popup";
import axios from "../utils/http";

class JobChangesModalContainer extends Component {

  state = {
    enableDialogLoader: false
  }
  closeModal = () => {
    const { closeChangesModal } = this.props;
    closeChangesModal()
  }

  acceptChanges = async () => {
    const { job, setMsgAndShow, removeOrderFromOrdersWithChanges, togglePageRefresh } = this.props;

    this.setState({
      enableDialogLoader: true
    })
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}contractor/order/accept_changes`,{
        orderId: job._id
      })
      this.setState({
        enableDialogLoader: false
      })
      if(res.data.status === "OK"){
        removeOrderFromOrdersWithChanges(job._id)
        togglePageRefresh();
        setMsgAndShow(res.data.message)
        this.closeModal()
      } else {
        setMsgAndShow(res.data.message)
      }
    } catch (error) {
      const { response } = error;
      this.setState({
        enableDialogLoader: false
      })
      setMsgAndShow(response ? response.data.message : "Error Occurred");
    }
  }

  cancelJob = async () => {
    const { job, removeOrderFromOrdersWithChanges, setMsgAndShow, togglePageRefresh } = this.props;
    this.setState({
      enableDialogLoader: true
    })
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}contractor/orders/cancel/${job._id}`)
      this.setState({
        enableDialogLoader: false
      })
      if(res.data.status === "OK"){
        removeOrderFromOrdersWithChanges(job._id)
        togglePageRefresh();
        setMsgAndShow("Job has been cancelled")
        this.closeModal()
      } else {
        setMsgAndShow(res.data.message)
      }
    } catch (error) {
      const { response } = error;
      this.setState({
        enableDialogLoader: false
      })
      setMsgAndShow(response ? response.data.message : "Error Occurred");
    }
  }
  render(){
    return (
      <JobChangesModal 
        closeModal={this.closeModal}
        acceptChanges={this.acceptChanges}
        cancelJob={this.cancelJob}
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  openJobChangesModal: state.jobChanges.openJobChangesModal,
  allJobs: state.jobs.allJobs,
  job: state.jobChanges.job
});

const mapDispatchToProps = (dispatch) => ({
  closeChangesModal: () => dispatch(closeChangesModal()),
  removeOrderFromOrdersWithChanges: (orderId) => dispatch(removeOrderFromOrdersWithChanges(orderId)),
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg)),
  togglePageRefresh: () => dispatch(togglePageRefresh())
})

export default connect(mapStateToProps, mapDispatchToProps)(JobChangesModalContainer);