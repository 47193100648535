function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 16.22,
    height: 14.691,
    viewBox: "0 0 16.22 14.691"
  }, props), React.createElement("g", {
    id: "prefix__iconfinder_Emergency_Stairs_751101",
    transform: "translate(-.086 -3.095)"
  }, React.createElement("path", {
    id: "prefix__Path_301",
    d: "M14.067 14.133H12.1a.878.878 0 0 1-.973-.874v-1.923a.831.831 0 0 0-.882-.882H8.333a.837.837 0 0 1-.888-.878V7.713a.844.844 0 0 0-.845-.938H4.681a.851.851 0 0 1-.916-.845V3.868c-.016-.245-.144-.773-.945-.773H.91a.725.725 0 0 0-.824.735v13.046a.869.869 0 0 0 .944.909H13.8c.8 0 .97-.45 1-.723v-1.941c0-.782-.45-.956-.733-.988zm-.193 2.056c0 .175-.086.68-.886.68H1.9c-.638 0-.795-.367-.827-.624V4.813c0-.666.415-.784.632-.8h.524c.154.007.648.1.648.977v1.274a1.255 1.255 0 0 0 1.344 1.3H5.55a.879.879 0 0 1 .88.992v1.4a1.427 1.427 0 0 0 1.616 1.408h1.037a.962.962 0 0 1 .956 1.043v1.248A1.532 1.532 0 0 0 11.8 15.14h1.157a.849.849 0 0 1 .92.831v.218z",
    className: "prefix__cls-1",
    "data-name": "Path 301",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Path_302",
    d: "M41.324 12.98c-.007-.725-.424-.416-.424-.416l-.409.416-.047.048-.1.1a.418.418 0 0 1-.618-.042l-.026-.026-5.15-5.153c-.281-.28-.483-.2-.583-.115l-.073.073-.12.12s-.311.3.077.684l5.2 5.191a.4.4 0 0 1 0 .589l-.138.14-.363.369s-.322.4.423.417l1.924.041h.024a.407.407 0 0 0 .429-.46z",
    className: "prefix__cls-1",
    "data-name": "Path 302",
    transform: "translate(-25.038 -3.445)",
    style: {
      "fill": "#00949c"
    }
  })));
};

export default __webpack_public_path__ + "static/media/job_floor.61b2054b.svg";
export { SvgComponent as ReactComponent };