function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    id: "prefix__iconfinder_ic_clean_floor_5803964",
    width: 20,
    height: 20,
    viewBox: "0 0 20 20"
  }, props), React.createElement("path", {
    id: "prefix__Path_320",
    d: "M12.434 70.625h-7.5a.313.313 0 0 1-.313-.292l-.621-10A.313.313 0 0 1 4.309 60h8.75a.313.313 0 0 1 .313.333l-.625 10a.313.313 0 0 1-.313.292zM5.228 70h6.912l.586-9.375H4.642z",
    className: "prefix__cls-1",
    "data-name": "Path 320",
    transform: "translate(-3.371 -50.625)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Path_321",
    d: "M10.313 60.625h-10a.312.312 0 1 1 0-.625h10a.312.312 0 1 1 0 .625z",
    className: "prefix__cls-1",
    "data-name": "Path 321",
    transform: "translate(0 -50.625)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Path_322",
    d: "M13.661 76.836a3.18 3.18 0 0 1-1.506-.341 2.584 2.584 0 0 0-1.25-.284 2.575 2.575 0 0 0-1.25.284 3.2 3.2 0 0 1-1.506.341 3.2 3.2 0 0 1-1.506-.336 2.584 2.584 0 0 0-1.25-.284.313.313 0 1 1 0-.625 3.2 3.2 0 0 1 1.506.341 2.556 2.556 0 0 0 1.25.284 2.566 2.566 0 0 0 1.25-.284 3.2 3.2 0 0 1 1.506-.341 3.2 3.2 0 0 1 1.506.341 2.566 2.566 0 0 0 1.25.284.313.313 0 0 1 0 .625z",
    className: "prefix__cls-1",
    "data-name": "Path 322",
    transform: "translate(-4.286 -63.775)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Path_323",
    d: "M82.188 11.406h-1.875a.313.313 0 0 1-.313-.312V1.25a1.25 1.25 0 0 1 2.5 0v9.844a.313.313 0 0 1-.312.312zm-1.562-.625h1.25V1.25a.625.625 0 1 0-1.25 0z",
    className: "prefix__cls-1",
    "data-name": "Path 323",
    transform: "translate(-67.5)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Path_324",
    d: "M77.313 72.75a.313.313 0 0 1-.313-.312v-3.125a.313.313 0 0 1 .313-.313h2.812a.313.313 0 0 1 .313.313v1.719a.312.312 0 1 1-.625 0v-1.407h-2.188v2.813a.313.313 0 0 1-.312.312z",
    className: "prefix__cls-1",
    "data-name": "Path 324",
    transform: "translate(-64.969 -58.219)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Path_325",
    d: "M89.043 106.79h-.023a25.992 25.992 0 0 1-3.5-.481 6.385 6.385 0 0 1-2.611-1.783.313.313 0 0 1 .469-.416 5.9 5.9 0 0 0 2.3 1.592 27.429 27.429 0 0 0 3.4.469.313.313 0 1 1-.023.625z",
    className: "prefix__cls-1",
    "data-name": "Path 325",
    transform: "translate(-69.884 -87.754)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Path_326",
    d: "M81.975 94.55a.313.313 0 0 1-.156-.045 14.141 14.141 0 0 0-5.531-1.641.31.31 0 1 1 .044-.619 14.656 14.656 0 0 1 5.814 1.731.313.313 0 0 1-.156.578z",
    className: "prefix__cls-1",
    "data-name": "Path 326",
    transform: "translate(-64.122 -77.831)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Path_327",
    d: "M86.65 90.4a.312.312 0 0 1-.259-.138c-.017-.027-1.78-2.616-3.577-3.262a14.019 14.019 0 0 0-2.158-.516.316.316 0 1 1 .094-.625 14.5 14.5 0 0 1 2.277.547c1.988.722 3.806 3.394 3.883 3.506a.312.312 0 0 1-.259.487z",
    className: "prefix__cls-1",
    "data-name": "Path 327",
    transform: "translate(-67.827 -72.442)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Path_328",
    d: "M79.013 88.331a6.332 6.332 0 0 1-4.5-1.448 3.458 3.458 0 0 1 0-4.887 3.461 3.461 0 0 1 4.888 0 7.278 7.278 0 0 1 1.639 3.511l.078.3.781.625a.312.312 0 0 1 .117.234.664.664 0 0 1-.408.6 3.039 3.039 0 0 0 .191.341.312.312 0 0 1-.206.469 14.7 14.7 0 0 1-2.58.255zm-4.053-5.9a2.831 2.831 0 0 0 0 4c1.542 1.542 4.514 1.345 6.094 1.1a3.011 3.011 0 0 1-.156-.373.311.311 0 0 1 .269-.425.781.781 0 0 0 .112-.017l-.625-.5a.313.313 0 0 1-.106-.156l-.108-.409a6.682 6.682 0 0 0-1.475-3.225 2.831 2.831 0 0 0-4 0zm6.44 4.23z",
    className: "prefix__cls-1",
    "data-name": "Path 328",
    transform: "translate(-62.021 -68.331)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Path_329",
    d: "M101.818 106.67h-.028a13.264 13.264 0 0 1-2.059-.352 3.159 3.159 0 0 1-1.264-.644.312.312 0 1 1 .433-.45 2.694 2.694 0 0 0 1.008.494 13.028 13.028 0 0 0 1.939.327.313.313 0 1 1-.028.625z",
    className: "prefix__cls-1",
    "data-name": "Path 329",
    transform: "translate(-82.991 -88.701)",
    style: {
      "fill": "#00949c"
    }
  }));
};

export default __webpack_public_path__ + "static/media/job_cleaning_type.e551eb72.svg";
export { SvgComponent as ReactComponent };