import React, { Component, Fragment } from 'react';
// import { Delete } from "mdi-material-ui";
import axios from "../utils/http";
import _ from "lodash";
import { connect } from "react-redux";
import { reset } from 'redux-form';
import Customers from "../components/Customers";
import { CustomerFormDialog } from "../components/CustomerForm";
import CustomerDetailsModal from '../components/CustomerDetailsModal';
import { setMsgAndShow } from "../store/actions/popup";
import { setItem, toggleLoader } from "../store/actions/app";
import { debounce } from "lodash";


import Storage from "../utils/Storage";
import { STATUS } from "react-joyride";

import { withMixpanel } from "../utils/react-mixpanel";
import getTotalPagesCount from '../utils/getTotalPagesCount';

class CustomersDashboardContainer extends Component {

  state = {
    rawCustomers: [],
    customers: [],
    isCustomerFormOpen: false,
    isCustomerDetailsModalOpen: false,
    customerDetails: {},
    totalPages: 0,
    currPage: 0,
    perPage:10,
    runGuide: false,
    q:""
  }

  searchCustomers = () => {
    this.getDataByPage(1);
  }

  lazySearch = debounce(this.searchCustomers,250);
  componentDidUpdate(prevProps) {
    const { item } = this.props;
    const { item: oldItem } = prevProps;
    const { isCustomerDetailsModalOpen } = this.state
    if (JSON.stringify(prevProps.businessDetails) !== JSON.stringify(this.props.businessDetails)) {
      this.addData();
    }

    if (!isCustomerDetailsModalOpen) {
      if (!_.isEmpty(item) && (JSON.stringify(oldItem) !== JSON.stringify(item))) {
        if (item.itemType === "customer") {
          this.openCustomerDetails(item.item)()
        }
      }
    }
  }

  componentDidMount() {

    const { trackPageLoad } = this.props;
    trackPageLoad("Customer_List");

    const {
      item: {
        itemType,
        item: itemObj
      }
    } = this.props;
    if (!_.isEmpty(this.props.businessDetails)) {
      this.addData();
    }

    if (!_.isEmpty(itemObj)) {
      if (itemType === "customer") {
        this.openCustomerDetails(itemObj)()
      }
    }

  }

  addData = async () => {
    const { toggleLoader, setMsgAndShow } = this.props;
    const contractorId = this.props.businessDetails._id;
    toggleLoader();
    try {
      const customersRes = await axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/get_customers`, {
        params: {
          contractorId,
        }
      })

      toggleLoader();
      if (customersRes.data.status === "OK") {
        const { customers, page: receivedPage, total, perPage } = customersRes.data;

        const totalPages = getTotalPagesCount(total, perPage);
        this.setState({
          customers,
          currPage: receivedPage,
          totalPages,
          perPage
        });
      }

      const { isTourEnabled } = this.props;

      if (Storage.get("customersListGuide") !== "completed" && isTourEnabled) {
        this.setState({
          runGuide: true
        });
      }
    } catch (error) {
      toggleLoader();
      const { response } = error;
      setMsgAndShow(response ? response.data.message : "Error Occurred!");
    }
  }

  getHelpers = (StoreHelpers) => {
    this.helpers = StoreHelpers;
  };

  handleJoyrideCallback = (CallBackProps) => {

    const { status } = CallBackProps;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      Storage.set("customersListGuide", "completed");
      this.setState({ runGuide: false });
    }

  };

  deleteCustomer = async (customerObj) => {
    const { toggleLoader, trackApiEvent } = this.props;
    const customerId = customerObj._id;
    toggleLoader();
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}user/remove`, {
        userId: customerId,
      })

      toggleLoader();

      if (res.data.status === "OK") {

        trackApiEvent("Delete_Customer", "POST", `${process.env.REACT_APP_API_PREFIX}user/remove`, "OK", window.location.pathname);

        this.updateList();
      }
    } catch (error) {
      toggleLoader();
      const { response } = error;
      let msg = "Error Occured!";

      if (response && response.data) {
        msg = response.data.message ? response.data.message : "Error Occured!";
      }

      trackApiEvent("Delete_Customer", "POST", `${process.env.REACT_APP_API_PREFIX}user/remove`, msg, window.location.pathname);

      setMsgAndShow(msg);
    }
  }

  openCustomerForm = () => {
    const { trackButtonEvent } = this.props;

    trackButtonEvent("Add_New_Customer", window.location.pathname);

    this.setState({
      isCustomerFormOpen: true
    })
  }

  closeCustomerForm = () => {
    this.props.resetForm("AddCustomer");
    this.setState({
      isCustomerFormOpen: false
    })
  }

  submitSuccess = (customer) => {
    const { customers } = this.state;
    customers.push(customer);
    this.setState({
      customers
    })
  }

  openCustomerDetails = (customer) => async () => {

    const { trackButtonEvent } = this.props;

    trackButtonEvent("View_Profile", window.location.pathname);

    this.setState({
      isCustomerDetailsModalOpen: true,
      customerDetails: customer
    })

  }
  handleCloseCustomerDetailsModal = () => {
    this.setState({
      isCustomerDetailsModalOpen: false,
      customerDetails: {}
    })

    this.props.setItem(null, null)
  }

  trackMapLink = () => {
    const { trackLinkEvent_inBlock } = this.props;
    trackLinkEvent_inBlock("View_On_Map", window.location.pathname);
  }

  trackBlurInput = (e) => {
    const value = e.target.value;
    if (value) {
      const { trackInputOnBlur } = this.props;
      trackInputOnBlur("Customer_Search", value, window.location.pathname);
    }
  }

  handleCustomerSearch = (e) => {
    this.setState({
      q:e.target.value
    }, () => {
      this.lazySearch();
    })

  }

  getDataByPage = async (pageNumber, isLoaderEnabled) => {
    const { toggleLoader, setMsgAndShow } = this.props;
    const contractorId = this.props.businessDetails._id;
    const { perPage, q } = this.state;
    if(!isLoaderEnabled){
      toggleLoader();
    }

    try {
      const customersRes = await axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/get_customers`, {
        params: {
          contractorId,
          limit:perPage,
          page:pageNumber,
          q,
        }
      })

      toggleLoader();
      if (customersRes.data.status === "OK") {
        const { customers, page: receivedPage, total, perPage } = customersRes.data;

        const totalPages = getTotalPagesCount(total, perPage);
        this.setState({
          customers,
          currPage: receivedPage,
          totalPages
        });
      }


    } catch (error) {
      toggleLoader();
      const { response } = error;
      setMsgAndShow(response ? response.data.message : "Error Occurred!");
    }

  }


  updateList = async () => {
    const { toggleLoader, setMsgAndShow } = this.props;
    const contractorId = this.props.businessDetails._id;
    const { perPage, q, currPage } = this.state;


    try {
      const customersRes = await axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/get_customers`, {
        params: {
          contractorId,
          limit:perPage,
          page:currPage,
          q,
        }
      })

      toggleLoader();
      if (customersRes.data.status === "OK") {
        const { customers, page: receivedPage, total, perPage } = customersRes.data;

        const totalPages = getTotalPagesCount(total, perPage);

        if(currPage > totalPages){
          this.getDataByPage(totalPages, true);
        } else {
          this.setState({
            customers,
            currPage: receivedPage,
            totalPages
          });
        }
  
      }
    } catch (error) {
      toggleLoader();
      const { response } = error;
      setMsgAndShow(response ? response.data.message : "Error Occurred!");
    }

  }

  handlePageChange = (_, page) => {
    this.getDataByPage(page);
  }

  clearSearch = () => {
    this.setState({
      q:""
    }, () => {
      this.getDataByPage(1);
    })
  }


  actions = [
    {
      label: "Delete",
      onClick: this.deleteCustomer
    }
  ]
  render() {

    return (
      <Fragment>
        <Customers
          actions={this.actions}
          openCustomerModal={this.openCustomerForm}
          closeCustomerForm={this.closeCustomerForm}
          submitSuccess={this.submitSuccess}
          openCustomerDetails={this.openCustomerDetails}
          handleCustomerSearch={this.handleCustomerSearch}
          handleJoyrideCallback={this.handleJoyrideCallback}
          getHelpers={this.getHelpers}
          trackBlurInput={this.trackBlurInput}
          trackMapLink={this.trackMapLink}
          handlePageChange={this.handlePageChange}
          clearSearch={this.clearSearch}
          {...this.state}
        />

        <CustomerFormDialog
          contractorId={this.props.businessDetails._id}
          isOpen={this.state.isCustomerFormOpen}
          handleClose={this.closeCustomerForm}
          onSubmitSuccessful={this.submitSuccess}
        />

        <CustomerDetailsModal
          handleCloseCustomerDetailsModal={this.handleCloseCustomerDetailsModal}
          {...this.state}
        />

      </Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  businessDetails: state.contractor.businessDetails,
  item: state.app.item,
  isTourEnabled: state.app.isTourEnabled
})

const mapDispatchToProps = (dispatch) => ({
  resetForm: (formName) => dispatch(reset(formName)),
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  setItem: (item, itemType) => dispatch(setItem(item, itemType)),
  toggleLoader: () => dispatch(toggleLoader())
})

const connectRedux = connect(mapStateToProps, mapDispatchToProps)(CustomersDashboardContainer);

export default withMixpanel(connectRedux);
