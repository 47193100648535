import React from "react";

import CalenderDashboardContainer from "containers/CalenderDashboardContainer";
import { MainLayout } from "layouts";

const CalenderPage = (props) => (
  <MainLayout {...props}>
    <CalenderDashboardContainer {...props} />
  </MainLayout>
)

export default CalenderPage;