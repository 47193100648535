import React from "react";
import Grid from "core/components/Grid";
import { Field } from "redux-form";
import { InputField, RadioField, MaterialCheckBox } from "../FormField";
import DatePicker from "../DatePicker";
import { withStyles } from "core/components/Styles";
import { required, maxLength, minLength } from "../FormField/Validations";

const PaymentDetails = ({ classes, formValues }) => {
  return (
    <Grid
      item
      container
      xs={12}
      className={classes.paymentCard}
      direction="column"
    >
      <p>Setup your Payment Process</p>
      <Grid
        container
        spacing={24}
      >
        <Grid item xs={12} md={4}>
          <Field
            type="text"
            id="beneficeryName"
            name="beneficeryName"
            component={InputField}
            placeholder="Beneficiary Name"
            validate={[required]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            name="ownersDob"
            placeholder="Beneficiary's D.O.B"
            component={DatePicker}
            type="text"
            isNotMinDate={true}
            leftIcon={false}
            validate={[required]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            type="number"
            id="ownersSocial"
            name="ownersSocial"
            component={InputField}
            placeholder="SSN (Last 4 Digits)"
            validate={[required, minLength(4), maxLength(4)]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={24}>
        <Grid item xs={12} md={4}>
          <Field
            type="text"
            id="bankName"
            name="bankName"
            component={InputField}
            placeholder="Bank Name"
            validate={[required]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            type="number"
            id="beneficeryAccountNumber"
            name="bankAccountNumber"
            component={InputField}
            placeholder="Account Number"
            validate={[required]}
            // minLength(12), maxLength(12)
          />
        </Grid>

         <Grid item xs={12} md={4}>
          <Field
            type="number"
            id="bankRoutingNumber"
            name="bankRoutingNumber"
            component={InputField}
            placeholder="Bank Routing Number"
            validate={[required, minLength(9), maxLength(9)]}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <div> Select One </div>
        <Field
          name="idType"
          radioValue="businessId"
          checked={formValues.idType === "businessId"}
          label="Business Tax ID"
          component={RadioField}
        />
        <Field
          name="idType"
          checked={formValues.idType === "ein"}
          radioValue="ein"
          label="EIN"
          component={RadioField}
        />
      </Grid>
      <Grid container justify="flex-start" spacing={24}>
        {formValues.idType === "businessId" ? 
          <Grid item xs={12} md={4}>
            <Field
              type="number"
              id="businessTaxId"
              name="businessTaxId"
              component={InputField}
              placeholder="Business Tax ID "
              validate={[required, minLength(9), maxLength(9)]}
            />
          </Grid> : 
          <Grid item xs={12} md={4}>
            <Field
              type="number"
              id="businessEIN"
              name="businessEIN"
              component={InputField}
              placeholder="EIN"
              validate={[required, minLength(9), maxLength(9)]}
            />
          </Grid> 
        }
      </Grid>
      <Grid container justify="flex-start">
        <Field
          id="acceptTerms"
          name="acceptTerms"
          component={MaterialCheckBox}
          label={<p>I accept {<a href="https://stripe.com/us/ssa" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>}</p>}
          validate={[ required ]}
        /> 
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  paymentCard: {
    padding: 20
  },
  bankAccountAndType: {
    marginTop: 10
  }
});

export default withStyles(styles)(PaymentDetails);
