import React from "react";
import Grid from "core/components/Grid";
import Card from "core/components/Card";
import CardContent from "core/components/CardContent";
import Button from "core/components/Button";
import Stepper from "core/components/Stepper";
import Step from "core/components/Step";
import StepLabel from "core/components/StepLabel";
import StepConnector from "core/components/StepConnector";
import { Form } from "redux-form";
import { withStyles } from "core/components/Styles";
import cn from "classnames";
import Loader from "../Loader";
import Zbutton from "core/components/Button";
// import logo from "../../assets/images/logo.jpg";



const styles = theme => ({
  root: {
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
    padding: 0,
    border: "13px solid #f2f7f8"
  },
  rootCard: {
    marginTop: 100,
    marginBottom: 100,
    overflow: "visible"
  },
  card:{
    overflow : "hidden",
    boxShadow : "none",
    padding: " 12px 44px"
  },
  mainData: {
    padding: 20,
    width:"100%",
    marginBottom: 34
  },
  mainDiv: {
    width: "100%"
  },
  title: {
    marginTop: 20
  },
  mainCard: {
    width: "100%"
  },
  loginBtn: {
    marginTop: 10,
    backgroundColor: "#4a4a4a",
    color: "white"
  },
  textCenter: {
    textAlign: "center"
  },
  secondItem: {
    // marginTop: 10,
    width:"100%"
  },
  businessTyeAndEmployees: {
    marginTop: 30
  },
  stepStyle:{
    color : "#47495a",
    fontWeight : 500,
    lineHeight : "24px",
    minHeight : 65,
    background: "none",
    textAlign : "center",
  },

  backBtnStyle :{
    background : "#D8D8D8",
    borderRadius : 5,
    color : "#9C9C9C",
    fontSize : 12,
    fontWeight : 500,
    height : 35,
    lineHeight: "18px",
    letterSpacing: "0.57px",
    padding:"9px 23px"
  },
  nextAndFin :{
    height : 41,
    background : " #5107AC", 
    boxShadow: "0px 9px 27px rgba(80, 80, 80, 0.31)",
    borderRadius:5,
    fontSize : 13,
    lineHeight: "19px",
    textTransform : "capitalize",
    color : "#fff",
    "&:hover":{
      background : " #5107AC"
    },
    padding: "11px 32px 12px 32px"

  },
  stepLabel:{
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "24px",
    color: "#47495A"
  },
  stepIcon:{
    borderRadius: "40px",
  },
  styledStep :{ 
    background:"#50E3C2",
    color:"#50E3C2",
    "& text":{
      fill: "#fff"
    },
    "& path":{
      fill: "#fff"
    }
  },
  simpleStep : {
    background:"#fff",
    color:"#fff",
    "& text":{
      fill: "#50E3C2"
    }
  },
  disableHorizontalLine :{
    display : "none"
  },
  stepperCont: {
    height: 40,
    width: "60%",
    padding: 0
  },
  stepIconContainer:{
    border: "2px solid #50E3C2",
    borderRadius: "50%",
    padding: "2px !important"
  },
  stepConnect:{
    top: 13,
    left: "calc(-50% + 13px)",
    right: "calc(50% + 13px) ",
  },
  connectLine: {
    border: "1px solid #50E3C2",
  },
  labelText:{
    color: "rgba(71, 73, 90, 0.4)",
    fontFamily: "Poppins"
  },
  noLeftPadding: {
    padding:"24px 24px 24px 0 !important",
  },
  paddingRight_12:{
    paddingRight: "12px !important"
  }

});

const AddBusinessDetails = props => {
  const {
    classes,
    load,
    onSave,
    handleSubmit,
    // handleSave,
    activeStep,
    getSteps,
    // isStepSkipped,
    getStepContent,
    handleNext,
    handleBack,
    confirmData,
    skipAll,
    showSkip,
    elevation
  } = props;
  const steps = getSteps();


  return (
    <Grid className={classes.root}>
       <Card className={classes.card} elevation={elevation}>
        <CardContent>
          <Form onSubmit={handleSubmit(onSave)}>
    
              <Grid item container
                className= {classes.mainData}
                justify = "space-evenly"
                alignItems = "center"
                direction = "column"
              >
                {/* <Grid item container alignItems="center" justify="center">
                  <img src={logo} alt="logo" />
                </Grid>  */}

                <Stepper 
                  alternativeLabel
                  classes={{
                    root: classes.stepperCont
                  }}
                  activeStep={activeStep}
                  connector={<StepConnector classes={{ root: classes.stepConnect, line: classes.connectLine }}/>}
                >
                  {steps.map((label, index) => {
                    return (
                      <Step 
                        key={`step-key-${label}-${index}`} 
                      >
                        <StepLabel 
                          classes={{
                            labelContainer: classes.stepLabel,
                            iconContainer: classes.stepIconContainer,
                            label: classes.labelText
                          }}
                          StepIconProps={{
                            classes: { root: cn(classes.stepIcon,{
                                [classes.styledStep]: index <= activeStep,
                                [classes.simpleStep]: index > activeStep
                              })
                            }
                          }}
                          
                          className={cn(
                            classes.stepStyle, 
                          //   {
                              // [classes.styledStep]: index <= activeStep,
                              // [classes.simpleStep]: index > activeStep
                          //   }
                          //   //(index <= activeStep) ? classes.styledStep : classes.simpleStep 
                          )}
                        > 
                          {label}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Grid>

              <Grid item className={classes.secondItem}>
                {getStepContent(activeStep)}
              </Grid>


              {load && <Loader />}

              <Grid item container spacing={6} justify="flex-end" alignItems="center">
                {
                  showSkip
                  ? <Grid item> 
                      <Button onClick={skipAll}> Skip For Now </Button>
                    </Grid>
                  : null
                }
                <Grid item className={classes.paddingRight_12}>
                  <Button className={classes.backBtnStyle} disabled={activeStep === 0} 
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item className={classes.noLeftPadding}>
                  {activeStep === steps.length - 1 ? (
                    <Zbutton 
                      className={classes.nextAndFin}
                      fullWidth 
                      onClick={confirmData} 
                      variant="contained" 
                      color="secondary"
                    >
                      {" "}
                      Finish{" "}
                    </Zbutton>
                  ) : (
                    <Zbutton
                      className={classes.nextAndFin} 
                      fullWidth 
                      onClick={handleNext} 
                      variant="contained" 
                      color="primary"
                      gradient
                    >
                      Next
                    </Zbutton>
                  )}
                </Grid>

              </Grid>

          </Form>
        </CardContent>
      </Card>  
    </Grid>
  );
};



export default withStyles(styles)(AddBusinessDetails);
