import React from 'react';
import Dialog from "core/components/Dialog";
import DialogContent from "core/components/DialogContent";
import DialogActions from "core/components/DialogActions";
import ZButton from "core/components/Button";
import DialogTitle from "core/components/DialogTitle";
import Loader from "../ProgressCircle";

import {
  MaterialTextField,
} from "../FormField";



const textInputProps = {
  maxLength:"4"
}
const GetOTPModal = ({
  open,
  closeModal,
  submitRequest,
  handleValueChange,
  code,
  showLoader,
  errorMsg,
  onKeyDown,
}) => {
  return (
    <Dialog
      open={open}
      onClose={closeModal}
      fullWidth
      maxWidth="sm"
    >
      {showLoader  && <Loader position="fixed" zIndex={9999} />} 
      <DialogTitle>
        Enter Order OTP
      </DialogTitle>
      <DialogContent>

        <MaterialTextField 
          onChange={handleValueChange}
          value={code}
          placeholder="4321"
          inputProps={textInputProps}
          errorMsg={errorMsg}
          onKeyDown={onKeyDown}
          // maxLength="5"
        />
        {/* <FormInputField label="First Name"
          type="text"
          placeholder="Enter OTP"
          component={MaterialTextField}
          validate={[required]}
          InputProps={{disableUnderline :true}}                      
        /> */}
      </DialogContent>
      <DialogActions>
        <ZButton color="primary" onClick={closeModal} >
          Cancel
        </ZButton>
        <ZButton onClick={submitRequest} gradient color="primary">
          Submit
        </ZButton>
      </DialogActions>
    </Dialog>
  )
}


export default GetOTPModal;