import React, { Component } from "react";
import Grid from "core/components/Grid";
import List from "core/components/List";
import ListItem from "core/components/ListItem";
import ListItemText from "core/components/ListItemText";
import Typography from "core/components/Typography";
// import { Link } from "react-router-dom";
import  { withStyles } from "core/components/Styles";
import { capitalizeFirstLetterOnly } from "../../utils/capitalize";
const styles = () => ({
  listCont:{
    minHeight: 100,
    backgroundColor:"#ffffff",
    // border:"1px solid black",
    position:"absolute",
    top: 55,
    left:0,
    width:"100%",
    zIndex: -1,
    boxShadow:"0 5px 5px 1px rgba(0, 0, 0, 0.1)",
    borderRadius: 5
  },
  noDataTextContainer:{
    flexDirection: "column",
    flex: 1,
    minHeight: 100,
    height:"100%"
  },
  link:{
    textDecoration:"none",
    "&:active":{
      textDecoration:"none"
    },
    "&:visited":{
      textDecoration:"none"
    }
  }
})



class Results extends Component {


  handleMouseDown = (e) => {
      e.stopPropagation()
      e.preventDefault()
  }
  render(){
    const { classes, results, openPage } = this.props;
    return (
      <Grid className={classes.listCont}>
        {
          results && results.length > 0 ? 
          <List>
            {
              results.map((r, i) => {
                if(r.itemType === "customer"){
                  return (
                      <ListItem  
                        key={`search-item-${i}`} 
                        component="div" 
                        divider 
                        button 
                        onClick={openPage("customers", r)} 
                        onMouseDown={this.handleMouseDown} 
                      >
                        <ListItemText inset primary={`${r.fname} ${r.lname}`} />
                        <Typography>
                        {capitalizeFirstLetterOnly(r.itemType)}
                        </Typography>
                      </ListItem>
                  )
                } else if(r.itemType === "job") {
                  return (
                      <ListItem 
                        key={`search-item-${i}`} 
                        button 
                        divider 
                        onMouseDown={this.handleMouseDown}
                        onClick={openPage("jobs", r)}
                      >
                        <ListItemText inset primary={r.displayId} />
                        <Typography>
                        {capitalizeFirstLetterOnly(r.itemType)}
                        </Typography>
                      </ListItem>
                  )
                } else {
                  return (
                    <ListItem 
                      key={`search-item-${i}`} 
                      button 
                      divider 
                      onMouseDown={this.handleMouseDown}
                      onClick={openPage("track", r)}
                    >
                      <ListItemText inset primary={`${r.fname} ${r.lname}`} />
                      <Typography>
                        {capitalizeFirstLetterOnly(r.itemType)}
                      </Typography>
                    </ListItem>
                  )
                }
              })
            }
          </List> 
          : 
          <Grid container justify="center" alignItems="center" className={classes.noDataTextContainer}>
            <Typography>
              No Data
            </Typography>
          </Grid>
  
        }
        
      </Grid>
    )
  }
}




export default withStyles(styles)(Results);