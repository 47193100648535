import React, { Component } from "react";
import {  Field } from "redux-form";
import Grid from "core/components/Grid";
import IconButton from "core/components/IconButton";
import MaterialTextField from "./MaterialTextField";
import { Close } from 'mdi-material-ui';
import {withStyles} from "core/components/Styles"

const styles = () => ({
  error: {
    color: "red",
    fontSize: 12,
  },
  warning: {
    color: "orange",
    fontSize: 12
  },

})



class GroupedMaterialInputFormField extends Component {
  render(){
    const { fields, meta, classes } = this.props;
    return (
      <Grid>
      {fields.map((f, index) => (
        <Grid key={index} container alignItems="center" spacing={2}>
          <Grid item xs>
            <Field
              key={`field-item-${index}`}
              name={f}
              component={MaterialTextField}
              label={`Invite #${index + 1}`}
            />
          </Grid>
          {
            index !== 0 &&
            <Grid item>
            <IconButton onClick={() => fields.remove(index)}>
              <Close />
            </IconButton>
          </Grid>
          }
        </Grid>

      ))}


      {
        meta && meta.touched &&
          ((meta.error && <span className={classes.error}>{meta.error}</span>) ||
          (meta.warning && <span className={classes.warning}>{meta.warning}</span>))
      }
      </Grid>
    )
     
  }
}


export default withStyles(styles)(GroupedMaterialInputFormField);