import React from "react";
import Dialog from "core/components/Dialog";
import DialogTitle from "core/components/DialogTitle";
import DialogContent from "core/components/DialogContent";
import Grid from "core/components/Grid";
// import Button from "core/components/Button";
import { withStyles } from "core/components/Styles";
import { Field, Form } from "redux-form";
import { connect } from "react-redux";
import cn from  "classnames";
import { 
  AdvanceSelectCompanyProfile, 
  FormInputField,
  MaterialTextField,
  RadioSelectBtn
} from "../FormField";
import states from "data/statesTitleCase.json";

import { required, number, minLength, maxLength, email } from "../FormField/Validations";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";
import {
  domesticCleaningOptions,
  commercialCleaningOptions,
  domesticPropertyOptions,
  commercialPropertyOptions,
  commercialPropertyTypes,
  addOnServicesWindows,
  addOnServices
} from "./FormFieldData";
import Loader from "../ProgressCircle";
import ZButton from "core/components/Button";
import ZTypography from "core/components/Typography";
import ServiceField from "./SeviceField";
import { ReactComponent as BedroomIcon } from "assets/images/icons/bedroom.svg";
import { ReactComponent as BathroomIcon } from "assets/images/icons/bathroom.svg";

const stateObjOptions = states.map(state => {
  return {
    label: state.name,
    value: state.abbreviation
  };
});

const minLength_10 = minLength(10);
const maxLength_10 = maxLength(10);

const minLength_5 = minLength(5);
const maxLength_5 = maxLength(5);

const styles = theme => ({
  modalCont: {},
  inputMarginTop_10: {
    marginTop: 10
  },
  cleaningTypeHeading: {
    marginRight: 0,
    marginTop: 0,
    marginBottom: 4,
    fontSize: 12,
    color: "#929292",
    lineHeight: "18px"
  },
  title: {
    marginLeft: 5,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16
  },
  selectorCont: {
    border: "1px solid #4a4a4a",
    maxWidth: 150,
    fontSize: "20px",
    borderRadius: ".3rem",
    overflow: "hidden",
    margin: 0,
    marginTop: 5,
    padding: 0,
    "& .dec, .inc": {
      background: "#f8f8f8",
      width: "30%",
      cursor: "pointer",
      textAlign: "center",
      padding: "0.3rem 0.5rem",
      display: "inline-block"
    },
    "& span:first-child": {
      borderRight: "1px solid #4a4a4a"
    },
    "& span:last-child": {
      borderLeft: "1px solid #4a4a4a"
    }
  },
  inputSelect:{
    paddingTop: 0,
		"& [class*=fieldWrapper]":{
      height: 46,
			"& > span":{
				right: 0,
				color: "#c4c4c4",
        "& img":{
          width: "1.3rem",
          height: "1.3rem",
          opacity: 0.7
        }
			},
			"& > input":{
        color: "#646464",
        fontWeight: 500,
        lineHeight: "21px",
				fontSize: 14,
				height: 46,
				border: "solid 1px #d8d8d8",
				background: "#ffffff",
				padding: "5px 18px", //"20px 56px 20px 20px",
				"&::placeholder ":{
					color: "#b7b7b7"
				}
			}
		},
	},
  disp: {
    width: "40%",
    textAlign: "center",
    display: "inline-block"
  },
  propertyDetailsHeading: {
    marginLeft: 5,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16
  },
  textCenter: {
    textAlign: "center"
  },
  addOnStyle:{
    backgroundColor:"blue"
  },
  setInCenter:{
    display:"flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputMarginTop_4:{
    marginTop: 4
  },
  noTopPadding:{
    paddingTop: "2px !important"
  },
  // costStyle:{
  //   display: "flex",
  //   alignItems: "center",
  //   height: 33
  // }
});

const AddJobModal = ({
  handleClose,
  handleSubmit,
  classes,
  handleSave,
  isModalOpen,
  formValues,
  increaseByOne,
  decreaseByOne,
  submitData,
  customerOptions,
  openNewUserform,
  addNewCustomer,
  showDialogLoader,
  searchCustomers,
  ...rest
}) => {

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      fullWidth
      className={classes.modalCont}
      maxWidth="md"
    >
      {showDialogLoader && <Loader position="fixed" zIndex={999} />}
      <Form onSubmit={handleSubmit(submitData)}>
        <DialogTitle id="form-dialog-title" className={classes.textCenter}>
          <ZTypography color="secondary" gradient bold fontSize={25}>Add New Job </ZTypography>
        </DialogTitle>
        <DialogContent>
  
          <Grid container className={classes.inputsCont}>
            <Grid>
              <Field
                component={RadioSelectBtn}
                name="type"
                validate={[required]}
                inRow
                options={[
                  { title: "Domestic", value: "domestic" },
                  // { title: "Commercial", value: "commercial" }
                ]}
              />
            </Grid> 

            <Grid container justify="space-between" alignItems="flex-end" className={cn([classes.inputsCont,classes.inputMarginTop_10])} spacing={6}>
              <Grid item md={8} xs={12}>
                {
                  !addNewCustomer 
                  ? <FormInputField 
                      label= "Select Customer"
                      type="text"
                      id="customer"
                      name="customer"
                      component={AdvanceSelectCompanyProfile}  
                      options={customerOptions}
                      validate={[required]}
                      backgroundColor="transparent"
                      material 
                      InputProps={{disableUnderline :true}} 
                      async
                      loadOptions={searchCustomers}
                      defaultOptions={customerOptions}
                    />
                  : null 
                }
              </Grid>
              <Grid item md={!addNewCustomer ? 4 : 12} xs={12} > 
                <Grid container justify="center" alignItems="flex-start">
                  <ZButton color="primary" gradient onClick={openNewUserform}>
                    {!addNewCustomer ? "Add New Customer" : "Select customer"}
                  </ZButton> 
                </Grid>
              </Grid>
            </Grid>

            {
              addNewCustomer 
              ?<Grid container justify="space-between" alignItems="center" spacing={6}>
                <Grid item xs={12} md={6} className={classes.inputMarginTop_10}>
                  <FormInputField label="First Name"
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="Enter First Name"
                    component={MaterialTextField}
                    validate={[required]}
                    InputProps={{disableUnderline :true}}
                    setBorder  
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.inputMarginTop_10}>
                  <FormInputField label="Last Name"
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Enter Last Name"
                    component={MaterialTextField}
                    validate={[required]}
                    InputProps={{disableUnderline :true}}
                    setBorder  
                  />
                </Grid>
              </Grid>
              : null 
            }
            <Grid container justify="space-between" alignItems="center" spacing={6}>
              <Grid item xs={12} md={6} className={classes.inputMarginTop_10}>
                <FormInputField label="Address"
                  type="text"
                  id="address"
                  name="address" 
                  placeholder="Enter Address"
                  component={MaterialTextField}
                  validate={[required]}
                  InputProps={{disableUnderline :true}}
                  setBorder  
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputMarginTop_10}> 
                <FormInputField label="Zipcode"
                  type="text"
                  id="zipcode"
                  name="zipcode"
                  placeholder="Enter Zipcode"
                  component={MaterialTextField}
                  validate={[required,number,minLength_5,maxLength_5]}
                  InputProps={{disableUnderline :true}}
                  setBorder  
                />
              </Grid>
            </Grid>


            <Grid container justify="space-between" alignItems="center" spacing={6}>
              <Grid item xs={12} md={6} className={classes.inputMarginTop_10}> 
                <FormInputField label="City"
                  type="text"
                  id="city"
                  name="city"
                  placeholder="Name Of City"
                  component={MaterialTextField}
                  validate={[required]}
                  InputProps={{disableUnderline :true}}
                  setBorder  
                />
              </Grid>
              <Grid item xs={12} md={6} className={ classes.inputMarginTop_10 }> 
                <FormInputField 
                  label="State"
                  type="text"
                  id="state"
                  name="state"
                  component={AdvanceSelectCompanyProfile}
                  options={stateObjOptions}
                  validate={[required]}
                  placeholderAdd="Select State..."
                  backgroundColor="transparent"
                  material
                  InputProps={{disableUnderline :true}}
                  // menuPlacement="top" // To open the list to top direction
                   
                />
              </Grid>
            </Grid>

            <Grid container justify="space-between" alignItems="center" spacing={6}>
              <Grid item xs={12} md={6} className={classes.inputMarginTop_10}> 
                <FormInputField label="Phone" 
                  id="phone"
                  name="phone"
                  placeholder="Enter Phone Number"
                  component={MaterialTextField}
                  validate={[required,number,minLength_10,maxLength_10]}
                  InputProps={{disableUnderline :true}}
                  setBorder  
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputMarginTop_10}> 
                <FormInputField label="Email"
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter Email Address"
                  component={MaterialTextField}
                  validate={[required,email]}
                  InputProps={{disableUnderline :true}}
                  setBorder  
                />
              </Grid>
            </Grid>

            <Grid
              container
              className={classes.inputMarginTop_4}
              justify="space-between"
              alignItems="center"
              spacing={6}
            >
              <Grid item xs={12} md={6} className={classes.inputSelect}>
                <p className={classes.cleaningTypeHeading}>Date</p>
                <Field
                  name="date"
                  component={DatePicker}
                  validate={[required]}
                  placeholder="Select date" 
                  addOnStyle={classes.addOnStyle}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.inputSelect}>
                <p className={classes.cleaningTypeHeading}>Time</p>
                <Field
                  name="time"
                  component={TimePicker}
                  validate={[required]}
                  placeholder="Select time" 
                  addOnStyle={classes.addOnStyle}
                />
              </Grid>
            </Grid>

            <Grid
              container
              className={classes.inputMarginTop_4}
              justify="space-between"
              alignItems="center"
              spacing={6} 
            > 
              <Grid item xs={12} md={6} className={classes.noTopPadding}>  
                <FormInputField 
                  label="Cleaning Type"
                  type="text"
                  id="cleaningType"
                  name="cleaningType"
                  component={AdvanceSelectCompanyProfile}
                  options={ 
                    formValues.type === "domestic"
                      ? domesticCleaningOptions
                      : commercialCleaningOptions
                  }
                  backgroundColor="transparent"
                  validate={[required]}
                  material
                  InputProps={{disableUnderline :true}}
                  // menuPlacement="top" // To open the list to top direction
                   
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.noTopPadding}> 
                <FormInputField label="Cost"
                  type="number"
                  id="orderCost"
                  name="orderCost"
                  placeholder="Enter Cost"
                  component={MaterialTextField}
                  validate={[required,number]}
                  InputProps={{disableUnderline :true}}
                  setBorder   
                />

                {/* background-color: #fff;
                    height: 46px;
                    font-size: 14px;
                    border-color: hsl(0,0%,85%);
                    border-radius: 5px;
                    border-style: solid;
                    border-width: 1px; */}
              </Grid>
            </Grid>

            <Grid container className={classes.inputMarginTop_10}>
              <Grid container spacing={6}> 
                <Grid item xs={12} md={6}>
                  <FormInputField 
                    label="Property Details"
                    type="text"
                    id="propertyArea"
                    name="propertyArea"
                    component={AdvanceSelectCompanyProfile}
                    options={
                      formValues.type === "domestic"
                        ? domesticPropertyOptions
                        : commercialPropertyOptions
                    }  
                    backgroundColor="transparent"
                    validate={[required]}
                    material
                    InputProps={{disableUnderline :true}}
                    menuPlacement={ 
                      formValues.type === "domestic"
                        ? "bottom"
                        : "top"
                    }  // To open the list to top direction
                     
                  />
                </Grid>

                {formValues.type === "commercial" ? (
                  <Grid item xs={12} md={6}> 
                    <FormInputField 
                      label="Select Property Type"
                      type="text"
                      id="propertyType"
                      name="propertyType"
                      component={AdvanceSelectCompanyProfile}
                      options={commercialPropertyTypes}
                      backgroundColor="transparent"
                      validate={[required]}
                      material
                      InputProps={{disableUnderline :true}}
                      menuPlacement="top" // To open the list to top direction
                       
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            {formValues.type === "domestic" ? (
              <Grid container className={classes.inputMarginTop_10}>
                <Grid item xs={12} md={6} className={classes.setInCenter}>
                  <ServiceField
                    title="Bedrooms"
                    icon={BedroomIcon}
                    count={formValues.bedrooms}
                    onAdd={increaseByOne("bedrooms")}
                    onDelete={decreaseByOne("bedrooms")}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.setInCenter}>
                  <ServiceField
                    title="Bathrooms"
                    icon={BathroomIcon}
                    count={formValues.bathrooms}
                    onAdd={increaseByOne("bathrooms")}
                    onDelete={decreaseByOne("bathrooms")}
                  />
                </Grid>
              </Grid>
            ) : null}
            <Grid container alignItems="center" justify="space-between"  spacing={6}>
              {formValues.type === "domestic" ? (
                <Grid item xs={12} md={8} className={classes.inputMarginTop_10}> 
                  <FormInputField 
                    isMulti={true}
                    close={false}
                    label="Add on services"
                    id="addOnServices"
                    name="addOnServices"
                    component={AdvanceSelectCompanyProfile}
                    options={addOnServices}
                    backgroundColor="transparent"
                    material
                    InputProps={{disableUnderline :true}}
                    closeMenuOnSelect={false}
                    menuPlacement="top" // To open the list to top direction
                     
                  />
                </Grid>
              ) : (
                  <Grid item xs={12} md={6} className={classes.inputMarginTop_10}> 
                     <FormInputField 
                      label="Number of Windows"
                      type="text"
                      id="addOnServices"
                      name="addOnServices"
                      component={AdvanceSelectCompanyProfile}
                      options={addOnServicesWindows}
                      backgroundColor="transparent"
                      validate={[required]}
                      material
                      InputProps={{disableUnderline :true}}
                      menuPlacement="top" // To open the list to top direction
                       
                    />
                  </Grid>
                )}
              <Grid item xs={12} md={formValues.type === "domestic" ? 4 : 6} className={classes.inputMarginTop_10}> 
                <FormInputField label="Estimate (in Minutes)"
                  type="number"
                  id="etc"
                  name="etc"
                  placeholder="Enter Time.."
                  component={MaterialTextField}
                  validate={[required,number]}
                  InputProps={{disableUnderline :true}}
                  setBorder  
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.inputMarginTop_10}>
              <ZButton fullWidth type="submit" gradient color="primary">
                Add Job
              </ZButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Form>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  showDialogLoader: state.app.showDialogLoader
})
const WithStylesAddJobModal = withStyles(styles)(AddJobModal);

export default connect(mapStateToProps)(WithStylesAddJobModal);
