import React, { Component } from "react";
import ReactEchartsCore from "echarts-for-react/lib/core";
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";


// const options = {
//   tooltip: {
//       trigger: 'axis',
//       axisPointer: {
//           type: 'cross',
//           crossStyle: {
//               color: '#fff'
//           }
//       }
//   },
//   toolbox: {
//       feature: {
//           dataView: {show: true, readOnly: false},
//           magicType: {show: true, type: ['line', 'bar']},
//           restore: {show: true},
//           saveAsImage: {show: true}
//       }
//   },
//   legend: {
//       data:[]
//   },
//   xAxis: [
//       {
//           type: 'category',
//           data: [],
//           axisPointer: {
//               type: 'shadow'
//           }
//       }
//   ],
//   yAxis: [
//       {
//           type: 'value',
//           min: 0,
//           max: 250,
//           interval: 50,
//           axisLabel: {
//               formatter: '{value} ml'
//           }
//       },
//       {
       
//       }
//   ],
//   series: [
//       {
          
//           type:'bar',
//           data:[2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3]
//       },
//       {
//           type:'line',
//           yAxisIndex: 1,
//           data:[2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
//       }
//   ]
// };
const defaultOptions = {
  tooltip: {
      trigger: 'axis',
      axisPointer: {
          type: 'cross',
          crossStyle: {
              color: '#fff'
          }
      }
  },
  toolbox: {
      feature: {
          dataView: {show: true, readOnly: false},
          magicType: {show: true, type: ['line', 'bar']},
          restore: {show: true},
          saveAsImage: {show: true}
      }
  },
  legend: {
      data:[]
  },
  xAxis:{
    type: 'category',
    data: [],
    axisPointer: {
        type: 'shadow'
    },
    axisLabel:{
        color:"#fff"
    },
    splitLine: {
      show: false,
  },
  },
  yAxis: [{
    type: 'value',
    min: 0,
    max: 250,
    interval: 50,
    axisLabel: {
    //  formatter: '{value} ml',
		 textStyle:{
			 color:"#fff"
			},
			show: true
    },
    axisTick: {
      lineStyle: {
          width: 0
      }
    },
    splitLine: {
      show: false,
      lintStyle:{

      }
    },
	}],
  series: [],
  textStyle:"#fff",
  backgroundColor:"#49d3b9",
  grid: {
    bottom: 10,
    height: "100%",
    width: "96%",
    right: "2%",
    containLabel:true
    
  },
};
class LineBarChart extends Component {
  render() {
    const { chartHeight, barData, lineData, max } = this.props;

    const styles = {};
    if(chartHeight) {
      defaultOptions.grid.height = chartHeight - 20;
      styles.height = chartHeight;
    }
    const options = {
      ...defaultOptions,
      ...this.props.options
    }

    if(barData){
      options.series[0].data = barData
    }

    if(lineData){
      options.series[1].data = lineData
    }

    if(max) {

      const int = max / 5;
      options.yAxis[0].max = max;
      options.yAxis[0].interval = int < 1 ? 0.5 : Math.floor(int)
    }

    return (
      <div>
        <ReactEchartsCore 
          echarts={echarts}
          option={options}
          notMerge={true}
          lazyUpdate={true}
          style={styles}
          />
      </div>
    );
  }
}

export default LineBarChart;
