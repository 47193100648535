import React,{ FunctionComponent } from 'react';
import Grid from "../../core/components/Grid";
import { withStyles } from "../../core/components/Styles";
import Icon from "../../core/components/Icon";

import { formatDate, formatTime } from "../../utils/formatDate";
import { StyleClasses, StyleObj } from "../../types/customTypes";

import { AdditionalBookingDataObj } from "./AdditionalBookingModalProps";

const styles: StyleObj = {
	iconStyle:{
		color: "#686666",
		height: "2.85rem",
		width: "2.85rem",
		borderRadius: "50%",
		display: "inline-flex",
		justifyContent: "center",
		alignItems: "center"
	},
	dataItem:{
		marginLeft: "1rem"
	},
	dataContainer:{
		marginRight: "3rem"
	}
};

export interface BookingsDataProps {
	classes: StyleClasses,
	bookingDataObj: AdditionalBookingDataObj
}
const BookingsData: FunctionComponent<BookingsDataProps> = ({
	classes,
	bookingDataObj
})=>{
	const { date: orderDate, time: orderTime } = bookingDataObj;
	return(
		<Grid item container justify="space-between">
			<DataItem
				classes={{
					dataItem: classes.dataItem 
				}}
				data = {formatDate(orderDate, "DD/MM/YYYY")}
				icon = "date"
			/>
			<DataItem
				classes={{
					dataContainer: classes.dataContainer,
					dataItem: classes.dataItem 
				}}
				data = {formatTime(orderTime, "hh:mm A", "HH:mm:ss A")}
				icon = "time"
			/>
		</Grid>
	);
}

export interface DataItemProps {
	classes: StyleClasses,
	data: string,
	icon: string
};

const DataItem: FunctionComponent<DataItemProps> = (props)=>{
	const { classes, data, icon } = props;
	return(
		<Grid classes={{
			root: classes.dataContainer
		}}>
			<Icon color="#A9A9A9" lineHeight="inherit" fontSize="0.92rem" variant="i">
				{icon}
			</Icon> 
			<span className={classes.dataItem}>
				{data}
			</span>
		</Grid>
	)
};

export default withStyles(styles)(BookingsData);