import React from 'react'
import { Grid,  Paper, Typography  } from "core/components";
import { withStyles } from "core/components/Styles";
import { Alert } from "mdi-material-ui";

const styles = () => ({
  root:{
    margin:15,
  },
  paper:{
    padding:10,
    backgroundColor:"#f34435"
  },
  messageCont:{
    color:"#fff"
  },
  message:{
    color:"#fff",
    fontSize:16
  },
  iconCont:{
    display: 'flex',
    alignItems: 'center',
  },
  marginLeft_10:{
    marginLeft:10
  }
})
const AlertBox = (props) => {
  const { classes, message } = props;
  return (
    <Grid className={classes.root}>
      <Paper className={classes.paper} elevation={1}>
        <Grid className={classes.messageCont} container alignItems="center">
          <Grid item className={classes.iconCont}>
            <Alert />
          </Grid>
          <Grid item className={classes.marginLeft_10} >
            <Typography className={classes.message}>
              {message}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default withStyles(styles)(AlertBox)
