import React, { Component } from 'react';
import { connect } from "react-redux";
import { reduxForm, arrayPush } from 'redux-form';
import InvoiceForm from '../components/InvoiceForm';
import { setMsgAndShow } from '../store/actions/popup';
import { 
  getCustomers,
  getServices, 
  editInvoice
} from "../services/contractor";
import storage from "../utils/Storage";
import { withMixpanel } from "../utils/react-mixpanel";
import DropZone, { DropZoneDialog } from "../components/DropZone";
import {isEqual,isEmpty} from "lodash";
import { updateInvoiceLogo  } from "../store/actions/contractor";
import axios from 'utils/http';
// form container
class EditInvoiceFormContainer extends Component {
  state = {
    total: 0, 
    customers:[],
    productsList: [],
    showDropZone: false ,
    showSavePermanently: false,
    canEditCustomer: false
  } 
  getCustomers = async inputValue => {
    try {
      let customers = [];
      let customersDataRes = getCustomers(inputValue);
      customersDataRes = await customersDataRes;
      const { customers: customerList } = customersDataRes.data;
      if(customerList && customerList.length > 0){
        customers = customerList;
      }
      return customers.map((customer)=>{
        let { serviceAddress:{city,state,address,zipcode} } = customer;
        return {
          label: `${customer.fname} ${customer.lname}`,
          email: customer.email,
          phone: customer.phone,
          address: `${address}, ${city}, ${state} ${zipcode}`,
          value: customer._id
        }
      })
    } catch (error) {
      throw error;
    }
  }

  onClientSelect = (event,newValue, previousValue={}, name) => {
    let showSavePermanently = this.state.showSavePermanently;
    const { value: previousCustomerId } = previousValue ;
    const { value: newCustomerId } = newValue;
    if(previousCustomerId !== newCustomerId){
      if(event && event.value) {
        this.props.change("customerEmail", event.email);
        this.props.change("billingAddress",event.address);
        this.props.change("customerPhone",event.phone); 
      }
      showSavePermanently = false;
    }
    this.setState({ showSavePermanently });
  }

  

  onProductSelect = (event, newValue, previousValue, name) => {
    const productIndex =  parseInt(name.replace(/[^\d]/g, ''));
    if( productIndex === this.props.formValues.products.length-1 ){
      this.props.addLines();
    }
    const selecetdproduct = name.split(".")
    this.props.change(`${selecetdproduct[0]}.description`, newValue.description)
    this.props.change(`${selecetdproduct[0]}.rate`, newValue.rate)
    this.props.change(`${selecetdproduct[0]}.quantity`, newValue.quantity)
  }
  // action performed when the form is submitted


  saveInvoice = ()=> {
    this.props.change("onlySave",true);
    // Settimeout used cause : Redux state update DELAY
    setTimeout(()=>{
      this.props.handleSubmit(this.invoiceSubmitAction)();
    },100);
  };


  invoiceSubmitAction = async (object) => {
    let { products, onlySave, _id:invoiceId, customerId, 
      createdat, 
			customerFirstName, 
			customerLastName, 
      message, 
      messageOnStatement, 
      paymentLinkFull,
      paymentLinkShort,
      amount,
      ...restFormValues
     } = object;
    customerId = customerId.value;
    products = products.filter( p=> ( !isEmpty(p) && !isEqual(p.amount,"") ) && p );
    const { trackApiEvent,setMsgAndShow } = this.props;
    try {
      const response = await editInvoice(invoiceId,{
        customerId,
        products,
        amount: this.state.total,
        onlySave,
        ...restFormValues
      });
      if (response.data.status === "OK") {
        this.props.reset("InvoiceForm")
        trackApiEvent( "Edit_Invoice", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/invoices/:invoiceid`,response.data.status, window.location.pathname);
        setMsgAndShow("Invoice updated successfully");
        this.props.history.push("/invoices");
      }
    } catch (error) {
      const { response  } = error;
      let msg = "Error";
      if(response && response.data){
        msg = response.data.message ? response.data.message : "Error";
      }
      trackApiEvent( "Edit_Invoice", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/invoices/:invoiceId`, msg, window.location.pathname);
      setMsgAndShow(msg);
    }
  }

  //when rows are updated, this function gets called and updates the total
  componentDidUpdate(prevProps, prevState) {
    const { formValues } = prevProps
    let products=[]
    if(formValues && formValues.products) products = formValues.products 
    // let totalValue = 0, amount = 0, rate = 0, qty = 0, amt = 0;
    let totalValue = 0, amount = 0, rate = 0, qty = 0;
    
    if (!isEqual(this.props.formValues,formValues)) { 
      const allProducts = this.props.formValues.products && this.props.formValues.products;
      if (!isEqual(allProducts, products) ) {
        // totalValue = amount = rate = qty = amt = 0;
        totalValue = amount = rate = qty = 0;
        for (let i = 0; i < allProducts.length; i++) {
          let num = allProducts[i];
          rate = isNaN(num.rate) ? 0 : parseInt(num.rate, 10);
          qty = isNaN(num.quantity) ? 0 : parseInt(num.quantity, 10);
          amount = rate * qty;
          if(isNaN(amount)) amount = 0;

          // isNaN(num.amount) ? (amt = 0) : (amt = num.amount)

          this.props.change(`products[${i}].amount`, ((amount === 0) ? "" : amount));

          (allProducts[i].amount === 0) ? (totalValue = prevState.total) :
            (totalValue += isNaN(amount) ? 0 : parseInt(amount, 10))
        }
        this.setState({ total: totalValue })
      }
    }
  }

  async componentDidMount() {
    const { trackApiEvent } = this.props; 
    try {
       const getAllCustomersRes = await getCustomers();
      const { data  } = getAllCustomersRes;
      let productsList = [{}];
      const producstServicesRes = await getServices(); 
      const { services } = producstServicesRes.data; 
      if(services && services.length > 0){
        
        productsList = services.map((p) => ({
          id: p._id,
          label: p.name,
          description: p.description,
          quantity: p.quantity,
          rate: p.rate,
          value: p.value
        }))
      } 
      if(data && data.customers){
        this.setState({ customers: data.customers, productsList, canEditCustomer:true });
      }
      trackApiEvent("Get_Services", "GET",`${process.env.REACT_APP_API_PREFIX}contractor/services/get_all`,"OK",window.location.pathname);
    } catch (error) { 
      trackApiEvent("Get_Services", "GET",`${process.env.REACT_APP_API_PREFIX}contractor/services/get_all`,error,window.location.pathname);
    }
  }

  checkCustomerDetails = (fieldname)=> (e)=>{ 
    const { value:inputValue } = e.target;
    if(!this.props.formValues.customerId) return; 
    const { customerId:{value:cid} } = this.props.formValues; 
    const filteredCustomer = this.state.customers.filter(customer => customer._id === cid);  
    const { serviceAddress: {city,state,address,zipcode}, phone, email } = filteredCustomer[0];
    let billingAddress = `${address}, ${city}, ${state} ${zipcode}`; 
    let flag = false; 
    switch(fieldname){
      case "billingAddress": 
        flag = billingAddress !== inputValue ;
        break;
      case "customerEmail":         
        flag = email !== inputValue ;
        break;
      case "customerPhone":  
        flag = phone !== inputValue ;
        break;
      default: break;
    }
    this.setState({
      showSavePermanently:flag
    });
  }

  // remove all the rows from the table
  clearLines = () => {
    this.props.change("products", [{}])
  }

  // can be used As Clear form option if needed
  // resetForm = () => { 
  //   storage.remove("currentInvoice")
  //   this.props.destroy()
  // } 
  cancelInvoice = () => this.props.history.goBack();

  trackInput = (fieldname)=> (e)=> {
    this.trackField(fieldname,e.target.value);
  }
  
  trackField = (fieldName,value)=>{
    const { trackInputOnBlur } = this.props;
    if(value){
      trackInputOnBlur(fieldName, value, window.location.pathname);
    }
  }

  trackRadio = (fieldName, defaultValue)=> (e)=>{
    const { trackRadioInput } = this.props;
    trackRadioInput(fieldName, defaultValue, e.target.value, window.location.pathname);
  }

  onLogoUpload = ()=> {
    const { trackButtonEvent } = this.props;
    trackButtonEvent("Upload_Company_Logo",window.location.pathname);
    this.setState({
      showDropZone : true
    });
  }

  handleDropZone = () => {
    this.setState({ showDropZone: !this.state.showDropZone });
  };

  fileUploaded = res => {

    const { setMsgAndShow, trackButtonEvent, trackApiEvent, change } = this.props;
    trackButtonEvent("Upload_Company_Logo",window.location.pathname);
    
    try {
      const { data } = res;
      if(data && data.status === "OK"){
        trackApiEvent("Upload_Company_Logo", "POST",`${process.env.REACT_APP_API_PREFIX}contractor/upload_logo`,"OK",window.location.pathname);
        if(data.logo){
          const { logo } = data;
          change("logo",logo.url); 
          setMsgAndShow("Upload was successful");
          this.handleDropZone();
        }
      } else {
        trackApiEvent("Upload_Company_Logo", "POST",`${process.env.REACT_APP_API_PREFIX}contractor/upload_logo`,data.message,window.location.pathname);
        setMsgAndShow(data.message ? data.message : "Error Occured");
      }
    } catch (error) { 
      trackApiEvent("Upload_Company_Logo", "POST",`${process.env.REACT_APP_API_PREFIX}contractor/upload_logo`,error,window.location.pathname);
      setMsgAndShow("Error Occured");
    }

  };

  saveNewCustomerDetails = async()=>{

    const { setMsgAndShow, trackApiEvent } = this.props;
    const { billingAddress, customerEmail, customerPhone, customerId:{value:customerId} } = this.props.formValues;
    try {
      if(customerPhone.length < 10 || customerPhone.length > 10 ){
        setMsgAndShow("Phone number must consist 10 characters");
        return;
      }
      if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(customerEmail)){
        setMsgAndShow("Invalid Email Address");
        return;
      }
      if(/(\d+) ((\w+[ ,])+ ){2}([a-zA-Z]{2}) (\d{5})/.test(billingAddress)){
        let newBillingAddress = billingAddress.match(/(\d+) ((\w+[ ,])+ ){2}([a-zA-Z]{2}) (\d{5})/); 
        let address =  newBillingAddress[0].split(",")[0].trim();
        let city = newBillingAddress[3].replace(",","").trim()
        let state = newBillingAddress[4].trim();
        let zipcode = newBillingAddress[5].trim();  
        const userRes = await axios.put(`${process.env.REACT_APP_API_PREFIX}contractor/update_customer`,{
          customerId,
          updateDetails:{
            email: customerEmail,
            phone: customerPhone,
            serviceAddress: { address, city, state, zipcode }
          }
        });
        // await editInvoice( invoiceId, { customerEmail, customerPhone, billingAddress: `${address}, ${city}, ${state} ${zipcode}` });
        let msg = "";
        if(userRes.data && userRes.data.status === 'OK'){
          if(userRes.data.customer){
            let updateCustomers = this.state.customers.filter(customer => customer._id !== userRes.data.customer._id);
            const updatedCustomers = [ ...updateCustomers, userRes.data.customer ]; 
            this.setState({
              customers: updatedCustomers,
              showSavePermanently:false
            });
            msg = "Customer info updated successfully" ;
          }
        } else {
          msg = userRes.data.message ? userRes.data.message : "Error Occurred";
        }
        trackApiEvent("Save_Permanently","PUT",`${process.env.REACT_APP_API_PREFIX}user/update_details`,msg,window.location.pathname);
        setMsgAndShow(msg);
      }else{
        setMsgAndShow("Invalid Address (Format Accepted : Address, City, State ZipCode )");
        return;
      } 
    } catch (error) { 

      console.log("ERRPR",error);
      let errorMsg = "An error occurred please try again!";
      const { response } = error;
      if(response && response.data && response.data.message){
        errorMsg = response.data.message;
      } 
      setMsgAndShow(errorMsg);
      trackApiEvent("Save_Permanently","PUT",`${process.env.REACT_APP_API_PREFIX}user/update_details`,error,window.location.pathname);
    }
  }

  render() { 
    return (
      <>
        <InvoiceForm
          addLines={this.props.addLines}
          clearLines={this.clearLines}
          invoiceSubmitAction={this.invoiceSubmitAction}
          total={this.state.total}
          customers={this.state.customers}
          onClientSelect={this.onClientSelect}
          onPaymentMethodChange={this.onPaymentMethodChange}
          productsList={this.state.productsList} //products list get by axios
          onProductSelect={this.onProductSelect}
          cancelInvoice={this.cancelInvoice}
          getCustomers={this.getCustomers}
          onLogoUpload={this.onLogoUpload}
          trackInput={this.trackInput}
          trackField={this.trackField}
          trackRadio={this.trackRadio}
          saveInvoice={this.saveInvoice}

          showSavePermanently={this.state.showSavePermanently}
          checkCustomerDetails={this.checkCustomerDetails}
          saveNewCustomerDetails={this.saveNewCustomerDetails}
          canEditCustomer={this.state.canEditCustomer}

          {...this.props}
        />
        <DropZoneDialog
          isOpen={this.state.showDropZone}
          toggle={this.handleDropZone}
          title="Upload Company Logo"
        >
          <DropZone
            onUploaded={this.fileUploaded}
            onCancel={this.handleDropZone}
            url={`${process.env.REACT_APP_API_PREFIX}contractor/upload_logo`}
            accept=".jpg,.png,.gif"
          />
        </DropZoneDialog>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const { form, app } = state;

  let initialValues = {};
  let formValues = {};
  if(form && form.InvoiceForm && form.InvoiceForm.values){
    formValues = {...form.InvoiceForm.values} 
  }
  const currentInvoice = JSON.parse(storage.get('currentInvoice') || "{}");

  if (app.formData.data && !isEmpty(app.formData.data)) {
    initialValues = {...app.formData.data}; 
  } else if(!isEmpty(currentInvoice)) {
    initialValues = {...currentInvoice};    
  }
  
  initialValues.customerId = { value: initialValues.customerId };
  initialValues.onlySave = false; 
  
  return {
    formValues,
    initialValues
  }
}

// addLines function being provided to dispatch action to add rows to the table
const mapDispatchToProps = (dispatch) => {
  return {
    addLines: () => dispatch(arrayPush("InvoiceForm", "products", {})),
    setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg)),
    updateInvoiceLogo: (updateObj) => dispatch(updateInvoiceLogo(updateObj))
  }
}

const withReduxForm = reduxForm({
  form: "InvoiceForm",
  destroyOnUnmount: true,
  enableReinitialize: true
})(EditInvoiceFormContainer);

const connectRedux = connect(mapStateToProps, mapDispatchToProps)(withReduxForm);

export default withMixpanel(connectRedux);