import React, { Fragment, Component } from "react";
import cn from "classnames";
import momentTz from 'moment-timezone';
import Grid from "core/components/Grid";
import TableRow from "core/components/TableRow";
import TableCell from "core/components/TableCell";
import PopUpMenu from "../PopUpMenu";
import Status from "../Status";
import BookingStatusBadge from "../BookingStatusBadge";
import BookingTimeline from "./BookingTimeline";
import { withStyles } from "core/components/Styles";

const styles = () => ({
  tableCell: {
    fontFamily: "Poppins",
    // fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    // textAlign: "center",
    color: " #979797",
    // height: 106,
    verticalAlign: "middle",
    "& button": {
      fontFamily: "Poppins",
      // fontSize: 16,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      // textAlign: "center",
      color: " #52575a",
    },

  },
  tdHeading: {
    // fontWeight: "bold",
    color: "#000"
  },
  actionBtn: {
    // border: "1px solid rgba(148, 151, 155, 0.6)"
  },
  iconCont: {
    marginLeft: 15
  },
  icon: {
    // marginTop:2,
  },
  timelineRow: {
    background: "#f5f5f5",
    border: "none"
  },
  orderDetailsRow: {
    borderTop: " 2px solid #fff",
    // borderTop:" 2px solid #fff",
    "&:hover": {
      cursor: "pointer"
    }
  },
  isTimelineRowOpen: {
    
  }
})

class BookingItemRow extends Component {
  state = {
    isTimelineRowOpen: false
  }

  toggleTimelineRow = () => {
    this.setState({
      isTimelineRowOpen: !this.state.isTimelineRowOpen
    });
  }


  render() {

    const { classes, item, columnProps, actions } = this.props;
    const { isTimelineRowOpen } = this.state;
    return (
      <Fragment>
        <TableRow className={cn(classes.orderDetailsRow, { [classes.isTimelineRowOpen]: isTimelineRowOpen })} key={item._id} onClick={this.toggleTimelineRow}>
          {columnProps &&
            columnProps.map((column, index) => {
              if (column.isName) {
                return (
                  <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                    {item[column.fnameProp] +
                      " " +
                      item[column.lnameProp]}
                  </TableCell>
                );
              } else if (column.isCost) {

                return (
                  <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                    {`$${item[column.name] ? item[column.name] : 0}`}
                  </TableCell>
                );
              } else if (column.isNumeric) {
                return (
                  <TableCell className={classes.tableCell} numeric key={`table-item-${index}`}>
                    {item[column.name]}
                  </TableCell>
                );
              } else if (column.isAddress && item[column.name]) {
                return (
                  <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                    {`${item[column.name][column.addressProp]}, ${
                      item[column.name][column.cityProp]
                      }, 
                            ${item[column.name][column.stateProp]}, ${
                      item[column.name][column.zipcodeProp]
                      }`}
                  </TableCell>
                );
              } else if (column.isDate) {
                return <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                  {momentTz(item[column.name]).format(column.dateFormat)}
                </TableCell>
              } else if (column.isTime) {
                return (
                  <TableCell
                    className={classes.tableCell}
                    key={`table-item-${index}`}
                  >
                    {momentTz(item[column.name], column.originalFormat).format(column.timeFormat)}
                  </TableCell>
                )

              } else {
                if (column.isActionButton) {
                  return (
                    <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                      {/* <Button variant="outlined" className={classes.actionBtn}>
                        {item[column.name]}
                        </Button> */}

                      <PopUpMenu
                        buttonProps={{
                          fontSize: "xs"
                        }}
                        actions={actions}
                        item={item}
                        itemIndex={index}
                        text={item[column.name]}
                      />

                    </TableCell>
                  );
                }


                if (column.isHeading) {
                  return <TableCell
                    className={cn(classes.tableCell, classes.tdHeading)}
                    key={`table-item-${index}`}
                  >
                    {item[column.name]}
                  </TableCell>
                }

                if (column.isStatus) {
                  if (column.isBadge) {
                    return (
                      <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                        <Grid container alignItems="center">
                          <Grid item xs={8}>
                            <BookingStatusBadge bookingStatus={item[column.name]} />
                          </Grid>


                          {
                            column.Icon &&
                            <Grid item xs={2} className={classes.iconCont}>
                              {
                                isTimelineRowOpen ?
                                  <column.SecondaryIcon className={classes.icon} />
                                  :
                                  <column.Icon className={classes.icon} />

                              }
                            </Grid>
                          }
                        </Grid>
                      </TableCell>
                    )
                  }
                  return <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                    <Status status={item[column.name]} />

                  </TableCell>
                }

                return (
                  <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                    {item[column.name]}
                  </TableCell>
                );

              }


            })}
        </TableRow>
        {isTimelineRowOpen ?
          <TableRow className={cn(classes.timelineRow, classes.isTimelineRowOpen)}>
            <TableCell colSpan={columnProps.length}>
              <BookingTimeline orderTimeline={item.jobTimeline} orderStatus={item.orderStatus} />
            </TableCell>
          </TableRow>
          : null
        }
      </Fragment>
    )
  }
}


export default withStyles(styles)(BookingItemRow);