import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
// import { reduxForm } from "redux-form";
import _ from "lodash";
import moment from "moment-timezone";
import axios from "utils/http";
import Dashboard from "../components/Dashboard";
// import AddTodoModal from "../components/AdEmployeeInfo.jsdTodoModal";
import JobDetailsModal from "../components/JobDetailsModal";
import { setMsgAndShow } from "../store/actions/popup";
import { toggleBusinessModal, toggleLoader, toggleDialogLoader } from "../store/actions/app"
import { setCurrTodo } from "../store/actions/todo";
import { openChangesModal } from "../store/actions/jobChanges";
import getReports from "../helpers/getReports";
import getAssignedOrOngoingJobs from "../helpers/getAssignedOrOngoingJobs";
import getEmployeeStats from "../helpers/getEmployeesStats";
import getOrdersByDate from "../helpers/getOrdersByDate";
import getCustomersReviewsStats from "../helpers/getCustomersReviewsStats";
import { withMixpanel } from "../utils/react-mixpanel";
import getCleaningTypesList from "../helpers/getCleaningTypesList";

class DashboardContainer extends Component {
  state = {
    isModalOpen: false,
    isEditTodo: false,
    todos: [],
    totalEarnings:0, 
    totalOutstanding:0, 
    totalPaid:0, 
    totalJobs:0, 
    earningsData:[],
    paidData:[],
    outstandingData:[],
    jobsData:[],
    reportsMode: "today",
    dateWiseJobs:[],
    currDate: new Date(),
    activeJobs:[],
    cleaningTypes:{},
    jobItem:{},
    isJobDetailsModalOpen: false,
    freeEmployeeData: [],
    freeEmployees: 0,
    busyEmployees: 0,
    busyEmployeesPercent: 0.00,
    employeeStatsMode:"week",
    customersReviewsData:[],
    customersReviewsLineData:[],
    totalCustomersReviews: 0,
    customersStatsMode: "month",
    weeks:[],
    currWeek: moment().week(),
    openAssignJobMenu: false,
    employeeList:[],
    gettingJobsReports:true,
    gettingActiveJobs:true,
    gettingCusStats:true,
    gettingEmpStats:true,
    gettingDateWiseJobs:true
  };
  cleaningTypes = {};


  handleClickStart = (e) => {
    e.preventDefault();
  };

  componentDidMount = () => {
    if (!_.isEmpty(this.props.contractorInfo.userInfo)) {
      this.addData();
    }
    const numberOfWeeks = 52;
    const weeks = []
    const matchWeek = moment().week()
    for(let i = 1; i <= numberOfWeeks;i++){

      if(matchWeek === i){
        weeks.push({
          label:`This week`,
          value: i
        });
      } else {
        weeks.push({
          label:`Week ${i}`,
          value: i
        });
      }

    }
    this.setState({ weeks });

    const { trackPageLoad } = this.props;
    trackPageLoad("Dashboard");
  };

  checkForPageRefresh = (prevProps, nextProps) => {
    const { refreshPage:prevRefreshPage } = prevProps;
    const { refreshPage:newRefreshPage } = nextProps;
    let triggerRefresh = false;

    if(prevRefreshPage !== newRefreshPage && newRefreshPage){
      triggerRefresh = true;
    }

    return triggerRefresh;
  }

  componentDidUpdate(prevProps) {
    const { contractorInfo } = prevProps;

    if (
      JSON.stringify(contractorInfo.userInfo) !==
      JSON.stringify(this.props.contractorInfo.userInfo)
    ) {
      this.addData();
    }

    if(this.checkForPageRefresh(prevProps, this.props)){

      console.log("DDDD");
      this.updateLists();
    }

  }



  getJobsReports = async ({mode, date, year, month}) => {

    try {

      this.setState({
        gettingJobsReports:true
      })
      const { _id: contractorId } = this.props.contractorInfo.businessDetails;

      let totalEarnings = 0,
      totalOutstanding = 0, 
      totalPaid = 0, 
      totalJobs = 0, 
      earningsData = [],
      paidData = [],
      outstandingData =[],
      jobsData = [],
      msgForPopUp = "";

       
      const jobsReportsRes = await getReports({
        contractorId,
        mode,
        date,
        year,
        month
      }) 



      if(jobsReportsRes.status === "OK"){
        totalEarnings = jobsReportsRes.totalEarnings
        totalOutstanding = jobsReportsRes.totalOutstanding
        totalPaid = jobsReportsRes.totalPaid
        totalJobs = jobsReportsRes.totalJobs
        earningsData = jobsReportsRes.earningsData
        paidData = jobsReportsRes.paidData
        outstandingData = jobsReportsRes.outstandingData
        jobsData = jobsReportsRes.jobsData
      } else {
        msgForPopUp = jobsReportsRes.data.message
      }


      this.setState({
        gettingJobsReports:false,
        totalEarnings,
        totalOutstanding,
        totalPaid,
        totalJobs,
        earningsData,
        paidData,
        outstandingData,
        jobsData,
      })


      if(msgForPopUp){
        this.props.setMsgAndShow(msgForPopUp);
      }




    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get reports!";
      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        gettingJobsReports:false,
      })
      this.props.setMsgAndShow(errMsg)
    }
  }


  getCleaningTypes = async () => {
    try {
      const cleaningTypes = await getCleaningTypesList();
      this.setState({
        cleaningTypes
      })
    } catch (error) {
      let errMsg = "Unable to get cleaning types!";
      this.props.setMsgAndShow(errMsg)
    }
  }
  


  getAssignedOrOngoingJobs = async () => {
    try {

      this.setState({
        gettingActiveJobs:true
      })

      let activeJobs = [];
      let msgForPop;

      const { _id: contractorId } = this.props.contractorInfo.businessDetails;

      const activeJobsData = await getAssignedOrOngoingJobs(contractorId);

      if(activeJobsData.status === "OK"){
        activeJobs = activeJobsData.orders;
      } else {
        msgForPop = activeJobsData.message;
      }


      this.setState({
        activeJobs,
        gettingActiveJobs:false
      })

      if(msgForPop){
        this.props.setMsgAndShow(msgForPop)
      }


    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get active jobs!";


      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }


      this.setState({
        gettingActiveJobs:false
      })

      this.props.setMsgAndShow(errMsg)
    }



  }



  getOrdersByDate = async  (date) => {

    try {
      
      this.setState({
        gettingDateWiseJobs:true
      })

      const { _id: contractorId } = this.props.contractorInfo.businessDetails;
      const { cleaningTypes } = this.state;
      let dateWiseJobs = [];
      const ordersRes = await getOrdersByDate({
        contractorId,
        date,
      })

      const orders = ordersRes.orders;

      dateWiseJobs = orders.map((job) => ({
          _id: job._id,
          cleaningType: cleaningTypes[job.cleaningType],
          startTime: moment(job.time, "hh:mm:ss").format("hh:mm A"),
          endTime: moment(job.time, "hh:mm:ss")
            .add(job.etc, "minutes")
            .format("hh:mm A")
      }))

      this.setState({
        dateWiseJobs,
        gettingDateWiseJobs:false
      })


    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get active jobs!";

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        gettingDateWiseJobs:false
      })


      this.props.setMsgAndShow(errMsg)
    }
  }


  getEmployeeStats = async ({mode,date,weekStartDate}) => {

    try {

      this.setState({
        gettingEmpStats:true,
      })
      const { _id: contractorId } = this.props.contractorInfo.businessDetails;
      let freeEmployeeData = [],
      freeEmployees = 0,
      busyEmployees = 0,
      busyEmployeesPercent = 0.00,
      msgForPopup;

      const empStatsRes = await getEmployeeStats({
        contractorId,
        mode,
        date,
        weekStartDate
      })
      


      if(empStatsRes.status === "OK"){
        freeEmployeeData = empStatsRes.freeEmployeeData
        freeEmployees = empStatsRes.freeEmployees
        busyEmployees = empStatsRes.busyEmployees
        busyEmployeesPercent = empStatsRes.busyEmployeesPercent
      } else {
        msgForPopup = empStatsRes.message
      }


      this.setState({
        gettingEmpStats:false,
        freeEmployeeData,
        freeEmployees,
        busyEmployees,
        busyEmployeesPercent,
      })

      if(msgForPopup){
        this.props.setMsgAndShow(msgForPopup);
      }


    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get employee stats!";

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        gettingEmpStats:false
      })

      this.props.setMsgAndShow(errMsg)
    }
  }

  getCustomersReviewsStats = async ({mode, date, year, month}) => {

    try {

      this.setState({
        gettingCusStats:true
      })
      const { _id: contractorId } = this.props.contractorInfo.businessDetails;
      let customersReviewsData = [],
      customersReviewsLineData = [],
      totalCustomersReviews = 0,
      msgForPopUp;

  
      const customerRes = await getCustomersReviewsStats({
        contractorId,
        mode,
        date,
        year,
        month
      })



      if(customerRes.status === "OK"){

        customersReviewsData = customerRes.customersReviewsData;
        customersReviewsLineData = customerRes.customersReviewsLineData;
        totalCustomersReviews = customerRes.totalCustomersReviews

      } else {
        msgForPopUp = customerRes.message;
      }

  

      this.setState({
        gettingCusStats:false,
        customersReviewsData,
        customersReviewsLineData,
        totalCustomersReviews
      })


      if(msgForPopUp){
        this.props.setMsgAndShow(msgForPopUp);
      }
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get active jobs!";

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        gettingCusStats:false
      })
      this.props.setMsgAndShow(errMsg)
    }
  }

  addData = async () => {
    const { reportsMode, customersStatsMode, employeeStatsMode } = this.state;
    const currDate = moment().format("YYYY-MM-DD")
    const currMonth =  moment().format("MMMM");
    const currYear = moment().format("YYYY");
    try {
      await this.getCleaningTypes();
      this.getJobsReports({
        mode:reportsMode,
        date:currDate
      })
      this.getAssignedOrOngoingJobs();
      this.getOrdersByDate(currDate);
      this.getEmployeeStats({mode:employeeStatsMode, date:currDate});
      this.getCustomersReviewsStats({mode:customersStatsMode,date:currDate,year:currYear,month:currMonth});
    } catch (error) {
      console.log("ERROR", error);
    }
  };



  updateLists = async () => {

    try {
      const { reportsMode, customersStatsMode, currDate:storedDate, currWeek:storedWeek } = this.state;
      // const { _id: contractorId } = this.props.contractorInfo.businessDetails;
      const currDate = moment().format("YYYY-MM-DD");
      const currMonth =  moment().format("MMMM");
      const currYear = moment().format("YYYY");
      const convertedDate = moment(storedDate).format("YYYY-MM-DD");
      const convertedWeekDate = moment().week(storedWeek).startOf("isoWeek").format("YYYY-MM-DD");
      const jobReportsParams = {
        mode:reportsMode,
        date:currDate,
      }

      const cusStatsParams = {
        mode: customersStatsMode,
        date: currDate
      }

      const empStatsParams = {
        mode: "week",
        weekStartDate: convertedWeekDate,
        date: currDate,
      };
  
      if(reportsMode === "month"){
        jobReportsParams.month = currMonth
        jobReportsParams.year = currYear
      }

      if(customersStatsMode === "month"){
        cusStatsParams.month = currMonth;
        cusStatsParams.year = currYear;
      }

      this.getJobsReports({...jobReportsParams})
      this.getAssignedOrOngoingJobs();
      this.getOrdersByDate(convertedDate);
      this.getCustomersReviewsStats({...cusStatsParams})
      this.getEmployeeStats({...empStatsParams})
      
    } catch (error) {
      console.log("ERROR", error);
    }


  }

  handleModalOpen = () => {
    this.setState({
      isModalOpen: true
    });
  };

  handleTodoChange = async e => {
    const { target } = e;
    let todos = _.cloneDeep(this.state.todos);
    const checked = target.checked;
    const todoBody = {
      todoId: target.value,
      completed: checked
    };

    try {
      await axios.put(`${process.env.REACT_APP_API_PREFIX}todo/update`, {
        ...todoBody
      });
      todos = todos.map(todo => {
        if (todo._id === target.value) {
          todo.completed = checked;
        }
        return todo;
      });

      this.setState({
        todos
      });
    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  };
  handleModalClose = () => {
    this.setState({
      isModalOpen: false,
      isEditTodo: false
    });

    this.props.reset("TodoForm");
    this.props.setCurrTodo(null);
  };

  handleData = type => async (obj, dispatch, props) => {
    let { todos } = this.state;
    const { initialValues } = this.props;
    if (type === "add") {
      try {
        const addTodoRes = await axios.post(
          `${process.env.REACT_APP_API_PREFIX}todo/add`,
          { ...obj }
        );

        const { todo } = addTodoRes.data;
        todos.push(todo);
        this.setState({
          todos
        });
        this.props.reset("TodoForm");
        this.handleModalClose();
      } catch (error) {
        console.error(error); // eslint-disable-line
      }
    } else {
      const todoBody = {
        ...obj,
        todoId: initialValues._id
      };

      delete todoBody._id;

      try {
        await axios.put(`${process.env.REACT_APP_API_PREFIX}todo/update`, {
          ...todoBody
        });

        todos = todos.map(todo => {
          if (todo._id === initialValues._id) {
            return {
              ...todo,
              ...obj
            };
          } else {
            return todo;
          }
        });
        this.setState({
          todos
        });
        this.props.reset("TodoForm");
        this.handleModalClose();
      } catch (error) {
        console.error(error); // eslint-disable-line
      }
    }
  };

  editTodo = todoObj => async () => {
    this.setState({
      isModalOpen: true,
      isEditTodo: true
    });

    const obj = {
      title: todoObj.title,
      date: moment(todoObj.date).format("YYYY-MM-DD"),
      _id: todoObj._id
    };

    this.props.setCurrTodo(obj);
  };

  deleteTodo = todoObj => async () => {
    let { todos } = this.state;
    const obj = {
      todoId: todoObj._id
    };

    try {
      await axios.post(`${process.env.REACT_APP_API_PREFIX}todo/remove`, {
        ...obj
      });
      todos = todos.filter(todo => todo._id !== todoObj._id);
      this.setState({
        todos
      });
      this.props.reset("TodoForm");
      this.handleModalClose();
    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  };

  handleJobDataWise = async e => {
    const type = e.target.value;
    const { toggleLoader } = this.props;
    const { _id: contractorId } = this.props.contractorInfo.businessDetails;
    toggleLoader();
    try {
      const jobsDetailsRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/get_jobs_details`,
        {
          params: {
            contractorId,
            type
          }
        }
      );

      toggleLoader();
      if (jobsDetailsRes.data.status === "OK") {
        const { jobAcceptedCount, jobRequestsCount } = jobsDetailsRes.data;
        this.setState({
          jobDataWise: type,
          jobAcceptedCount,
          jobRequestsCount
        });
      }
    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  };


  getReports = async e => {
    const { toggleLoader, trackPickerEvent, trackApiEvent  } = this.props;
    const IntevalType = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) ;
    trackPickerEvent("Ratings_Interval",IntevalType);
    
    const type = e.target.value;

    const { _id: contractorId } = this.props.contractorInfo.businessDetails;
    const params = {
      mode: type,
    };

    this.setState({
      reportsMode: type
    })

    if(type === "month"){
      params.month = moment().format("MMMM");
      params.year = moment().format("YYYY");
    }
    toggleLoader();
    try {
      const reportsRes = await axios.get(
        `${
          process.env.REACT_APP_API_PREFIX
        }contractor/get_reports`,
        {
          params: {
            contractorId,
            date: moment().format("YYYY-MM-DD"),
            ...params
          }
        }
      );
      toggleLoader();

      if (reportsRes.data.status === "OK") {

        trackApiEvent(`Show_Reports_By_${type}`, "GET", `${process.env.REACT_APP_API_PREFIX}contractor/get_reports`, "OK", window.location.pathname);

        const { 
          totalEarnings, 
          totalOutstanding, 
          totalPaid, 
          totalJobs, 
          earningsData,
          paidData,
          outstandingData,
          jobsData,
        } = reportsRes.data;
        this.setState({
          totalEarnings, 
          totalOutstanding, 
          totalPaid, 
          totalJobs, 
          earningsData,
          paidData,
          outstandingData,
          jobsData,
        });
      }
    } catch (error) {
      toggleLoader();
      const { response } = error;
      let msg = "Error";

      if(response && response.data){
        msg = response.data.message ? response.data.message : "Error";
      }

      trackApiEvent(`Show_Reports_By_${type}`, "GET", `${process.env.REACT_APP_API_PREFIX}contractor/get_reports`, msg, window.location.pathname);
      this.props.setMsgAndShow(msg);
    }
  };

  onDateChange = async (date) => {
    const { cleaningTypes } = this.state
    const { toggleLoader, trackApiEvent, trackInputOnBlur } = this.props;
    trackInputOnBlur("Calendar_Date_Select",moment(date).format("YYYY-MM-DD"),window.location.pathname);
    const { _id: contractorId } = this.props.contractorInfo.businessDetails;
    let dateWiseJobs = [];
    toggleLoader();
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_orders?`,
        {
          params: {
            contractorId,
            date: moment(date).format("YYYY-MM-DD")
          }
        }
      );

      toggleLoader();
  
      const { orders, status, message } = data;
  
      if(status === "OK"){
        trackApiEvent("Calendar_Date_Select","GET",`${process.env.REACT_APP_API_PREFIX}contractor/get_orders`,"OK",window.location.pathname);
        dateWiseJobs = orders.map((job) => ({
            _id: job._id,
            cleaningType: cleaningTypes[job.cleaningType],
            startTime: moment(job.time, "hh:mm:ss").format("hh:mm A"),
            endTime: moment(job.time, "hh:mm:ss")
              .add(job.etc, "minutes")
              .format("hh:mm A")
          }))
      } else {
        this.props.setMsgAndShow(message);
        trackApiEvent("Calendar_Date_Select","GET",`${process.env.REACT_APP_API_PREFIX}contractor/get_orders`,message,window.location.pathname);
        
      }
      this.setState({
        dateWiseJobs,
        currDate: date
      })
    } catch (error) {
      toggleLoader();
      const { response } = error;
      let msg = "Error";
      if(response  && response.data){
        msg = response.data.message ? response.data.message : "Error";
      }
      this.props.setMsgAndShow(msg);
    }
   
  }

  openJob = (jobItem) => () => {
    const { trackButtonEvent } = this.props;
    trackButtonEvent("View_Job",window.location.pathname);
    this.setState({
      isJobDetailsModalOpen: true,
      jobItem
    });
  }

  handleJobDetailsModal = () => {
    this.setState({
      isJobDetailsModalOpen: false,
      openAssignJobMenu: false,
      jobItem: {}
    });
  }

  getEmployeesStats = async (e) => {

    const type = e ? e.target.value : moment().week();
    const { toggleLoader, trackDropdownInput }  = this.props;
    const { _id: contractorId } = this.props.contractorInfo.businessDetails;
    const params = {
      contractorId,
      mode: "week",
      weekStartDate: moment().week(type).startOf("isoWeek").format("YYYY-MM-DD"),
      date: moment().format("YYYY-MM-DD")
    };

    trackDropdownInput(`Week_${e.target.value}`,params.weekStartDate,"Employee_Stats_Week_Interval");

    this.setState({
      currWeek: type
    });

    toggleLoader();
    try {
      
      const employeeStatsRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_employee_stats?`,
        {
          params: {
            ...params
          }
        }
      )

      toggleLoader()

      if(employeeStatsRes.data.status === "OK"){
        const { data } = employeeStatsRes;


        const { 
          freeEmployeeData,
          freeEmployees,
          busyEmployees,
          busyEmployeesPercent,
        } = data;
        this.setState({
          freeEmployeeData,
          freeEmployees,
          busyEmployees,
          busyEmployeesPercent,
        })

      } else {
        this.props.setMsgAndShow(employeeStatsRes.data.message)
      }
    } catch (error) {
      toggleLoader();
      const { response } = error;
      this.props.setMsgAndShow(response ? response.data.message : "Error")
    }

  }

  handleCustomersReviewsStatsModeChange = async (e) => {
    const type = e.target.value;
    const { toggleLoader, trackPickerEvent } = this.props;
    trackPickerEvent("Customer_Reviews_Interval",type);

    const { _id: contractorId } = this.props.contractorInfo.businessDetails;
    const params = {
      contractorId,
      mode: type,
      date: moment().format("YYYY-MM-DD")
    };



    if(type === "month"){
      params.month = moment().format("MMMM");
      params.year = moment().format("YYYY");
    }


    this.setState({
      customersStatsMode: type
    })

    toggleLoader();
    try {
      
      const customersStatsRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_customers_reviews_stats?`,
        {
          params: {
            ...params
          }
        }
      )

      toggleLoader();

      if(customersStatsRes.data.status === "OK"){
        const { data } = customersStatsRes;

        const { 
          customersReviewsData,
          customersReviewsLineData,
          totalCustomersReviews
        } = data;
        this.setState({
          customersReviewsData,
          customersReviewsLineData,
          totalCustomersReviews,
        })

      } else {
        this.props.setMsgAndShow(customersStatsRes.data.message)
      }
    } catch (error) {
      toggleLoader();
      const { response } = error;
      this.props.setMsgAndShow(response ? response.data.message : "Error")
    }
  }

  assignJob = e => {
    const { target } = e;
    const { value, checked } = target;
    const jobItem = _.cloneDeep(this.state.jobItem);

    if (checked) {
      jobItem.assignedEmployeeIds.push(value);
      this.setState({
        jobItem
      });
    } else {
      const employeeIndex = jobItem.assignedEmployeeIds.indexOf(value);
      if (employeeIndex > -1) {
        jobItem.assignedEmployeeIds.splice(employeeIndex, 1);
        this.setState({
          jobItem
        });
      }
    }
  };
  openAssignMenuFromModal =  (item) => async () => {
    const { toggleDialogLoader } = this.props;
    toggleDialogLoader()
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}employee/get_employee_data_by_job`,
        {
          params: {
            contractorId: this.props.contractorInfo.businessDetails._id,
            time: item.time,
            date: moment(item.date).format("YYYY-MM-DD"),
            jobId: item._id
          }
        }
      );
      toggleDialogLoader();

      const { employeeList } = data;

      this.setState({
        employeeList,
        openAssignJobMenu:true,
        // jobItemIndex: jobIndex,
        // jobItemObj: item
      });
    } catch (error) {
      toggleDialogLoader();
      const { response } = error;

      this.setState({
        open: true,
        // btnRef: itemRef,
        // jobItemIndex: jobIndex
      });
      this.props.setMsgAndShow(response ? response.data.msg : "Error");
    }
  }

  handleAssignMenuClose = () => {
    this.setState({
      openAssignJobMenu: false,
     });
  }


  confirmAssign = async () => {
    const { toggleDialogLoader } = this.props;
    let {
      jobItem,
      activeJobs
    } = this.state;
    let msgForPopUp;



    const jobId = jobItem._id;
    const displayId = jobItem.displayId;
    const updateObj = {
      orderId: jobId,
      assignedEmployeeIds: jobItem.assignedEmployeeIds
    };

    if (jobItem.assignedEmployeeIds.length > 0) {
      msgForPopUp = `Job Id '${displayId}' is assigned`;
    } else {
      msgForPopUp = `Job Id '${displayId}' is unassigned`;
    }

    toggleDialogLoader();

    try {
      const updatedJobRes = await axios.post(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/assign`,
        {
          ...updateObj
        }
      );


      toggleDialogLoader();
      if(updatedJobRes.data.status === "Success"){
        const updatedJob = updatedJobRes.data.job;
        activeJobs = activeJobs.map(job => job._id === jobId ? {...job, updatedJob} : job);

  
        this.setState({
          jobItem: {
            ...jobItem,
            ...updatedJob
          },
          activeJobs
        });

        if(moment(updatedJob.date).week() === moment().week()){
          this.getEmployeesStats();
        };
        this.handleAssignMenuClose();
      } else {
        msgForPopUp = updatedJobRes.data.message;
      }

      if(msgForPopUp){
        this.props.setMsgAndShow(msgForPopUp);
      }
  
    } catch (error) {
      toggleDialogLoader()
      const { response } = error;
      this.props.setMsgAndShow(response ? response.data.message : "Error");
    }
  };

  onBusinessModelClose = () => {
    const { toggleBusinessModal } = this.props;
    const res = confirm("This information is required for getting orders if you don't wish to receive orders click 'OK'") //eslint-disable-line no-restricted-globals

    if(res){
      toggleBusinessModal()
    }
  }

  closeBusinessDetailsModal = () => {
    const { toggleBusinessModal } = this.props;
    toggleBusinessModal();
  }

  openJobChangesModal = (obj) => () => {
    this.props.openChangesModal(obj)
  }

  handleLinkEvent = (linkName)=> ()=>{
    const { trackLinkEvent_inBlock } = this.props;
    trackLinkEvent_inBlock(linkName,window.location.pathname);
  }

  render() {

    const jobActions = [
      {
        label: "Assign",
        onClick: this.confirmAssign
      }
    ];
    return (
      <Fragment>
        <Dashboard
          handleModalOpen={this.handleModalOpen}
          handleModalClose={this.handleModalClose}
          handleTodoChange={this.handleTodoChange}
          editTodo={this.editTodo}
          deleteTodo={this.deleteTodo}
          handleJobDataWise={this.handleJobDataWise}
          handleInvoiceType={this.handleInvoiceType}
          getReports={this.getReports}
          onDateChange={this.onDateChange}
          openJob={this.openJob}
          getEmployeesStats={this.getEmployeesStats}
          getCustomersReviewsStats={this.handleCustomersReviewsStatsModeChange}
          onBusinessModelClose={this.onBusinessModelClose}
          closeBusinessDetailsModal={this.closeBusinessDetailsModal}
          openJobChangesModal={this.openJobChangesModal}
          handleLinkEvent={this.handleLinkEvent}
          {...this.state}
          {...this.props}
        />

        <JobDetailsModal
          handleJobDetailsModal={this.handleJobDetailsModal}
          openAssignMenuFromModal={this.openAssignMenuFromModal}
          handleMenuClose={this.handleAssignMenuClose}
          errMsg="No Data Available"
          assignJob={this.assignJob}
          jobActions={jobActions}
          cleaningTypesObj={this.state.cleaningTypes}
          jobItemObj={this.state.jobItem}
          {...this.state}
        />
        {/* <AddTodoModal
          handleModalClose={this.handleModalClose}
          handleData={this.handleData}
          {...this.state}
          {...this.props}
        /> */}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: state.todos.currTodo,
  contractorInfo: state.contractor,
  showBusinessDetailsModal: state.app.showBusinessDetailsModal,
  ordersWithChanges: state.app.ordersWithChanges,
  refreshPage:state.app.refreshPage
});

const mapDispatchToProps = dispatch => ({
  setCurrTodo: todoObj => dispatch(setCurrTodo(todoObj)),
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  toggleBusinessModal: () => dispatch(toggleBusinessModal()),
  toggleLoader: () => dispatch(toggleLoader()),
  toggleDialogLoader: () => dispatch(toggleDialogLoader()),
  openChangesModal: (obj) => dispatch(openChangesModal(obj))
});

const connectRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardContainer);

export default withMixpanel(connectRedux);
