import axios from "utils/http";

const getAssignedOrOngoingJobs = async (contractorId) => {
  try {
    const dataResult = await axios.get(
      `${process.env.REACT_APP_API_PREFIX}contractor/get_assigned_or_ongoing_jobs?`,
      {
        params: {
          contractorId,
        }
      }
    );

    const res = dataResult.data;
    return res;
  } catch (error) {
    throw error;
  }
}


export default getAssignedOrOngoingJobs;