function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 18.02,
    height: 17.995,
    viewBox: "0 0 18.02 17.995"
  }, props), React.createElement("g", {
    id: "prefix__iconfinder_user-2_430127",
    transform: "translate(-13.503 -13.51)"
  }, React.createElement("path", {
    id: "prefix__Path_314",
    d: "M40.887 25.954a4.8 4.8 0 0 1-4.79-4.79V18.8a4.79 4.79 0 1 1 9.58 0v2.364a4.8 4.8 0 0 1-4.79 4.79zm0-11.265a4.115 4.115 0 0 0-4.11 4.11v2.364a4.11 4.11 0 0 0 8.221 0V18.8a4.115 4.115 0 0 0-4.111-4.11z",
    className: "prefix__cls-1",
    "data-name": "Path 314",
    transform: "translate(-18.339)",
    style: {
      "fill": "#fff",
      "stroke": "#fff"
    }
  }), React.createElement("path", {
    id: "prefix__Path_315",
    d: "M29.015 84.517h-13A2.011 2.011 0 0 1 14 82.508v-1.484c0-.655.332-1.534 1.914-1.995l1.945-.538a.34.34 0 0 1 .181.655l-1.94.537c-1.42.414-1.42 1.112-1.42 1.341v1.484a1.33 1.33 0 0 0 1.329 1.329h13a1.33 1.33 0 0 0 1.329-1.329v-1.484c0-.619-.479-1.071-1.423-1.342l-1.851-.517a.34.34 0 1 1 .183-.655l1.854.518c1.585.456 1.917 1.337 1.917 2v1.484a2.01 2.01 0 0 1-2.003 2.005z",
    className: "prefix__cls-1",
    "data-name": "Path 315",
    transform: "translate(0 -53.512)",
    style: {
      "fill": "#fff",
      "stroke": "#fff"
    }
  })));
};

export default __webpack_public_path__ + "static/media/job_id.249f4979.svg";
export { SvgComponent as ReactComponent };