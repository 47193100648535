import React from "react";
import Grid from "core/components/Grid";
import Card from "core/components/Card";
import CardContent from "core/components/CardContent";
import Button from "core/components/Button";
import TextField from 'core/components/TextField';
import { withStyles } from "core/components/Styles";
import logo from "../../assets/images/logo.jpg";

const styles = theme => ({
  root: {
    backgroundColor: "#f9f9f9",
    height: "100vh",
    width: "100%"
  },
  mainData: {
    padding: 20
  },
  mainDiv: {
    width: "100%"
  },
  title: {
    marginTop: 20
  },
  mainCard: {
    width: 400
  },
  textCenter: {
    textAlign: "center"
  },
  secondItem: {
    marginTop: 30
  },
  formCont:{
    display: 'flex',
    flexDirection: 'column',
  },
  loginBtn: {
    backgroundColor:"#4a4a4a",
    marginTop: 20
  }
});

const Login = ({
  classes,
  handleLogin,
  handleChange
}) => {
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <Card raised={true}>
        <CardContent className={classes.mainCard}>
          <Grid
            container
            className={classes.mainData}
            justify="center"
            alignContent="center"
            alignItems="center"
            direction="column"
          >
            <img src={logo} alt="logo" />
            <span className={classes.title}>Zoklean Dashboard</span>
          </Grid>
          <Grid className={classes.secondItem}>
            <form className={classes.formCont}>
              <TextField
                id="username"
                label="Username"
                margin="normal"
                type="email"
                onChange={handleChange("username")}
              />
              <TextField
                id="password"
                label="Password"
                type="password"
                margin="normal"
                onChange={handleChange("password")}
              />
             <Button onClick={handleLogin} className={classes.loginBtn}>Login</Button>
            </form>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(Login);