export const getCookie = (cookie) => {
  return document.cookie.split(';').reduce((prev, c) => {
      var arr = c.split('=');
      return (arr[0].trim() === cookie) ? arr[1] : prev;
  }, undefined);
}

export const setCookie = (name,value,domaiin,days) =>{
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export const deleteCookie =  (name) => {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}




