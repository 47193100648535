import React from "react";
import { Grid,IconButton } from "core/components";
import { withStyles } from "core/components/Styles";
// import { Magnify } from "mdi-material-ui";
// import cn from "classnames"

import { Magnify, Close } from "mdi-material-ui";
// import Icon from "core/components/Icon";

const styles = theme => ({
  searchBoxContTwo:{
    width: "100%", 
    height: "inherit",
    boxShadow : "0px 2px 6px rgba(0, 0, 0, 0.128223)",
    borderRadius : 34,
    backgroundColor: "#ffffff",
    paddingLeft:"25px !important",
    paddingRight: "15px !important",
    fontFamily: "Montserrat",
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    alignContent:"center"
  },
  searchBox:{
    fontFamily : "Poppins",
    width : "100%",
    lineHeight : "29px",
    fontWeight : 300,
    fontStyle : "normal",
    border:"none",
    fontSize: 12,
    outline:"none",
    color: "rgba(107, 107, 107, 0.54)",
    flex:1,
    "&::placeholder":{
      color: "#a8b2b9",
    }
  },
  searchIcon:{
    color:"#a8b2b9",
    height : 19,
    width : 19
  },
  clearSearchCont:{
    height: 24,
    width: 24,
    color: "#a8b2b9"
  },
  clearIcon:{
    color:"#a8b2b9",
    height : 19,
    width : 19
  },
  marginRight :{
    marginRight : 24
  } 
})
 

const SearchBar = (props) => {

  const { placeholder, classes, onChange, reverse, onBlur, value, onClear}  = props;
  return (
     <Grid container direction={reverse ? "row-reverse" : "row"} className={classes.searchBoxContTwo}>
        {
          value ?
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClear}
            classes={{
              root : classes.clearSearchCont,
            }}
          >
            <Close classes={{
              root: classes.clearIcon
            }} />
          </IconButton>
          : 
          <Magnify 
            className={classes.searchIcon}
          />
        }
        
        <input 
          placeholder={placeholder} 
          className={classes.searchBox} 
          onChange={onChange} 
          onBlur={onBlur}
          value={value}
        />
      </Grid>
  )
} 

/**
 * <Icon className={classes.iconColor} lineHeight="inherit" fontSize="1.2rem">
      {icon}
    </Icon>
 */
export default withStyles(styles)(SearchBar); 