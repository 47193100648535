function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 18,
    height: 17.996,
    viewBox: "0 0 18 17.996"
  }, props), React.createElement("path", {
    id: "prefix__iconfinder_icon-home_2867911",
    d: "M11.895 18.2v-4.5h-1.8v4.5a1.8 1.8 0 0 1-1.8 1.8h-2.7a1.8 1.8 0 0 1-1.8-1.8v-6.831l-.27.27a.9.9 0 1 1-1.26-1.278l8.1-8.1a.9.9 0 0 1 1.26 0l8.1 8.1a.9.9 0 0 1-1.26 1.278l-.27-.27V18.2a1.8 1.8 0 0 1-1.8 1.8h-2.7a1.8 1.8 0 0 1-1.8-1.8zm4.5 0V9.569l-5.4-5.4-5.4 5.4V18.2h2.7v-4.5a1.805 1.805 0 0 1 1.8-1.8h1.8a1.8 1.8 0 0 1 1.8 1.8v4.5z",
    transform: "translate(-1.945 -2.004)",
    style: {
      "fill": "#fff"
    }
  }));
};

export default __webpack_public_path__ + "static/media/job_domestic_type.174da5b0.svg";
export { SvgComponent as ReactComponent };