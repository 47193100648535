import React from "react";
import { Typography } from "core/components";
import cn from "classnames";
import {capitalizeFirstLetterOnly } from "../../utils/capitalize";
import {  withStyles } from "core/components/Styles";

const styles = () => ({
  default:{
    color: "#52575a"
  },
  confirmed:{
    color: "#f5a623"
  },
  ongoing:{
    color: "#4a90e2"
  },
  completed:{
    color: "#63ac13"
  },
  status:{
    fontSize: 13
  }

});


const Status = (props) => {
  const {status, classes } = props;
  let statusStyleClass = "";
  switch(status){
    case "completed":
    statusStyleClass = classes.completed;
    break;
    case "ongoing":
    statusStyleClass = classes.ongoing;
    break;
    case "confirmed":
    statusStyleClass = classes.confirmed;
    break;
    default:
    statusStyleClass = classes.default;
  }

  return (
    <Typography className={cn(classes.status,statusStyleClass)}>
       {capitalizeFirstLetterOnly(status)}
    </Typography>
  )
}


export default withStyles(styles)(Status);