import React from "react";
import {
  StripeProvider,
  Elements
} from "react-stripe-elements";

const Stripe = props => {
  const {children} = props;
  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
      <Elements>
        {children}
      </Elements>
    </StripeProvider>
  );
};

export default Stripe;
