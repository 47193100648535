import React from "react";
import Grid from "core/components/Grid";
import {primaryGradient, secondaryGradient,
  // warning, success, danger
} from "../../utils/colors";

import cn from "classnames";
import { withStyles } from  "core/components/Styles"
import moment from "moment-timezone";
import { ReactComponent as DoneOCircle} from "assets/images/icons/done-o-circle.svg"

const styles =  (theme) => ({
  bookingTimeline: {
    padding: "2rem 3rem"
  },
  timeline: {
    position:"relative"
  },
  timelineTitle: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: "30px",
    color: "#1b1b1b"
  },
  event: {
    position: "relative",
    zIndex: 1,
    textAlign: "center",
  },

  eventPoint: {
    width: "1.8rem",
    height: "1.8rem",
    display: "inline-flex",
    borderRadius: "50%",
    position:"relative",
    background: "#f5f5f5",
    "& svg": {
      width: "100%",
      height: "100%"
    }
  },
  eventPointCircle: {
    border: "2px solid #50d2c2"
  },
  eventPointPrimary: {
    background: primaryGradient
  },
  eventPointSecondary: {
    background: secondaryGradient
  }, 
  connector: {
    position: "absolute",
    width: "100%",
    height: 3,
    background: "#50d2c2",
    zIndex: 0,
    left: 0,
    top: "0.9rem",
    transform: "translateY(-50%)"
  },
  connectorMask: {
    display: "block",
    background: "#f5f5f5",
    height: "1.8rem",
    top: 0,
    position: "absolute",
    width: "50%",
    zIndex: -1,
  },
  connectorMaskRight: {
    right: 0
  },
  eventTitle: { 
    color: "#4A4A4A"
  }, 
  eventDate: {
    color: "#000"
  },
  [theme.breakpoints.down('sm')]: {
    timelineTitle: {
      textAlign: "left"
    },
    bookingTimeline: {
      padding: "1.5rem"
    },
    connectorMask: {
      display: "none"
    },
    event: {
      display: "flex",
      width: "100%",
      marginBottom: 40
    },
    eventPoint: {
      marginRight: 15
    },
    eventDetail: {
      textAlign: "left",
      fontFamily:"Poppins"
    },
    connector: {
      top: "1rem",
      height: "75%",
      transform: "none",
      left: "0.9rem",
      width: 3
    }
  }
})

const TimelineEvent = ({classes, mask, maskRight, variant, title, value}) => (
  <div className={classes.event}>
    {mask && <span className={classes.connectorMask}></span> }
    {maskRight && <span className={cn(classes.connectorMask, classes.connectorMaskRight)}></span>}
    <span className={
      cn(
        classes.eventPoint, 
        variant === "primary" && classes.eventPointCircle, 
        // variant === "secondary" && classes.eventPointSecondary, 
        // variant === "primary" && classes.eventPointPrimary
        )
      }
    >{variant === "secondary" ? <DoneOCircle /> : null}</span>
    <div className={classes.eventDetail}>
      <div className={classes.eventTitle}>{title}</div>
      <div className={classes.eventDate}>{value ? moment(value).format("DD MMM YYYY") : null}</div>
    </div>
  </div>
)

const Timeline = ({classes, orderTimeline, orderStatus}) => (
  orderTimeline ? (<div className={classes.bookingTimeline}>
    <p className={cn(classes.timelineTitle, "success")}>Your Booking has been {orderStatus}</p>
    <div className={classes.timeline}>
      <Grid className={cn(classes.eventPoints)} container alignItems="flex-start" justify="space-between" alignContent="center">
        
        <TimelineEvent key={`timeline-event-1`} variant={orderTimeline.booked ? "secondary" : "primary" } classes={classes} title="Booked" value={orderTimeline.booked} mask/>
        <TimelineEvent key={`timeline-event-2`} variant={orderTimeline.accepted ? "secondary" : "primary"} classes={classes} title="Accepted" value={orderTimeline.accepted} />
        <TimelineEvent key={`timeline-event-3`} variant={orderTimeline.assigned ? "secondary" : "primary"} classes={classes} title="Assigned" value={orderTimeline.assigned} />
        <TimelineEvent key={`timeline-event-4`} variant={orderTimeline.completed ? "secondary" : "primary"} classes={classes} title="Completed" value={orderTimeline.completed} maskRight/>
        <span className={classes.connector}></span>
  
      </Grid>
    </div>
  </div>) : ""
);

export default withStyles(styles)(Timeline);