import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import FormControl from "core/components/FormControl";
import withStyles from "core/components/Styles/withStyles";
import InputLabel from "core/components/InputLabel";
import {isEmpty} from "lodash";
import cn from "classnames";

import { Async } from 'react-select';

const icon = () => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
   fontSize: 16,
    color:"#b7b7b7",
    borderRadius: 10,
    content: '"\\e91b"', // Icomoon property
    display: 'block',
    fontFamily: "icomoon",
    margin: "8px 16px 8px 8px"
  },
});

const styles = theme => ({
  chip: {
    margin: "4px 8px 4px 0px",
    boxShadow: "0 2px 4px 0 rgba(178, 188, 196, 0.15) !important",
    border: "solid 1px #cbcbcb !important",
    // background: "#fff !important"
  },
  chipMultiSelect: {
    "&.has-value": {
      "& .Select-control": {
        padding: 5
      }
    },
    "&.Select--multi": {
      "& .Select-value": {
        background: "none",
        border: 0,
        margin: 0,
        "& .Select-value-icon": {
          display: "none"
        },
      },
      ".Select-value-label": {
        padding: 0
      }
    }
  },
  advanceSelect: {
    margin : 0,
    height : 45,
    //width : 392,

    "& .Select-control": {
      border: 0,
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      boxShadow: "none",
      borderRadius: 0,
      position: "relative",
      transition: "borderBottom 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
      },
      "&:hover::before": {
        opacity: 1
      },
      "&::before": {
        content: '" "',
        display: "block",
        width: "100%",
        position: "absolute",
        bottom: 0,
        opacity: 0,
        // border: "1px solid #000"
      },
      "& .Select-value": {
        paddingLeft: 0
      },
      "& .Select-placeholder": {
        color: theme.palette.default,
        paddingLeft: 0
      }
    },
    "&.is-focused .Select-control": {
      opacity: 1,
      borderBottom: "1px solid #f79029 !important"
    },
    "&.chip-multi-select": {
      "&.has-value": {
        "& .Select-control": {
          padding: 5
        }
      },
      "& .Select-value": {
        background: "none",
        border: 0,
        margin: 0,
        "& .Select-value-icon": {
          display: "none"
        },
      },
      "&.Select--multi .Select-value-label": {
        padding: 0
      }
    }
  },
  disableSelectUnderline: {
    "& .Select-control": {
      borderBottom: "none !important",
    },
    "&.is-focused .Select-control": {
      opacity: 1,
      borderBottom: "none !important"
    },
  },
  advanceSelectContainer: {
    margin: "0.3rem 0",
  },
  advanceSelectControl: {
    border : 0,
    borderRadius : 3,
    marginTop: 0,
    position: 'static',
    "& label": {
      color : "#929292",
      lineHeight : "18px",
      fontFamily : "Poppins",
      fontWeight : "normal",
      pointerEvents: "none",
      zIndex: 0,
      transform: "translate(0, 1.5px) scale(0.75)",
      transformOrigin: "top left"
    }
  },
  error: {
    color: "red",
    fontSize: 12,
  },
  warning: {
    color: "orange",
    fontSize: 12
  },
  noMargin : {
    margin : 0
  }
});

const createMaterialStyles = materialStyles =>  {
  return {
    control: styles => ({ 
      ...styles,
      height: "100%",
      backgroundColor: 'black',
      border: 0,
      boxShadow: "none",
      borderRadius: 0,
      position: "relative",
      transition: "borderBottom 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
      },
      "&:hover::before": {
        opacity: 1
      },
      "&::before": {
        content: '" "',
        display: "block",
        width: "100%",
        position: "absolute",
        bottom: 0,
        opacity: 0,
        // border: "1px solid #000"
      },
      ...materialStyles
    })
  }
}
const createStyles = (controlOptions) =>  {
  // Cheking property in case of Add invoice page 'Select...' User option.
  let styleObj = {
    control: styles => ({ 
      ...styles,
      height: "100%",
      border: 0,
      boxShadow: "none",
      borderRadius: 0,
      position: "relative",
      transition: "borderBottom 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      backgroundColor: "#F6F7F9",
      "&:hover::before": {
        opacity: 1
      },
      "&::before": {
        content: '" "',
        display: "block",
        width: "100%",
        position: "absolute",
        bottom: 0,
        opacity: 0,
        // border: "1px solid #000"
      },
      ...controlOptions
    }), 
    indicatorSeparator: styles => ({
      display: "none"
    }),
    menu: (base)=>({
      ...base,
      zIndex: 100
    })
  }
  if(controlOptions && controlOptions.userIcon){
    styleObj = {
      ...styleObj,
      input: (styles)=> ({ ...styles, ...icon() }),
      placeholder: (styles)=> ({ 
        ...styles, 
        color: "#b7b7b7",
        marginLeft: 42
      }),
      singleValue: (styles)=> ({ ...styles, ...icon() }),
      menu: (base)=>({
        ...base,
        zIndex: 100
      })
    }
  }
  return styleObj;
}

const preventDefault = event => event.preventDefault();

class SelectComponent extends Component {
  static propTypes = {
    classes: PropTypes.object,
    config: PropTypes.object,
    options: PropTypes.array,
    input: PropTypes.object,
    meta: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    id: PropTypes.string,
    chip: PropTypes.bool,
    placeholderAdd: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    allowAdd: PropTypes.bool,
    createObject: PropTypes.func,
    multiple: PropTypes.bool,
    rightArrow: PropTypes.func,
    objectCreate: PropTypes.object,
    notClear: PropTypes.bool,
    renderSelectedFunc: PropTypes.func,
    renderOptionFunc: PropTypes.func,
    clearValueFunc: PropTypes.func,
    disableUnderline: PropTypes.bool
  };

  state = {
    openNew: false,
    selected: null
  };

  componentWillReceiveProps(newProps) {
    if (
      this.props.objectCreate &&
      newProps.objectCreate &&
      this.props.objectCreate.success !== newProps.objectCreate.success &&
      newProps.objectCreate.success === true
    ) {
      this.setState({ openNew: false });
    }
  }

  emptyFunc = () => { };

  openNew = () => {
    this.setState({ openNew: true });
  };

  onChange = item => {
    if (item && item.value === "-1") {
      this.openNew();
    } else {
      const { input } = this.props;
      input.onChange(item);
    }
  };

  handleOnBlur = e => {
    e.preventDefault();
  }

  render() {
    const {
      input,
      label,
      meta,
      classes,
      id,
      isMulti,
      options,
      chip,
      rightArrow,
      allowAdd,
      placeholderAdd,
      placeholder,
      createObject,
      config,
      notClear,
      disableUnderline,
      disableSpacing,
      disableControlSpacing,
      material,
      persistentLabel,
      async,
      loadOptions,
      multiple,
      backgroundColor,
      
      userIcon,
      ...custom
    } = this.props;

    const selectClasses = cn({
      'advance-select': true,
      [classes.advanceSelect]: true,
      'chip-multiple-select': chip,
      [classes.chipMultiSelect]: chip,
      [classes.disableSelectUnderline]: disableUnderline,
      [classes.disableSpacing]: disableSpacing
    });

    const controlClasses = cn({
      "advance-select-control": true,
      [classes.advanceSelectControl]: true,
      [classes.disableSpacing]: disableControlSpacing,
      [classes.advanceSelectControl]: classes.advanceSelectControl
    })


    const showLabel = (input && input.value && !isEmpty(input.value)) || persistentLabel;

    const { touched = false, error, warning, invalid } = meta || {};

    const selectOptions = allowAdd && options
    ? [...options, { label: placeholderAdd, value: "-1" }]
    : options
    
    const selectedValue = input && input.value && input.value.value ? 
      options ? options.find(item => item.value === input.value.value) 
      : input.value
    : null;

    let materialStyles = createMaterialStyles();
    if(backgroundColor){
      materialStyles = createMaterialStyles({backgroundColor});
    }

    let styles = createStyles();
    if(backgroundColor) {
      styles = createStyles({backgroundColor});
    }

    if(userIcon){
      styles = createStyles({backgroundColor,userIcon});
    }
    let selectProps = {
      ...custom,
      isMulti: isMulti,
      options: selectOptions,
      className: selectClasses,
      placeholder: placeholder,
      id: id,
      clearable:notClear ? false : !isMulti,
      styles: material ? materialStyles: styles,
    }

    if(input) {
      selectProps = {
        ...input,
        ...selectProps,
        value: selectedValue,
        onBlur: preventDefault,
        onChange: this.onChange
      }
    }

    let SelectComponent = Select;
    if(async && loadOptions) {
      SelectComponent = Async;
      // selectProps.cacheOptions = true;
      selectProps.defaultOptions = options;
      selectProps.loadOptions = loadOptions;
    } else {
      selectProps.searchable = true;
    }

    return (
      <div className={`${classes.advanceSelectContainer} ${meta && touched && error ? "has-error" : ""}`}>
        <FormControl fullWidth className={controlClasses}>
          <SelectComponent
            {...selectProps}
          />
          {showLabel && <InputLabel htmlFor={id}>{label} </InputLabel>}
        </FormControl>
        <div className="help-block">
        {touched &&
          (
            (error && <span className={classes.error}>{error}</span>) ||
            (warning && <span className={classes.warning}>{warning}</span>) ||
            (invalid && <span className={classes.invalid}>{invalid}</span>)
          )
        }
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SelectComponent);
