import React, { FunctionComponent, MouseEventHandler } from 'react';
import { connect } from "react-redux";
import { removeAndHideAdditionalBookings } from "../../store/actions/jobChanges";

import Loader from "../ProgressCircle";
import BookingsData from "./BookingsData";

import Dialog from "../../core/components/Dialog";
import DialogTitle from "../../core/components/DialogTitle";
import DialogContent from "../../core/components/DialogContent";
import IconButton from '../../core/components/IconButton';
import Icon from "../../core/components/Icon";

import Grid from "../../core/components/Grid";
import { withStyles } from "../../core/components/Styles";
import cn from "classnames";

import { AdditionalBookingDataObj } from "./AdditionalBookingModalProps";
import { StyleClasses, StyleObj, CallBack } from "../../types/customTypes";

const styles: StyleObj = {
	root:{
		width: "22rem",
    height: "25rem",
    "&:first-child":{
      padding: "0px"
    }
	},
	title:{
		position:"relative",
		height: "3.7rem",
		textAlign: "center",
		color: "#686f78",
		borderBottom: "1px solid #caccce"
	},
	titleText:{
		fontSize: "1rem",
	},
	noPadding: {
		padding: "0rem"
	},
	flexDisplay: {
		display: "flex"
	},
	closeButton:{
		position: "absolute",
		right: "1.2rem",
		top: "1.4rem"
	}
};

export interface AdditionalBookingsModalProps {
	classes: StyleClasses,
	showDialogLoader: boolean,
	additionalBookings: Array<AdditionalBookingDataObj>,
	showAdditionalBookingsModal: boolean
	removeAndHideAdditionalBookings: CallBack,
}

const AdditionalBookingsModal: FunctionComponent<AdditionalBookingsModalProps> = (props)=> {
	const {
		classes,
		showDialogLoader,
		showAdditionalBookingsModal,
		additionalBookings,
		removeAndHideAdditionalBookings
	} = props;

	const closeModal:MouseEventHandler = (e)=> removeAndHideAdditionalBookings();
	
	return (
		<Dialog
      open={showAdditionalBookingsModal}
      onClose={closeModal}
    >
			<DialogTitle classes={{root:classes.title}} disableTypography> 
				<span className={classes.titleText}>
					Additional Bookings 
				</span>
				<IconButton 
					aria-label="close" 
					classes={{root: cn([
						classes.noPadding,
						classes.closeButton
					])}} 
					onClick={closeModal}
				>
					<Icon lineHeight="inherit" fontSize="1rem">ignore</Icon>
				</IconButton> 
			</DialogTitle>
			<DialogContent 
        classes={{ root: classes.root }}
      >
				{showDialogLoader &&  <Loader zIndex={999} />}
				<Grid container direction = "column">
					{
						additionalBookings.map((bookingDataObj:AdditionalBookingDataObj,index:number)=>
							<BookingsData
								key = {index}
								bookingDataObj = {bookingDataObj}
							/>
						)
					}
				</Grid>
			</DialogContent>
		</Dialog>
	);
}

const mapStateToProps = (state:any) => {
	
	let showDialogLoader;
	if(state && state.app) {
		showDialogLoader = state.app.showDialogLoader
	}

  let showAdditionalBookingsModal ;
  let additionalBookings;
  if(state && state.jobChanges ){
    showAdditionalBookingsModal =  state.jobChanges.showAdditionalBookings;
    additionalBookings = state.jobChanges.additionalBookings;
  }
	
	return {
		showDialogLoader,
		showAdditionalBookingsModal,
		additionalBookings
	};
};

const mapDispatchToProps = (dispatch:any) => ({
	removeAndHideAdditionalBookings: ()=> dispatch(removeAndHideAdditionalBookings())
});

const WithStylesAdditionalBookingsModal = withStyles(styles)(AdditionalBookingsModal);

export default connect(mapStateToProps,mapDispatchToProps)(WithStylesAdditionalBookingsModal);

