import React, { Fragment, Component } from "react";
import Cookies from "js-cookie";
import Storage from "utils/Storage";
import { connect } from "react-redux";
import Sidebar from "../components/SideDrawer";
import axios from "utils/http";
import { setMsgAndShow } from "../store/actions/popup";
import { updateUserInfo  } from "../store/actions/contractor";
import { ImageDropZone, DropZoneDialog } from "../components/DropZone";
import InviteModal from "../components/InviteModal";

import { withMixpanel } from "../utils/react-mixpanel";
class SidebarContainer extends Component {
  state = {
    anchorEl: null,
    notificationAnchor: null,
    openNotifications: false,
    isDropZoneOpen: false,
    isInviteModalOpen: false
  }
  handleClick = event => {

    this.setState({ anchorEl: event.currentTarget });
  };

  handleLogout = async () => {
    try {
      const res =  await axios.get(`${process.env.REACT_APP_API_PREFIX}user/logout`)
      if(res.data) {
        Cookies.remove("authToken",{path:"/", domain:process.env.REACT_APP_HOME_SITE});
        Storage.remove("userInfo");
        Storage.remove("orderHistory");
        Storage.remove("contractorInfo");
        Storage.remove("auth_token");
        window.location.href = process.env.REACT_APP_HOME_URL
      }

    } catch (error) {
      console.error(error); // eslint-disable-line
      Cookies.remove("authToken",{path:"/", domain:process.env.REACT_APP_HOME_SITE});
      Storage.remove("userInfo");
      Storage.remove("orderHistory");
      Storage.remove("contractorInfo");
      Storage.remove("auth_token");
      window.location.replace('/login')
    }
  }
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  setNotificationAnchor = node => {
    this.setState({
      notificationAnchor: node
    })
  }

  openNotificationsMenu = () => {
    this.setState({openNotifications: !this.state.openNotifications});
  }

  handleNotificationMenu = () => {
    this.setState({
      notificationAnchor: null,
      openNotifications: false
    })
  }
  handleDropZoneToggle = () => {
    const { trackButtonEvent } = this.props;
    trackButtonEvent("Cancel_Pic_Upload",window.location.pathname);
    this.setState({ isDropZoneOpen: !this.state.isDropZoneOpen });
  };

  fileUploaded = res => {

    const { setMsgAndShow, updateUserInfo, trackButtonEvent, trackApiEvent } = this.props;
    trackButtonEvent("Upload_Profile_Pic",window.location.pathname);
    try {
      const { data } = res;
      if(data && data.status === "OK"){
        trackApiEvent("Upload_Profile_Pic","POST",`${process.env.REACT_APP_API_PREFIX}user/upload_image`,"OK",window.location.pathname)
        const { profileImage } = data;
        updateUserInfo({
          profileImage
        })
        setMsgAndShow("Upload was successful")
        this.handleDropZoneToggle();
      } else {
        let msg = "Error Occured";
        if(data){
          msg = data.message? data.message:"Error Occured";
        }
        setMsgAndShow(msg);
        trackApiEvent("Upload_Profile_Pic","POST",`${process.env.REACT_APP_API_PREFIX}user/upload_image`,msg,window.location.pathname)
        
      }
    } catch (error) {
      setMsgAndShow("Error");
      trackApiEvent("Upload_Profile_Pic","POST",`${process.env.REACT_APP_API_PREFIX}user/upload_image`,error,window.location.pathname)
    }

  };


  openImageUpload = () => {
    const { trackButtonEvent } = this.props;
    trackButtonEvent("Edit_Profile_Pic",window.location.pathname);
    this.setState({
      isDropZoneOpen: true
    });
  }


  handleInviteModal = () => {
    const { trackButtonEvent } = this.props;
    trackButtonEvent("Invite",window.location.pathname);
    this.setState({
      isInviteModalOpen: !this.state.isInviteModalOpen
    });
  }

  render() {
    const { userInfo:{ _id:userId } } = this.props;
    return (
      <Fragment>
        <Sidebar 
          handleLogout={this.handleLogout}
          openImageUpload={this.openImageUpload}
          handleInviteModal={this.handleInviteModal}
          {...this.state}
          {...this.props} 
        />

        <DropZoneDialog
          isOpen={this.state.isDropZoneOpen}
          toggle={this.handleDropZoneToggle}
          title="Upload Profile Photo"
        >
          <ImageDropZone
            onUploaded={this.fileUploaded}
            onCancel={this.handleDropZoneToggle}
            url={`${process.env.REACT_APP_API_PREFIX}user/upload_image`}
            accept=".jpg,.png,.gif"
            formData={{
              userId
            }}
          />
        </DropZoneDialog>
        <InviteModal 
          handleModal={this.handleInviteModal}
          isModalOpen={this.state.isInviteModalOpen}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.contractor.userInfo
})

const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg)),
  updateUserInfo: (updateObj) => dispatch(updateUserInfo(updateObj))
})

const connectRedux = connect(mapStateToProps, mapDispatchToProps)(SidebarContainer);
export default withMixpanel(connectRedux);