import React, { Component } from 'react';
import { cloneDeep } from "lodash";
import axios from "../utils/http";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { AccountForm } from "../components/AccountForm";

import { setMsgAndShow } from "../store/actions/popup";
class AccountFormContainer extends Component {
  state = {
    load: false
  }
  
  submitData = async (obj, dispatch, props) => {
    // const contractorId = this.props.businessDetails._id;
    let formValues = cloneDeep(obj);
    let resload;
    formValues.state = formValues.state.value;
    try {
      this.setState({
        load: true
      })
      const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}contractor/payment_methods/add`,{
        ...formValues
      })

      if(res.data.status === "OK"){
        const { paymentDetails, message } = res.data;
        resload = false
        this.props.onPaymentDetailsAdded(paymentDetails)
        this.props.handleClose();
        this.props.setMsgAndShow(message)
      } else {
        resload = false
        this.props.setMsgAndShow(res.data.message);
      }

      this.setState({
        load: resload
      });

    } catch (error) {
      const { response } = error;
      this.setState({
        load: false
      })
      this.props.setMsgAndShow(response ? response.data.message : "Error");
    }
  }
  render(){
    return (
      <AccountForm 
        submitData={this.submitData}
        {...this.state}
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state) => {
  let formValues = {};
  if(state.form.AccountForm){
    const { AccountForm } = state.form;
    if(AccountForm.values){
      formValues = AccountForm.values;
    }
  }
  return {
    formValues,
    businessDetails: state.contractor.businessDetails
  };
}


const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg)),
})

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form:"AccountForm",
  destroyOnUnmount: true,
  initialValues:{
    idType:"businessId"
  }
})(AccountFormContainer));
