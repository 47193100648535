import React from 'react';
import { Component } from 'react'
import { Grid } from 'core/components';
import { withStyles } from "core/components/Styles";
import { 
  TextAreaField, 
  RadioGroupField
} from '../FormField'
import Typography from 'core/components/Typography';
import cn from 'classnames'
// import { TrashCanOutline, CameraTimer, Link } from 'mdi-material-ui'
import { Field, Form, FieldArray } from 'redux-form'
import IconButton from 'core/components/IconButton';

import { required, address, maxLength, minLength, email } from '../FormField/Validations';
import { SelectFormField, InputField } from '../FormField';
import DatePicker from "../DatePicker";
import Button from "core/components/Button";

import Icon from "core/components/Icon";

import moment from "moment-timezone";
//import {connect} from 'react-redux'

import blob from "../../assets/images/svg/blob.svg";
import blobOne from "../../assets/images/svg/blobOne.svg";
import priceBlob from "../../assets/images/svg/priceBlob.svg";
import header from "../../assets/images/header.png";
import FabButton from "core/components/FabButton";
import { Pencil } from "mdi-material-ui";

const radioGroupOptions = [
	{
		title: "Offline",
		value: "offline",
		icon :"offline"
  },
  {
		title: "Online",
		value: "online",
		icon : "online"
  },
];

// const textAreaPlaceholders = {
//   invoice: "This will showup on the Invoice",
//   statement: "If you send Statements to clients, this will show up as the description for the invoice"
// }

const styles = (theme) => ({
  root: {
		backgroundColor: "#fff",
		position: "relative",
		overflow: "hidden",
		backgroundImage: `url(${header})`,
		backgroundRepeat: "no-repeat",
		backgroundSize: "100%",
		width:"100%"
	},
	form: {
		margin: "auto",
		maxWidth: 1474,
		padding: "0 15px"
	},
	invoiceCount: {
		fontWeight:"normal",
		marginLeft: 10,
		fontSize: 24,
		lineHeight: "28px",
		color: "#333333"
	},
	margTop:{
		margin: "64px 0 0 0",
	},
	saveBtnStyle:{
		background: "linear-gradient(98.2deg, #462EB4 2.88%, #B062D2 83.6%)",
		marginRight: 14,
		borderRadius: 12,
	},
	permSaveBtnStyle:{
		background: "linear-gradient(98.2deg, #462EB4 2.88%, #B062D2 83.6%)",
		borderRadius: 12,
		marginTop: 20,
		color:"#fff"
	},
	clearBtnStyle:{
		border: "2px solid #35c4ad",
		color : "#fff",
		marginRight: 14,
		borderRadius: 12,
		padding: "4px 10px"
	},
	sendInvoiceBtn:{
		padding: 0,
		background: "linear-gradient(184deg, #50e3c2, #008da7)",//linear-gradient(184deg, #50e3c2 86%, #008da7 -61%)
		boxShadow:"none",
		borderRadius: 12,
	},
	 sendInvoiceText:{
		color: "#fff",
		textTransform: "uppercase",
		fontSize: 16,
		lineHeight: "25px",
		fontWeight: 500,
		margin: "11px 62px",
		padding:"7px 0px"
	},

	saveTextColor:{
		color: "#ffffff"
	},
	clearTextColor:{
		color: "#35c4ad",
	},
	buttonText:{
		textTransform: "uppercase",
		fontSize: 16,
		lineHeight: "25px",
		fontWeight: 500,
		margin: "11px 82px"
	},
	
	linkStyle:{
		color: "#8d8d8d",
		fontSize: 36,
		height: 51,
		margin: "78px 0 0 0",
		textDecoration: "none",
	},
	helpLink: {
		fontSize: 28,
		// lineHeight: "52px",
		fontWeight:300,
		textDecoration: "none",
		color: "#35c4ad"
	},
	themeColor: {
		color: "#35c4ad",
	},
	hr_style: {
		borderTop: "2px solid #ededed ",
		padding:0
	},
	payment_hr_style: {
		borderTop: "2px solid #ededed ",
		padding:0,
		margin: "97px 0 0 0"
	},
	top_hr:{
		border: "1px solid #ededed ",
		height: 1,
		width: "100%" ,
		margin: 0
	},
	bottom_hr:{
		border: "1px solid #ededed ",
		height: 1,
		width: "100%",
		margin: 0
	},
	hr_help:{
		border: "1px solid #ededed",
		height: 1,
		width: "100%",
		margin: "14px 0 20px 0"
	},
	invoiceTagCont:{
		display:"flex",
		justifyContent:"flex-end",
		paddingRight:8
	},
	invoiceTag:{
		height: 340,
		width: 173, // Remove if checked.
		color: "#fff",
		fontSize: 28,
		fontWeight: 600,
		
		display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingBottom: 40,

		background: "linear-gradient(181deg, #50e3c2, #008da7 )",
	},
	logoAndCompany: {
		display:"flex",
		alignItems:"flex-end",
		paddingLeft: 9
	},

	title:{
		fontSize: 24,
		fontWeight: 300,
		textalign: "left",
		color: "#383838",
		textTransform: "uppercase",
	},
	billFromTitle:{
		margin:"20px 0"
	},
	billToTitle:{
		margin:"30px 0"
	},
	invoiceTable: {
		width: "inherit",
		borderSpacing: "0 30px"
	},
	itemStyle: {
		fontWeight:"normal",
		fontSize:22
	},
	setInline:{
		display: "inline",
		fontSize: 16,
		fontWeight: "normal",
		lineHeight: "28px",
		color: "#383838"
	},
	textCenter: {
		textAlign: "center"
	},	 
	linkText: {
		fontSize: 28,
		color: "#bababa",
		fontWeight: 300
	},
	blobStyle: {
		position: "absolute",
		top: 615,
		left: -151,
		zIndex: 1
	},
	topAndBottom_12:{
		margin:"60px 0 0 0 ", 
 		padding: "12px 0 10px 0"
	},		
	priceDiv:{
		position:"absolute", // Remove if Corrected
		textTransform: "uppercase",
	},
	dueDateText:{
		fontSize: 19,
		fontWeight: 600,
		margin:0,
		color: "#fff",
		padding:" 0 16px",
		textTransform:"capitalize"
	},
	priceFig:{
		fontSize: 57,
		fontWeight: 600,
		color:"#ffffff"
	},
	dueBal:{
		color: "#fff",
		fontSize: 21,
		margin:0,

		fontWeight: 500,
		textTransform:"uppercase"
	},	 
	dueDate:{
		border: " 1px solid #e6e6e6",
		height: 53,
		width: "100%",
		display:"flex",
		justifyContent:"center",
		alignItems:"center",
	},
	blobOneStyle: {
		position: "absolute",  // Remove If checked
		bottom: 8,
		left: -151,
		opacity: 0.88
	},	
	logoStyle:{
    color: "#686868",
    fontSize: "18px",
    textTransform: "uppercase",
    border: "1px dashed #aaa",
    padding: "5px 15px",
    borderRadius: "5px",
	},
	displayInline: {
		display: "inline"
	},
	inputSelect:{
		"& [class*=fieldWrapper]":{
			height: 56,
			"& > span":{
				right: 0,
				fontSize: 16,
				color: "#c4c4c4"
			},
			"& > input":{
				fontSize: 16,
				height: 56,
				border: "solid 1px #d8d8d8",
				background: "#ffffff",
				padding: "20px 56px 20px 20px",
				"&::placeholder ":{
					color: "#b7b7b7"
				}
			}
		},
	},	
	fieldStyle:{
		fontSize: 16,
		fontWeight:"normal",
		height: 56,
		padding: "19px 14px",
		borderRadius: 3,
		border: "solid 1px #d8d8d8",
		background: "#ffffff",
	  margin: 0,
		"&::placeholder ":{
			color: "#b7b7b7"
		}
	},
	fieldTopMargin:{
		margin: "20px 0 0 0"
	},
	addOnIcon:{
		fontSize: 16,
		color:"#b1b1b1"
	},
	tableFieldStyle:{
		color: "#6c6c6c",
		fontSize: 16,
		padding: "19px 14px",
		borderRadius: 3,
		border: "solid 1px #f4f4f4",
		background: "#fdfdfd",
		fontWeight: "normal"
	},
	tableGroupStyle:{
		margin: 0
	},
	itemSelect:{
		width: "100%",
		fontSize: 16,
		height: 56,
		color: "#404040"
	},
	billToSelect:{
		fontSize: 16,
		color: "#b7b7b7",
		fontWeight: "normal"
	},
	fullWidth:{
		width:"100%",	
		margin:"0 0 20px 0"
	},
	textAreaStyle:{
		fontWeight:"normal",
		resize: "none",
		borderRadius: 3,
		border: "solid 1px #d8d8d8",
		padding: "23px 55px",
		background: "#ffffff",
		"&::placeholder ":{
			color: "#b7b7b7"
		}
	},
	textAreaDiv:{
		position:"relative",
		height: 121, 
		fontWeight:"normal",
		resize: "none",
		borderRadius: 3,
		border: "solid 1px #d8d8d8",
		padding: "17px 55px",
		fontSize:16,
		color: "#101010"
	},
	textAreaIcon:{
		color: "#b7b7b7",
    position: "absolute",
    left: 20,
    top: 21,
	},
	inputDiv:{
		height: 56,
    borderRadius: 3,
    border: "solid 1px #d8d8d8",
    marginTop: 20,
    padding: "19px 14px 19px 56px",
    fontSize: 16,
    display: "flex",
		alignItems: "center",
		position: "relative"
	},
	inputDivIcon:{
		color: "#b7b7b7",
    position: "absolute",
    left: 20,
    top: 20,
	},
	textAreaContHeight:{
		minHeight: 121
	},
	advanceSelect:{
		height: 56,
		"& [class*=control]":{
			border: "solid 1px #d8d8d8",
			background: "#ffffff",
			color: "#b7b7b7"
		},			
		color: "#404040"
	},
	tableAdvanceSelect:{
		height: 56,
		"& [class*=control]":{
			border: "solid 1px #f4f4f4",
			background: "#fdfdfd",
			color: "#b7b7b7"
		}
	},
	tableSelectContainer:{
		margin:0
	},
	paymentTitle:{
		fontSize: 24,
		fontWeight: "normal",
		color: "#363636"
	},
	paymentText:{
		margin: 0,
    fontSize: 24,
		fontWeight: "normal",
		color: "#363636",
		textAlign: "center"
	},
	counterStyle:{
		fontSize:22,
		fontWeight:300,
		color: "#575757",
		textAlign: "center",
		border: "solid 1px #f4f4f4",
		background: "#fdfdfd",
		padding: "16px 14px"
	},
	counterGroupStyle:{
		margin:0
	},
	amountTextDiv:{
		fontSize: "22px",
		fontWeight: 300,
		color: "#575757",
		
		border: "solid 1px #f4f4f4",
		background: "#fdfdfd",
	},
	amountText:{
		margin: 0
	},
	flexDateDisplay:{
		display:"flex",
		alignItems:"center",
		marginTop: 54,
		"& [class*=group]":{
			borderRadius: 3,
			margin : "0 0 0 15px"
		}
	},
	flexDueDisplay:{
		display:"flex",
		alignItems:"center",
		marginTop:64,
		"& [class*=group]":{
			borderRadius: 3,
			margin : "0 0 0 41px"
		}
	},	
	iconButton: {
		marginLeft: 17
	},
	iconLabel:{
		color: "#c42727",
		fontSize: 17,
    width: 17,
    height: 17
	},
	tableOptions: {
		padding: 0
	},
	tableOptionsMargin: {
		margin: "0 10px",
		height: 46,
		borderStyle: "solid",
		borderWidth: "1px",
		borderRadius: "30px"
	},
	tableButtonText:{
		fontSize: 12,
		fontWeight: "normal",
	},
	tableOptionOne:{
		borderColor: "#50e3c2",
		padding: "0 29px",
	},
	tableOptionTwo:{
		borderColor: "#ea7255",
		padding: "0 23px",
	},
	buttonTextOne:{
		color: "#50e3c2",
		margin: "0 0 0 13px",
	},
	buttonTextTwo:{
		color: "#ea7255",
		margin: "0 0 0 13px",
	},
	buttonOneIcon:{
		color: "#50e3c2",
		fontSize: 14,
		fontWeight: 600
	},
	buttonTwoIcon:{
		color: "#ea7255",
		fontSize: 12,
		fontWeight: 600,
	},
	paymentBottomLine:{
		width: "100%",
		margin: "0 49px",
	},	
	paymentSelect:{
		height: 80,
		padding: "0px 44px 0px 22px",
		border: "solid 1px #ededed",
		borderRadius: 28,
		color: "#6c6c6c",
		fontSize: 16,
		lineHeight: "2px",
		fontWeight: 500,
		textTransform: "uppercase",
		display:"flex",
		justifyItems:"center",
		margin: "0 10px",
		position: "relative", // For radion button tick
	},
	radioBtn:{
		height: 24,
		width: 24,
		position: "absolute", // For radion button tick
    top: 10,
		right: 0,
		border: 0,
		margin:"18px 28px", 
		"&:checked":{
			"& ~ span":{
				color: "#fff"
			}
		}
	},
	setBackground:{
		background : "linear-gradient(147deg, #492fb5 -59%, #9e59cc 121%)",
		color: "#fff"
	},
	payIconStyle:{
		margin:"0 0 0 45px"
	},
	titleOfBtn:{
		fontSize: 16,
		padding: "0 64px 0 69px",
		color: "#6c6c6c"
	},
	paymentMode: {
		color:"#535353",
		fontSize: 24,
		fontWeight: 300,
		margin: "52px 0 42px 0"
	},
	cancelButton:{
		borderRadius: "50%",
		minWidth:40,
		height:40,
		padding:0,
		color:"#fff",
		background:"#eD5454",
		"&:hover":{
      background:"#ed5454",
      boxShadow:"0px 3px 5px -1px rgba(0, 0, 0, 0.4), 0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 1px 18px 0px rgba(0, 0, 0, 0.15)"
    }
	},
	cancelButtonCont:{
		margin: "0 14px 20px 0"
	},
	positionRelative:{ // need for COmpany Logo
		position:"relative"
	},
	optionStyle:{
		background: "yellow",
		height: 30,
		width:30
	},
	companyNameField:{
		color: "#35c4ad",
		fontSize: 36,
		border: "none",
		background: "inherit",
		fontWeight: 400,
		padding:"0"
	},
	editLogoBtn:{
    height: 30,
    width: 30,
    boxShadow: "4px 7px 14px 0 rgba(59, 39, 95, 0.21)",
    background:"#fff",
    borderRadius: "50%",
    "&:hover":{
      background:"#fff"
		},
		// position: "absolute", // need absolute 
    
	},
	logoEditPosition:{
    right: 28,
    top: -9
	},
	textEditPosition:{
		right: 8,
    top: 1
	},

	pencilIcon:{
    color: "#fd9b5b",
    fontSize: 14
	},
	priceBlobRow:{
		marginTop: 117,
		marginBottom: 68
	},
	noMargin:{
		margin: 0
	},
	fixedColor:{
		color: "#b7b7b7"
	},
	fieldTopMargin_17:{
		margin: "17px 0 0 0" 
	},
	inputHeight_56:{
		height: 56
	},
	marginBottom_30:{
		marginBottom: 30
	},
	closeIconBold:{
		fontWeight:"bold"
	}
});

class TableMember extends Component {

	removeIndex = (index) => () => this.props.fields.remove(index)

	render() {
    const {
      fields,
      // meta, 
      classes,
      // trashClass,
			iconButton,
			iconLabel,
      products,
      productsList,
      onProductSelect,

			trackTableField,
			trackTableInput
    } = this.props;

    return (
      (fields && fields.length) ? fields.map((f, index) => (
        <tr key={`product-item-${index}`} className={classes.marginBottom_30} >
          <td>
						{ index === 0 
							? <Field
									component={SelectFormField}
									name={`${f}.name`}
									placeholder="Select Item"
									options={productsList}
									validate={[required]}
									onChange={onProductSelect}
									classes={{
										advanceSelectControl : classes.itemSelect,
										advanceSelect: classes.tableAdvanceSelect,
										advanceSelectContainer: classes.tableSelectContainer
									}} 
									trackSelectInput={trackTableField}
									trackTitle={"Item"}
								/>
							:	<Field
									component={SelectFormField}
									name={`${f}.name`}
									placeholder="Select Item"
									options={productsList} 
									onChange={onProductSelect}
									classes={{
										advanceSelectControl : classes.itemSelect,
										advanceSelect: classes.tableAdvanceSelect,
										advanceSelectContainer: classes.tableSelectContainer
									}} 
									trackSelectInput={trackTableField}
									trackTitle={"Item"}
								/>
						}
						
          </td>

          <td>
						<Field
							component={InputField}
							name={`${f}.description`}
							type="text"
							placeholder="Enter Description"
							classes={{
								field: classes.tableFieldStyle,
								group: classes.tableGroupStyle
							}}

							onBlur={trackTableInput("Item_Description")}							
						/>
          </td>

					<td align="center">
						<Field
							component={InputField}
							name={`${f}.quantity`} 
							type="number"
							classes={{
								field: classes.counterStyle,
								group: classes.counterGroupStyle
							}}

							onBlur={trackTableInput("Item_Quantity")}	
						/>
					</td>

					<td align="center">
						<Field
							component={InputField}
							name={`${f}.rate`} 
							type="number"
							classes={{
								field: classes.counterStyle,
								group: classes.counterGroupStyle
							}}

							onBlur={trackTableInput("Item_Rate")}	
						/>
          </td>

          <td align="center" className={ classes.tableAmountTextDiv } >
						<p className={classes.tableAmountText}>
              ${
								products && (products[index] && products[index].amount)
								? " "+products[index].amount
								: " "+0
							}
            </p>
          </td>

					<td align="center"> {
            index ?
              <IconButton
                aria-label="Delete"
								classes={{
									root: iconButton,
									label: iconLabel		
								}}
                onClick={this.removeIndex(index)}
							>
								<Icon lineHeight="inherit">trash</Icon>
              </IconButton>
              : ""}
          </td>
        </tr>
      )) : <tr></tr>


    )
  }
}

const maxLength_10 = maxLength(10);
const minLength_10 = minLength(10);

const InvoiceForm = (props) => {
  const {
    classes,
    invoiceSubmitAction,
    handleSubmit,
    addLines,
    clearLines,
    customers,
    onClientSelect,
    productsList,
    onProductSelect,
    getCustomers,
    resetForm,
		cancelInvoice,
		saveInvoice,

		showSavePermanently,
		checkCustomerDetails,
		saveNewCustomerDetails,
		canEditCustomer,

		onLogoUpload,
		formValues,
		trackInput, 
		trackField,
		trackRadio,
		total
  } = props;

  let advancedSelected = [{}];

	const {  
    number,
    products,
		logo,
		dueDate,
		companyName,
		companyAddress,
		companyPhone,
		companyMail
	} = formValues;

	trackField("Company_Name", companyName);
	trackField("Company_Address", companyAddress);
	trackField("Company_Email", companyMail);
	trackField("Company_Phone", companyPhone);

  if (customers && customers.length) {
    advancedSelected = customers.map((customer) => {
			let { serviceAddress:{city,state,address,zipcode} } = customer
			return {
        label: `${customer.fname} ${customer.lname}`,
				email: customer.email,
				phone: customer.phone,
				address: `${address}, ${city}, ${state} ${zipcode}`,
				value: customer._id
      }
		});
	}
 
  return (
		<Form onSubmit={handleSubmit(invoiceSubmitAction)}>
			<Grid container className={classes.root}>
				
				<Grid container justify="flex-start" className={classes.form}>

					<Grid item className={classes.invoiceTagCont}>
						<Grid className={classes.invoiceTag}>
							Invoice
						</Grid>
					</Grid>

					<Grid item className={classes.logoAndCompany}>
						<Grid>
							<Grid container className={classes.positionRelative} onClick={onLogoUpload}  >
								{ 
									logo 	
									?	
										<img 
											src={logo} 
											alt="Company Logo"
										/>
									: 
										<Typography classes={{
											root: classes.logoStyle
										}}>+ Add Your Logo</Typography>
								 }    
								<IconButton 
									size="small"
									className={ cn(classes.editLogoBtn)}
								>
									{/* {
										!logo 
											? <Icon lineHeight="inherit" className={classes.pencilIcon} ></Icon>
											:  */}
											<Pencil className={classes.pencilIcon}/>
									{/* } */}
								</IconButton>
								
							</Grid>

							<Grid className={classes.companyNameField}>
								{ companyName }
							</Grid>	
							
						</Grid>

					</Grid>

					<img className={classes.blobStyle} src={blob} alt="side design one"/>
					<Grid
						container
						justify="space-between"
						alignItems="center"
					>		
						<Grid item md={4} xs={12}>

							<Grid className={classes.topAndBottom_12}>
								<Typography className={classes.setInline}>Invoice #</Typography>
								<Typography className={cn(classes.invoiceCount,classes.displayInline)}>{number ? number : "0000"}</Typography>
							</Grid>

							<Grid className={cn(classes.inputSelect, classes.flexDateDisplay)} >
								<Typography className={classes.setInline}>Invoice Date :</Typography>
								<Field
									component={
										DatePicker
									}
									validate={[required]}
									name="createdDate"
									placeholder="Select Invoice Date"

									rightIcon="invoice_calender"
									leftIcon={false}

									trackDateInput={trackField}
									trackTitle={"Select_Invoice_Date"}
								/>
							</Grid>

							<Grid className={cn(classes.inputSelect, classes.flexDueDisplay)} >
								<Typography className={classes.setInline}>Due Date :</Typography>
								<Field
									validate={[required]}
									component={
										DatePicker
									}
									placeholder="Select Due Date"
									name="dueDate"

									rightIcon="invoice_calender"
									leftIcon={false}

									trackDateInput={trackField}
									trackTitle={"Select_Due_Date"}
								/>
							</Grid>


						</Grid>




						<Grid item md={4} xs={12}>
							<Grid 
								className={cn(classes.title,classes.billFromTitle)}
							>
								Bill from :							
							</Grid>

							<Grid className={classes.textAreaDiv}>
								<Icon className={classes.textAreaIcon}>location</Icon>
								<p className={classes.noMargin}>
									{ companyAddress }
								</p> 
							</Grid>

							{
								companyMail &&
								<Grid className={classes.inputDiv}>
									<Icon className={classes.inputDivIcon}>mail</Icon>
									<p className={classes.noMargin}>
										{ companyMail }
									</p> 
								</Grid>
							}

							<Grid className={classes.inputDiv}>
								<Icon className={classes.inputDivIcon}>call</Icon>
								<p className={classes.noMargin}>
									{ companyPhone }
								</p>
							</Grid>
							
						</Grid>
					
					</Grid>
 
					<Grid container justify="space-between" alignItems="center" 	className={classes.priceBlobRow}>
						<Grid 
							item 
							md={4}
							xs={12}
						>
							<Grid>
								<Typography className={ cn(classes.title, classes.billToTitle)}>
									BILL TO :
								</Typography>
							</Grid>
							<Grid >
								<Field
									async
									placeholderAdd="Select name"
									loadOptions={getCustomers}
									validate={[required]}
									component={SelectFormField}
									name="customerId"
									options={advancedSelected}
									onChange={onClientSelect}
									classes={{
										advanceSelectControl : classes.billToSelect,
										advanceSelectContainer : classes.fullWidth,
										advanceSelect : classes.advanceSelect
									}}
									trackSelectInput={trackField}
									trackTitle={"Customer_Name"}
									userIcon 
								/>
							</Grid>

							<Grid>
								{
									canEditCustomer
									? <Field
											validate={[required,address]}
											component={
												TextAreaField
											}
											name="billingAddress"
											placeholder="Enter Address"
											rows={4}
											classes = {{
												textAreaInput: classes.textAreaStyle,
												relativePosition: classes.textAreaContHeight
											}}
											onChange={checkCustomerDetails("billingAddress")}
											leftIcon="location"
											onBlur={trackInput("Billing_Address")}
										/>
									: <Grid className={classes.textAreaDiv}>
											<Icon className={classes.textAreaIcon}>location</Icon>
											<p className={ cn([classes.fixedColor,classes.noMargin])}>
												Enter Address
											</p> 
										</Grid>
								}
								
							</Grid>

							<Grid>
								{
									canEditCustomer 
									? <Field
											component={InputField}
											name="customerEmail"
											validate={[required,email]}
											type="text"
											placeholder="Enter Email ID"
											classes={{
												field: classes.fieldStyle,
												group: classes.fieldTopMargin_17,
												addOn: classes.addOnIcon,
												fieldWrapper: classes.inputHeight_56
											}}
											onChange={checkCustomerDetails("customerEmail")}
											leftIcon={<Icon lineHeight="inherit">mail</Icon>}
											onBlur={trackInput("Customer_Email")} 
										/>
									:	<Grid className={cn([classes.inputDiv,classes.fieldTopMargin])}>
											<Icon className={classes.inputDivIcon}>mail</Icon>
											<p className={ cn([classes.fixedColor,classes.noMargin]) }>
												Enter Email ID
											</p> 
										</Grid>
								}
							</Grid>

							<Grid>
								{
									canEditCustomer 
									? <Field
											component={InputField}
											name="customerPhone"
											validate={[required, maxLength_10, minLength_10]}
											type="text"
											placeholder="Enter Phone Number"
											classes={{
												group: classes.fieldTopMargin,
												field: classes.fieldStyle,
												addOn: classes.addOnIcon,
												fieldWrapper: classes.inputHeight_56
											}}
											onChange={checkCustomerDetails("customerPhone")}
											leftIcon={<Icon lineHeight="inherit">call</Icon>}
											onBlur={trackInput("Customer_Phone")}
										/>
									:	
										<Grid className={cn([classes.inputDiv,classes.fieldTopMargin])}>
											<Icon className={classes.inputDivIcon}>call</Icon>
											<p className={ cn([classes.fixedColor,classes.noMargin]) }>
												Enter Phone Number
											</p> 
										</Grid> 
								}
							</Grid>
							{ showSavePermanently &&
								<Grid>
									<Button className={classes.permSaveBtnStyle} 
										onClick={saveNewCustomerDetails}
									>
										Save Permanently
									</Button>
								</Grid>
							}
						</Grid>
			
						<Grid container alignItems="center" justify="center"item md={6} xs={12}>
							<img 
								src={priceBlob} 
								alt="Price tag"
							/>
							<Grid 
								className={classes.priceDiv}
							>
								<Grid >
									<Typography className={classes.dueBal}>Balance Due :</Typography>
								</Grid>
								<Grid>
									<Typography className={classes.priceFig} >
										$ {total ? total : 0} 
									</Typography>
								</Grid>	
								<Grid>
									<div className={classes.dueDate}>
										<Typography className={classes.dueDateText}>
											Due By : {dueDate ? moment(dueDate).format("MM-DD-YYYY") : "MM-DD-YYYY" }
										</Typography> 
									</div>
								</Grid>
							</Grid>				
						</Grid>

					</Grid>

					<Grid
						container
						justify="space-between"
						alignItems="center"
					>
						<table className={classes.invoiceTable}>
							<thead
								align="left"
							>

								<tr>
									<td colSpan="6" className={classes.hr_style}/>
								</tr>


								<tr>
									<th width="400">
										<Typography className={classes.itemStyle}>Item</Typography>	
									</th>
									<th width="780">
										<Typography className={classes.itemStyle}>Description</Typography>
									</th>
									<th width="100" align="center">
										<Typography className={classes.itemStyle}>Qty.</Typography>
									</th>
									<th width="100" align="center">
										<Typography className={classes.itemStyle}>Rate</Typography>
									</th>
									<th width="150" align="center">
										<Typography className={classes.itemStyle}>Amount</Typography>
									</th>
									<th width="50" align="center">
										{/* <Typography className={classes.itemStyle}>
											Delete
								</Typography> //here is close*/}
									</th>
								</tr>
							</thead>

							<tbody>
							
								{(products && products.length) ?
									<FieldArray
										name="products"
										component={
											TableMember
										}
										classes={{
											tableFieldStyle: classes.tableFieldStyle,
											tableGroupStyle: classes.tableGroupStyle,
											tableAdvanceSelect: classes.tableAdvanceSelect,
											tableSelectContainer: classes.tableSelectContainer,
											counterStyle: classes.counterStyle,
											counterGroupStyle: classes.counterGroupStyle,
											itemSelect: classes.itemSelect,
											tableAmountTextDiv: classes.amountTextDiv,
											tableAmountText: classes.amountText,
											marginBottom_30: classes.marginBottom_30,
										}}
										products={
											products
										}
										productsList={
											productsList
										}
										onProductSelect={
											onProductSelect
										}
										trashClass={
											classes.trashOutline
										}
										iconButton={
											classes.iconButton
										}
										iconLabel={
											classes.iconLabel
										}
										trackTableField = {trackField}
										trackTableInput = {trackInput}
									/> : null
								}

								<tr>
									<td colSpan="6" className={classes.tableOptions} >
										<Button 
											variant="outlined" 
											onClick={addLines}
											className={cn(classes.tableOptionsMargin,classes.tableOptionOne)}
										>
											<Icon lineHeight="inherit" className={classes.buttonOneIcon}>add</Icon>
											<Typography className={cn(classes.tableButtonText, classes.buttonTextOne)}>
												Add Line
											</Typography> 
										</Button>
										<Button 
											variant="outlined" 
											onClick={clearLines}
											className={cn(classes.tableOptionsMargin, classes.tableOptionTwo)}
										>
											<Icon lineHeight="inherit" className={classes.buttonTwoIcon}>wrong</Icon>
											<Typography className={cn(classes.tableButtonText, classes.buttonTextTwo)}>
												Clear 		Lines
											</Typography>
										</Button>
									</td>
								</tr>

								<tr>
									<td colSpan="6">
										<hr className={classes.top_hr}/>
									</td>
								</tr>


								<tr>
									<td colSpan="4">
										<Typography className={classes.paymentTitle}>Grand Total : </Typography>
									</td>
									<td>
										<Typography className={classes.paymentText}>
											$ {total ? total : 0}
										</Typography>
									</td>
								</tr>

								<tr>
									<td colSpan="6">
										<div className={classes.bottom_hr}></div>
									</td>
								</tr>


							</tbody>	
							
						</table>
					</Grid> 
 
					<Grid
						container
						justify="center"
						alignContent="center"
					>
						<Grid
							className={classes.paymentMode}
						>
							Payment Mode :
						</Grid>
					</Grid>

					<Grid
						container
						justify="center"
						alignContent="center"
						id="paymentMethod"
					>
						<Grid>
							<Field
								component={
									RadioGroupField
								}
								validate={[required]}
								name="paymentMethod"
								options={
									radioGroupOptions
								}
								classes={{
									inputStyle: classes.paymentSelect,
									radioBtn: classes.radioBtn,
									setBackground: classes.setBackground,
									titleOfBtn: classes.titleOfBtn,
									iconStyle: classes.payIconStyle	
								}}

								onBlur={trackRadio("Payment_Method","offline")}
							/>
						</Grid>
					</Grid>
					
					<Grid container justify="center" alignContent="center" >
						<Grid className={classes.paymentBottomLine}>
							<hr className={classes.payment_hr_style}/>
						</Grid>
					</Grid>
					
					<Grid container justify="center" alignItems="center" className={classes.margTop} >
					{
						resetForm &&
						<Grid>
							<Button variant="outlined" className={classes.clearBtnStyle} onClick={resetForm} gradient >
								<Typography className={ cn([classes.buttonText, classes.clearTextColor])}>
									Clear
								</Typography>
							</Button>
						</Grid>
					}
						<Grid>
							<Button variant="outlined" className={classes.saveBtnStyle} onClick={saveInvoice} type="button" gradient >
								<Typography  className = {cn([classes.buttonText, classes.saveTextColor])}>
									Save
								</Typography>
							</Button>
						</Grid>
						<Grid>
							<Button color="primary" className={classes.sendInvoiceBtn} gradient type="submit">
								<Typography className = { classes.sendInvoiceText }>
									Save and Send
								</Typography>
							</Button>
						</Grid>
					</Grid>

					<Grid container	justify="center" alignItems="center" >
						<Grid>
							<Typography  className = { classes.linkStyle }>
								Need help ?
							</Typography>
							<div className={classes.hr_help}></div>
						</Grid>
					</Grid>

					<Grid container justify="center" alignItems="center" >
						<Grid>
							<div className={cn(classes.helpLink, classes.linkText)}>
								Please send any feedback or report complaint  
							</div>
							<div className={classes.textCenter}>
								<span className={cn(classes.helpLink, classes.linkText)}>to{" "}</span>
								<a href={`${process.env.REACT_APP_SUPPORT_MAIL}`} className={cn(classes.helpLink,classes.themeColor)} >support@zoklean.com</a>
							</div>
						</Grid>
					</Grid>
				

				</Grid> 

				<img className={classes.blobOneStyle} src={blobOne} alt="side design two"/>
				
				<Grid container justify="flex-end" alignItems="center" className={classes.cancelButtonCont} >
					<FabButton 
						onClick={cancelInvoice} 
						icon={<Icon lineHeight="inherit" className={classes.closeIconBold}>ignore</Icon> }
					/>
				</Grid>

			</Grid>
		</Form>
	)
}
export default withStyles(styles)(InvoiceForm);
/* 
	
*/