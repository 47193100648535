import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Storage from "../utils/Storage";
import MainPage from "../components/MainPage";

class MainPageContainer extends Component {
  componentDidMount(){
    const { history } = this.props;
      if(!_.isEmpty(JSON.parse(Storage.get("userInfo")))){
        history.push("/dashboard");
      }
  }
  render(){
    return (
      <MainPage />
    )
  }
}

const mapStateToProps = (state) => ({
  contractorInfo: state.contractor
})

export default connect(mapStateToProps)(MainPageContainer);