import React from "react";
import Content from "./TourGuideContent";
import Title  from "./TourGuideTitle";

export const sideBarSteps = [
	{
		disableBeacon: true,
		target: `#mainContDiv`,
		title: <Title text="Welcome"/>,
		content: <Content 
							text=	"Thanks for choosing Zoklean. Let me help you on your First visit as a Tour Guide."
							listView
						/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "center",
	},
	{
		
		target: `#sideDrawer`,
		title: <Title text="Sidebar Menu"/>,
		content: <Content 
								text= "This is Your SideBar you can Edit your Profile, Invite users and navigate across the menus according to your need."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right-start",
	},
	{
		
		target: `#avatar`,
		title: <Title text="Avatar"/>,
		content: <Content 
		 						text= "Tap on Edit Profle button to Update Your Profile or you can send invite to your friends using Invite Button."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right-start",
	},
	{
		
		target: "#overview",
		title: <Title text="Overview"/>,
		content:<Content 
								text= "To view information about your Job, Employees, Users and so much more."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right",
	},
	{
		
		target: "#calendar",
		title: <Title text="Calendar"/>,
		content:	<Content 
								text= "To view information about Jobs on the basis of specific time interval eg Day, Week, Month etc. "
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right",
	},
	{
		
		target: "#jobs",
		title: <Title text="Jobs"/>,
		content: <Content 
								text= "To Have a look at the Jobs offered to you and so much more."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right",
	}, 
	{
		
		target: "#customer",
		title: <Title text="Customer List"/>,
		content:<Content 
								text= "To know about your Customers."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right",
	},
	{
		
		target: "#customer_listgps",
		title: <Title text="GPS Tracking"/>,
		content:	<Content 
								text= "To Track the Assigned employee or Cleaning Address."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right",
	},
	{
		
		target: "#employee_list",
		title: <Title text="Employee List"/>,
		content:<Content 
								text= "To Know about your Employees. "
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right",
	},
	{
		
		target: "#px",
		title: <Title text="Invoice"/>,
		content:<Content 
								text= "Getting info about Invoice."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right",
	},
	{
		
		target: "#settings",
		title: <Title text="Settings"/>,
		content:<Content 
								text= "To check and update your Profile."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right-end",
	},
];

export const appBarSteps = [
	{
		disableBeacon: true,
		target: "#appBar",
		title: <Title text="App Bar"/>,
		content:  <Content 
								text= "This is Your App Bar."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#mainSearchBar",
		title: <Title text="Search Bar"/>,
		content:  <Content 
								text= "To get Details about any Cleaning Job, Employee or Customer."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#profileBtn",
		title: <Title text="My Dashboard"/>,
		content:  <Content 
								text= "You can click on this button on any page to Open this Dashboard."
							/>,            
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#logoutBtn",
		title: <Title text="Logout"/>,
		content:  <Content 
								text= "To Log out from your account."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	}
];

export const dashboardSteps = [
// Ratings Graphs	
	{
		disableBeacon: true,
		target: "#ratingsStats",
		title: <Title text="Ratings Graph"/>,
		content:  <Content 
								text= "To Get idea about how's the business is Going."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#ratingsGap",
		title: <Title text="Ratings Interval"/>,
		content:  <Content 
								text= "Here you can select the Time Period eg Day/Week/Month . In order to view Ratings according to that period only."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "left-start"
	},
// Active Jobs & Customer reviews	
	{
		
		target: "#jobChartCont",
		title: <Title text="Graphical View"/>,
		content:  <Content 
								text= "To Check data about Cleaning Jobs, Employees and your Customers."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top"
	},
	{
		
		target: "#jobsAndReviews",
		title: <Title text="Active Jobs and Customer Reviews"/>,
		content:  <Content 
								text= "To Check data about Cleaning Jobs that are being processed and our Customer Reviews."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right"
	},
	{
		
		target: "#activeJobList",
		title: <Title text="Active Jobs List"/>,
		content:  <Content 
								text= "To view the Cleaning jobs that are under process. You can scroll Up to view more jobs."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right"
	},
	{
		
		target: "#viewJob",
		title: <Title text="View job"/>,
		content:  <Content 
								text= "Each Cleaning job listed here has this button so to view details about that Job."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right"
	},
	{
		
		target: "#viewAllJobs",
		title: <Title text="View All Jobs"/>,
		content:  <Content 
								text= "To View details regarding all the Cleaning jobs."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right"
	},
	{
		
		target: "#customerReviews",
		title: <Title text="Customer Reviews"/>,
		content:  <Content 
								text= "To Show data regarding Customer's Reviews as a chart."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right"
	},
	{
		
		target: "#reviewsPeriod",
		title: <Title text="Reviews Interval"/>,
		content:  <Content 
								text= "You can select the Time Period eg Day/Week/Month . In order to view Customer Reviews Ratings according to that period."
							/>,                         
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right"
	},    
// Employee Stats From here
	{
		
		target: "#employeeStats",
		title: <Title text="Employee Information Graph"/>,
		content:  <Content 
								text= "To Check data regarding Employees."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "left"
	},
	{
		
		target: "#employeePeriod",
		title: <Title text="Time View"/>,
		content:  <Content 
								text= "Select the Time Period you want information about."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top"
	},
	{
		
		target: "#employeeChart",
		title: <Title text="Employee Graph"/>,
		content:  <Content 
								text= "The Result of the selected Time period can be seen here."
							/> ,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "left"
	},
	{
		
		target: "#employeeUtil",
		title: <Title text="Employee Utilization Chart"/>,
		content:  <Content 
								text= "A Chart to analyze data about Employee Utilization."
							/>,  
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "left"
	},
// Calendar Starts Here calendarDiv
	{
		
		target: "#calendarDiv",
		title: <Title text="Job Calandar"/>,
		content:  <Content 
								text= " To view Cleaning jobs according to the selected Date, Month etc."
							/>,  
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "left"
	}
];

export const calendarSteps = [
	{
		disableBeacon: true,
		target: "#calenderRoot",
		title: <Title text="Job Calendar"/>,
		content:  <Content 
								text= "Here you can Select any Date to view Cleaning jobs related to it. Each of the Date will show you the jobs that have been Assigned or Pending on that date."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "center"
	},
	// {
	// 	target: "#eventCalendar",
	// 	title: <Title text="Job Calendar"/>,
	// 	content:  <Content 
	// 							text= "Here each of the Date will show you the jobs that have Assigned or the ones
	// 								that are Pending yet , if related to that particular Date/Day. You can select any Date/Day
	// 								to view jobs related to it.
	// 							"
	// 						/>,
	// 	locale: { skip: <strong aria-label="skip">Skip</strong> },
	// 	placement: "top"
	// },
	{
		
		target: "#calendarToolbar",
		title: <Title text="Navigation Bar"/>,
		content:  <Content 
								text= "This toolbar can be used to Navigate between different months."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#currentMonth",//thisMonth
		title: <Title text="Month"/>,
		content:  <Content 
								text= "Current month or the selected month."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#prevMonth",
		title: <Title text="Previous Month"/>,
		content:  <Content 
								text= "To view Cleaning Jobs of Previous month."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#thisMonth",//
		title: <Title text="Month"/>,
		content:  <Content 
								text= "Current month or the month selected using Previous or Next button."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#nextMonth",
		title: <Title text="Next Month"/>,
		content:  <Content 
								text= "To view Cleaning Jobs of Next month."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	// {
	// 	target: "#weekView",
	// 	title: <Title text="Weekly jobs"/>,
	// 	content:  <Content 
	// 							text= "Click this button to view Jobs regarding Current week ."
	// 						/>,
	// 	locale: { skip: <strong aria-label="skip">Skip</strong> },
	// 	placement: "bottom",

	// },
	{
		
		target: "#weekView",
		title: <Title text="Weekly jobs"/>,
		content:  <Content 
								text= "To view Cleaning Jobs regarding current week ."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#monthView",
		title: <Title text="Monthly jobs"/>,
		content:  <Content 
								text= "To view Cleaning Jobs regarding Current month, viewed by default."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#addJobBtn",
		title: <Title text="Add Job"/>,
		content:  <Content 
								text= "This Button can be used to add a New Job"
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "left-end"
	},
];

export const jobsSteps = [
	{
		disableBeacon: true,
		target: "#jobsRoot",
		title: <Title text="Jobs Dashboard"/>,
		content:  <Content 
								text= "Here you can perform different Operation with the Cleaning Jobs. Like to view all Cleaning jobs, Active jobs, Pending jobs and so much more."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "center"
	},
	{
		
		target: "#jobScroller",
		title: <Title text= "All Jobs"/>,
		content:  <Content 
								text= "This Window views all the Cleaning jobs that have been Offered to you. You can scroll left to view the Jobs."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#jobScroller > div:first-of-type ",
		title: <Title text= "Job Description Card"/>,
		content:  <Content 
								text= "Card that views description regarding a Cleaning Job."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#jobScroller > div:first-of-type > div > div > div:nth-of-type(2) > div > div",
		title: <Title text= "Booking date"/>,
		content: 	<Content 
								text= "Date on which Booking was made."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		target: "#jobScroller > div:first-of-type > div > div > div:nth-of-type(2) > div > div:nth-of-type(2)",
		title: <Title text= "Cleaning date"/>,
		content: 	<Content 
								text= "Date on which Cleaning is to be done."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		target: "#jobScroller > div:first-of-type > div > div > div:nth-of-type(2) > div > div:last-of-type",
		title: <Title text= "Cleaning time"/>,
		content: 	<Content 
								text= "Time booked for Cleaning."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		target: "#jobScroller > div:first-of-type > div > div > div:nth-of-type(2) >  div:nth-of-type(2) > div",
		title: <Title text= "Cost"/>,
		content: 	<Content 
								text= "Cost of Cleaning."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#jobScroller > div:first-of-type > div > div > div:nth-of-type(2) >  div:nth-of-type(2) > div:nth-of-type(2)",
		title: <Title text= "Job Booking ID"/>,
		content:  <Content 
								text= "This ID can be used to track this Cleaning job."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom-start"
	},
	{
		
		target: "#jobScroller > div:first-of-type > div> div:nth-of-type(2) > div",
		title: <Title text= "Accept/Quote"/>,
		content:  <Content 
								text= "In case of Domestic job offer this Button is used for Accepting the Job and in case of Commercial job offer to submit a Price quote fot this job. If your price quote is selected by the user then you will be notified. "
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom-start"
	},			
	{
		
		target: "#jobScroller > div:first-of-type > div> div:nth-of-type(2) > div:nth-of-type(2)",
		title: <Title text= "Reject"/>,
		content:  <Content 
								text= "Button for Rejecting the Job."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#jobScroller > div:first-of-type > div> div:nth-of-type(2) > div:nth-of-type(3)",
		title: <Title text= "Details"/>,
		content:  <Content 
								text= "To view Details regarding this job."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom-start"
	},
	{
		
		target: "#jobSearch",
		title: <Title text= "Search Bar"/>,
		content:  <Content 
								text= "You can search here about a Cleaning Job using its Address or ID."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right"
	},
	{
		
		target: "#jobTable",
		title: <Title text= "Jobs Table"/>,
		content:  <Content 
								text= "You can view all the Cleaning Jobs that you have Accepted and the Results of your search will also be reflected here."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top-start"
	},
	{
		
		target: "tbody > tr:first-of-type",
		title: <Title text= "Job details Row"/>,
		content:  <Content 
								text= "Details of each Cleaning job will be displayed in the form of a Row like this one."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top"
	},
	{
		
		target: "td:first-of-type",
		title: <Title text= "Job Options"/>,
		content:  <Content 
								text= "You can view options regarding any Job by clicking on its ID located in front of each row."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "right"
	},
	{
		
		target: `	tr:first-of-type > td:nth-of-type(6)  p`,
		title: <Title text= "Job Status"/>,
		content:  <Content 
								text= "Current status of the job will be displayed here."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "left"
	},
	// {
		
	// 	target: "tfoot > tr > td > div > div:nth-of-type(2) > div > div ",
	// 	title: <Title text= "Row Limit"/>,
	// 	content:  <Content 
	// 							text= "From here you can select how many Rows you want to view at a time." 
	// 						/>,
	// 	locale: { skip: <strong aria-label="skip">Skip</strong> },
	// 	placement: "left-end"
	// },
	// {
		
	// 	target: "tfoot > tr > td > div > div:nth-of-type(3) > button:first-of-type ",
	// 	title: <Title text= "First Page"/>,
	// 	content:  <Content 
	// 							text= "Button to view the First page ." 
	// 						/>,
	// 	locale: { skip: <strong aria-label="skip">Skip</strong> },
	// 	placement: "left-end"
	// },
	// {
		
	// 	target: "tfoot > tr > td > div > div:nth-of-type(3) > button:nth-of-type(2)",
	// 	title: <Title text= "Previous Page"/>,
	// 	content:  <Content 
	// 							text= "To view the Previous page." 
	// 						/>,
	// 	locale: { skip: <strong aria-label="skip">Skip</strong> },
	// 	placement: "left-end"
	// },
	// {
		
	// 	target: "tfoot > tr > td > div > div:nth-of-type(3) > button:nth-of-type(3)",
	// 	title: <Title text= "Next Page"/>,
	// 	content:  <Content 
	// 							text= "To view the Next page." 
	// 						/>,
	// 	locale: { skip: <strong aria-label="skip">Skip</strong> },
	// 	placement: "left-end"
	// },
	// {
		
	// 	target: "tfoot > tr > td > div > div:nth-of-type(3) > button:last-of-type ",
	// 	title: <Title text= "Last Page"/>,
	// 	content:  <Content 
	// 							text= "To view the Last page ." 
	// 						/>,
	// 	locale: { skip: <strong aria-label="skip">Skip</strong> },
	// 	placement: "left-end"
	// },			
	{
		
		target: "#jobFilters > div:first-of-type",
		title: <Title text= "All Jobs"/>,
		content:  <Content 
								text= "To view all the Cleaning Jobs in the table."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "left"
	},
	{
		
		target: "#jobFilters > div:nth-of-type(2)",
		title: <Title text= "Today's Jobs"/>,
		content:  <Content 
								text= "To view all the Cleaning jobs regarding Today in the table."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "left"
	},
	{
		
		target: "#jobFilters > div:last-of-type",
		title: <Title text= "Jobs Not Assigned."/>,
		content:  <Content 
								text= "To view all the Cleaning jobs waiting to be Assigned." 
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "left"
	},
	{
		
		target: "#addJobBtn",
		title: <Title text="Add Job"/>,
		content:  <Content 
								text= "To add a New Job."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "left-end"
	},
											
]; 

export const customerListSteps = [
	{
		disableBeacon: true,
		target: "#customersListCont",
		title: <Title text="Customers"/>,
		content:  <Content 
								text= "Information about your Customers will be shown here."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "center"
	},
	{
		
		target: "#customersSearch",
		title: <Title text= "Search Bar"/>,
		content:  <Content 
								text= "You can Search about a Particular customer using Customer's Name and Email."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "ul > div:first-of-type",
		title: <Title text= "Customer's Detail"/>,
		content:  <Content 
								text= "Information regarding each Customer will be displayed in a row like this."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top"
	},
	// {
	// 	target: "#customersCont",
	// 	title: <Title text= "Customer's Detail"/>,
	// 	content:  <Content 
	// 							text= "Information regarding each Customer will be displayed in a row like this."
	// 						/>,
	// 	locale: { skip: <strong aria-label="skip">Skip</strong> },
	// 	placement: "top"
	// },
	{
		
		target: "#customersCont > div:first-of-type > div:first-of-type ",
		title: <Title text= "Basic Information"/>,
		content:  <Content 
								text= "Information like Customer's Name and Address."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top"
	},
	{
		
		target: "#customersCont > div:first-of-type p:nth-of-type(2) > a",
		title: <Title text= "View On Map"/>,
		content:  <Content 
								text= "To view the Address provided by user on the Map you can click on this link."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top"
	},
	{
		
		target: "#customersCont > div:first-of-type > div:nth-of-type(2) ",
		title: <Title text= "Contact number"/>,
		content:  <Content 
								text= "Cell phone number provided by Customer."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top"
	},
	{
		
		target: "#customersCont > div:first-of-type > div:nth-of-type(3)",
		title: <Title text= "Email"/>,
		content:  <Content 
								text= "Email provided by the Customer."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top"
	},
	{
		
		target:	"#customersCont > div:first-of-type > div:last-of-type",
		title:	<Title text= "View Profile"/>,
		content:  <Content 
								text= "To View Customer's profile. It includes information regarding the Bookings made by customer."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top"
	},
	{
		
		target:	"#addJobBtn",
		title:	<Title text= "Add Customer"/>,
		content:  <Content 
								text= "To Add a New Customer."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top-end"
	}
];

export const employeeListSteps = [
	{
		disableBeacon: true,
		target: "#employeeListCont",
		title: <Title text="Employees"/>,
		content:  <Content 
								text= "You can manage all your Employees from here."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "center"
	},
	{
		
		target: "#employeeSearch",
		title: <Title text="Search Bar"/>,
		content:  <Content 
								text= "You can search any Employee from here."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#employeeInfo > div:first-of-type",
		title: <Title text="Total Employees"/>,
		content:  <Content 
								text= "Total Number of Employees will be displayed here."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#employeeInfo > div:nth-of-type(2)",
		title: <Title text="Free Employees"/>,
		content:  <Content 
		 						text= "Total Number of Employees who are not on any job."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#employeeInfo > div:last-of-type",
		title: <Title text="Employees on Job"/>,
		content:  <Content 
								text= "Total number of employees currently on job."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target: "#employeeList > div:first-of-type",
		title: <Title text="Employee detail"/>,
		content:  <Content 
								text= "Information regarding an Employee will be displayed in a row like this."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top"
	},
	{
		
		target: "#employeeList > div:first-of-type > div:first-of-type",
		title: <Title text= "Basic Information"/>,
		content:	<Content 
								text= "Information like Employee's name, profile picture and Current Job Location."
							/>,
    locale: { skip: <strong aria-label="skip">Skip</strong> },
    placement: "top"

		},
		{

		target: "#employeeList > div:first-of-type p:last-of-type > a",
		title: <Title text="View on Map"/>,
		content:  <Content 
								text= "To track current Cleaning address location of Employee."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top"
		},
		{

		    target: "#employeeList > div:first-of-type > div:nth-of-type(2) ",
		    title: <Title text= "Contact number"/>,
		    content:  <Content 
		                text= "Mobile/Phone number provided by Employee."
		              />,
		    locale: { skip: <strong aria-label="skip">Skip</strong> },
		    placement: "top"
  },
  {
		
    target: "#employeeList > div:first-of-type > div:nth-of-type(3)",
    title: <Title text= "Date of Joining"/>,
    content:  <Content 
                text= "Date on which the Employee joined the company."
              />,
    locale: { skip: <strong aria-label="skip">Skip</strong> },
    placement: "top"
  },
	{
		
		target: "#employeeList > div:first-of-type > div:last-of-type > div > div:first-of-type",
		title: <Title text="View Profile"/>,
		content:  <Content 
								text= "To View Employee's profile details like Job Shift timing, Workdays etc."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top-end"
	},
	{
		
		target: "#employeeList > div:first-of-type > div:last-of-type > div > div:last-of-type",
		title: <Title text="More Options"/>,
		content:  <Content 
								text= "To show other actions that can be performed."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top-end"
	},
	{
		
		target:	"#addJobBtn",
		title:	<Title text= "Add Employee"/>,
		content:  <Content 
								text= "To Add a New Employee."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top-end"
	}
];

export const paymentMethodSteps = [
	{
		disableBeacon: true,
		target: "#paymentMethods",
		title: <Title text="Payment Methods."/>,
		content:  <Content 
								text= "All of your Added Payment methods will be listed here. "
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "top"
	},
	{
		
		target: "#addPaymentBtn",
		title: <Title text="Add Payment Method"/>,
		content:  <Content 
								text= "To Add a new Payment Method."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom-start"
	}
];

export const companyProfileSteps = [
	{
		disableBeacon: true,
		target:"#settingsCont",
		title: <Title text="Settings"/>,
		content:  <Content 
								text= "Here you can adjust your Account Settings."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "center"
	},
	{
		
		target: "#settingOptions > div:first-of-type" ,
		title: <Title text="Payment methods"/>,
		content:  <Content 
								text= "To view your Added Payment methods."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom-start"
	},
	{
		
		target:"#settingOptions > div:nth-of-type(2)" ,
		title: <Title text="Company Profile"/>,
		content:  <Content 
								text= "To view and Update your Company profile . Currently you are on the this page."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
	{
		
		target:"#settingOptions > div:last-of-type" ,
		title: <Title text="Plan Settings"/>,
		content:  <Content 
								text= "To view your Account's Payment Plan settings."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "bottom"
	},
];

export const paymentPlanSteps = [
	{
		disableBeacon:true,
		target:"#selectPlan",
		title: <Title text="Choose Plan"/>,
		content:  <Content 
								text= "Here you can change your Payment Plan according to your need."
							/>,
		locale: { skip: <strong aria-label="skip">Skip</strong> },
		placement: "center"
	}
];





// export const companyProfileSteps= [
	// 
	// 
	// {
	// 	target:"#paymentMethod> div> div> div> button",
	// 	title: <Title text="Add Payment Method"/>,
	// 	content:  <Content 
	// 							text= "To add a new Payment method."
	// 						/>,
	// 	locale: { skip: <strong aria-label="skip">Skip</strong> },
	// 	placement: "bottom"
	// },
	// {
	// 	target:	"#settingOptions > div:nth-of-type(2)" ,
	// 	title: <Title text="Company Profile"/>,
	// 	content:  <Content 
	// 							text= "To check your Company's profile click on this button."
	// 						/>,
	// 	locale: { skip: <strong aria-label="skip">Skip</strong> },
	// 	placement: "bottom",

	// 	spotlightClicks: true,
	// 	styles: {
	// 		tooltipFooter: {
	// 			opacity: 0
	// 		},
	// 		buttonClose: {
	// 			display: "none"
	// 		}
	// 	}
	// },
	// {
	// 	
	// 	target: "#paymentMethod",
	// 	title: <Title text="Company Profile"/>,
	// 	content:  <Content 
	// 							text= "You can edit your Company's profile here."
	// 						/>,
	// 	locale: { skip: <strong aria-label="skip">Skip</strong> },
	// 	placement: "bottom"
	// },
	// {
	// 	target: "#nextStep",
	// 	title: <Title text="Next"/>,
	// 	content:  <Content 
	// 							text= "After you "
	// 						/>,
	// 	locale: { skip: <strong aria-label="skip">Skip</strong> },
	// 	placement: "bottom",
	// 	spotlightClicks: true,
	// 	styles: {
	// 		tooltipFooter: {
	// 			opacity: 0
	// 		},
	// 		buttonClose: {
	// 			display: "none"
	// 		}
	// 	}
	// }
	// {
	// 	target:"#settingOptions > div:last-of-type",
	// 	title: <Title text="Plan Settings"/>,
	// 	content:  <Content 
	// 							text= "To change your Payment Plan Settings."
	// 						/>,
	// 	locale: { skip: <strong aria-label="skip">Skip</strong> },
	// 	placement: "bottom"
	// },
// ];