import React from "react";

import SettingsContainer from "containers/SettingsContainer";
import { MainLayout } from "layouts";

const CalenderPage = (props) => (
  <MainLayout {...props}>
    <SettingsContainer  {...props} />
  </MainLayout>
)

export default CalenderPage;