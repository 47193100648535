import React, { Component } from 'react';
import { isEmpty } from "lodash";
import axios from "../utils/http";
import { reset } from "redux-form";
import { connect } from "react-redux";
import Payments from "../components/Payments";
import { setMsgAndShow } from "../store/actions/popup";
import { toggleLoader } from "../store/actions/app";

import { STATUS } from "react-joyride";
import Storage from "../utils/Storage";

import { withMixpanel } from "../utils/react-mixpanel";
class PaymentsContainer extends Component{
  state = {
    isAccountFormOpen: false,
    paymentDetailsList:[],
    load: false,
    makingDefaultId: "",

    runGuide: false
  }
  componentDidMount(){
    if(!isEmpty(this.props.businessDetails)){
      this.addData();
    }

    const { trackPageLoad } = this.props;
    trackPageLoad("Payment_Methods_Settings");
  }
  componentDidUpdate(prevProps, nextProps){
    if(JSON.stringify(prevProps.businessDetails) !== JSON.stringify(this.props.businessDetails)){
        this.addData();
    }
  }
  addData = async () => {
    // const contractorId = this.props.businessDetails._id
    const { toggleLoader } = this.props;
    toggleLoader();
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/payment_methods/list`)

      toggleLoader();
      if(res.data.status === "OK"){
        const { paymentDetailsList } = res.data;
        this.setState({
          paymentDetailsList
        })
      }
      
      const { isTourEnabled } = this.props;

      if(Storage.get("paymentMethodGuide") !== "completed" && isTourEnabled){
        this.setState({
          runGuide: true
        });
      }

    } catch (error) {
      toggleLoader();
      console.error(error); // eslint-disable-line
    }
  }

  getHelpers = (StoreHelpers) => {
    this.helpers = StoreHelpers;
  };

  handleJoyrideCallback = (CallBackProps) => {  
    const { status } = CallBackProps;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      Storage.set("paymentMethodGuide","completed");
      this.setState({ runGuide: false });
    }
  };

  openAccountForm = () => {
    
    const { trackButtonEvent } = this.props;

    trackButtonEvent("Add_Payment_Method",window.location.pathname);

    this.setState({
      isAccountFormOpen: true
    })
  }
  handleAccountFormClose = () => {
    this.props.resetForm("AccountForm")
    this.setState({
      isAccountFormOpen: false
    })
  }

  onPaymentDetailsAdded = (paymentDetails) => {
    const { paymentDetailsList } = this.state;
    paymentDetailsList.push(paymentDetails)
    this.setState({
      paymentDetailsList
    })
    this.props.setMsgAndShow("New payment method added");
  }
  makeAccountDefault = (paymentObj) => async (e) => {
    const paymentDetailsId = paymentObj._id;
    // const contractorId = this.props.businessDetails._id
    try {
      this.setState({
        makingDefaultId: paymentDetailsId,
        load: true
      })
      const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}contractor/payment_methods/${paymentDetailsId}/make_default`);

      if(res.data.status === "OK"){
        this.setState({
          makingDefaultId: "",
          load: false
        })
        this.addData();
        this.props.setMsgAndShow("Payment method is now default")
      } else {
        this.setState({
          makingDefaultId: "",
          load: false
        })
        this.props.setMsgAndShow(res.data.message)
      }
    } catch (error) {
      this.setState({
        makingDefaultId: "",
        load: false
      })
      this.props.setMsgAndShow("Error in server")
    }
  }
  render(){
    return (
      <Payments 
        openAccountForm={this.openAccountForm}
        handleAccountFormClose={this.handleAccountFormClose}
        onPaymentDetailsAdded={this.onPaymentDetailsAdded}
        makeAccountDefault={this.makeAccountDefault}
        getHelpers={this.getHelpers}
        handleJoyrideCallback={this.handleJoyrideCallback}
        {...this.state}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  businessDetails: state.contractor.businessDetails,
  isTourEnabled: state.app.isTourEnabled
});

const mapDispatchToState = (dispatch) => ({
  resetForm: (formName) => dispatch(reset(formName)),
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  toggleLoader: () => dispatch(toggleLoader())
});

const connectRedux = connect(mapStateToProps, mapDispatchToState)(PaymentsContainer);

export default withMixpanel(connectRedux);