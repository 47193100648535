import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "../components/ProgressCircle";
import Header from "../components/Header";
import SideBar from "../containers/SidebarContainer";
import ChangeInJobAlert from "../containers/ChangeInJobAlertContainer";
import { withStyles } from "core/components/Styles";
import {mobileAppBar, appBar, drawerWidth} from "theme/vars"
import AlertBox from "core/components/AlertBox";

import Joyride, { STATUS } from "react-joyride"; 
import { sideBarSteps, appBarSteps, dashboardSteps } from "../components/TourGuide/TourGuideSteps";
import Storage from "../utils/Storage";

import { withMixpanel } from "../utils/react-mixpanel";
import { tooltipButtonColor } from "../theme/colors";

const styles = theme => ({
  mainContent:{
    overflow:"visible",
    marginTop: appBar,
    flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
    backgroundColor: "#f4f8f9",
    width: window.innerWidth - drawerWidth,
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      marginTop: mobileAppBar
    }
  },
  root:{
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    minHeight:"100vh",
    minWidth: "100%",
    maxWidth: "100%"
  }
})

const list = [
  {
    serviceAddress:{
      fname:"Chris",
      lname:"Lanme",
      address:"1515 St Woolsey St",
      city:"Berkeley",
      state:"CA"
    },
    time:"14:30",
    date:"2019-06-03"
  },
  {
    serviceAddress:{
      fname:"Chris",
      lname:"Lanme",
      address:"1515 St Woolsey St",
      unit:"15",
      city:"Berkeley",
      state:"CA"
    },
    time:"14:30",
    date:"2019-06-03",
    changes:{
      time:"15:00",
      date:"2019-06-10"
    }
  },
  {
    serviceAddress:{
      fname:"Chris",
      lname:"Lanme",
      address:"1515 St Woolsey St",
      city:"Berkeley",
      state:"CA"
    },
    time:"14:30",
    date:"2019-06-03"
  },

]
class MainLayout extends Component {
  state = {
    currentTour: '',
    isSidebarOpen: false,
    sideBarRun: false,
    appBarRun: false,
    dashboardRun: false,
    stepIndex: 0,
    sidebarSkipped: false
  }

  getHelpers = (StoreHelpers) => {
    this.helpers = StoreHelpers;
  };

  componentDidMount(){
    const { isTourEnabled } = this.props;
    if(Storage.get("dashboardGuide") !== "completed" && isTourEnabled ){
      this.setState({
        currentTour: 'sidebar',
        sideBarRun: true,
      })
    }

    const { mixpanel } = this.props; 
    const { distinct_id } = mixpanel.cookie.props 
    mixpanel.identify(distinct_id); 
    mixpanel.people.set({ 
      $email: `${process.env.REACT_APP_MIXPANEL_TRACKER_EMAIL}`, 
      $last_login: new Date(), 
    }); 
  }

  handleJoyrideCallback = (CallBackProps) => {


    const { index, size, lifecycle, status } = CallBackProps;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      Storage.set("dashboardGuide","completed");
      this.setState({ runGuide: false });
    }

    const { currentTour } = this.state;
    const isComplete = lifecycle === "complete";
    if(!isComplete) 
      return;
    if( currentTour === "sidebar" && index ===  size-1 ){
      Storage.set("dashboardGuide","completed");
      this.setState({ appBarRun: true, stepIndex: 1, sideBarRun: false, currentTour: 'appbar' });
    }
    if( currentTour === "appbar" && index === size-1 ){
      this.setState({ dashboardRun: true, stepIndex: 2, appBarRun: false, currentTour: 'dashboard' });
    }
    
  };

  toggleSidebar = (isVisible) => e => {
    if(isVisible !== false && isVisible !== true) {
      this.setState({isSidebarOpen: !this.state.isSidebarOpen});
    } else {
      this.setState({isSidebarOpen: isVisible});
    }
  }

  toggleTourGuide = ()=>{
    this.setState({
      sideBarRun: !this.state.sideBarRun,
    })
  }

  render() {
    const {children, classes, showLoader, ordersWithChanges, alert, isTourEnabled, ...restProps} = this.props;
    const { sideBarRun, appBarRun, dashboardRun } = this.state;
    return (
      <div className={classes.root} id="mainContDiv">
        <Joyride
          callback={this.handleJoyrideCallback}
          continuous={true}
          getHelpers={this.getHelpers}
          run={sideBarRun}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={sideBarSteps}
          styles={{
            options: {
              primaryColor: tooltipButtonColor
            }
          }}
        />
        <Joyride
          callback={this.handleJoyrideCallback}
          continuous={true}
          getHelpers={this.getHelpers}
          run={appBarRun}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={appBarSteps}
          styles={{
            options: {
              primaryColor: tooltipButtonColor   
            }
          }}
        />
        <Joyride
          callback={this.handleJoyrideCallback}
          continuous={true}
          getHelpers={this.getHelpers}
          run={dashboardRun}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={dashboardSteps}
          styles={{
            options: {
              primaryColor: tooltipButtonColor   
            }
          }}
        />
        <Header {...this.state} toggleSidebar={this.toggleSidebar} {...restProps}/>
        <SideBar {...this.state} toggleSidebar={this.toggleSidebar} {...restProps}/>
        <div className={classes.mainContent}>
          {showLoader && <Loader zIndex={999} />}
          {list && list.length > 0 && <ChangeInJobAlert ordersWithChanges={ordersWithChanges} />}
          {alert && alert.show  && <AlertBox message={alert.message} /> }
          {children}
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    showLoader: state.app.showLoader,
    alert: state.app.alert,
    ordersWithChanges: state.app.ordersWithChanges,
    isTourEnabled: state.app.isTourEnabled
  }
};


const WithStylesMainLayout = withStyles(styles)(MainLayout)

const connectRedux = connect(mapStateToProps)(WithStylesMainLayout);

export default withMixpanel(connectRedux);


// const Tooltip = ({
//   continuous,
//   index,
//   step,
//   backProps,
//   closeProps,
//   primaryProps,
//   skipProps,
//   tooltipProps,
// }) => {
//   return(
//     <div {...tooltipProps}>
//       {step.title && <div>{step.title}</div>}
//       <div>{step.content}</div>
//       <div>
//         {index > 0 && (
//           <button {...backProps}>
//             back
//           </button>
//         )}
//         {continuous && (
//           <button {...primaryProps}>
//             next
//           </button>
//         )}
//         {!continuous && (
//           <button {...closeProps}>
//             close
//           </button>
//         )}
//       </div>
//     </div>
//   )
// };
