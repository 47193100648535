import React from "react";
// import Grid from "core/components/Grid";
import TextField from 'core/components/TextField';
import FormHelperText from "core/components/FormHelperText";
import {withStyles} from "core/components/Styles";

import cn from "classnames";

const styles = {
  error: {
    color: "red",
    fontSize: 12,
  },
  warning: {
    color: "orange",
    fontSize: 12
  },

  noMargin :{
    margin : 0
  },
  labelStyle: {
    "& input": {
      paddingTop:0,
      fontSize: 15,
      lineHeight: "28px",
      opacity: 0.5,
      "&::placeholder":{
        color: "#A3A3A3",
      }
    }
  },
  setBorder : {
    border: "1px solid #d8d8d8",
    borderRadius: 5,
    padding: "5px 18px", 
    "& input":{
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '21px',
      color: "#646464"
    }
  },
}



const handleEnter =  e => {
  if (e.keyCode === 13) {
    const target = e.target;
    const form = target.form;
    if (Array.prototype && form && target) {
      let index = Array.prototype.indexOf.call(form, target);
      let idx = form.elements[index + 1].tabIndex;
      while (idx < 0) {
        index++;
        idx = form.elements[index + 1].tabIndex;
      }
      form.elements[index + 1].focus();
      e.preventDefault();
    }
  }
}
const MaterialTextField = ({
  classes, 
  input, 
  onClick, 
  meta, 
  rightIcon, 
  leftIcon, 
  placeholder, 
  size,
  name,
  label,
  shrink,
  setBorder,
  errorMsg,
  onKeyDown,
  ...rem
}) => { 
  return (
    //#region 
    // <Grid>
    //   {input ? 
    //     (<TextField
    //       {...input}
    //       {...rem}
    //       id={name}
    //       name={name}
    //       label={label}
    //       onClick={onClick}
    //       onKeyDown={ e => {
    //         if (e.keyCode === 13) {
    //           const target = e.target;
    //           const form = target.form;
    //           if (Array.prototype && form && target) {
    //             let index = Array.prototype.indexOf.call(form, target);
    //             let idx = form.elements[index + 1].tabIndex;
    //             while (idx < 0) {
    //               index++;
    //               idx = form.elements[index + 1].tabIndex;
    //             }
    //             form.elements[index + 1].focus();
    //             e.preventDefault();
    //           }
    //         }
    //       }}
    //       placeholder={placeholder}
    //       margin="normal"
    //       fullWidth
    //     />) : 
    //   (<TextField
    //     {...rem}
    //     id={name}
    //     label={label}
    //     name={name}
    //     onClick={onClick}
    //     onKeyDown={ e => {
    //       if (e.keyCode === 13) {
    //         const target = e.target;
    //         const form = target.form;
    //         if (Array.prototype && form && target) {
    //           let index = Array.prototype.indexOf.call(form, target);
    //           let idx = form.elements[index + 1].tabIndex;
    //           while (idx < 0) {
    //             index++;
    //             idx = form.elements[index + 1].tabIndex;
    //           }
    //           form.elements[index + 1].focus();
    //           e.preventDefault();
    //         }
    //       }
    //     }}
    //     placeholder={placeholder}
    //     margin="normal"
    //     fullWidth
    //   />)}
    //   {
    //     meta.touched &&
    //       ((meta.error && <span className={classes.error}>{meta.error}</span>) ||
    //       (meta.warning && <span className={classes.warning}>{meta.warning}</span>))
    //   }
    // </Grid>
    //#endregion
    <div>
      {
        input 
        ? (<TextField
            {...input}
            {...rem}
            id={name}
            name={name}
            label={label}
            onClick={onClick}
            onKeyDown={ e => {
              if (e.keyCode === 13) {
                const target = e.target;
                const form = target.form;
                if (Array.prototype && form && target) {
                  let index = Array.prototype.indexOf.call(form, target);
                  let idx = form.elements[index + 1].tabIndex;
                  while (idx < 0) {
                    index++;
                    idx = form.elements[index + 1].tabIndex;
                  }
                  form.elements[index + 1].focus();
                  e.preventDefault();
                }
              }
            }}
            placeholder={placeholder}
            margin="normal"
            fullWidth
            InputLabelProps={shrink ? {
              shrink: true,
            }: null}
            className = {cn([
              {
                [classes.setBorder]: setBorder,
                [classes.labelStyle]: rem.fixedstyle === "true"
              },
              classes.noMargin
            ])}/>) 
        :  (<TextField
            {...rem}
            id={name}
            label={label}
            name={name}
            onClick={onClick}
            onKeyDown={onKeyDown ? onKeyDown : handleEnter}
            placeholder={placeholder}
            margin="normal"
            fullWidth
            className = {classes.noMargin}
            error={Boolean(errorMsg)}
          />)
      }
      {
        meta && meta.touched &&
          ((meta.error && <span className={classes.error}>{meta.error}</span>) ||
          (meta.warning && <span className={classes.warning}>{meta.warning}</span>))
      }

      { errorMsg 
        &&
        <FormHelperText className={classes.error}>{errorMsg}</FormHelperText>
      }
    </div>
  );
}



export default withStyles(styles)(MaterialTextField);