export const required = value => value ? undefined : "Required";
export  const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export  const minLength = min => value =>
  value && value.length < min ? `Must be at leas ${min} characters or more` : undefined;
export const number = value => value && isNaN(Number(value)) ? "Must be a number" : undefined;
export const maxValue = max => value =>
  value && value > max ? `Must be ${max} value or less` : undefined;
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    "Invalid email address" : undefined;
export const aol = value =>
  value && /.+@aol\.com/.test(value) ?
    "Really? You still use AOL for your email?" : undefined;
export const matchPasswords = (value, allValues) => 
  value !== allValues.password ? "Passwords don't match" : undefined
export const address = value => 
  value && !/(\d+) ((\w+[ ,])+ ){2}([a-zA-Z]{2}) (\d{5})/.test(value) ? 
    "Invalid Address (Format Accepted : 'Address, City, State ZipCode')" : undefined