import React, { Component } from 'react';
import { PlanChangeForm } from "../components/PlanChangeForm";
import { connect } from "react-redux";
import { reduxForm} from "redux-form";
import { isEmpty, isEqual } from "lodash";
import { withStripe } from "../components/Stripe";
import { updateDetails } from "../store/actions/contractor";
import encrypter from "crypto-js";
import axios from "../utils/http";
import { sortCardsByDefault } from "../utils/sortCards";
import { setMsgAndShow } from "../store/actions/popup";
import { withMixpanel } from "../utils/react-mixpanel";

class PlanChangeFormContainer extends Component {

  state = {
    errorCodes:{},
    showLoader: false
  }


  componentDidUpdate(prevProps) {
    const { businessDetails: oldBusinessDetails } = prevProps;
    const { businessDetails: newBusinessDetails } = this.props;
    if(!isEqual(oldBusinessDetails, newBusinessDetails)){
      // const {plan:newPlan, payFrom:newPayFrom } = newBusinessDetails;
      // const {plan:oldPlan, payFrom:oldPayFrom } = oldBusinessDetails;
      // if(oldPlan !== newPlan && !isEqual(oldPayFrom, newPayFrom)){
        this.setData(newBusinessDetails);
      // }
    }
  }

  setData = (businessDetails) => {

    const values = {}

    this.openLoader();
    if(!isEmpty(businessDetails)){
      const { plan, payFrom } = businessDetails;
      values.plan = plan;

      if(plan === "premium"){
        values.cardSource = payFrom.cardId;
      }

      for(const key in values){
        this.props.change(key,values[key]);
      }
    }

    this.closeLoader()
  }



  componentDidMount(){
    const { businessDetails, userInfo } = this.props;
    this.setData(businessDetails, userInfo)
  }

  toggleLoader = () => {
    this.setState({
      showLoader: !this.state.showLoader
    })
  }

  closeLoader = () => {
    this.setState({
      showLoader: false
    })
  }
  openLoader = () => {
    this.setState({
      showLoader: true
    })
  }
  submitPlanData = async (obj, dispatch, props) => {
    const { showMsg, trackApiEvent } = this.props;
    try {
      this.toggleLoader()
      const { userInfo, businessDetails } = this.props;
      const { plan, cardSource } = obj;
      const formValues = {
        plan
      };
      let cardData = {};

      if(plan && plan === "premium"){
        if(cardSource === "new"){
          const stripe = await this.props.stripe.createToken();
          if(stripe.error){
            this.setState({errorCodes: stripe.error})
            throw new Error("Error in your card details");
          };
          const { token:{id:tokenId, card } } = stripe;
          cardData = {
            last4: card.last4,
            brand: card.brand,
            object: card.object,
            stripeToken: tokenId
          };
        } else {
          cardData = {
            cardId: cardSource
          };
        }
      }
      if(!isEmpty(cardData)){
        const stringifiedCardData = JSON.stringify(cardData);
        formValues.sourceToken = encrypter.AES.encrypt(stringifiedCardData,userInfo._id).toString();
      }

     
      const res = await axios.post("contractor/change_plan",{
        ...formValues,
        userId: userInfo._id
      });

      const { status, message, cardToken } = res.data;

      if(status === "OK"){
        let userD, conD;
        const updates = {};
        let payFrom = {};
        if(plan === "premium" && cardToken){

          trackApiEvent("Premium_Plan_Select", "POST", "contractor/change_plan", "OK", window.location.pathname);

          let cardData = encrypter.AES.decrypt(cardToken, userInfo._id);
          cardData = JSON.parse(cardData.toString(encrypter.enc.Utf8));
          let cards = userInfo.cards || [];
          payFrom = {
            cardId: cardData._id,
            method:"stripe"
          }
          if(cardSource === "new"){
            const card = {
              ...cardData
            }

            cards = cards.map((c) => ({...c, default: false}))
            cards.unshift(card);
          } else {
            cards = cards.map((c) => {
              if(c._id === cardData._id){
                return {
                  ...c,
                  ...cardData
                }
              }
              return {
                ...c,
                default: false
              };
            })
          }
   
          cards = [...cards].sort(sortCardsByDefault);
          userD = {
            ...userInfo,
            cards
          }

        }
        else if(plan === "basic") {
          trackApiEvent("Basic_Plan_Select", "POST", "contractor/change_plan", "OK", window.location.pathname);
        }


        conD = {
          ...businessDetails,
          plan,
          payFrom
        }


        if(!isEmpty(conD)){
          updates.businessDetails = {...conD};
        }


        if(!isEmpty(userD)){
          updates.userInfo = { ...userD };
        }



        if(!isEmpty(updates)){
          this.props.updateDetails(updates);
        }


        this.closeLoader()
        showMsg(message ? message : "Error Occured");
      }

    
    } catch (error) {
      const { response, message } = error;
      
      let msg = message;

      if(response && response.data){
        msg = response.data.message ? response.data.message : message;
      }

      trackApiEvent("Plan_Select", "POST", "contractor/change_plan", msg, window.location.pathname);

      showMsg(msg);

      this.toggleLoader();
    }
  }

  changePlan = (plan) => () => {

    const { trackButtonEvent, change } = this.props; 

    const planName = plan.charAt(0).toUpperCase() + plan.slice(1);
    trackButtonEvent(`Select_${planName}_Plan`,window.location.pathname);
    
    change("plan", plan);
  }
  render() {
    return (
      <PlanChangeForm  
        submitPlanData={this.submitPlanData}
        changePlan={this.changePlan}
        {...this.props}
        {...this.state}
      />
    )
  }
}



const mapStateToProps = (state) => {
  let formValues = {};

  if(!isEmpty(state.form.PlanChangeForm)){
    const { PlanChangeForm} = state.form;
    if(!isEmpty(PlanChangeForm.values)){
      formValues = PlanChangeForm.values;
    }
  }

  return {
    formValues,
    businessDetails: state.contractor.businessDetails,
    userInfo: state.contractor.userInfo
  }

}

const mapDispatchToProps = (dispatch) => ({
  updateDetails: (updates) => dispatch(updateDetails(updates)),
  showMsg: (msg) => dispatch(setMsgAndShow(msg))
})

const WithStripe = withStripe(PlanChangeFormContainer);

const withReduxForm = reduxForm({
  destroyOnUnmount: true,
  form:"PlanChangeForm"
})(WithStripe)

const connectRedux = connect(mapStateToProps, mapDispatchToProps)(withReduxForm) ;

export default withMixpanel(connectRedux);

// export default connect(mapStateToProps, mapDispatchToProps)(
//   reduxForm({
//     destroyOnUnmount: true,
//     form:"PlanChangeForm"
//   })(WithStripe)
// ); 