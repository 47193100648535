import React from "react";
import { startCase } from "lodash";
import { ReactComponent as AddOnWindowsIcon } from "../../assets/images/svg/job_windows.svg";
import { ReactComponent as AddOnEcoIcon } from "../../assets/images/svg/job_eco_friendly.svg";
import { ReactComponent as AddOnBlindsIcon } from "../../assets/images/svg/job_blinds.svg";
import { ReactComponent as AddOnFridgeIcon } from "../../assets/images/svg/job_fridge.svg";
import { ReactComponent as AddOnOvenIcon } from "../../assets/images/svg/job_oven.svg";
import { ReactComponent as AddOnWallIcon } from "../../assets/images/svg/job_wall.svg";

const iconObj = {
  windows: <AddOnWindowsIcon/>,
  oven: <AddOnOvenIcon/>,
  walls: <AddOnWallIcon/>,
  blinds: <AddOnBlindsIcon/>,
  fridge: <AddOnFridgeIcon/>,
  ecoFriendly: <AddOnEcoIcon/>
};

const handleAddOns = (addOns,type)=>{
  let addOnsList= [];
  if(type === "commercial" && addOns && addOns.windowsRange){
    addOnsList = [{
      value: addOns.windowsRange,
      icon: <AddOnWindowsIcon/>,
      label: "Windows"
    }];
  }else if(type === "domestic" && addOns && Object.keys(addOns).length){
    const addOnKeys = Object.keys(addOns); 
    for(let key of addOnKeys){
      if(addOns[key] !== 0){
        addOnsList = [...addOnsList,{
          value: addOns[key],
          icon: iconObj[key],
          label: startCase(key)
        }];
      }
    }
  }
  return addOnsList;
}

export default handleAddOns;