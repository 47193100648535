import { 
  SET_ITEM, 
  TOGGLE_BUSINESS_MODAL, 
  CLOSE_BUSINESS_MODAL,
  TOGGLE_LOADER, 
  TOGGLE_DIALOG_LOADER, 
  SET_FORM_DATA,
  SET_ORDERS_WITH_CHANGES,
  CLEAR_ORDERS_WITH_CHANGES,
  REMOVE_ORDER_FROM_ORDERS_WITH_CHANGES,
  ADD_ORDER_WITH_CHANGES,
  SHOW_ALERT,
  HIDE_ALERT,
	SHOW_TOUR_GUIDE,
  TOGGLE_PAGE_REFRESH
} from "../types";
import  { cloneDeep } from "lodash";

const defaultState = {
  item:{
    itemType:null,
    item: null
  },
  showBusinessDetailsModal: false,
  showLoader: false,
  showDialogLoader: false,

  isTourEnabled: false,

  formData:{
    data: null
  },
  alert:{
    show:false,
    message:""
  },
  refreshPage:false,
  ordersWithChanges: []
}


const reducer = (state = defaultState, action) => {
  let finalState = {}

  switch(action.type){
    case SET_ITEM:
      finalState = cloneDeep(state);
      finalState.item.item = cloneDeep(action.item);
      finalState.item.itemType = action.itemType
      break;
    case TOGGLE_BUSINESS_MODAL:
      finalState = cloneDeep(state);
      finalState.showBusinessDetailsModal = !finalState.showBusinessDetailsModal
      break;
    case TOGGLE_LOADER:
      finalState = cloneDeep(state);
      finalState.showLoader = !finalState.showLoader
      break;
    case TOGGLE_DIALOG_LOADER:
      finalState = cloneDeep(state);
      finalState.showDialogLoader = !finalState.showDialogLoader;
      break;
    case SET_FORM_DATA:
      finalState = cloneDeep(state);
      finalState.formData.data = cloneDeep(action.payload);
      break;
    case CLOSE_BUSINESS_MODAL:
      finalState = cloneDeep(state);
      finalState.showBusinessDetailsModal = false
      break;
    case SET_ORDERS_WITH_CHANGES:
      finalState = cloneDeep(state)
      finalState.ordersWithChanges = cloneDeep(action.payload)
      break;
    case ADD_ORDER_WITH_CHANGES:
      finalState = cloneDeep(state);
      finalState.ordersWithChanges.push(action.payload);
      break;
    case REMOVE_ORDER_FROM_ORDERS_WITH_CHANGES:
      finalState = cloneDeep(state);
      finalState.ordersWithChanges = finalState.ordersWithChanges.filter((o) => o._id !== action.payload)
      break;
    case CLEAR_ORDERS_WITH_CHANGES:
      finalState = cloneDeep(state);
      finalState.ordersWithChanges = [];
      break;
    case SHOW_ALERT:
      finalState = cloneDeep(state);
      finalState.alert.show = true;
      finalState.alert.message = action.payload;
      break;
    case HIDE_ALERT:
      finalState = cloneDeep(state);
      finalState.alert = {
        show:false,
        message:""
      }
      break;
    case SHOW_TOUR_GUIDE:
		  finalState = cloneDeep(state);
      finalState.isTourEnabled = action.payload;
      break;
    case TOGGLE_PAGE_REFRESH:
      finalState = cloneDeep(state);
      finalState.refreshPage = !finalState.refreshPage;
      break;
    default:
      finalState = state;
  }
  return finalState;
}


export default reducer;