import React, {Fragment} from 'react';
import { withStyles } from "core/components/Styles";
import SettingsSideBar from "./SettingsSideBar";
import SettingsRoutesSwitcher from "../../routes/SettingsRoutesSwitcher";
import settingsRoutes from '../../routes/settingsRoutes';
import { appBar } from "theme/vars";
import { Grid } from 'core/components';
import { Menu } from "mdi-material-ui";
import IconButton from 'core/components/IconButton';
import dd from "utils/detectDevice";

import Joyride from "react-joyride";
import { companyProfileSteps } from "../TourGuide/TourGuideSteps";
import {tooltipButtonColor} from "../../theme/colors";

const styles = theme => ({
  root: {
    flexGrow: 1,
    //overflow: "hidden",
    height : "100%",
    display: "flex",
    overflow : "visible",
  },
  settingsMainContentWrapper:{
    overflow : "visible",
    display:"flex",
    width : "100%",
    padding : "30px 29px",
    flex: 1,
    minHeight:`calc(100vh - ${appBar}px)`
  },
  settingsMainContent:{
    marginTop: 30,
    flexGrow:1,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    }
  },
  jobsBtnCont: {
    marginTop: 10
  },
  newJobBtnCont: {
    marginTop: 10
  },
  jobslistCont: {
    marginTop: 10
  },
  textCenter: {
    textAlign: "center"
  },
  settingMenu: {
    padding: "0 14px",
    background: "white"
  }
});


const Settings = ({
  classes, 
  toggleSidebar, 
  isSettingsbarOpen, 
  showLoader,

  handleJoyrideCallback,
  getHelpers,
  runGuide,
}) => {
  return (
    <Fragment>
      <Joyride
        callback={handleJoyrideCallback}
        run={runGuide}
        continuous={true}
        getHelpers={getHelpers}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={companyProfileSteps}
        styles={{
          options: {
            primaryColor: tooltipButtonColor  
          }
        }}
      />
      {dd.isPhone && 
        <Grid container justify="space-between" alignItems="center" className={classes.settingMenu}>
          <Grid item>Settings Menu</Grid>
          <IconButton onClick={toggleSidebar(true)}>
            <Menu />
          </IconButton>
        </Grid>}
      
      <div className={classes.root}>
        <Grid container className={classes.settingsMainContentWrapper} direction="column" id="settingsCont">
          <Grid item>
            <SettingsSideBar 
              toggleSidebar={toggleSidebar} 
              isSettingsbarOpen={isSettingsbarOpen} 
            />
          </Grid>
          <Grid item className={classes.settingsMainContent} id="paymentMethod">
            <SettingsRoutesSwitcher routes={settingsRoutes} />
          </Grid>
        </Grid>
      </div>
    </Fragment>
  )
}



const WithStylesSettings = withStyles(styles)(Settings);


export default WithStylesSettings