

export const contractor = {
  base: "contractor",
  customers: {
    base: "/get_customers",
  },
  services: {
    base: "/services",
    getAll: "/get_all"
  },
  invoices: {
    base: "/invoices",
    add: "/add",
  }
}

export default {
  contractor
}