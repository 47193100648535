import React from "react";
import Table from "../TableWithPagination";
import { withStyles } from "core/components/Styles";
import { isEmpty } from "lodash";
import Grid from "core/components/Grid";
import FabButton from "core/components/FabButton";
import { capitalizeFirstLetterOnly } from "../../utils/capitalize";
const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex"
  },
  jobsBtnCont: {
    marginTop: 10
  },
  newJobBtnCont: {
    marginTop: 10
  },
  jobslistCont: {
    marginTop: 10
  },
  textCenter: {
    textAlign: "center"
  },
  mainCont: {
    paddingTop: 20,
    paddingLeft: 23,
    paddingRight: 23,
    paddingBottom: 20,
    width: "100%",
  },
  jobsScroller: {
    minWidth: 0
  },
  jobOffersCont: {
    overflowX: "auto",
    maxWidth: "100%"
  },
  btnsCont: {
    marginTop: 32,
    marginBottom: 25,
    paddingLeft: 5,
    paddingRight: 5
  },
  jobBtn: {
    height: 34,
    width: 136,
    borderRadius: 17,
    background: "#fff",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    letterSpacing: -0.1,
    color: "#51516b",
    boxShadow: "0 2px 17px 0 rgba(0, 0, 0, 0.11)",
    border: "2px solid transparent",
    "&:hover": {
      background: "#fff",
      border: "2px solid #49d3b9"
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%"
    }
  },
  activeBtn: {
    background: "#fff",
    border: "2px solid #49d3b9"
  },
  jobBtnsRow: {
    marginLeft: 40,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  fabBtn: {
    position: "absolute",
    right: 20,
    bottom: 20,
    zIndex: 99,
    height: 40,
    width: 40,
    background: "#ed5454",
    color: "#fff",
    "&:hover": {
      background: "#ed5454",
      boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.4), 0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 1px 18px 0px rgba(0, 0, 0, 0.15)"
    }
  },
  addIcon: {
    height: 12,
    width: 12,

  },
  searchBoxCont: {
    width: 250,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginBottom: 20
    }
  },
  jobBtnsItem: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      padding: "4px 0 !important"
    }
  },
  noDataText: {
    marginLeft: 10,
  }
});

const headers = [
  {
    name: "number",
    label: "Invoice Number"
  },
  {
    name: "customerFirstName",
    label: "Customer Name"
  },
  {
    name: "customerEmail",
    label: "Customer Email"
  },
  {
    name: "billingAddress",
    label: "Customer Address"
  },
  {
    name: "createdDate",
    label: "Created Date"
  },
  {
    name: "dueDate",
    label: "Due Date"
  },
  {
    name: "paymentMethod",
    label: "Method"
  },
  {
    name: "isPaid",
    label: "Status"
  },
  // {
  //   name:"paidOn",
  //   label:"Paid On"
  // }
];

const columnProps = [
  {
    name: "number",
    isActionButton: true
  },
  {
    fnameProp: "customerFirstName",
    lnameProp: "customerLastName",
    isName: true
  },
  {
    name: "customerEmail"
  },
  {
    name: "billingAddress",
    isAddress: false
  },
  {
    name: "createdDate",
    isDate: true,
    dateFormat: "DD MMM YYYY"
  },
  {
    name: "dueDate",
    isDate: true,
    dateFormat: "DD MMM YYYY"
  },
  {
    name: "paymentMethod",
    convertToUpperCase: true,
    convert: (text) => capitalizeFirstLetterOnly(text)
  },
  {
    name: "isPaid",
    prop1: "Paid",
    prop2: "unPaid",
    isPaid: true
  },
  // {
  //   name:"paidOn"
  // }
];

const changeRowColor = (obj) => !isEmpty(obj.changes) && obj.changeReason

const ListInvoice = (props) => {
  const {
    classes,
    invoicesList,
    actions,
    goTo,
    totalRows, 
    limit, 
    currentPage,
    handlePageChange, 
    handleLimitChange
  } = props

  return (

    <div className={classes.root}>
      <Grid className={classes.mainCont}>
        <FabButton onClick={goTo('/invoices/add')}/>
        <Grid className={classes.tableCont}>
          <Table
            list={invoicesList}
            handleOnPageChange = {handlePageChange}
            handleOnLimitChange = {handleLimitChange}
            rowsLimit={limit} 
            totalCount = {totalRows}
            headers={headers}
            pageNumber={currentPage}
            columnProps={columnProps}
            actions={actions}
            changeRowColor={changeRowColor}
          />
        </Grid>
      </Grid>
    </div>
  );
};



export default withStyles(styles)(ListInvoice)