import React from "react";
// import DialogTitle from "core/components/DialogTitle";
import DialogContent from "core/components/DialogContent";
import DialogActions from "core/components/DialogActions";
import Grid from "core/components/Grid";
// import Button from "core/components/Button";
import {
  MaterialCheckBox, 
  AdvanceSelectCompanyProfile, 
  FormInputField,
  MaterialTextField,
  RadioSelectBtn
} from "../FormField";
import DatePicker from "../DatePicker";
import Loader from "../Loader";
import { required, number, maxLength, minLength } from "../FormField/Validations";
import { Field, Form } from "redux-form";
import { withStyles } from "core/components/Styles";
import states from "data/statesTitleCase.json";
import ZTypography from "core/components/Typography";
import ZButton from "core/components/Button";

const stateObjOptions = states.map(state => {
  return {
    label: state.name,
    value: state.abbreviation
  };
});
const minLength4 = minLength(4);
const maxLength4 = maxLength(4);
const minLength9 = minLength(9);
const minLength_5 = minLength(5);
const maxLength_5 = maxLength(5);
// const maxLength9 = maxLength(9);

const styles = theme => ({
  inputMarginTop_10: {
    marginTop: 10
  },
  title: {
    marginLeft: 5,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16
  },
  selectorCont: {
    border: "1px solid #4a4a4a",
    maxWidth: 150,
    fontSize: "20px",
    borderRadius: ".3rem",
    overflow: "hidden",
    margin: 0,
    marginTop: 5,
    padding: 0,
    "& .dec, .inc": {
      background: "#f8f8f8",
      width: "30%",
      cursor: "pointer",
      textAlign: "center",
      padding: "0.3rem 0.5rem",
      display: "inline-block"
    },
    "& span:first-child": {
      borderRight: "1px solid #4a4a4a"
    },
    "& span:last-child": {
      borderLeft: "1px solid #4a4a4a"
    }
  },
  disp: {
    width: "40%",
    textAlign: "center",
    display: "inline-block"
  },
  propertyDetailsHeading: {
    marginLeft: 5,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16
  },
  dialogContent: {
    background: "#F2F7F8"
  },
  dialogSection: {
    margin:"15px 0",
    padding: "25px 45px",
    background: "#fff"
  },
  dialogTitle: {
    textAlign: "center",
  },
  titleText: {
    fontSize: 25
  },
  sectionDivider: {
    position: "relative",
    "& .divider": {
      width: "100%",
      position: "absolute",
      border: "1px solid rgba(0, 0, 0, 0.08)"
    }
  },
  dividerText: {
    background: "#fff",
    position: "relative",
    padding: 10
  },
  cleaningTypeHeading: {
    marginRight: 0,
    marginTop: 0,
    marginBottom: 7,
    fontSize: 12,
    color: "#929292",
    lineHeight: "18px"
  },
  inputSelect:{
    paddingTop: 0,
		"& [class*=fieldWrapper]":{
      height: 46,
			"& > span":{
				right: 0,
				color: "#c4c4c4",
        "& img":{
          width: "1.3rem",
          height: "1.3rem",
          opacity: 0.7
        }
			},
			"& > input":{
        color: "#646464",
        fontWeight: 500,
        lineHeight: "21px",
				fontSize: 14,
				height: 46,
				border: "solid 1px #d8d8d8",
				background: "#ffffff",
				padding: "5px 18px", //"20px 56px 20px 20px",
				"&::placeholder ":{
					color: "#b7b7b7"
				}
			}
		},
  },
  bottomMargin_12:{
    marginBottom: 12
  },
  textStyle:{
    color: "#929292",
    marginRight: 28
  },
  inputsCont:{
    margin: "4px 0"
  },
  flexDisplay:{
    display: "flex"
  }
});


const AccountForm = ({
  handleClose,
  handleSubmit,
  classes,
  submitData,
  formValues,
  load
}) => {


  return (
    <Form onSubmit={handleSubmit(submitData)}>

      <DialogContent className={classes.dialogContent}>
        <Grid className={classes.dialogSection}>
          <div className={classes.dialogTitle}>
            <ZTypography color="secondary" gradient bold className={classes.titleText}>
              Add Bank Account 
            </ZTypography>
          </div>
          <Grid container alignItems="center" justify="center" className={classes.sectionDivider}>
            <span className="divider"></span>
            <ZTypography inlineBlock className={classes.dividerText}>
              You are
            </ZTypography>
          </Grid>
          <Grid container className={classes.inputsCont} spacing={4} justify="center">
            <Grid item>
              <Field
                component={RadioSelectBtn}
                name="legalEntity"
                validate={[required]}
                inRow
                options={[
                  { title: "An Individual", value: "individual" },
                  { title: "A Company", value: "company" }
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} className={classes.bottomMargin_12}>
              <FormInputField label="Beneficiary Name"
                type="text"
                id="accountHolderName"
                name="accountHolderName"
                placeholder="Enter Beneficiary Name"
                component={MaterialTextField}
                validate={[required]}
                InputProps={{disableUnderline :true}}
                setBorder  
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <FormInputField label="Bank Name"
                type="text" 
                id="bankName"
                name="bankName"
                placeholder="Enter Bank Name"
                component={MaterialTextField}
                validate={[required]}
                InputProps={{disableUnderline :true}}
                setBorder  
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.inputSelect}>
              <p className={classes.cleaningTypeHeading}>Date Of Birth</p>
              <Field
                name="ownersDob"
                placeholder="Beneficiary's D.O.B"
                component={DatePicker}
                type="text"
                isNotMinDate={true}
                validate={[required]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInputField label="SSN"
                type="text"
                id="ownersSocial"
                name="ownersSocial"
                placeholder="SSN (Last 4 Digits)"
                component={MaterialTextField}
                validate={[required, minLength4, maxLength4]}
                InputProps={{disableUnderline :true}}
                setBorder  
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInputField label="Bank Account No."
                type="text"
                id="accountNumber"
                name="accountNumber"
                placeholder="Enter Bank Account No."
                component={MaterialTextField}
                validate={[required]}
                InputProps={{disableUnderline :true}}
                setBorder  
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInputField label="Bank Routing Number"
                type="text"
                id="bankRoutingNumber"
                name="bankRoutingNumber"
                placeholder="Enter Bank Routing Number"
                component={MaterialTextField}
                validate={[required, minLength9]}
                InputProps={{disableUnderline :true}}
                setBorder  
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.dialogSection}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}> 
              <FormInputField label="Address"
                type="text"
                id="address"
                name="address"
                component={MaterialTextField}
                placeholder="Enter Address"
                validate={[required]}
                InputProps={{disableUnderline :true}}
                setBorder  
              />
            </Grid>
            <Grid item xs={12} md={4}> 
              <FormInputField label="City"
                type="text"
                id="city"
                name="city"
                component={MaterialTextField}
                placeholder="Enter City"
                validate={[required]}
                InputProps={{disableUnderline :true}}
                setBorder  
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInputField 
                label="State"
                type="text"
                id="state"
                name="state"
                component={AdvanceSelectCompanyProfile}  
                options={stateObjOptions}
                validate={[required]}
                backgroundColor="transparent"
                material 
                InputProps={{disableUnderline :true}}  
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInputField label="Zipcode"
                type="text"
                id="zipcode"
                name="zipcode"
                placeholder="Enter Zipcode"
                component={MaterialTextField}
                validate={[required,number,minLength_5,maxLength_5]}
                InputProps={{disableUnderline :true}}
                setBorder  
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.dialogSection}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8} className={classes.flexDisplay}>
              <Grid container alignItems="center">
                <div className={classes.textStyle}> Select One Bussiness ID :</div>
                <Field
                  component={RadioSelectBtn}
                  name="idType"
                  validate={[required]}
                  options={[
                    { title: "Business Tax ID", value: "businessId" },
                    { title: "EIN", value: "ein" }
                  ]}
                  width="auto"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              {formValues.idType === "businessId" ?
                <FormInputField  
                  type="text"
                  id="businessTaxId"
                  name="businessTaxId"
                  component={MaterialTextField}
                  placeholder="Enter Business Tax ID "
                  validate={[required, minLength9]}
                  InputProps={{disableUnderline :true}}
                  setBorder  
                />
              : <FormInputField  
                  type="text"
                  id="businessEIN"
                  name="businessEIN"
                  component={MaterialTextField}
                  placeholder="Enter EIN"
                  validate={[required, minLength9]}
                  InputProps={{disableUnderline :true}}
                  setBorder  
                />
              }
            </Grid>
          </Grid>
          <Grid container justify="space-around" spacing={4}>
              <Grid item>
                <Field
                  id="isPrefered"
                  name="isPrefered"
                  component={MaterialCheckBox}
                  color="primary"
                  label={<p>Make it default account</p>}
                />
              </Grid>
              <Grid item>
                <Field
                  id="acceptTerms"
                  name="acceptTerms"
                  component={MaterialCheckBox}
                  color="primary"
                  label={
                    <p>
                      I accept{" "}
                      {
                        <a href="https://www.zoklean.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                          Terms & Conditions
                        </a>
                      }
                    </p>
                  }
                  validate={[required]}
                />
              </Grid>
            </Grid>
        </Grid>
        {load && <Loader />}
      </DialogContent>
      <DialogActions>
        <ZButton onClick={handleClose} variant="text" >
          Cancel
        </ZButton>
        <ZButton type="submit" variant="contained" color="primary" gradient>Add Bank Account</ZButton>
      </DialogActions>
    </Form>
  );
};

export default withStyles(styles)(AccountForm);
