import { capitalizeAllFirstLetters } from "utils/capitalize";
import getStateName from "utils/getStateName";

export const handleAddressAndInfo = ( serviceAddress, zipcode )=> {
  let city,state,address,propertyType,jobProperty; 
  if(serviceAddress){
    city = serviceAddress.city;
    state = serviceAddress.state;
    address = serviceAddress.address;
    if (serviceAddress.unit) {
      address += ` UNIT ${serviceAddress.unit.replace(/[^a-zA-Z0-9]/g, "")}`
    }

    propertyType = capitalizeAllFirstLetters(serviceAddress.propertyType,"_")
    jobProperty = parseInt(serviceAddress.propertyArea);
  };
  return {
    billingAddress: ""+address+", "+city+", \n"+getStateName(state)+" - "+zipcode,
    propertyType,
    jobProperty
  };
}

export const handleCleaningType = (type, cleaningType, cleaningTypesObj)=> {
  const displayJobCleaning = cleaningTypesObj && cleaningTypesObj[cleaningType] ? cleaningTypesObj[cleaningType] : "No Cleaning Type";
  let [ cleaning, whenToClean ] = displayJobCleaning.split(" (");
  whenToClean = type === "domestic" ? ` (${whenToClean}`: "";
  return {
    cleaning,
    whenToClean
  };
}