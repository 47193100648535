import React, { Component } from 'react';
import axios from "../utils/http";
import _ from "lodash";
import { connect } from "react-redux";
import {  reduxForm } from "redux-form";
import { CustomerForm } from "../components/CustomerForm";
import { setMsgAndShow } from "../store/actions/popup"
import { toggleDialogLoader } from "../store/actions/app";
import { withMixpanel } from "../utils/react-mixpanel";

class CustomerFormContainer extends Component {
  submitData = async (obj, dispatch, props) => {
    const { toggleDialogLoader , trackApiEvent} = this.props;
    const contractorId = this.props.contractorId;
    let formValues = _.cloneDeep(obj);

    formValues.serviceAddress.state = formValues.serviceAddress.state.value;


    toggleDialogLoader();
    
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}contractor/add_customer`,{
        contractorId,
        customerInfo:{
          ...formValues
        }
      });

      toggleDialogLoader();

      if(res.data.status === "OK"){

        trackApiEvent("Add_New_Customer", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/add_customer`, "OK", window.location.pathname);

        this.props.reset("AddCustomer");
        this.props.handleClose();
        this.props.onSubmitSuccessful(res.data.customer)
        this.props.setMsgAndShow("Customer created");
      } else {
        this.props.setMsgAndShow(res.data.message)
        trackApiEvent("Add_New_Customer", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/add_customer`, res.data.message, window.location.pathname);
      }
    } catch (error) {
      toggleDialogLoader();
      const { response } = error;
      let msg = "Error Occured!";

      if(response && response.data){
        msg = response.data.message ? response.data.message : "Error Occured!";
      }
      
      this.props.setMsgAndShow(msg);
      trackApiEvent("Add_New_Customer", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/add_customer`, msg, window.location.pathname);
    }
  }
  render(){
    return ( 
      <CustomerForm submitData={this.submitData} {...this.props} />
    )  
  }
}


const mapStateToProps = (state) => ({
  showDialogLoader: state.app.showDialogLoader
})
const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg)),
  toggleDialogLoader: () => dispatch(toggleDialogLoader())
})

const connectRedux = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: "AddCustomer",
  destroyOnUnmount: true
})(CustomerFormContainer))

export default withMixpanel(connectRedux);