import React from "react";
import { withStyles } from "core/components/Styles";
import CircularProgress from "core/components/CircularProgress";

const styles = theme => ({
  overlayStyles: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: "rgba(255,255,255,0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9
  }
});
const Loader = ({ classes, position, size, zIndex, opacity, background = "rgba(255,255,255,0.8)" }) => {

  const defaultStyles = {
    position: position || 'absolute'
  }

  if(zIndex){
    defaultStyles.zIndex = zIndex;
  }

  if(opacity){
    defaultStyles.opacity = opacity;
  }

  if(background){
    defaultStyles.background = background;
  }
  return (
    <div
      className={classes.overlayStyles}
      style={defaultStyles}
    >
      {" "}
      <CircularProgress color="secondary" size={size}/>
    </div>
  );
}



export default withStyles(styles)(Loader);
