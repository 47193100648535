import React, { Component } from "react";
import { InviteForm } from "../components/InviteForm";
import { connect } from "react-redux";
import { reduxForm, arrayPush } from 'redux-form';
import { setMsgAndShow } from "../store/actions/popup";
import axios from "../utils/http";
import { withMixpanel } from "../utils/react-mixpanel";
class InviteModelFormContainer extends Component {

  componentDidMount(){
    this.props.initialize({ emails: ["","","","",""] });
  }

  addInvites = () => {
    for(let i = 1; i <= 5; i++){
      this.props.pushToArray("");
    }
  }
  submitData = async (obj, dispatch, props) => {
    const { trackMultiPickEvent, trackApiEvent } = this.props;
    let { emails } = obj;
    emails = emails.filter(email => email);

    trackMultiPickEvent("User_Invites",emails, window.location.pathname);
    
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}contractor/invite`,{
        emails
      });

      const { status, message } = res.data;

      if(status === "OK"){
        trackApiEvent("Invite_Users", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/invite`, message, window.location.pathname);
        this.props.setMsgAndShow(message);
        this.props.onModalClose();
      } else {
        this.props.setMsgAndShow(message);
      }
    } catch (error) {
      const { response } = error;
      let message = "Error Occured!";
      if(response && response.data){
        message = response.data.message ? response.data.message :"Error Occured!";
      }
      trackApiEvent("Invite_Users", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/invite`, message, window.location.pathname);
      this.props.setMsgAndShow(message);
    }
  }
  render(){
    return (
      <InviteForm 
        submitData={this.submitData}
        addInvites={this.addInvites}
        {...this.props}
      />
    )
  }
}



const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg)),
  pushToArray: (data) => dispatch(arrayPush("InviteForm","emails",data)) 
})


const connectRedux = connect(null, mapDispatchToProps)(reduxForm({
  form:"InviteForm",
  destroyOnUnmount: true,
})(InviteModelFormContainer));

export default withMixpanel(connectRedux);