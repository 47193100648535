import React from "react";
import Grid from "core/components/Grid";
import cn from "classnames";
// import Button from "core/components/Button";
// import Card from "core/components/Card";
// import CardContent from "core/components/CardContent";
import Typography from "core/components/Typography";

import Pagination from "core/components/Pagination";
import { withStyles } from "core/components/Styles";
import FabButton from "core/components/FabButton";
// import Datalist from "../DataList";
import SearchBar from "../SearchBar";
// import EmployeeItem from "../EmployeeItem";
import ListItem from "../ListItem";

import Joyride from "react-joyride";
import { employeeListSteps } from "../TourGuide/TourGuideSteps";
import {tooltipButtonColor} from "../../theme/colors";

const styles = theme => ({
  root: {
    padding: "25px 85px 25px 85px",
    [theme.breakpoints.down('sm')]: {
      padding: 16
    } 
  },
  cardCol: {
    background : "#ffffff",
    marginRight : 11,
    borderRadius : 8,
    border : "1px solid #e0e0e0",
    "&:hover ":{
      boxShadow : "0px 6px 12px rgba(0, 0, 0, 0.158543)"
    },
    [theme.breakpoints.down('sm')]: {
      flex: 1
    }
  },
  cardDiv:{
    width: 231,
    textAlign:"center",
    paddingTop: 15,
    [theme.breakpoints.down('sm')]: {
      width: "100%"
    }
  },
  jobsBtnCont: {
    marginTop: 10
  },
  newJobBtnCont: {
    marginTop: 10
  },
  jobslistCont: {
    marginTop: 10
  },
  textCenter: {
    textAlign: "center"
  },
  title: {
    margin: 0
  },
  
  totalEmployeeCont: {
    marginTop: 20
  },
  addBtnCard: {
    marginTop: 20,
    textAlign: "center"
  },
  cardsCont:{
    marginTop: 25
  },
  pageTitle:{
    fontFamily : "Roboto",
    fontWeight : "normal",
    fontSize: 21,
    lineHeight: "25px",
    marginLeft: 6,
    color:"#697b8c"
  },
  heading:{
    lineHeight:"27px",
    fontSize: 18,
    fontFamily : "Poppins",
    fontWeight : 500,
    borderRadius : 8
  },

  totalEmployee : {
    color: "transparent",
    "-webkit-background-clip": "text",
    backgroundImage: "linear-gradient(to bottom, #fff 1.1%, #49d6b7 64.6%)",
  },
  totalEmployeeText :{
    color : "#49d6b7"
  },
  freeEmployee : {
    color: "transparent",
    "-webkit-background-clip": "text",
    backgroundImage: "linear-gradient(to bottom, #fff 1.1%, #4e96ea 64.6%)",
  },
  freeEmployeeText :{
    color : "#4e96ea"    
  },
  onJobEmployee :{
    color: "transparent",
    "-webkit-background-clip": "text",
    backgroundImage: "linear-gradient(to bottom, #fff 1.1%, #e7a02a 64.6%)",
  },
  onJobEmployeeText : {
    color : "#e7a02a"    
  },
  employeeNumber: {
    fontSize: 55,
    fontFamily: "Poppins",
    lineHeight: "82px",
    marginTop: 1.8,
    fontStyle : "normal",
    fontWeight : 500,
    borderRadius : 8
  },
  titleContainer : {
    height : 50
  },
  searchBoxCont:{
    marginLeft : 46,
    width: 440,
    height : 40,
    [theme.breakpoints.down('sm')]: {
      width: "100%"
    }
  },
  // employeeList:{
  //   marginTop: 25
  // },
  employeesCont:{
    marginTop: 25
  },
  employeeListItem: {
    marginTop: 25
  },
  paginateWrapper:{
    marginTop:20
  }
});

// const employeeObjProps = [
//   {
//     name: "Name",
//     isName: true,
//     firstNameGetter:"fname",
//     lastNameGetter:"lname",
//   },
//   {
//     name: "Date Of Joining",
//     label: "createdat",
//     isTimeStamp: true,
//     formatRequired:"DD MMM YYYY"
//   },
//   {
//     name: "Contact Number",
//     label: "phone",
//     isPhone:true,
//     phoneLabel:"phone"
//   }
// ];

const EmployeeDashboard = ({
  classes,
  numOfFreeEmployees,
  numOfBusyEmployees,
  openModal,
  filteredEmployees,
  employeeCount,
  actions,
  handleModal,
  updateEmployee,
  removeEmployee,
  handleEmployeeSearch,

  handleJoyrideCallback,
  getHelpers,
  runGuide,

  trackInput,
  trackMapLink,
  totalPages,
  currPage,
  handlePageChange,
  q,
  clearSearch,
  ...restProps
}) => {


  // const employeesArray = []
  // filteredEmployees.forEach((employee, i)=>{
  //   const index = employeesArray.length - 1;
  //   const nextIndex = employeesArray.length;
  //   const curentIndex = i + 1;
  //   if(curentIndex % 2 === 0 && employeesArray[index]){
  //     employeesArray[index].push(employee)
  //   } else {
  //     employeesArray[nextIndex] = [ employee ]
  //   }
  // });

  return (
    <Grid className={classes.root} id="employeeListCont" >
      <FabButton onClick={openModal}/>
      <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={runGuide}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={employeeListSteps}
        styles={{
          options: {
            primaryColor: tooltipButtonColor    
          }
        }}
      />
      <Grid container 
        alignItems="center" 
        justify="flex-start" 
        direction="row" 
        className={classes.titleContainer}
      >
        <Grid item>
          <Typography className={classes.pageTitle}>
            Employee List
          </Typography>
        </Grid>
        <Grid item 
          className={classes.searchBoxCont}
          id="employeeSearch"
        >
          <SearchBar 
            placeholder="Search Employee" 
            onChange={handleEmployeeSearch} 
            onBlur={trackInput}
            value={q}
            onClear={clearSearch}
            reverse
          />
        </Grid>
      </Grid>
      <Grid container alignContent="center" className={classes.cardsCont} id="employeeInfo">
        <EmployeeCard
          title ={"Total Employee"}
          titleStyle = {classes.totalEmployeeText}
          empCount = {employeeCount}
          empCountStyle = {classes.totalEmployee}
          classes = {classes}
        /> 
        <EmployeeCard
          title ={"Free Employee"}
          titleStyle = {classes.freeEmployeeText}
          empCount = {numOfFreeEmployees}
          empCountStyle = {classes.freeEmployee}
          classes = {classes}
        />
        <EmployeeCard
          title ={"Employee on Jobs"}
          titleStyle = {classes.onJobEmployeeText}
          empCount = {numOfBusyEmployees}
          empCountStyle = {classes.onJobEmployee}
          classes = {classes}
        />
      </Grid>

      <Grid className={classes.employeeList}>
        <Grid container 
          alignContent="center" 
          alignItems="center" 
          className={classes.employeesCont}
          id="employeeList" 
        >
          {filteredEmployees.map((e, i) => (
            <ListItem
              key={`employee-item-key-${i}`}  
              user={e}  
              editUser={updateEmployee}
              deleteUser={removeEmployee}
              className={classes.employeeListItem}

              trackLink={trackMapLink}

            />
          ))}
        </Grid>


        <Grid className={classes.paginateWrapper}>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Pagination 
                  count={totalPages} 
                  page={currPage} 
                  onChange={handlePageChange} 
                  showFirstButton
                  showLastButton
                  color="primary"
                />
              </Grid>
            </Grid>

          </Grid>
      </Grid>
    </Grid>
  );
}

const EmployeeCard = (props)=>{
  const {title, titleStyle, empCount, empCountStyle, classes} = props
  return(
    <Grid item className={classes.cardCol}>
      <Grid className={classes.cardDiv}>
        <Typography variant="subtitle1" className={cn(classes.heading, titleStyle)}>
          {title}
        </Typography>
        <span className={cn(classes.employeeNumber, empCountStyle)}>{empCount}</span>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(EmployeeDashboard);