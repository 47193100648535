import React from "react";
import { 
  Grid,
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Button,
  Slide,
  Typography
} from "core/components";
import { withStyles } from "core/components/Styles";
import momentTz from "moment-timezone";
import Loader from "../ProgressCircle";


const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
))


const styles = () => ({
  textCenter:{
    textAlign:'center'
  },
  changeList:{
    marginTop:10,
  },
  changesCont:{
    marginTop: 10,
  },
  orderCancelBtn:{
    color:"red"
  }
})

const JobChangesModal = (props) => {

  const { acceptChanges, cancelJob, closeModal, openJobChangesModal, classes, job, enableDialogLoader } = props;

  const { changes = {}, changeReason = "TEST", serviceAddress = {}} = job;


  const customerName = `${serviceAddress.fname} ${serviceAddress.lname}`
  const jobAddress = `${serviceAddress.address}${serviceAddress.unit ? ` UNIT ${serviceAddress.unit}` : ""}, ${serviceAddress.city}, ${serviceAddress.state}`
  return (
    <Dialog
      open={openJobChangesModal}
      TransitionComponent={Transition}
      onClose={closeModal}
      fullWidth
    >

      {enableDialogLoader && <Loader />}
      <DialogTitle id="alert-dialog-slide-title">
        Job Updates
      </DialogTitle>
      <DialogContent>
        <Grid>
          <Grid>
            <Typography>
              <strong>Customer Name - </strong>
              <span> {customerName}</span>
            </Typography>
          </Grid>

          <Grid>
            <Typography>
              <strong>Address - </strong>
              <span> { jobAddress }</span>
              </Typography>
          </Grid>
          
        </Grid>
        <Grid container className={classes.changesCont}>
          <Grid item xs>
            <Typography variant="subtitle1">
                  Current
            </Typography>

            <Grid className={classes.changeList}>

              <Grid>
                <Typography>
                  <strong>Time - </strong>
                  <span> {momentTz(job.time,"HH:mm:ss").format("hh:mm A")} </span>
                </Typography>
              </Grid>

              <Grid>
                <Typography>
                  <strong>Date - </strong>
                  <span> {momentTz(job.date,"YYYY-MM-DD").format("Do MMMM, YYYY")}</span>
                 </Typography>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs>
            <Typography variant="subtitle1">
                  Changes Requested
            </Typography>

            <Grid className={classes.changeList}>
              <Grid>
                <Typography> 
                  <strong>
                    Time - 
                  </strong>
                  <span>
                      {momentTz(changes.time,"HH:mm:ss").format("hh:mm A")}
                  </span>
               </Typography>
              </Grid>
              <Grid>
                <Typography>
                  <strong>
                    Date -
                  </strong>
                  <span>
                    {momentTz(changes.date,"YYYY-MM-DD").format("Do MMMM, YYYY")}
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <Typography>
                <strong>
                  Reason
                </strong>
              </Typography>
              <Typography>
                {changeReason}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      
      </DialogContent>
      <DialogActions>
        <Button className={classes.acceptBtn} color="primary" onClick={acceptChanges}>
          Accept Changes
        </Button>
        <Button className={classes.orderCancelBtn} onClick={cancelJob}>
          Cancel Job
        </Button>
        <Button className={classes.cancelBtn} onClick={closeModal} color="default">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}



export default  withStyles(styles)(JobChangesModal)