import React from 'react';
import cn from "classnames";
import Image from "core/components/Image";
import withStyles from "react-jss";


const styles = {
  inputStyle:{
    display:"block",
    border: "1px solid #E0E0E0",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.128223)",
    borderRadius: "0.6rem",
    background: "#fff",
    width: "100%",
    margin: "0.3rem 0",
    lineHeight: "normal",
    cursor:"pointer",
    position: "relative",
    marginTop: "0.75rem"
  },
  active:{
    border: "1px solid #50E3C2",
  },
  perferred: {
    border: "2px solid #50E3C2",
  },
  hideButton: {
    position: "absolute",
    opacity:0,
    cursor:"pointer"
  },
  heading: {
    letterSpacing: "-0.491481px",
    fontSize: "1rem",
    lineHeight: "1.3125",
    color: "#4a4a4a",
    marginTop: "2rem"
  },
  card: {
    display: "flex",
    padding: "0.92rem 1.14rem",
    alignItems: "center",
    "& p":{
      margin:0,
    }
  },
  cardIcon: {
    marginRight: "1rem",
    width: "3.5rem",
    height: "3.5rem",
    borderRadius: "50%",
    overflow: "hidden",
    padding: "0.7rem",
    background: "#F1F2F6",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    "& img":{
      maxWidth: "100%"
    }
  },
  newCard: {
    background: "#41D0B0"
  },
  cardContent: {
    flex: 1,
    fontSize: "1.214rem",
    "& p":{
      color:"#4a4a4a"
    }
  },
  cardContentText:{
    color:"#4a4a4a"
  },
  cardNumber: {

  },
  cardBrand: {
    fontSize: "1.07rem",
    color: "#BEC2CE",
    textTransform: "uppercase"
  },
};

const brands = {
  visa:"assets/images/svgs/visa.svg",
  "americanexpress":"assets/images/svgs/amex.svg",
  "mastercard":"assets/images/svgs/mastercard.svg",
  discover:"assets/images/svgs/discover.svg"
}
const getBrandImg = (brand) => {
  const localBrand = brand.toLowerCase().trim().replace(/[^a-zA-Z]/g,"");
  return brands[localBrand];
}


const PaymentCardItem = (props) => {
  const { classes, id, o, input} = props;
  return (
    <div>
      {o.label === "Add a new card" && <div className={classes.heading}>Add Another</div>}
      <label 
        htmlFor={id} 
        className={cn(classes.inputStyle, {[classes.active]: o.value === input.value, [classes.perferred]: o.card && o.card.default && (o.value === input.value || !input.value)})} 
      >
        {/* <Image src="assets/images/svgs/visa.svg" /> */}
        <input
          {...input}
          type="radio"
          className={cn("form-control", classes.hideButton)}
          id={id}
          value={o.value || ""}
        />
        {o.label === "Add a new card" ? <div className={classes.card}>
          <span className={cn(classes.cardIcon, classes.newCard)}><Image src="assets/images/svgs/card-white.svg" alt="card white" /></span>
          <p className={cn(classes.cardContentText)}>{o.label}</p>
        </div> : <div className={classes.card}>
          <span className={cn(classes.cardIcon)}><Image src={getBrandImg(o.card.brand)} alt="card-brand"/></span>
          <div className={classes.cardContent}>
            <p className={classes.cardNumber}>**** **** **** {o.card.last4}</p>
            <p className={classes.cardBrand}>{o.card.brand}</p>
          </div>
        </div>}
        
      </label>
    </div>
  )
}

export default  withStyles(styles)(PaymentCardItem)
