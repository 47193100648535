import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "core/components/Styles";
import AppBar from "core/components/AppBar";
import Grid from "core/components/Grid";
import Avatar from "core/components/Avatar";
// import Avatar from "core/components/Avatar";
import {
  // Magnify,
  Menu
} from "mdi-material-ui";
import search from "../../assets/images/search.png"
import cn from "classnames";
import logo from "../../assets/images/logo-final.png";
import logoFull from "../../assets/images/logo-final.png";
  // import notify from "../../assets/images/notify.png";
  // import logout from "../../assets/images/Logout.png";
  // import demoUser from "../../assets/images/dennisRitchie.jpg" 
import { drawerWidth, appBar, mobileAppBar } from "theme/vars";
import Results from "./Results";
import dd from "utils/detectDevice";
import { isEmpty } from "lodash";
import {IconButton, Hidden} from 'core/components';
import Icon from "core/components/Icon";
// import SearchBar from "../SearchBar";

import TextField from 'core/components/TextField';
import { Button } from "core/components"; 

const styles = theme => ({
  logoWrapper: {
    width: drawerWidth,
    backgroundColor: "#fff",
    textAlign: "center",
    height: appBar,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      height: 60
    }
    // boxShadow:"-8px 0 42px 0 rgba(42, 34, 64, 0.14)",
  },
  logoBox: {
    position: "fixed",
    width: drawerWidth,
    height: appBar + 1,
    borderBottom: "1px solid #e7e9ea",
    background: "#fff",
    zIndex: 1,
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    boxShadow:"0px 2px 33px rgba(0, 0, 0, 0.128223)",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      height: mobileAppBar,
      boxShadow: "none",
      border: 0,
      display: "flex",
      justifyContent: "space-between",
      padding: "0 20px",
      background: "#edfffd",
    }
  },
  logo: {
    marginTop : -5 ,
    height: 56,
    width: 206,
    [theme.breakpoints.down('sm')]: {
      width: "auto",
      height: 36
    }
  },
  header: {
    zIndex: 20,
    background:"#ffffff",
    borderBottom:"1px solid #e7e9ea",
    boxShadow :"0px 2px 33px rgba(0, 0, 0, 0.128223)",
    
  },
  headerBar: {
    height : 51,
    width : "100%",
    background: '#F1F6F8',
    borderRadius : 31,
    paddingLeft: 30,
    paddingRight: 30,
    minHeight: 40,
  },
  searchIcon: {
    color: "rgba(152, 152, 152, 0.61)",
    fontSize: 20,
    position:"absolute",
    height : 18.9,
    width : 18.9
  },
  title: {
    color: "white",
    fontWeight: "300"
  },


  profilePic: {
    height: 30,
    width: 30,
    borderRadius: "50%"
  },
  colorWhite: {
    color: "#fff"
  },


  searchBarAndOptionContainer :{
    width : "100%"
  },
  searchBoxWrapper :{
    maxWidth : 538,
    marginLeft : 25,
    width: "100%"
  },
  searchInputContainer : {
    color: "#F1F6F8",
    height : 51,
    width : "100%",
  },
  searchInput:{
    border: "none",
    outline: "none",
    height: 25,
    fontSize: 16,
    color:" rgba(152, 152, 152, 0.5)",
    fontFamily:"Poppins",
    width: "100%",
    "&::placeholder":{
      color:" rgba(152, 152, 152, 0.5)"
    }
  },
  searchInputTextArea : {
    width : 370,
    marginLeft : 16
  },
  searchInputContent:{
    fontFamily:"Poppins",
    fontSize : 12,
    fontWeight : 300,
    lineHeight : "29px",
    fontStyle : "normal",
    textTransform : "capitalize",
    marginLeft: 16,
    width:"100%",
    background : " #F1F6F8",
  },


  userOptionsContainer:{
    height : 51,
    width : 383,
    paddingRight: 43
  },
  userOptionNotifyBtnContainer : {
    height : 41,
    display : "inline-block",
    position : "relative",
    marginRight: 15
  },
  userOptionsNotifyBtn:{
    height : 35,
    width : 35        
  },
  userOptionsNotifyBtnIcon:{
    height : 20,
    width : 18,
  },
  userOptionNotifyBtnCounter :{
    color : "#FFFFFF",
    height : 15,
    width : 15,
    display : "inline-block",
    position : "absolute",
    padding : 0,
    marginTop : -4,
    background : "#E9526A",
    marginLeft : -10,
    borderRadius : 34,
    fontSize : 10,
    textAlign : "center"
  },


  userOptionsBtn : {
    height : 35,
    minWidth : 113,
    borderRadius : 28,
    marginRight : 11,
    padding : "0px 16px 0px 4px",
    color : "rgba(140, 140, 140, 0.5)",
    display : "inline-flex",
    justify : "center",
  },
  userOptionsBtnLabel :{
    display : "inline-flex",
    alignItems : "center",
    fontFamily : "Poppins"
  },
  userOptionsBtnLabelImage : {
    display : "inline",
    height : 29,
    width : 29,
    marginTop : 0,
    marginBottom:"0.2px",
    borderRadius: 15,
    marginRight : 5 
  },


  userOptionsLogoutBtn : {
    height : 35,
    width : 35,
    background : "linear-gradient(95.38deg, #50E3C2 1.81%, #00949C 110.86%)",
    marginLeft: 10,
    minWidth: 0,
    borderRadius:34
  },
  userOptionsLogoutBtnIcon:{
    fontSize: 18
  },


  listCont:{
    minHeight: 100,
    backgroundColor:"#ffffff",
    // border:"1px solid black",
    position:"absolute",
    top: 55,
    left:0,
    width:"100%",
    boxShadow:"0 2px 48px 1px rgba(176, 192, 237, 0.42)",
    borderRadius: 5
  },
  positionRelative:{
    position:"relative"
  },
  profileImage:{
    display:"inline-block",
    height:29,
    width:29,
  },
  userProfileInfo:{
    zIndex: 0,
    textDecoration:"none",
    display:"flex",
    alignItems:"center",
    borderRadius: 28,
    border:"1px solid #BFCED5",
    padding: 3,
    paddingRight: 12,
    "&:visited":{
      color:"#8D8D8D"
    }
  },
  userName:{
    marginLeft:6,
    fontSize:12
  }
});

class Header extends Component {
  render() {
    const {
      classes,
      handleSearch,
      onSearchBlur,
      onSearchFocus,
      showResults,
      results,
      openPage,
      toggleSidebar,
      userInfo,
      handleLogout
    } = this.props;
    let profileImage = null;

    if(!isEmpty(userInfo) && userInfo.profileImage){
      profileImage = `${process.env.REACT_APP_USER_FILES}${userInfo.profileImage}`
    }
 
    return (
      <AppBar position="absolute" color="default" className={classes.header}>
        <Grid container justify="flex-start" alignItems="center" id="appBar">
           
          <Grid item >
            <Grid
              container
              className={classes.logoWrapper}
              justify="center"
              alignItems="center"
            >
              <div className={classes.logoBox}>
                {dd.isPhone 
                  ? <Fragment>
                      <img className={classes.logo} src={logoFull} alt="logo" /> 
                      <IconButton aria-label="menu" onClick={toggleSidebar(true)}>
                        <Menu />
                      </IconButton>
                    </Fragment>
                  : <img className={classes.logo} src={logo} alt="logo" />}              
              </div>
            </Grid>
          </Grid>
          
          <Hidden xsDown> 
            <Grid item xs> 
                <Grid
                  container
                  alignItems = "center"
                  justify = "space-between"
                  className={classes.searchBarAndOptionContainer}
                > 
                  <Grid 
                    item
                    container 
                    className = {classes.searchBoxWrapper}
                    justify = "center" 
                    id="mainSearchBar"
                  >
                    <Grid
                      item
                      container
                      alignItems = "center"
                      className = {cn(classes.headerBar, classes.positionRelative)}
                    > 
                      <Grid
                        item
                        container
                        justify="flex-start"
                        alignItems="center"
                        className ={classes.searchInputContainer}
                      >
                        <img src = {search} className={classes.searchIcon} alt="search"/> 
                        <Grid
                          item
                          container
                          xs
                          className={classes.searchInputContent}
                        >
                          <TextField
                            InputProps={{disableUnderline:true}}
                            placeholder="Find a user, team, Job" 
                            onChange={handleSearch}
                            onBlur={onSearchBlur}
                            onFocus={onSearchFocus}
                            className = {classes.searchInputTextArea}
                          />{
                            showResults && <Results openPage={openPage} results={results}/>
                            }
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
   
                {
                  userInfo && userInfo.fname ?

                  <Grid
                    item
                    container 
                    alignItems = "center"
                    justify = "flex-end"
                    className = {classes.userOptionsContainer}
                  >
                    
                    {/* <Grid
                      className= {classes.userOptionNotifyBtnContainer}
                    > */}
                      {/* <Button
                        variant="fab"
                        color= "secondary"
                        className={classes.userOptionsNotifyBtn}
                      >
                        <img className = {classes.userOptionsNotifyBtnIcon}  src={notify} alt="Notifications" />
                      </Button> */}
                      {/* <span className={classes.userOptionNotifyBtnCounter}>3</span> */}
                    {/* </Grid> */}

                    
                    <Link to="/" className={classes.userProfileInfo} id="profileBtn">
                      <Avatar
                        className="profile-btn-avatar img-fluid mr-2"
                        image={profileImage}
                        name={`${userInfo.fname} ${userInfo.lname}`}
                        disableBorder
                      />
                      {/* <Avatar className={classes.profileImage} src={demoUser} alt="Profile Image"/> */}
                      <span className={classes.userName}> 
                        {userInfo.fname} {userInfo.lname.charAt(0)}.
                      </span>
                    </Link>
    
                    {/* <Button 
                      variant="outlined" 
                      className = {classes.userOptionsBtn}
                    >
                      <span className = {classes.userOptionsBtnLabel}>
                        <img className = {classes.userOptionsBtnLabelImage}  src={demoUser} alt="UserImage" />
                        User Profile
                      </span>
                    </Button> */}

                    
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.userOptionsLogoutBtn }
                      onClick={handleLogout}
                      id="logoutBtn"
                    >
                      <Icon className = {classes.userOptionsLogoutBtnIcon} lineHeight="inherit">
                        logout
                      </Icon>
                    </Button>
                  
                  </Grid>
                  : null
                }


              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </AppBar>
    );
  }
}

export default withStyles(styles)(Header);
 