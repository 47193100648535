import React from "react";

import ListInvoiceDashboardContainer from "../../containers/ListInvoiceDashboardContainer";
import { MainLayout } from "layouts";

const ListInvoicePage = (props) => (
  <MainLayout {...props}>
    <ListInvoiceDashboardContainer {...props} />
  </MainLayout>
)

export default ListInvoicePage;