function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 28,
    height: 18.375,
    viewBox: "0 0 28 18.375"
  }, props), React.createElement("g", {
    id: "prefix__Group_366",
    "data-name": "Group 366",
    transform: "translate(-397 -1476)"
  }, React.createElement("path", {
    id: "prefix__Path_276",
    d: "M0 6v18.375h28V6zm.875 17.5V6.875H21V23.5zm26.25 0h-5.25V6.875h5.25z",
    className: "prefix__cls-1",
    "data-name": "Path 276",
    transform: "translate(397 1470)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Path_277",
    d: "M28 12a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0-3a1 1 0 1 1-1 1 1 1 0 0 1 1-1z",
    className: "prefix__cls-1",
    "data-name": "Path 277",
    transform: "translate(393 1470)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("circle", {
    id: "prefix__Ellipse_35",
    cx: 1,
    cy: 1,
    r: 1,
    className: "prefix__cls-1",
    "data-name": "Ellipse 35",
    transform: "translate(420 1484.618)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("circle", {
    id: "prefix__Ellipse_36",
    cx: 1,
    cy: 1,
    r: 1,
    className: "prefix__cls-1",
    "data-name": "Ellipse 36",
    transform: "translate(420 1488.066)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Path_278",
    d: "M19.5 9h-14A2.313 2.313 0 0 0 3 11.063v8.25a2.313 2.313 0 0 0 2.5 2.063h14a2.313 2.313 0 0 0 2.5-2.063v-8.25A2.313 2.313 0 0 0 19.5 9zM21 19.313a1.388 1.388 0 0 1-1.5 1.237h-14A1.388 1.388 0 0 1 4 19.313v-8.25a1.388 1.388 0 0 1 1.5-1.238h14a1.388 1.388 0 0 1 1.5 1.238z",
    className: "prefix__cls-1",
    "data-name": "Path 278",
    transform: "translate(397 1470)",
    style: {
      "fill": "#00949c"
    }
  })));
};

export default __webpack_public_path__ + "static/media/job_oven.477be5f0.svg";
export { SvgComponent as ReactComponent };