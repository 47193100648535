function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 14,
    height: 23.8,
    viewBox: "0 0 14 23.8"
  }, props), React.createElement("g", {
    id: "prefix__Group_367",
    "data-name": "Group 367",
    transform: "translate(-259 -1474)"
  }, React.createElement("path", {
    id: "prefix__Path_273",
    d: "M74.739 23.132a1.4 1.4 0 0 0-.071-.214.7.7 0 0 0-.143-.244.838.838 0 0 0-.464-.351 1.166 1.166 0 0 0-.321-.046h-.268c-.125 0-.214.015-.321.015h-.357l-1.428-.015c-1.892-.015-3.8-.015-5.693-.031h-2.642l-.161-.015c-.232-.015-.482-.031-.75-.031h-.232a2.227 2.227 0 0 0-.339.046.893.893 0 0 0-.535.306 1 1 0 0 0-.2.55c-.018.061 0 .092-.018.107v.26c0 .229.036.428.054.642.018.2.054.428.054.565v20.591a.588.588 0 0 0 .143.321 1.42 1.42 0 0 0 .714.351 3.949 3.949 0 0 0 .607.061c.339 0 .643-.031.91-.046.214-.015.535 0 .821-.015h6.086c2.2 0 3.98-.229 3.98-.519.071 0 .143-.214.161-.642.143-6.462.268-12.939.339-19.431 0-.367.071-.764.107-1.176a3.994 3.994 0 0 0 .018-.657c-.015-.138-.033-.26-.051-.382zM62 23.208c0-.015.018-.015 0 0a.354.354 0 0 1 .143-.015c.2 0 .428.015.66.031l.2.015h2.713c1.892-.015 3.8-.015 5.693-.031l1.428-.015h.357c.125 0 .268-.015.393-.015h.089a.036.036 0 0 1 .036.031.044.044 0 0 0 .018.031l.018.076a1.026 1.026 0 0 1 .036.244 3.071 3.071 0 0 1-.018.565c-.018.382-.089.794-.089 1.237.018 1.5.036 2.994.071 4.491a1.034 1.034 0 0 0-.214-.031h-4.5c-2.3 0-4.6.122-6.889.336V24.69a6.337 6.337 0 0 0-.054-.657c-.018-.2-.036-.4-.054-.58v-.137zm4.69 21.677h-3.034c-.125 0-.339 0-.482.015-.3.015-.571.046-.821.046h-.161a.247.247 0 0 1-.089-.015V30.48c2.285.229 4.587.336 6.889.336h4.515a1.045 1.045 0 0 0 .232-.031c.071 4.659.143 9.334.25 13.978a1.684 1.684 0 0 0 .071.535 12.153 12.153 0 0 0-3.909-.412z",
    className: "prefix__cls-1",
    "data-name": "Path 273",
    transform: "translate(198.2 1451.8)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Path_274",
    d: "M75.967 50.964a1.715 1.715 0 0 0 .446-1.1v-.672c0-.046-.268-.092-.607-.092s-.607.046-.607.092v.657a1.715 1.715 0 0 0 .446 1.1.774.774 0 0 0 .322.015z",
    className: "prefix__cls-1",
    "data-name": "Path 274",
    transform: "translate(186.37 1429.009)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Path_275",
    d: "M75.907 89.2c-.339 0-.607.076-.607.168v1.176a4.455 4.455 0 0 0 .446 1.925c.018.046.286.046.3 0a4.455 4.455 0 0 0 .446-1.925v-1.176c.022-.092-.264-.168-.585-.168z",
    className: "prefix__cls-1",
    "data-name": "Path 275",
    transform: "translate(186.288 1395.035)",
    style: {
      "fill": "#00949c"
    }
  })));
};

export default __webpack_public_path__ + "static/media/job_fridge.0544d89b.svg";
export { SvgComponent as ReactComponent };