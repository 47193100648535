import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

const styles = theme =>({
  infoLabelCont: {
    minHeight:"3rem",
    padding:"1.2rem 0"
  },
  infoLabelIcon:{
    minHeight: "2.85rem",
    width: "2.85rem",
    borderRadius: "50%",
    border: "solid 1px #20c9d2",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center"
  },
  inlineFlexColumn: {
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  infoLabelTitle:{
    color: "#00949c",
    fontSize: ".78rem",
    display: "block"
  },
  infoLabelData:{
    padding: ".3rem 0 0 .57rem"
  },
  infoLabelInfo:{
    color: "#767676",
    fontSize: ".92rem"
  },
  infoLabelSubtext:{
    fontSize: ".65rem"
  },
});

const InfoLabel = (props)=>{ 
  const { classes, icon, title, info, subText } = props;
  return(
    <Grid container item 
      className={classes.infoLabelCont} 
      xs={5}
      md={4}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={3} md={2} >
        <span className={classes.infoLabelIcon}>{icon}</span>
      </Grid> 
      <Grid item xs={6} className={classes.inlineFlexColumn}>
        <span className={classes.infoLabelTitle}>
          {title}
        </span>
        <span className={classes.infoLabelInfo}>
          {info}
          { subText && 
            <span className={classes.infoLabelSubtext}>{subText}</span> 
          }
        </span>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(InfoLabel);