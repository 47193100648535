function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 19.053,
    height: 10.423,
    viewBox: "0 0 19.053 10.423"
  }, props), React.createElement("g", {
    id: "prefix__icon",
    transform: "translate(.499 -5.201)"
  }, React.createElement("path", {
    id: "prefix__Path_271",
    d: "M4.721 15.123a.377.377 0 0 0 .266-.11l8.619-8.62a.375.375 0 0 0-.53-.53l-8.355 8.355-4.08-4.08a.375.375 0 0 0-.53.53l4.345 4.346a.377.377 0 0 0 .265.109z",
    className: "prefix__cls-1",
    "data-name": "Path 271",
    style: {
      "fill": "#fff",
      "stroke": "#fff"
    }
  }), React.createElement("path", {
    id: "prefix__Path_272",
    d: "M20.237 5.863l-8.619 8.62a.375.375 0 0 0 .531.53l8.619-8.62a.375.375 0 0 0-.531-.53z",
    className: "prefix__cls-1",
    "data-name": "Path 272",
    transform: "translate(-2.877)",
    style: {
      "fill": "#fff",
      "stroke": "#fff"
    }
  })));
};

export default __webpack_public_path__ + "static/media/job_status.b7cdb29f.svg";
export { SvgComponent as ReactComponent };