import React from "react"
import { isEmpty } from "lodash";
import Table from "../TableWithPagination";
import Dialog from "core/components/Dialog";
import DialogContent from "core/components/DialogContent";
import AssignJobMenu from "../AssignJobMenu";
import { Alert } from "mdi-material-ui";
import Loader from "../ProgressCircle";


const headers = [
  {
    name: "displayId",
    label: "ID"
  },
  {
    name: "date",
    label: "Date"
  },
  {
    name: "time",
    label: "Time"
  },
  {
    name: "serviceAddress",
    label: "Address"
  },
  {
    name: "orderCost",
    label: "Cost"
  },
  {
    name: "orderStatus",
    label: "Status"
  },
  {}
];


const columnProps = [
  {
    name: "displayId",
    isActionButton: true
  },
  {
    name: "date",
    isDate: true,
    dateFormat: "DD MMM YYYY"
  },
  {
    name: "time",
    isTime: true,
    originalFormat: "hh:mm:ss",
    timeFormat: "hh:mm A"
  },
  {
    cityProp: "city",
    stateProp: "state",
    zipcodeProp: "zipcode",
    addressProp: "address",
    unitProp: "unit",
    name: "serviceAddress",
    isAddress: true
  },
  {
    name: "orderCost",
    isCost: true
  },
  {
    name: "orderStatus",
    isStatus: true
  },
  {
    isAlert: true,
    displayAlert: (obj) => !isEmpty(obj.changes) && obj.changeReason,
    Icon: Alert
  }
];


const changeRowColor = (obj) => !isEmpty(obj.changes) && obj.changeReason

const JobsListModal = ({
  isOpen,
  handleClose,
  jobs,
  actions,
  handlePageChange,
  handlePerPageLimit,
  page,
  total,
  perPage,
  assignJob,
  jobData,
  handleAssignMenuClose,
  employeeList,
  jobActions,
  isAssignMenuOpen,
  btnRef,
  gettingOrders,
  load = true,
}) => {

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
    >
    <DialogContent>
      {gettingOrders && <Loader />}
      {load && <Loader position="fixed" zIndex={9999} />} 
      {jobs && jobs.length ? (
        <Table
          list={jobs}
          headers={headers}
          columnProps={columnProps}
          actions={actions}
          changeRowColor={changeRowColor}
          handleOnPageChange={handlePageChange}
          handleOnLimitChange={handlePerPageLimit}
          pageNumber={page}
          totalCount={total}
          rowsLimit={perPage}
        />

      ) : null
      }

      {jobs.length === 0 ? <p style={{
        textAlign: "center"
      }}>No Data Available</p> : null}


        <AssignJobMenu
          handleMenuClose={handleAssignMenuClose}
          employeeList={employeeList}
          title="Available EmployeeL List"
          errMsg="No Data Available"
          jobItemObj={jobData}
          assignJob={assignJob}
          jobActions={jobActions}
          open={isAssignMenuOpen}
          btnRef={btnRef}
        />
    </DialogContent>
  </Dialog>
  )
}

export default JobsListModal;