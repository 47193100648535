import React from "react";
import Grid from "core/components/Grid";
import { Link } from "react-router-dom";
import DateFnsUtils from '@date-io/date-fns';
import { Calendar, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createMuiTheme, MuiThemeProvider } from "core/components";
import Typography from "core/components/Typography";
import Select from "core/components/Select";
import MenuItem from "core/components/MenuItem";
import Button from "core/components/Button";
import Checkbox from "core/components/Checkbox";
import List from "core/components/List";
import ListItem from "core/components/ListItem";
import ListItemSecondaryAction from "core/components/ListItemSecondaryAction";
import ListItemText from "core/components/ListItemText";
import CircularProgressbar from "react-circular-progressbar";
import AddDetailsModal from "./AddDetailsModal";
import { withStyles } from "core/components/Styles";
import { cardHeader, cardSubHeader, muted } from "theme/colors";
import cn from "classnames";
import momentTz from "moment-timezone";

// Custom Components
import { BarChart, LineBarChart } from "../EChart";
import GraphicInfo from "./GraphInfo";
import { CalendarOutline } from "mdi-material-ui";
import TodaysJobsList from "../CalenderDashboard/TodaysJobsList";
// import ChangeInJobAlert from "../ChangeInJobAlert";
// CSS Files
import "react-circular-progressbar/dist/styles.css";
import Loader from "../ProgressCircle";

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    width: "100%"
  },
  secondaryCardTitle: {
    fontFamily: "Roboto",
    fontSize: 21,
    lineHeight: "32px",
    marginBottom: 0,
    fontWeight: "normal",
    color: "#212121",
    minHeight: 36
  },
  invoiceCard: {
    height: "auto",
    width: "100%"
  },
  activeJobsCard: {
    height: "auto",
    width: "100%"
  },
  todosCard: {
    height: "auto",
    width: "100%"
  },
  secondRow: {
    marginTop: 45,
    //width : "100%"
  },
  jobsList: {
    background: "rgba(249, 249, 249, 0.4)",
    marginTop: 20,
    height: 235,
    overflowY: "auto",
    position:"relative"
    //opacity: 0.4
  },
  userReviews: {
    marginTop: "3.18rem",
    backgroundColor: "rgba(249, 249, 249, 0.38)",
    padding: 10
  },
  userReviewHeader: {
    marginBottom: "0.81rem"
  },
  employeeGraphic: {
    marginTop: 20,
    padding: 10,
    background: "rgba(249, 249, 249, 0.38)",
    position:"relative"
  },
  textAlignRight: {
    textAlign: "right"
  },
  employeeNumber: {
    fontSize: 90,
    fontWeight: 300
  },
  employeeFree: {
    marginLeft: 15,
    fontSize: 22,
    fontWeight: 300
  },
  padList: {
    padding: 10
  },
  cardHeader: {
    color: cardHeader,
    fontWeight: 400
  },
  cardSubHeader: {
    color: cardSubHeader,
    fontWeight: 300,
    marginBottom: 15
  },
  cardText: {
    fontSize: 40,
    fontWeight: 300
  },
  cardTextDanger: {
    color: "#d0021b"
  },
  cardTextPrimary: {
    color: "#49d3b9"
  },
  cardTextDefault: {
    color: "#4a4a4a"
  },
  cardSelect: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: "21px",
    color: cardHeader,
    "&:before, &:after": {
      display: "none"
    }
  },
  activeJobsItemTitle: {
    fontSize: 16,
    color: "rgba(0, 0, 0, 0.87) "
  },
  activeJobsItemSub: {
    fontSize: 14,

    color: muted
  },
  progressTrail: {
    stroke: "#f5a623 !important",
    strokeWidth: 5
  },
  progressPath: {
    stroke: "#49d3b9 !important"
  },
  progressText: {
    fill: "#bebebe !important"
  },
  employeeUsage: {
    width: 200,
    display: "inline-block",
    textAlign: "center",
    fontSize: 20,
    color: "#bebebe",
    fontWeight: 300
  },
  todoList: {
    marginTop: 21
  },
  todoListItem: {
    border: "1px solid #ddd",
    borderRadius: 4,
    padding: "0 10px 0 0",
    marginTop: 8
  },
  todoTitle: {
    color: "#6a737a"
  },
  todoDueDate: {
    color: "#bac3ca"
  },
  textDefault: {
    color: "#4a4a4a"
  },
  textFadedBlack: {
    color: "#4a4a4a",
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "roboto"
  },
  mainContent: {
    width: "100%",
    padding: "13px 25px 13px 25px",
    background: "rgba(236, 240, 241, 0.58)"
  },
  graphInfo: {
    height: 247,
    width: "100%"
  },
  earningGraph: {
    minWidth: 320,
    width: "35%",
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    }
  },
  outstandingGraph: {
    minWidth: 188,
    width: "16%",
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    }
    // maxWidth: 188
  },
  paidGraph: {
    minWidth: 188,
    width: "16%",
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    }
    // maxWidth: 188
  },
  jobGraph: {
    minWidth: 280,
    width: "24%",
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    }
    // maxWidth: 280
  },
  graphsCont: {
    //width : "100%",
    marginTop: 20,
  },
  statsDiv: {
    minWidth: 320,
    width: "73.25%",
  },
  calenderDiv: {
    minWidth: 280,
    width: "24%",
    position:"relative",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginTop: 24
    }
  },
  jobsAndUserCont: {
    boxShadow: "0 0 56px 0 rgba(0, 0, 0, 0.08)",
    borderRadius: 9,
    padding: "55px 30px 35px 30px",
    //width: "100%"  785,
    backgroundColor: "#ffffff"
  },
  calenderCont: {
    boxShadow: "0 0 56px 0 rgba(0, 0, 0, 0.08)",
    borderRadius: 9,
    padding: "35px 40px 10px 40px",
    //width: "100%",
    backgroundColor: "#ffffff"
  },
  empStats: {
    marginTop: 30
  },
  empStatsText: {
    color: "#bebebe",
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: "21px",
    textAlign: "center",
    fontWeight: 500
  },
  calenderIconCont: {
    width: 55,
    height: 55,
    background: "#ffd012",
    color: "#fff",
    boxShadow: "1px 2px 17px 0 rgba(0, 0, 0, 0.07)",
    borderRadius: 4

  },
  currDateCont: {
    marginLeft: 25,
  },
  currDateTitleText: {
    color: "#323C47",
    fontFamily: "Roboto",
    fontSize: 20,
    lineHeight: "23px"
  },
  currDateText: {
    fontSize: 18,
    fontFamily: "Roboto",
    lineHeight: "36px",
    color: "#989898"
  },
  calendarDiv: {
    height: "100%",
    width: "100%",
    overflow: "hidden"
  },
  currJobCont: {
    borderTop: "1px solid #eeeeee",
    paddingLeft: 10,
    paddingTop: 10
  },
  calenderDivCont: {
    marginTop: 50,
    // background:"grey"
  },
  linkText: {
    // fontWeight: "bold",
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: "16px",
    fontFamily: "Roboto",
    textAlign: "center",
    lineSpacing: "0.5px",
    textTransform: "uppercase",
    textDecoration: "none",
    marginTop: "11px"
  },
  noActiveJobsText: {
    textAlign: "center",
    marginTop: 20
  },
  infoTitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 20,
    lineHeight: "30px"
  },
  boldInfoTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 20,
    lineHeight: "30px"
  },
  earningSubHeadingStyle: {
    fontSize: "36px",
    lineHeight: "54px",
    fontWeight: "600"
  },
  outstandingSubHeadingStyle: {
    fontSize: "34px",
    lineHeight: "51px",
    fontWeight: "600"
  },
  paidJobsSubHeadingStyle: {
    fontSize: "32px",
    lineHeight: "30px",
    fontWeight: "600"
  },
  cardLabel: {
    fontFamily: "Roboto",
    fontSize: "18px",
    lineHeight: "21px"
  },

  ratingsDiv: {
    marginTop: 40,
    paddingRight: 24
  },
  reportsWrapper:{
    position:"relative"
  },
  statsWrapper:{
    position:"relative"
  },
  cusStatsWrapper:{
    position:'relative'
  }
});

const mtTheme = createMuiTheme({
  overrides: {
    MuiPickersCalendar: {
      week: {
        justifyContent: "space-evenly"
      }
    },
    MuiPickersCalendarHeader: {
      daysHeader: {
        justifyContent: "space-evenly"

      },
      dayLabel: {
        width: 24,
        color: "#808080",
        textTransform: "uppercase",
        fontFamily: "Poppins"
      },
      // MuiTypography:{
      //   color:"#989898"
      // }
      iconButton: {
        color: "#4da1ff"
      }
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "#45c5b3",
          color: "#fff"
        }
      }
    },
    MuiTypography: {
      body1: {
        color: "#989898"
      }
    },
    MuiPickersDay: {
      // height: "24px",
      // width: "24px",
      day: {
        color: "#999999",
        "@media(max-width: 600px)": {
          height: 28,
          width: 28
        }
      },
      current: {
        color: "#999999",
        backgroundColor: "rgba(77, 161, 255, 0.1)"
      },
      isSelected: {
        color: "#fff",
        backgroundColor: "#45c5b3",
        "&:hover": {
          backgroundColor: "#45c5b3",
        }
      }
    }
  }
})

const ActiveJobs = ({ classes, jobs, openJob, cleaningTypes }) =>
  jobs.map((job, key) => (
    <ListItem dense button key={`active-job-${key}`} disableGutters className={classes.jobStyle}>
      <Checkbox />
      <ListItemText>
        <div className={classes.activeJobsItemTitle}>{cleaningTypes[job.cleaningType]}</div>
        <div className={classes.activeJobsItemSub}>{momentTz(job.date).format("MMM DD, YYYY")}</div>
      </ListItemText>
      <ListItemSecondaryAction>
        <Button onClick={openJob(job)} className={classes.textFadedBlack} id="viewJob" >
          View
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  ));



const Dashboard = ({
  classes,
  totalEarnings,
  totalOutstanding,
  totalPaid,
  totalJobs,
  earningsData,
  paidData,
  outstandingData,
  jobsData,
  reportsMode,
  getReports,
  dateWiseJobs,
  onDateChange,
  currDate,
  openJob,
  cleaningTypes,
  activeJobs,
  freeEmployeeData,
  freeEmployees,
  busyEmployees,
  busyEmployeesPercent,
  getEmployeesStats,
  customersReviewsData,
  customersReviewsLineData,
  totalCustomersReviews,
  getCustomersReviewsStats,
  customersStatsMode,
  weeks,
  currWeek,
  openJobChangesModal,
  ordersWithChanges,

  handleLinkEvent,
  gettingJobsReports,
  gettingActiveJobs,
  gettingCusStats,
  gettingEmpStats,
  gettingDateWiseJobs,
  ...restProps
}) => {


  const userReviews = {
    xAxis: [
      {
        type: "category",
        axisPointer: {
          type: "shadow"
        },
        axisLine: {
          show: false
        },
        splitLine: {
          show: false,
        },
      }
    ],
    yAxis: [
      {
        type: "value",
        min: 0,
        max: 100,
        interval: 20,
        axisLabel: {
          formatter: "{value}",
          textStyle: {
            color: "#fff"
          }
        },
        axisTick: {
          lineStyle: {
            width: 0
          }
        },
        axisLine: {
          show: false
        },
        splitLine: {
          show: false,
        },
      },
      {
        show: false
      }
    ],
    series: [
      {
        type: "bar",
        data: customersReviewsData,
        itemStyle: {
          color: "#fff"
        }
      },
      {
        type: "line",
        // yAxisIndex: 1,
        data: customersReviewsLineData,
        itemStyle: {
          color: "#fff"
        }
      }
    ]
  };
  const freeEmployeesOptions = {
    animate: true,
    xAxis: {
      type: "category",
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      axisLine: {
        show: false
      },
      axisTick: {
        lineStyle: {
          width: 0
        }
      },
    },
    yAxis: {
      min: 0,
      max: 10,
      type: "value",
      axisLine: {
        show: false
      },
      interval: 2,
      splitLine: {
        // interval: 100,
        lineStyle: {
          // color: ['#483d8b'],
          width: 1,
          opacity: 0.4
        },
        // show: false
      }
    },
    grid: {
      top: 0,
      left: 0,
      right: 0,
      height: 90,
      containLabel: false
    },

    series: [
      {
        data: freeEmployeeData,
        type: "bar",
        itemStyle: {
          barBorderRadius: 100,
          color: "#f5a623"
        }
      }
    ]
  };

  return (
    <div className={classes.root} >
      <Grid className={classes.mainContent}>
        <Grid className={classes.reportsWrapper}>
          {gettingJobsReports && <Loader background="#fff"/>}
          <Grid container spacing={8} id="ratingsStats" >
            <Grid container className={classes.ratingsDiv} justify="flex-end">
              <Grid item id="ratingsGap">
                <Select
                  value={reportsMode}
                  className={classes.cardSelect}
                  onChange={getReports}
                >
                  <MenuItem value='today'>Today</MenuItem>
                  <MenuItem value='week'>This week</MenuItem>
                  <MenuItem value='month'>This Month</MenuItem>
                </Select>
              </Grid>
            </Grid>


              <Grid
                container
                alignContent="center"
                justify="space-evenly"

                className={classes.graphsCont}
              >
                <Grid item className={cn(classes.graphInfo, classes.earningGraph)}>
                  <GraphicInfo
                    chartHeight={115}
                    fontSize={36}
                    title="Earning"
                    subHeading={`$${totalEarnings}`}
                    data={earningsData}
                    className={classes.earningLabelHeight}
                    titleStyle={classes.infoTitle}
                    subHeadingStyle={classes.earningSubHeadingStyle}
                  />
                </Grid>
                <Grid item className={cn(classes.graphInfo, classes.outstandingGraph)}>
                  <GraphicInfo
                    chartHeight={102}
                    fontSize={34}
                    title="Outstanding"
                    subHeading={`$${totalOutstanding}`}
                    lineStyle={{
                      firstColor: "#fb61e2",
                      secondColor: "#fba361"
                    }}
                    areaStyle={{
                      firstColor: "#fa4fdf",
                      secondColor: "#fa994f"
                    }}
                    data={outstandingData}
                    titleStyle={classes.infoTitle}
                    subHeadingStyle={classes.outstandingSubHeadingStyle}
                  />
                </Grid>
                <Grid item className={cn(classes.graphInfo, classes.paidGraph)}>
                  <GraphicInfo
                    chartHeight={102}
                    fontSize={32}
                    title="Paid"
                    subHeading={`$${totalPaid}`}
                    lineStyle={{
                      firstColor: "#25ceda",
                      secondColor: "#0ed198"
                    }}
                    areaStyle={{
                      firstColor: "#25ceda",
                      secondColor: "#0ed198"
                    }}
                    data={paidData}
                    titleStyle={classes.infoTitle}
                    subHeadingStyle={classes.paidJobsSubHeadingStyle}
                  />
                </Grid>
                <Grid item className={cn(classes.graphInfo, classes.jobGraph)}>
                  <GraphicInfo
                    chartHeight={120}
                    fontSize={32}
                    title="Total Jobs"
                    subHeading={totalJobs}
                    lineStyle={{
                      firstColor: "#ffbf45",
                      secondColor: "#ff8309"
                    }}
                    areaStyle={{
                      firstColor: "#ffbf45",
                      secondColor: "#ff850b"
                    }}
                    data={jobsData}
                    titleStyle={classes.boldInfoTitle}
                    subHeadingStyle={classes.paidJobsSubHeadingStyle}
                  />
                </Grid>

            </Grid>

          </Grid>
        </Grid>

        

        <Grid className={classes.statsWrapper}>
            

        <Grid
          container
          justify="space-between"
          className={classes.secondRow}
        >
          <Grid item className={classes.statsDiv}>
            <Grid className={classes.jobsAndUserCont} id="jobChartCont">
              <Grid container spacing={2}>
                <Grid item md={7} xs={12} className={classes.activeJobs} id="jobsAndReviews" >
                  <Grid
                    container
                    justify="space-between"
                    alignContent="center"
                  >
                    <Typography
                      variant="h4"
                      gutterBottom
                      className={classes.secondaryCardTitle}
                    >
                      Active Jobs
                    </Typography>
                    <Link className={cn(classes.textFadedBlack, classes.linkText)} to="/jobs" onClick={handleLinkEvent("View_All_Jobs")} id="viewAllJobs">
                      View All
                    </Link>
                  </Grid>
                  <Grid item className={classes.jobsList}>
                    {gettingActiveJobs && <Loader background="#fff" />}
                    {
                      activeJobs && activeJobs.length > 0
                        ?
                        <List id="activeJobList">
                          <ActiveJobs
                            jobs={activeJobs}
                            classes={classes}
                            openJob={openJob}
                            cleaningTypes={cleaningTypes}
                          />
                        </List>
                        :
                        null
                    }
                    {

                      (!gettingActiveJobs && (!activeJobs || !activeJobs.length)) ?
                        <Typography className={classes.noActiveJobsText}>
                          No Active Jobs
                        </Typography>
                        : null
                    }
                  </Grid>

                  <Grid item className={classes.userReviews} id="customerReviews">
                    <Grid container className={classes.userReviewHeader} justify="space-between">
                      <Grid item>
                        <Typography
                          className={classes.secondaryCardTitle}
                        >
                          Customers Reviews ({totalCustomersReviews})
                        </Typography>
                      </Grid>
                      <Grid item id="reviewsPeriod">
                        <Select
                          value={customersStatsMode}
                          className={classes.cardSelect}
                          onChange={getCustomersReviewsStats}
                        >
                          <MenuItem value="today">Today</MenuItem>
                          <MenuItem value="week">This week</MenuItem>
                          <MenuItem value="month">This Month</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid  className={classes.cusStatsWrapper}>
                      {gettingCusStats && <Loader background="#fff"/>}
                      {

                        customersReviewsData ?
                          <LineBarChart
                            options={userReviews}
                            chartHeight={140}
                            max={totalCustomersReviews}
                          />
                          : null


                      }

                    </Grid>
                  </Grid>

                </Grid>
                <Grid item md={5} xs={12} id="employeeStats">
                  <Grid item className={classes.employeeInfo}>
                    <Typography
                      variant="h4"
                      gutterBottom
                      className={classes.secondaryCardTitle}
                    >
                      Employee Stats
                    </Typography>
                  </Grid>

                  <Grid className={classes.employeeGraphic}>
                    {gettingEmpStats && <Loader background="#fff"/>}
                    <Grid item className={classes.textAlignRight} >
                      <Grid container justify="flex-end">
                        <Grid item id="employeePeriod" >
                          <Select value={currWeek} className={classes.cardSelect} onChange={getEmployeesStats}>
                            {/* <MenuItem value="today">Today</MenuItem> */}
                            {
                              weeks.map((w, i) => {
                                return (
                                  <MenuItem key={`week-key-${i}`} value={w.value}>
                                    {w.label}
                                  </MenuItem>
                                )
                              })
                            }
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid id="employeeChart">
                      <Grid item style={{ marginTop: -45 }}>
                        <span
                          className={cn([
                            classes.employeeNumber,
                            classes.textDefault
                          ])}
                        >
                          {freeEmployees}
                        </span>
                        <span
                          className={cn([
                            classes.employeeFree,
                            classes.textFadedBlack
                          ])}
                        >
                          Employee Free
                          </span>
                      </Grid>

                      <Grid item >
                        <BarChart chartOptions={freeEmployeesOptions} chartHeight={120} />
                      </Grid>
                    </Grid>


                    <Grid item>
                      <Grid container justify="center" className={classes.empStats} >
                        <div className={classes.employeeUsage} id="employeeUtil">
                          <CircularProgressbar
                            percentage={busyEmployeesPercent}
                            text={`${busyEmployeesPercent}%`}
                            classes={{
                              root: "CircularProgressbar",
                              trail: cn(
                                "CircularProgressbar-trail",
                                classes.progressTrail
                              ),
                              path: cn(
                                "CircularProgressbar-path",
                                classes.progressPath
                              ),
                              text: cn(
                                "CircularProgressbar-text",
                                classes.progressText
                              )
                            }}
                            strokeWidth={8}
                          />
                          <Typography className={classes.empStatsText}>Employee Utilization</Typography>
                        </div>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
          <Grid item className={classes.calenderDiv}>
            {gettingDateWiseJobs && <Loader background="#fff"/>}
            <Grid container
              direction="column"
              className={classes.calenderCont}
              id="calendarDiv"
            >
              <Grid item container>
                <Grid
                  item container
                  className={classes.calenderIconCont}
                  alignItems="center"
                  justify="center"
                >
                  <CalendarOutline />
                </Grid>

                <Grid item className={classes.currDateCont}>
                  <Typography className={classes.currDateTitleText}>
                    Calendar
                  </Typography>
                  <Typography className={classes.currDateText}>
                    {momentTz().format("DD MMM")}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item className={classes.calenderDivCont}>
                <Grid className={classes.calendarDiv}>
                  <MuiThemeProvider theme={mtTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Calendar date={currDate} onChange={onDateChange} />
                    </MuiPickersUtilsProvider>
                  </MuiThemeProvider>
                </Grid>
              </Grid>

              <Grid item>
                <Grid className={classes.currJobCont}>
                  <TodaysJobsList todaysJobs={dateWiseJobs} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </Grid>


      </Grid>
      <AddDetailsModal {...restProps} showSkip elevation={0} doNotCloseModal />
    </div>
  );
};

export default withStyles(styles)(Dashboard);
