function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 20,
    height: 20,
    viewBox: "0 0 20 20"
  }, props), React.createElement("path", {
    id: "prefix__iconfinder_architecture-interior-19_809105_1_",
    d: "M20 .833A.787.787 0 0 0 19.167 0H.833A.787.787 0 0 0 0 .833v18.334A.787.787 0 0 0 .833 20h18.334a.787.787 0 0 0 .833-.833zm-18.333.834H12.5v5H1.667zm0 5.833h5v5h-5zm0 10.833v-5H12.5v5zm16.667 0h-5v-5h5zm0-5.833H7.5v-5h10.833zm0-5.833h-5v-5h5z",
    "data-name": "iconfinder_architecture-interior-19_809105 (1)",
    style: {
      "fill": "#00949c"
    }
  }));
};

export default __webpack_public_path__ + "static/media/job_wall.341945c4.svg";
export { SvgComponent as ReactComponent };