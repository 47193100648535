import React from "react";
import { connect } from "react-redux";
import momentTz from "moment-timezone";
import Dialog from "core/components/Dialog";
import cn from "classnames";
import DialogContent from "core/components/DialogContent";
import FabButton from "core/components/FabButton";
import { getInitials } from "core/components/Avatar";
import AssignJobMenu from "../AssignJobMenu";
import Grid from "core/components/Grid";
import Typography from "core/components/Typography"
import { capitalizeFirstLetterOnly } from "../../utils/capitalize";
import { withStyles } from "core/components/Styles";
import Loader from "../ProgressCircle";
import AddOn from "./AddOn";
import Options from "./Options";
import OptionListItem from "./OptionListItem";
import InfoLabel from "./InfoLabel";
import JobInfoLabel from "./JobInfoLabel";
import AssignedCleanerInfo from "../AssignedCleanersInfo";
import CostBreakDown from "./CostBreakDown";
import handleAddOns from "./handleAddOns";
import { handleAddressAndInfo, handleCleaningType } from "./modalHelpers";
import checkOrderType from "./checkOrderType";
import { ReactComponent as JobLocationPin } from "../../assets/images/svg/job_address_pin.svg";
import { ReactComponent as CleaningTypeIcon } from "../../assets/images/svg/job_cleaning_type.svg";
import { ReactComponent as FloorIcon } from "../../assets/images/svg/job_floor.svg";
import { ReactComponent as FrequencyIcon } from "../../assets/images/svg/job_frequency.svg";
import { ReactComponent as BathroomIcon } from "../../assets/images/svg/job_bathroom.svg";
import { ReactComponent as BedroomIcon } from "../../assets/images/svg/job_bedroom.svg";
import { ReactComponent as EmployeesIcon } from "../../assets/images/svg/job_employees.svg";
import { ReactComponent as PropertyAreaIcon } from "../../assets/images/svg/job_property_area.svg";
import { ReactComponent as PropertyConditionIcon } from "../../assets/images/svg/job_property_condition.svg";
import { ReactComponent as PropertyTypeIcon } from "../../assets/images/svg/job_property_type.svg";
import { ReactComponent as LocationPinIcon } from "../../assets/images/svg/job_location.svg";

import { ReactComponent as DomesticIcon } from "../../assets/images/svg/job_domestic_type.svg";
import { ReactComponent as CommercialIcon } from "../../assets/images/svg/job_commercial_type.svg";
import { ReactComponent as JobIdIcon } from "../../assets/images/svg/job_id.svg";
import { ReactComponent as JobDateIcon } from "../../assets/images/svg/job_date.svg";
import { ReactComponent as JobTimeIcon } from "../../assets/images/svg/job_time.svg";
import { ReactComponent as JobStatusIcon } from "../../assets/images/svg/job_status.svg";
import { ReactComponent as JobAddInfoIcon } from "../../assets/images/svg/job_add_info.svg";

import TopFigure from "../../assets/images/svg/job_modal_top.svg";
import InfoBlob from "../../assets/images/svg/job_modal_info.svg";
import BottomBlob from "../../assets/images/svg/job_modal_bottom.svg";
import createImgURL from "utils/createImageURL";

const styles = theme => ({
  root: {
    "&:first-child": {
      padding: "0px"
    }
  },
  jobDataCont: {
    width: "inherit"
  },
  modalTopBackground: {
    backgroundColor: "#fff",
    position: "relative",
    overflow: "hidden",
    backgroundImage: `url(${TopFigure})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    minHeight: "23.35rem"
  },
  infoBackgroundCont: {
    width: "18.5rem",
    paddingTop: ".8rem",
    [theme.breakpoints.down('sm')]: {
      paddingBottom: "3rem"
    }
  },
  infoBackground: {
    backgroundImage: `url(${InfoBlob})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "18.5rem",
    height: "15.7rem",
    paddingBottom: "1rem"
  },
  avatarImgCont: {
    borderRadius: "50%",
    height: "4rem",
    width: "4rem",
    boxShadow: "2.7px 2.7px 1.9px 0 rgba(0, 0, 0, 0.3)",
    backgroundImage: "linear-gradient(250deg, #fea46e 95%, #fa656b -39%)",
    position: "relative",
    left: "-.7rem",
    top: "-.6rem"
  },
  initialPad: {
    padding: "1.3rem",
  },
  circularDiv: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: '#fff'
  },
  avatarText: {
    width: "100%",
    height: "100%",
    fontSize: "1.07rem",
    color: "#ffffff",
    alignItems: "center",
    justifyContent: "center"
  },
  nameStyle: {
    fontSize: "1.14rem",
    color: "#ffffff"
  },
  addressStyle: {
    paddingTop: ".3rem"
  },
  billAddress: {
    height: "2.14rem",
    width: "10.85rem",
    lineHeight: "1.5",
    paddingLeft: ".4rem"
  },
  subText: {
    color: "#ffffff",
    fontSize: ".71rem",
  },
  jobLocationPin: {
    marginTop: ".1rem"
  },
  infoContainer: {
    minHeight: "9.07rem",
    width: "100%",
  },
  infoContainerRow: {
    minHeight: "4.53rem"
  },
  addressGridCont: {
    minHeight: "14.5rem",
    padding: "3rem 1rem"
  },
  addressTextCont: {
    minHeight: "6.85rem",
    borderRadius: "56px",
    border: "solid 1px #20c9d2",
    padding: "2rem 6.3rem 2rem 3.3rem",
    fontSize: ".95rem",
    color: "#00949c",
    display: "flex",
  },
  addressTag: {
    height: "3.78rem",
    width: "7.71rem",
    position: "relative",
    top: "-3.35rem",
    fontSize: ".78rem",
    paddingLeft: ".8rem",
    background: "#ffffff"
  },
  jobInfoCont: {
    minHeight: "33.51rem"
  },
  jobInfoContUpdate: {
    minHeight: "23.51rem"
  },
  jobDetailCont: {
    height: "5.3rem"
  },
  jobDetailsTag: {
    fontSize: "1rem",
    color: "#6b6b6b",
    paddingLeft: "4rem"
  },
  jobDataTilesCont: {
    minHeight: "11.89rem",
    padding: "0 2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0 2rem"
    }
  },
  jobDataTilesContRow: {
    minHeight: "5.94rem"
  },
  infoTagsCont: {
    borderRadius: "6.8px",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.19)",
    backgroundColor: "#ffffff",
  },
  infoTagIconStyle: {
    height: "2.62rem",
    width: "2.62rem",
    borderRadius: "3.8px",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.19)",
    backgroundImage: "linear-gradient(to bottom, #50e3c2, #00949c)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  infoTagIconPostition: {
    margin: "-2.857rem 0 0 -1.6rem"
  },
  infoTagData: {
    padding: ".3rem 0 0 1.08rem"
  },
  infoTagInfo: {
    color: "#7c7c7c",
    fontSize: ".857rem"
  },
  jobLabelCont: {
    minHeight: "4.55rem",
    [theme.breakpoints.down('sm')]: {
      minHeight: '0rem'
    }
  },
  addInfoCont: {
    minHeight: "7.714rem",
    borderRadius: "6.8px",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.19)",
    backgroundColor: "#ffffff",
    margin: "2.45rem 4.8rem 3.57rem 4.8rem",
  },
  addInfoIconPosition: {
    position: "relative",
    top: "-.5rem",
    left: "-.4rem",
  },
  addInfoLabel: {
    fontSize: ".78rem",
    color: "#00949c",
    height: "1.1rem",
    position: "relative",
    top: "-1.2rem",
    left: "3.6rem"
  },
  billAddInfoText: {
    fontSize: "1rem",
    color: "#767676"
  },
  addInfoText: {
    whiteSpace: "normal",
    padding: '0 0 2.4rem 3.8rem',
    fontSize: "1rem",
    color: "#767676"
  },
  addOptionsCont: {
    padding: "0 4.35rem"
  },
  bottomPadding_2: {
    paddingBottom: "2.14rem"
  },
  addOnsGrid: {
    minHeight: "6.05rem",
    display: "flex",
    flexWrap: "wrap"
  },
  bottomPadding_3: {
    paddingBottom: "2.35rem"
  },
  bottomBlobStyle: {
    backgroundImage: `url(${BottomBlob})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "11.94rem",
    width: "17.35rem",
    marginTop: "-11.99rem"
  },
  marginAuto: {
    margin: "auto 0"
  },
  weightedFont: {
    fontWeight: "600"
  },
  freqIcon:{
    "& path":{
      fill:"#fff!important",
      stroke:"#fff!important"
    }
  }
});


const CleaningAreaText = ({
  item
}) => {
  return (
    `${item.name} (${item.size} sq ft) --- ${item.count}`
  )
}


const CarpetAddOnText = ({
  item
}) => {
  return (
    `${item.name} --- ${item.count}`
  )
}
const JobDetailsModal = ({
  classes,
  isJobDetailsModalOpen,
  jobItemObj,
  // handleJobCompleted,
  // acceptJob,
  // rejectJob,
  handleJobDetailsModal,
  // doNotShowJobBtns,
  cleaningTypesObj,
  // addRef,
  openAssignMenuFromModal,
  openAssignJobMenu,
  hideBtn,
  showDialogLoader,
  ...restProps
}) => {


  const {
    // createdat,
    serviceAddress,
    customerProfile,
    customerName,
    type,
    zipcode,
    cleaningType,
    time,
    date,
    bedrooms,
    bathrooms,
    orderCost,
    originalCost,
    discount,
    displayId,
    orderStatus,
    // jobCompleted,
    // contractorId,
    frequency,
    floor,
    condition,
    requiredEmployees,
    cleaningServices,
    equipments,
    addOns,
    additionalBookingNotes,
    assignedEmployeesDetails,
    carpetAddOns,
    cleaningAreas,
  } = jobItemObj;


  const { billingAddress, propertyType, jobProperty } = handleAddressAndInfo(serviceAddress, zipcode);
  const { cleaning, whenToClean } = handleCleaningType(type, cleaningType, cleaningTypesObj);

  const addOnsList = handleAddOns(addOns, type);

  const additionalNotes = additionalBookingNotes || "";
  const {
    isDomestic,
    isCarpet,
    // isCommercial
  } = checkOrderType(type);

  return (
    <Dialog
      open={isJobDetailsModalOpen}
      onClose={handleJobDetailsModal}
      maxWidth="md"
    >
      <DialogContent
        classes={{ root: classes.root }}
      >
        {showDialogLoader && <Loader zIndex={999} />}

        <Grid container className={classes.jobDataCont} id="jobModalCont">
          {/* Header */}
          <Grid container
            className={classes.modalTopBackground}
            justify="space-around"
          >
            <Grid item container
              justify="center"
              alignItems="flex-start"
              className={classes.infoBackgroundCont}
              xs={12}
              md={4}
            >
              <Grid item container
                justify="space-between"
                alignItems="center"
                className={classes.infoBackground}
              >
                <Grid item xs={3} >
                  <div className={cn([classes.avatarImgCont, {
                    [classes.initialPad]: !customerProfile
                  }])}>
                    {customerProfile
                      ? <div
                        className={classes.circularDiv}
                        style={{ backgroundImage: `url(${createImgURL(customerProfile)})` }}
                      />
                      :
                      <span className={classes.avatarText}>
                        {getInitials(customerName)}
                      </span>
                    }
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <Grid item xs={12} className={classes.nameStyle}>
                    {customerName}
                  </Grid>
                  <Grid item container xs={12} className={cn([classes.addressStyle, classes.subText])}>
                    <div className={classes.jobLocationPin}><JobLocationPin /></div>
                    <span className={classes.billAddress}>{billingAddress}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <CostBreakDown
              orderCost={orderCost}
              discount={discount}
              originalCost={originalCost}
            />
          </Grid>

          {/* Address Info Labels */}
          {
            !isCarpet ?
              <Grid className={classes.infoContainer} >


                <Grid container
                  className={classes.infoContainerRow}
                  alignItems="flex-start"
                // xs={12} 
                >
                  <InfoLabel
                    icon={isDomestic ? <CleaningTypeIcon /> : <PropertyTypeIcon />}
                    title={isDomestic ? "Cleaning Type" : "Property Type"}
                    info={isDomestic ? cleaning : propertyType}
                    subText={whenToClean}
                  />

                  {jobProperty ?
                    <InfoLabel
                      icon={<PropertyAreaIcon />}
                      title="Property Area"
                      info={`${jobProperty.toFixed(2)} Sqf`}
                    />
                    : null
                  }
                  {condition &&
                    <InfoLabel
                      icon={<PropertyConditionIcon />}
                      title="Property Condition"
                      info={condition}
                    />
                  }
                </Grid>

                <Grid container
                  className={classes.infoContainerRow}
                  alignItems="flex-end"
                // xs={12} 
                >
                  <InfoLabel
                    icon={isDomestic ? <BathroomIcon /> : <FloorIcon />}
                    title={isDomestic ? "Bathroom" : "Floor Type"}
                    info={isDomestic ? bathrooms : floor}
                  />
                  <InfoLabel
                    icon={isDomestic ? <BedroomIcon /> : <FrequencyIcon />}
                    title={isDomestic ? "Bedroom" : "Frequency"}
                    info={isDomestic ? bedrooms : frequency}
                  />
                  {requiredEmployees &&
                    <InfoLabel
                      icon={<EmployeesIcon />}
                      title="No of Employees"
                      info={requiredEmployees}
                    />
                  }
                </Grid>

              </Grid>
              : null

          }



          {/* Billing Address */}
          <Grid container className={classes.addressGridCont} alignItems="center" justify="center">
            <Grid item xs={11} className={classes.addressTextCont}>
              <div className={classes.addressTag}>
                <p>Address Details</p>
                <LocationPinIcon />
              </div>
              <div className={cn([classes.billAddInfoText, classes.marginAuto])}>
                {billingAddress}
              </div>
            </Grid>
          </Grid>

          {/* Job Info Labels */}
          <Grid container
            justify="center"
            alignItems="center"
            className={cn([classes.jobInfoCont, {
              [classes.jobInfoContUpdate]: !additionalNotes
            }])}
          >
            <Grid container item
              alignItems="center"
              className={classes.jobDetailCont}
              xs={12}
            >
              <Typography className={classes.jobDetailsTag}>
                Job Details ------
              </Typography>
            </Grid>

            <Grid container item
              alignItems="center"
              justify="center"
              className={classes.jobDataTilesCont}
              xs={12}
            >
              <Grid container item
                className={cn([classes.jobDataTilesContRow,])}
                justify="space-between"
                alignItems="flex-start"
                xs={12}
                sm={11}
              >
                <JobInfoLabel
                  icon={<JobIdIcon />}
                  title="Job ID"
                  info={displayId}
                />
                <JobInfoLabel
                  icon={<JobDateIcon />}
                  title="Job Date"
                  info={momentTz(date).format("DD MMM, YYYY")}
                />
                <JobInfoLabel
                  icon={<JobTimeIcon />}
                  title="Job Time"
                  info={momentTz(time, "HH:mm:ss").format("hh:mm a")}
                />
              </Grid>

              <Grid container item
                className={classes.jobDataTilesContRow}
                justify="space-between"
                alignItems="flex-end"
                xs={12}
                sm={11}
              >
                <JobInfoLabel
                  icon={isDomestic ? <DomesticIcon /> : <CommercialIcon />}
                  title="Job Type"
                  info={capitalizeFirstLetterOnly(type)}
                />
                <JobInfoLabel
                  icon={<JobStatusIcon />}
                  title="Job Status"
                  info={capitalizeFirstLetterOnly(orderStatus)}
                />
                {
                  isCarpet ?
                  <JobInfoLabel
                    icon={<FrequencyIcon className={classes.freqIcon}/>}
                    title="Frequency"
                    info={frequency}
                  />
                  :
                <Grid container item
                  className={classes.jobLabelCont}
                  xs={3}
                  justify="center"
                  alignItems="center"
                />
                
                }
                {/* <Grid container item
                  className={classes.jobLabelCont}
                  xs={3}
                  justify="center"
                  alignItems="center"
                ></Grid> */}
              </Grid>

            </Grid>
            {
              additionalNotes &&
              <Grid item container xs={11}
                className={classes.addInfoCont}
                direction="column"
              >
                <Grid item className={cn([classes.infoTagIconStyle, classes.addInfoIconPosition])}>
                  <JobAddInfoIcon />
                </Grid>
                <span className={classes.addInfoLabel}>Additional Job Details</span>
                <Grid item xs={10} className={classes.addInfoText}>
                  {additionalNotes}
                </Grid>
              </Grid>
            }

          </Grid>

          <AssignedCleanerInfo cleaners={assignedEmployeesDetails} />

          {/* Equipments and Services */}
          {!isDomestic &&
            <Grid container className={classes.addOptionsCont}>
              {
                (cleaningServices && cleaningServices.length > 0) &&
                <Grid item container alignItems="center" justify="flex-start" xs={12}>
                  <Options optionLabel="Service Type ----">
                    {cleaningServices.map((item, index) =>
                      <OptionListItem key={index} item={item} />
                    )}
                  </Options>
                </Grid>
              }
              {
                (equipments && equipments.length > 0) &&
                <Grid item container alignItems="center" justify="flex-start" xs={12}>
                  <Options
                    classes={{
                      optionLabelStyle: classes.optionLabelStyle,
                      bottomPadding: classes.bottomPadding_2
                    }}
                    optionLabel="Equipments ----"
                  >
                    {equipments.map((item, index) =>
                      <OptionListItem key={index} item={item} />
                    )}
                  </Options>
                </Grid>
              }
            </Grid>
          }


          {isCarpet ?
            <Grid container className={classes.addOptionsCont}>
              {
                (cleaningAreas && cleaningAreas.length > 0) ?
                <Grid item container alignItems="center" justify="flex-start" xs={12}>
                  <Options optionLabel="Cleaning Areas ----">
                    {cleaningAreas.map((item, index) =>
                      <OptionListItem key={index} item={item} textComponent={CleaningAreaText} />
                    )}
                  </Options>
                </Grid>
                : null
              } 
              {
                ( carpetAddOns && carpetAddOns.length > 0) ?
                <Grid item container alignItems="center" justify="flex-start" xs={12}>
                  <Options
                    classes={{
                      optionLabelStyle: classes.optionLabelStyle,
                      bottomPadding: classes.bottomPadding_2
                    }}
                    optionLabel="Add Ons ----"
                  >
                    {carpetAddOns.map((item, index) =>
                      <OptionListItem key={index} item={item} textComponent={CarpetAddOnText}/>
                    )}
                  </Options>
                </Grid>
                : null
              }
            </Grid>
            : null
          }

          {/* Add Ons */}
          {
            (addOnsList && addOnsList.length > 0) &&
            <Grid container alignItems="center" className={classes.addOptionsCont}>
              <Grid item xs={10}>
                <Options
                  classes={{
                    optionLabelStyle: classes.optionLabelStyle,
                    optionListContainer: classes.addOnsGrid
                  }}
                  optionLabel="Add Ons ----"
                >
                  {addOnsList.map((item, key) =>
                    <AddOn
                      key={key}
                      icon={item.icon}
                      addOn={item.value}
                      label={item.label}
                    />
                  )}
                </Options>
              </Grid>
            </Grid>
          }

          {/* Bottom blob */}
          <Grid container justify="flex-end">
            <div className={cn([classes.bottomBlobStyle, {
              [classes.bottomBlobAddOnStyle]: addOnsList.length === 0
            }])} />
          </Grid>
        </Grid>
      </DialogContent>
      <FabButton iconName="ignore" onClick={handleJobDetailsModal} />
      <AssignJobMenu
        {...restProps}
        open={openAssignJobMenu}
        title="Employee List"
        jobItemObj={jobItemObj}
      />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  showDialogLoader: state.app.showDialogLoader
})

const WithStylesJobDetailsModal = withStyles(styles)(JobDetailsModal);

export default connect(mapStateToProps)(WithStylesJobDetailsModal);