import React, { Fragment } from "react";
import cn from "classnames";
import { withStyles } from "core/components/Styles";

const styles = theme =>( {
  group: {
    position: "relative",
    margin: "0.3rem 0",
    // fontSize: "1.2rem",
    width: "100%",
  },
  addOn : {
    position: "absolute",
    width: "4rem",
    height: "100%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&.left-icon": {
      leflt: 0
    },
    "&.right-icon": {
      right: 0
    },
    "& img": {
      width: "1.5rem",
      height: "1.5rem",
      opacity: 0.7
    }
  },
  field: {
    border: 0,
    borderRadius: 3,
    padding: "0.85rem 1rem",
    backgroundColor:"#F6F7F9",
    width: "100%",
    fontSize:16,
    lineHeight:"normal",
    "&.right-icon" : {
      paddingRight: "4rem"
    },
    "&.left-icon" : {
      paddingLeft: "4rem"
    },
    "&::placeholder": {
      color: "#646464"
    },
    "&:focus": {
      boxShadow: 0,
      outline: "none"
    }
  },
  fieldWrapper: {
    position: "relative",
    textAlign: "left"
  },
  errors:{
    color:"red",
    fontSize:"0.75rem"
  }
});

const Input = ({
  classes, 
  input = {}, 
  onClick, 
  meta, 
  rightIcon, 
  leftIcon, 
  placeholder,
  ...rem
  }) => {
  
 

  return (
      <Fragment>
        <div className={classes.fieldWrapper}>
          {leftIcon && <span className={cn({
            [classes.addOn]: rem.addOnStyle ? rem.addOnStyle : classes.addOn
          }, 'left-icon')}>{leftIcon}</span>}
          {rightIcon && <span className={cn(classes.addOn, 'right-icon')}>{rightIcon}</span>}
          <input 
            {...rem}
            {...input}
            className={cn(classes.field, classes.leftMargin_15, rightIcon ? "right-icon" : "", leftIcon ? "left-icon" : "")}
            onClick={onClick}  
            onKeyDown={ e => {
              if (e.keyCode === 13) {
                const target = e.target;
                const form = target.form;
                if (Array.prototype && form && target) {
                  let index = Array.prototype.indexOf.call(form, target);
                  let idx = form.elements[index + 1].tabIndex;
                  while (idx < 0) {
                    index++;
                    idx = form.elements[index + 1].tabIndex;
                  }
                  form.elements[index + 1].focus();
                  e.preventDefault();
                }
              }
            }}
            placeholder={placeholder}
          />

          
        </div>
        {meta.touched &&
        ((meta.error && <span className={classes.errors} >{meta.error}</span>) ||
          (meta.warning && <span>{meta.warning}</span>))}
      </Fragment>

  );
};



const InputField = (props) => {
  return (
    <div className={props.classes.group}>
      <Input {...props}/>
    </div>
  );
};

export default withStyles(styles)(InputField);