import React, { Component } from "react";
// import Drawer from "core/components/Drawer";
import { withStyles } from 'core/components/Styles';
// import List from 'core/components/List';
// import ListItem from 'core/components/ListItem';
import { NavLink } from "react-router-dom";
import { 
  Grid, 
  // Typography, 
  // Button 
} from "core/components";
import {drawerWidth, appBar} from "theme/vars"
// import dd from 'utils/detectDevice';
// import cn from "classnames";

const styles = theme => ({
  settingsDrawerPaper: {
    // height:"100vh",
    paddingTop: appBar,
    position: 'relative',
    width: drawerWidth,
    backgroundColor:"#f7f7f7"
  },
  navButton: {
    color: "#51516B",
    fontFamily : "Montserrat",
    fontWeight : 500,
    fontSize : 13,
    lineHeight : "42px",
    minHeight : 44,
    minWidth : 217,
    textAlign : "center",
    display : "inline-block",
    textDecoration :"none",

    background : "#ffffff",
    boxShadow : "0px 2px 17px rgba(0, 0, 0, 0.10861)",
    borderRadius : 17,
    "&.active": {
      color: "#4a4a4a",
      border :"2px solid #49d3b9"
    }
  },
   
  sideDrawerHeading: {
    display: "flex",
    alignItems: "center",
    padding: "0 13px",
    fontSize: 24,
    color: "#50e3c2",
    borderLeft: "5px solid #50e3c2",
    marginBottom: 8,
  },
  btnsContainer:{
    minHeight : 108
  }
})

const routes = [
 {
   name:"Payment Methods",
   link:"/settings/payment_methods",
 },
 {
   name:"Company Profile",
   link:"/settings/company_details"
 },
 {
   name:"Plan Settings",
   link:"/settings/plan_settings"
 }
]

class SettingsSideBar extends Component {
  render() {
    const { classes, isSettingsbarOpen, toggleSidebar , ...rest } = this.props;
    return (
      <Grid container 
        className={classes.btnsContainer} 
        alignItems="center" 
        justify="flex-start" 
        spacing={3}
        id="settingOptions"//
      >
        <SideBarLinks routes={routes} classes={classes} { ...rest } />
      </Grid>
    );
  }
}


const SideBarLinks = ({
  classes, 
  routes,
  // handleSteps
}) => (
  routes.map((route, key)=> (
      <Grid item key={`side-drawer-item-${key}`}>
        <NavLink
          // onClick={handleSteps}
          to={route.link} 
          className={classes.navButton}
        >
          {route.name}
        </NavLink>
      </Grid>
    )
  )
)

export default withStyles(styles)(SettingsSideBar);