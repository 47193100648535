import React from 'react'
import Stripe from "./Stripe";
import { injectStripe } from "react-stripe-elements";
const WithStripe = (Component) => {
  const InjectedWithStripeComponent = injectStripe(Component);
  return (props) => (
    <Stripe>
      <InjectedWithStripeComponent {...props}/>
    </Stripe>
  )
}

export default WithStripe;
