import React, {Component, Fragment} from 'react';
import IconButton from 'core/components/IconButton';
import Menu from 'core/components/Menu';
import MenuItem from 'core/components/MenuItem';
import Button from 'core/components/Button';
 
class PopUpMenu extends Component {
  state = {
    anchorEl: null,
  };
  btnsRef = {};

  handleClick = event => {
    event.stopPropagation();
    event.preventDefault();
    if(this.props.onClick) this.props.onClick(event);
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = event => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  selectItem = (action, index) => e => {
    if(action.onClick) action.onClick(this.props.item, index, this.state.anchorEl);
    this.handleClose(e) 
  }

  render() {
    const { anchorEl } = this.state;
    const { actions, icon, buttonProps, text, item } = this.props;
    const open = Boolean(anchorEl);

    return (
      <Fragment>
        {icon && <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onMouseDown={this.handleClick}
          {...buttonProps}
        >
          {icon}
        </IconButton>}

        {text && <Button
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onMouseDown={this.handleClick}
          variant="outlined"
          {...buttonProps}
        >
          {text}
        </Button>}
        
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
        >
        { actions && actions.map( (action, index) => {
            if(action.isRef) {
              if (action.checkForDisabled) {
                return (
                  <MenuItem
                    disabled={item[action.propToCheck]}
                    key={action.label}
                    onClick={this.selectItem(action, index)}
                    ref={(node) => this.btnsRef[index] = node}
                  >
                    {action.isSecondary(item)
                      ? action.secondaryLabel
                      : action.label}
                  </MenuItem>
                );
              }
              return (
                <MenuItem
                  key={action.label}
                  onClick={this.selectItem(action, index)}
                  ref={(node) => this.btnsRef[index] = node}
                >
                  {action.isSecondary(item)
                    ? action.secondaryLabel
                    : action.label}
                </MenuItem>
              );
            } else if (action.isForJobModal) {
              return (
                <MenuItem
                  key={action.label}
                  onClick={this.selectItem(action, index)}
                  ref={(node) => this.btnsRef[index] = node}
                >
                  {action.label}
                </MenuItem>
              )
            } else if(action.checkForDisabled){
              if (action.disableButton) {
                if(!action.checkDisableButton(item)){
                  return (
                    <MenuItem
                    key={action.label}
                    onClick={this.selectItem(action, index)}
                    ref={(node) => this.btnsRef[index] = node}
                  >
                    {action.label}
                  </MenuItem>
                );
                } else {
                  return ""
                }
              }
              return (
                <MenuItem
                    key={action.label}
                    onClick={this.selectItem(action, index)}
                    ref={(node) => this.btnsRef[index] = node}
                    disabled={item[action.propToCheck]}
                  >
                    {action.label}
                </MenuItem>
              );
            } else if(action.checkForRender) {
                if(action.renderAction(item)){
                  return ( 
                    <MenuItem
                      key={action.label}
                      onClick={this.selectItem(action, index)}
                      ref={(node) => this.btnsRef[index] = node}
                    >
                      {action.label}
                    </MenuItem>
                  )
                } else {
                  return ""
                }
            } else {
              return (
                <MenuItem
                  key={action.label}
                  onClick={this.selectItem(action, index)}
                  ref={(node) => this.btnsRef[index] = node}
                >
                  {action.label}
                </MenuItem>
              )
            }
          })}
        </Menu>
      </Fragment>
    );
  }
}

export default PopUpMenu;
