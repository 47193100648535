import React, { Component } from "react";
import ListInvoice from '../components/ListInvoice'
import { connect } from 'react-redux'
import axios from 'utils/http';
// import _ from "lodash";
import storage from "../utils/Storage";
import { setMsgAndShow } from '../store/actions/popup'
import { toggleLoader } from "../store/actions/app";
// form container

import { withMixpanel } from "../utils/react-mixpanel";
import getTotalPagesCount from "../utils/getTotalPagesCount";
class ListInvoiceDashboardContainer extends Component {
  state = {
    q: "",
    currentPage: 0,
    totalRows: 0,
    page: 1,
    invoicesList: [],
    limit: 10,
  }

  getInvoices = async () => {
    const { toggleLoader, setMsgAndShow } = this.props;

    toggleLoader()
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/invoices/get_all`, {
        params: {
          q: this.state.q,
          limit: this.state.limit,
          // page: this.state.currentPage
        }
      });
      const { invoices, total } = response.data;
      this.setState({ invoicesList: invoices, totalRows: total });
      toggleLoader()
    } catch (error) {
      const { response } = error;
      setMsgAndShow(response ? response.data.message : "Error while getting invoices");
      toggleLoader();
    }
  }

  componentDidMount() {
    const { trackPageLoad } = this.props;
    trackPageLoad("Invoice_Dashboard");
    this.getInvoices();
  }

  editInvoice = (e) => {
    storage.set('currentInvoice', JSON.stringify(e));
    this.props.history.push(`/invoices/${e.number}`);
  }

  markAsPaid = async (invoice) => {
    const { toggleLoader, setMsgAndShow } = this.props
    toggleLoader();
    try {
      let { invoicesList } = this.state;
      const res = await axios.put(`${process.env.REACT_APP_API_PREFIX}contractor/invoices/${invoice._id}/mark_as_paid`);
      const { invoice: updatedInvoice } = res.data;
      invoicesList = invoicesList.map((i) => {
        if (i._id === invoice._id) {
          return {
            ...i,
            isPaid: true,
            paymentStatus: "paid",
            paidOn: updatedInvoice.paidOn
          }
        }
        return i;
      })
      this.setState({
        invoicesList
      })
      setMsgAndShow("Invoice marked as paid!");
      toggleLoader()
    } catch (error) {
      const { response } = error;
      setMsgAndShow(response ? response.data.message : "Error while marking invoice as paid!");
      toggleLoader();
    }
  }

  markAsUnpaid = async (invoice) => {
    const { toggleLoader, setMsgAndShow } = this.props
    toggleLoader();
    try {
      let { invoicesList } = this.state;

      await axios.put(`${process.env.REACT_APP_API_PREFIX}contractor/invoices/${invoice._id}/mark_as_unpaid`);

      invoicesList = invoicesList.map((i) => {
        if (i._id === invoice._id) {
          return {
            ...i,
            isPaid: false,
            paymentStatus: "pending",
            paidOn: null
          }
        }

        return i;
      })
      this.setState({
        invoicesList
      })
      setMsgAndShow("Invoice marked as Unpaid!");
      toggleLoader()
    } catch (error) {
      const { response } = error;
      setMsgAndShow(response ? response.data.message : "Error while marking invoice as unpaid!");
      toggleLoader();
    }
  }

  componentDidUpdate(prevProps) {
    const { contId: prevConId } = prevProps;
    const { contId } = this.props;
    if (prevConId !== contId) {
      this.getInvoices();
    }
  }


  goTo = page => e => {
    const { history: { push }, trackButtonEvent } = this.props;
    trackButtonEvent("Add_New_Invoice", window.location.pathname);
    push(page);
  }

  handlePageChange = async (_, pageNumber) => {
    const { toggleLoader, setMsgAndShow, trackApiEvent } = this.props;
    toggleLoader();
    try {

      const { q } = this.state;
      const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/invoices/get_all`, {
        params: {
          q,
          page: pageNumber + 1,
          limit: this.state.limit
        }
      });
      const { invoices, total, page } = response.data;
      this.setState({
        invoicesList: invoices,
        totalRows: total,
        currentPage: page - 1,
        page,
      });
      trackApiEvent("Get_Invoices", "GET", `${process.env.REACT_APP_API_PREFIX}contractor/invoices/get_all`, "OK", window.location.pathname);
      toggleLoader();
    } catch (error) {
      const { response } = error;
      console.error("error is ", error); // eslint-disable-line
      let msg = "Error Occurred!";
      if (response && response.data) {
        msg = response.data.message ? response.data.message : "Error Occurred!"
      }
      setMsgAndShow(msg);
      trackApiEvent("Get_Invoices", "GET", `${process.env.REACT_APP_API_PREFIX}contractor/invoices/get_all`, msg, window.location.pathname);
      toggleLoader();
    }
  }


  getListByPageAndLimit = async (pageNumber, newLimit) => {
    const { toggleLoader, setMsgAndShow, trackApiEvent } = this.props;
    try {
      const { q, page:storedPage, limit } = this.state;
      const pageNumberToSend  = pageNumber ? pageNumber : storedPage;
      const limitToSend = newLimit ? newLimit : limit;
      const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/invoices/get_all`, {
        params: {
          q,
          page: pageNumberToSend,
          limit: limitToSend
        }
      });
      const { invoices, total, page } = response.data;
      this.setState({
        invoicesList: invoices,
        totalRows: total,
        currentPage: page - 1,
        page,
        limit:limitToSend,
      });
      trackApiEvent("Get_Invoices", "GET", `${process.env.REACT_APP_API_PREFIX}contractor/invoices/get_all`, "OK", window.location.pathname);
      toggleLoader();
    } catch (error) {
      const { response } = error;
      console.error("error is ", error); // eslint-disable-line
      let msg = "Error Occurred!";
      if (response && response.data) {
        msg = response.data.message ? response.data.message : "Error Occurred!"
      }
      setMsgAndShow(msg);
      trackApiEvent("Get_Invoices", "GET", `${process.env.REACT_APP_API_PREFIX}contractor/invoices/get_all`, msg, window.location.pathname);
      toggleLoader();
    }
  }

  handleLimitChange = async (newLimit) => {
    // this.setState({ limit:newLimit },()=> console.log("new state limit: ",this.state.limit)); 
    const { toggleLoader, setMsgAndShow, trackApiEvent } = this.props;
    toggleLoader();
    try {
      const { q, page:storedPage } = this.state;

      const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/invoices/get_all`, {
        params: {
          q,
          page: storedPage,
          limit: newLimit
        }
      });
      const { invoices, total, page } = response.data;
      const totalPages = getTotalPagesCount(total,newLimit);

      if(storedPage > totalPages){
        this.getListByPageAndLimit(totalPages, newLimit);
      } else {
        this.setState({
          invoicesList: invoices,
          totalRows: total,
          limit: newLimit,
          currentPage: page - 1,
        });
        trackApiEvent("Get_Invoices", "GET", `${process.env.REACT_APP_API_PREFIX}contractor/invoices/get_all`, "OK", window.location.pathname);
        toggleLoader();
      }

    } catch (error) {
      const { response } = error;
      let msg = "Error Occurred!";
      if (response && response.data) {
        msg = response.data.message ? response.data.message : "Error Occured!"
      }
      setMsgAndShow(msg);
      trackApiEvent("Get_Invoices", "GET", `${process.env.REACT_APP_API_PREFIX}contractor/invoices/get_all`, msg, window.location.pathname);
      toggleLoader();
    }

  }

  actions = [
    {
      label: "Edit",
      onClick: this.editInvoice
    },
    {
      label: "Mark as Paid",
      onClick: this.markAsPaid,
      checkForDisabled: true,
      disableButton: true,
      checkDisableButton: (item) => item.isPaid && item.paymentStatus === "paid"
    },
    {
      label: "Mark as Unpaid",
      onClick: this.markAsUnpaid,
      checkForDisabled: true,
      disableButton: true,
      checkDisableButton: (item) => !item.isPaid && item.paymentStatus === "pending"
    }
  ];
  render() {

    return (
      <div>
        <ListInvoice
          invoicesList={this.state.invoicesList}
          currentPage={this.state.currentPage}
          totalRows={this.state.totalRows}
          limit={this.state.limit}
          actions={this.actions}
          handlePageChange={this.handlePageChange}
          handleLimitChange={this.handleLimitChange}
          goTo={this.goTo}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    // form,
    contractor
  } = state;

  let contId = "";

  if (contractor && contractor.userInfo) {
    contId = contractor.businessDetails._id;

    return {
      contId
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleLoader: () => dispatch(toggleLoader()),
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg))
})

const connectRedux = connect(mapStateToProps, mapDispatchToProps)(ListInvoiceDashboardContainer);

export default withMixpanel(connectRedux);
