import React, { Component } from 'react'

 class RowFormField extends Component {

  onValueChange = (e) => {
    const { input, onChange } = this.props;
    const optionValue = e.target.value;
    if(input && input.onChange) {
      input.onChange(optionValue)
    } else {
      onChange(optionValue)
    }
  }
  render() {

    const { input, options, itemComponent: Component } = this.props;
    return (
        options && options.map((o,index) => {
          const optionKey = `option-key-${index}`;
          return (
            <Component 
              item={o}
              key={optionKey}
              selectedValue={input.value}
              forKey={optionKey}
              onValueChange={this.onValueChange}
              input={input}
            />
          )
        })
    )
  }
}

export default RowFormField

