import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "core/components/Grid";
import Loader from "../ProgressCircle";
import { withStyles } from "core/components/Styles";

import DataItem from "../DataItem";

const styles = theme => ({
  jobslistCont: {
    marginTop: 20
  },
  overLay: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    background: "rgba(255, 255, 255, 0.75)",
    height: "100%"
  },
  textCenter: {
    textAlign: "center"
  }
});

class DataList extends Component {
  render() {
    const {
      errMsg,
      classes,
      dataList,
      dataItemObjProps,
      actions,
      marginTop,
      activeIndex,
      getDetails,
      // showBtn,
      showDialogLoader
    } = this.props;

    return (
      <Grid
        item
        style={{
          marginTop: marginTop || 0,
          position: "relative"
        }}
      >
        {showDialogLoader && <Loader zIndex={999}/>}
        {dataList &&
          dataList.map((dataObj, index) => {
            return (
              <DataItem
                activeIndex={activeIndex}
                getDetails={getDetails}
                dataItemIndex={index}
                dataItemObj={dataObj}
                dataItemObjProps={dataItemObjProps}
                actions={actions}
                key={`data-list-${index}`}
              />
            );
          })}

        {!dataList || dataList.length === 0 ? (
          <p className={classes.textCenter}>{errMsg}</p>
        ) : null}

        {activeIndex >= 0 &&
        activeIndex !== null &&
        activeIndex !== undefined &&
        activeIndex !== "" ? (
          <span className={classes.overLay} />
        ) : null}
      </Grid>
    );
  }
}


const mapStateToProps = (state) => ({
  showDialogLoader: state.app.showDialogLoader
})


const WithStylesDataList = withStyles(styles)(DataList);
export default connect(mapStateToProps)(WithStylesDataList);
