import React from "react";
import Typography from "core/components/Typography";
import Grid from "core/components/Grid";
import Button from "core/components/Button";
import { withStyles } from "core/components/Styles";
import List from "core/components/List";
import ListItem from "core/components/ListItem";
// import ListItemSecondaryAction from "core/components/ListItemSecondaryAction";
import ListItemText from "core/components/ListItemText";
// import IconButton from "core/components/IconButton";
// import Card from "core/components/Card";
// import CardContent from "core/components/CardContent";
import { AccountFormDialog } from "../AccountForm";
import ProgressCircle from "../ProgressCircle";
import Zbutton from "core/components/Button";

import Joyride from "react-joyride";
import { paymentMethodSteps } from "../TourGuide/TourGuideSteps";
import {tooltipButtonColor} from "../../theme/colors";

const styles = () => ({
  paymentsContainer: {
    marginTop: 50,
    padding: 10
  },
  paymentItem:{
    marginBottom: 8,
    boxShadow:"0px 0px 3px 0px rgba(0,0,0,0.2)"
  }
});
const Payments = props => {
  const {
    classes,
    openAccountForm,
    isAccountFormOpen,
    handleAccountFormClose,
    onPaymentDetailsAdded,
    paymentDetailsList,
    load,
    makeAccountDefault,
    makingDefaultId,

    runGuide,
    handleJoyrideCallback,
    getHelpers
  } = props;

  return (
    <div>
      <Joyride
        callback={handleJoyrideCallback}
        run={runGuide}
        continuous={true}
        getHelpers={getHelpers}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={paymentMethodSteps}
        styles={{
          options: {
            primaryColor: tooltipButtonColor    
          }
        }}
      /> 
      <Grid container justify="space-between" alignContent="center">
        <Grid item id="addPaymentBtn">
          <Zbutton onClick={openAccountForm} variant='contained' color="primary" gradient>
            Add Payment Method
          </Zbutton>
        </Grid>
      </Grid>
      <Grid item className={classes.paymentsContainer} id="paymentMethods">
        <Typography variant="inherit" gutterBottom>
          Payment Methods:-
        </Typography>
        <List>
          {paymentDetailsList.map((pm, ki) => {
            const accountNumberLength = pm.bankAccountNumber.length;
            const startFrom = accountNumberLength - 4;
            const displayAccountNumber = pm.bankAccountNumber.slice(startFrom, accountNumberLength);
            return (
              <ListItem key={`payment-method-item-${ki}`} className={classes.paymentItem}>
                <ListItemText>
                  <Grid container spacing={6} alignItems="center">
                    <Grid item container spacing={6} xs>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {`${"x".repeat(startFrom)}${displayAccountNumber}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" gutterBottom>
                          {pm.bankName}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Button
                        disabled={pm.isPrefered}
                        variant="raised"
                        color="secondary"
                        onClick={makeAccountDefault(pm)}
                      >
                        {pm._id === makingDefaultId && load && (
                          <ProgressCircle size={20}/>
                        )}
                        Make it default
                      </Button>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      <AccountFormDialog
        isOpen={isAccountFormOpen}
        handleClose={handleAccountFormClose}
        onPaymentDetailsAdded={onPaymentDetailsAdded}
      />
    </div>
  );
};

export default withStyles(styles)(Payments);
