import React, { Fragment } from "react";
import Grid from "core/components/Grid";
// import Button from "core/components/Button";
import { Field } from "redux-form";

import Typography from "core/components/Typography";
import { withStyles } from "core/components/Styles";
import {
  MaterialTextField,
  // MaterialSelectField,
  AdvanceSelectCompanyProfile,
  // RadioField,
  RadioSelectBtn
} from "../FormField";
// import DatePicker from "../DatePicker";
import {required, minLength, maxLength, email} from "../FormField/Validations";
// import cn from "classnames";
import states from "data/statesTitleCase.json";
import FormInputField from "../FormField/FormInputField";

const nums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const numObjOptions = nums.map(num => {
  return {
    label: num,
    value: num
  };
});

const minLength1 = minLength(1);
const maxLength_10 = maxLength(10);
const minLength_10 = minLength(10);
const maxLength_5 = maxLength(5);
const minLength_5 = minLength(5);

const stateObjOptions = states.map(state => {
  return {
    label: state.name,
    value: state.abbreviation
  };
});

const businessTypes = [
  {
    "label":"Commercial",
    value:"commercial",
  },
  {
    label:"Domestic",
    value:"domestic"
  },
  {
    label:"Carpet",
    value:"carpet"
  }
]

const styles = theme => ({
  gradientText :{
    fontWeight : 600,
    fontSize : 25,
    lineHeight : "49px",
    color : "transparent",
    "-webkit-background-clip" : "text",
    backgroundImage : "linear-gradient(103.34deg, rgba(70, 46, 180, 0.8) 2.88%, rgba(176, 98, 210, 0.8) 83.6%)"
  },
  businessTypeAndName :{
    background : "#fff",
    borderRadius : 8,
    height : 117,
    border: "1px solid #d8d8d8",
    padding : "0px 40px",
    marginBottom: 20
  },
  margBottom :{
    width: "calc(100% + 64px)",
    marginBottom: 8,
    marginLeft:-32,
    marginRight:-32,
    marginTop:-32,
    "& > div": {
      padding: "20px 32px !important"
    }
  },
  
  businessName : {
    background: "#ffffff",
    border: "1px solid #D8D8D8",
    borderRadius: 3,
    height:65,
    padding : "12px 12px 4px 20px",
    paddingBottom: 4,
    paddingLeft:18
  },
  allInputsMainCont :{
    height : "100%",
    width : "100%",
    padding : "20px 0 17px 0",
    margin : 0,
    background : "#ffffff",
    borderRadius : 3
  },
  labelStyle : {
    color : "#4f4f4f",
    fontSize : 10,
    lineHeight : "18px",
    textTransform : "uppercase"
  },
  // updatedZindex:{
  //   position:"relative",
  //   zIndex: 50
  // }
});

const BasicInfoForm = ({
  classes,
  handleSubmit,
  handleSave,
  options,
  openUploader,

  trackRadioInput,
  trackInput,

  trackSelectedValue
}) => {
  return (
    <Fragment>
      <Grid 
        container 
        direction="row"
        alignItems="center" 
        justify="space-between"
        className={classes.businessTypeAndName}
      >
        <Grid item xs={12} md={7}>
          <span className={classes.gradientText} >You are </span> 
          <Field
            component={RadioSelectBtn}
            name="legalEntity"
            validate={[required]}
            inRow
            options={[
              { title: "An Individual", value: "individual" },
              { title: "A Company", value: "company" }
            ]}
            //InputProps={{disableUnderline :true}}
            onBlur={trackRadioInput("Business_Entity")}
          />
        </Grid>
        
        <Grid item xs={12} md={5} className={classes.businessName}>
          <Typography className={classes.labelStyle}>
            Business Name 
          </Typography>
          <Field
            type="text"
            id="businessName"
            name="businessName"
            placeholder="Enter Business Name..."
            component={MaterialTextField}
            size={4}
            validate={[required]}
            InputProps={{disableUnderline :true}}

            onBlur={trackInput("Business_Name")}
            // fixedstyle ="true"
          />
          </Grid>
      </Grid>
      
      
      <Grid container 
        alignItems="center" 
        justify="space-around"
        direction="column"
        className={classes.allInputsMainCont }
      >
        <Grid container alignItems="center" justify="center" className={classes.margBottom} spacing={10}>
          <Grid item md={4} xs={12} className={classes.updatedZindex}>
            <FormInputField 
              label="Select Business Type"
              name="businessType"
              component={AdvanceSelectCompanyProfile}
              isMulti={true}
              close={false}
              options={businessTypes}
              validate={[required, minLength1]}
              backgroundColor="transparent"
              material 
              InputProps={{disableUnderline :true}}
              trackSelected={trackSelectedValue}
              trackName="Select_Business_Type"  
            />
          </Grid>
          <Grid item  md={4} xs={12} className={classes.updatedZindex}>
            <FormInputField label="No. of Employees"
              type="text"
              id="businessType"
              name="numberOfEmployees"
              component={AdvanceSelectCompanyProfile}
              options={numObjOptions}
              backgroundColor="transparent"
              validate={[required]}
              material
              //InputProps={{disableUnderline :true}}
              disableUnderline  
              

              trackSelected={trackSelectedValue}
              trackName="Number_Of_Employees"
           />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormInputField label="Unit"
              type="text"
              id="businessUnit"
              name="businessAddress.unit"
              component={MaterialTextField}
              InputProps={{disableUnderline :true}}
              setBorder 
              trackField={ trackInput}    
            />
          </Grid>       
        </Grid>            

        <Grid container alignItems="center" justify="center" className={classes.margBottom} spacing={10}>
          
          <Grid item md={4} xs={12}>
            <FormInputField label="City"
              type="text"
              id="businessCity"
              name="businessAddress.city"
              component={MaterialTextField}
              validate={[required]}
              InputProps={{disableUnderline :true}}
              setBorder

              trackField={ trackInput}
            />
          </Grid>       

          <Grid item md={4} xs={12}>
            <FormInputField label="Street"
              type="text"
              id="businessStreet"
              name="businessAddress.street"
              component={MaterialTextField}
              validate={[required]}
              InputProps={{disableUnderline :true}}
              setBorder  
              
              trackField={ trackInput}  
            />
          </Grid>     

          <Grid item md={4} xs={12} className={classes.updatedZindex}>
            <FormInputField label="State"
              type="text"
              id="businessState"
              name="businessAddress.state"
              component={AdvanceSelectCompanyProfile}
              options={stateObjOptions}
              backgroundColor="transparent"
              validate={[required]}
              material
              InputProps={{disableUnderline :true}}
              trackSelected={trackSelectedValue}
              trackName="State"
            />
          </Grid>    
        </Grid>

        <Grid container alignItems="center" justify="center" className={classes.margBottom} spacing={10}>

          <Grid item md={4} xs={12}>
            <FormInputField label="Contact Number"
              type="tel"
              id="businessPhone"
              name="businessPhone"
              component={MaterialTextField}
              validate={[required, maxLength_10, minLength_10]}
              InputProps={{disableUnderline :true}}
              setBorder  
              trackField={ trackInput}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <FormInputField label="ZipCode"
              type="text"
              id="businessZipcode"
              name="businessAddress.zipcode"
              component={MaterialTextField}
              validate={[required, maxLength_5, minLength_5]}
              InputProps={{disableUnderline :true}}
              setBorder
              trackField={ trackInput}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <FormInputField label="Website (optional)"
              type="text"
              id="businessWebsite"
              name="businessWebsite"
              component={MaterialTextField}
              InputProps={{disableUnderline :true}}
              setBorder 
              trackField={ trackInput}
            />
          </Grid>

        </Grid>

        <Grid container alignItems="center" justify="flex-start" className={classes.margBottom} spacing={10}>
          <Grid item md={4} xs={12}>
            <FormInputField label="Email"
              type="text"
              id="businessEmail"
              name="businessEmail"
              validate={[email,required]}
              component={MaterialTextField}
              InputProps={{disableUnderline :true}}
              setBorder  
              trackField={ trackInput}
            />
          </Grid>
        </Grid>

      </Grid>
    </Fragment>
  );
};


export default withStyles(styles)(BasicInfoForm);