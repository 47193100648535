import React from "react";
import DialogTitle from "core/components/DialogTitle";
import DialogContent from "core/components/DialogContent";
import DialogActions from 'core/components/DialogActions';
import Grid from "core/components/Grid";
// import Button from "core/components/Button";
import ZButton from "core/components/Button";
import ZTypography from "core/components/Typography";
import {
  AdvanceSelectCompanyProfile, 
  FormInputField,
  MaterialTextField
} from "../FormField";
import Loader from "../ProgressCircle";
import { required, number, minLength, maxLength, email } from "../FormField/Validations";
import { Form } from "redux-form";
import { withStyles } from "core/components/Styles";
import states from "data/statesTitleCase.json";

const stateObjOptions = states.map(state => {
  return {
    label: state.name,
    value: state.abbreviation
  };
});

const styles = theme => ({
  modalCont: {},
  cleaningTypeHeading: {
    marginLeft: 5,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16
  },
  title: {
    marginLeft: 5,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16
  },
  selectorCont: {
    border: "1px solid #4a4a4a",
    maxWidth: 150,
    fontSize: "20px",
    borderRadius: ".3rem",
    overflow: "hidden",
    margin: 0,
    marginTop: 5,
    padding: 0,
    "& .dec, .inc": {
      background: "#f8f8f8",
      width: "30%",
      cursor: "pointer",
      textAlign: "center",
      padding: "0.3rem 0.5rem",
      display: "inline-block"
    },
    "& span:first-child": {
      borderRight: "1px solid #4a4a4a"
    },
    "& span:last-child": {
      borderLeft: "1px solid #4a4a4a"
    }
  },
  disp: {
    width: "40%",
    textAlign: "center",
    display: "inline-block"
  },
  propertyDetailsHeading: {
    marginLeft: 5,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16
  },
  setHeight:{
    height: 45
  }
});

const minLength_10 = minLength(10);
const maxLength_10 = maxLength(10);

const minLength_5 = minLength(5);
const maxLength_5 = maxLength(5);

const CustomerForm = ({ handleClose, handleSubmit, classes, submitData, showDialogLoader }) => {

  return (
    <Form onSubmit={handleSubmit(submitData)}>

      <DialogTitle id="form-dialog-title">
        <ZTypography color="secondary" gradient bold fontSize={25}>
          Add Customer 
        </ZTypography>
      </DialogTitle>
      <DialogContent>
        { showDialogLoader &&  <Loader zIndex={999} />}
        <Grid className={classes.inputsCont}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormInputField label="First Name"
                type="text"
                id="fname"
                name="fname"
                placeholder="Enter First Name"
                component={MaterialTextField}
                validate={[required]}
                InputProps={{disableUnderline :true}}
                setBorder  
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInputField label="Last Name"
                type="text"
                id="lname"
                name="lname"
                placeholder="Enter Last Name"
                component={MaterialTextField}
                validate={[required]}
                InputProps={{disableUnderline :true}}
                setBorder  
              />
            </Grid> 
            <Grid item xs={8}>
              <FormInputField label="Address"
                type="text"
                id="serviceAddress.address"
                name="serviceAddress.address"
                placeholder="Enter Address"
                component={MaterialTextField}
                validate={[required]}
                InputProps={{disableUnderline :true}}
                setBorder  
              />
            </Grid>

            <Grid item xs={12} md={4} >
              <FormInputField 
                label= "State"
                type="text"
                id="state"
                name="serviceAddress.state"
                component={AdvanceSelectCompanyProfile}  
                options={stateObjOptions}
                validate={[required]}
                backgroundColor="transparent"
                material 
                InputProps={{disableUnderline :true}}
              />
            </Grid>
                
            <Grid item xs={12} md={4}>
              <FormInputField label="City"
                type="text"
                id="serviceAddress.city"
                name="serviceAddress.city"
                placeholder="Name Of City"
                component={MaterialTextField}
                validate={[required]}
                InputProps={{disableUnderline :true}}
                setBorder  
              />
            </Grid>
            
            <Grid item xs={12} md={4}>
              <FormInputField label="Phone" 
                id="phone"
                name="phone"
                placeholder="Enter Phone Number"
                component={MaterialTextField}
                validate={[required,number,minLength_10,maxLength_10]}
                InputProps={{disableUnderline :true}}
                setBorder  
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInputField label="Email"
                type="text"
                id="email"
                name="email"
                placeholder="Enter Email Address"
                component={MaterialTextField}
                validate={[required,email]}
                InputProps={{disableUnderline :true}}
                setBorder  
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInputField label="Zipcode"
                type="text"
                id="serviceAddress.zipcode"
                name="serviceAddress.zipcode"
                placeholder="Enter Zipcode"
                component={MaterialTextField}
                validate={[required,number,minLength_5,maxLength_5]}
                InputProps={{disableUnderline :true}}
                setBorder  
              />
            </Grid>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <ZButton onClick={handleClose}>
          Cancel
        </ZButton>
        <ZButton type="submit" color="primary" gradient >
          Add Customer
        </ZButton>
      </DialogActions>
    </Form>
  );
};


export default withStyles(styles)(CustomerForm);
