import { createMuiTheme } from 'core/components/Styles';

export default createMuiTheme({
  palette: {
    primary: {
      light: "#77e9cf",
      main: "#55e4c3",
      dark: "#3b9f88",
      contrastText: "#fff"
    },
    secondary: {
      light: "#7c33c3",
      main: "#492fb5",
      dark: "#40007e",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiButton: {
      root:{
        
        textTransform: "capitalize",
      },
      contained:{
        boxShadow:"none"
      }
    },
    MuiTypography:{
      root:{
        fontFamily:"Poppins",
      },
      body1:{
        color:"#4a4a4a",
        fontFamily:"Poppins",
        fontSize:"1rem"
      },
      body2:{
        fontSize:"1rem"
      },
      subheading:{
        fontFamily:"Poppins",
        fontSize: 18
      },
      title:{
        fontFamily:"Poppins",
      }
    },
    MuiTableCell:{
      body:{
        fontSize: 16
      },
      root:{
        padding:"4px 10px 4px 24px"
      }
    },
    MuiStepLabel:{
      // iconContainer :{
      //   display :"none"
      // },
      // active: {
      //   boxShadow : "0px 9px 33px rgba(0, 0, 0, 0.09)",
      //   borderBottom : "3px solid #50e3c2"
      // },
      
    },
    MuiStepConnector: {
      lineHorizontal: {
        // display: "none"
      } 
    }
  }
})