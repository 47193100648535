import React from "react";
import { Grid, Typography } from "core/components";
import { capitalizeFirstLetterOnly } from "../../utils/capitalize";
import { withStyles } from "core/components/Styles";
import cn from "classnames";

const styles = (theme) => ({
  badgeCont: {
    borderRadius: 19,
    border:"1px solid #52575a",
    padding: "5px 10px 5px 10px",
    [theme.breakpoints.down('sm')]: {
      flexWrap: "nowrap",
      border: 0
    }
  },
  badgeDot:{
    height: 7.5,
    width: 7.5,
    borderRadius:"50%",
    background:"#52575a"
  },
  default:{
    color: "#52575a"
  },
  confirmed:{
    color: "#f5a623"
  },
  ongoing:{
    color: "#4a90e2"
  },
  completed:{
    color: "#d0021b"
  },
  dotCompleted:{
    background: "#d0021b"
  },
  dotConfirmed:{
    background:"#f5a623"
  },
  dotOngoing:{
    background: "#4a90e2"
  },
  badgeCompleted: {
    borderColor: "#d0021b"
  },
  badgeConfirmed:{
    borderColor: "#f5a623"
  },
  badgeOngoing:{
    borderColor:"#4a90e2"
  },
  dotItem: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  bookingStatus:{
    fontSize:12
  }
})
const BookingStatusBadge = ({classes, bookingStatus}) => {

  let bookingStatusStyles = "";
  // let badgeDotStyles = "";
  let badgeContStyles = "";

  switch(bookingStatus){
    case "completed":
      bookingStatusStyles = [classes.completed];
      // badgeDotStyles = [classes.dotCompleted];
      badgeContStyles = [classes.badgeCompleted]
      break;
    case "ongoing":
      bookingStatusStyles = [classes.ongoing];
      // badgeDotStyles = [classes.dotOngoing];
      badgeContStyles = [classes.badgeOngoing]
      break;
    case "confirmed":
      bookingStatusStyles = [classes.confirmed];
      // badgeDotStyles = [classes.dotConfirmed];
      badgeContStyles = [classes.badgeConfirmed]
      break;
    default:
      // do nothing
  }
  return (
    <Grid className={cn(classes.badgeCont, ...badgeContStyles)}>
      <Typography align="center" className={cn(classes.bookingStatus, ...bookingStatusStyles)}>
        {capitalizeFirstLetterOnly(bookingStatus)}
      </Typography> 
    </Grid>
  )
}



export default withStyles(styles)(BookingStatusBadge);