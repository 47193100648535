import React, { Component } from "react";
import DatePickerModal from "./DatePickerModal";
import moment from "moment-timezone";
import {InputField, MaterialTextField} from "../FormField";

import Icon from "core/components/Icon";
// const _img_time = require("../../images/utils/calendar.png");
class DatePicker extends Component {

  state = {
    isOpen: false,
    height: window.innerHeight / 2 
  }

  componentDidMount() {
    this.setState({height: window.innerHeight / 2});
  }

  toggleModal = () => {
    this.setState({isOpen: !this.state.isOpen});
  }
  openModal = () => {
    this.setState({isOpen: true});
  }
  closeModal = () => {
    this.setState({isOpen: false});
    this.onChange(null);
  }

  handleDateChange = (date)=>{
    date = moment(date).format("YYYY-MM-DD");

    if(this.props.trackDateInput && this.props.trackTitle ){
      this.props.trackDateInput(this.props.trackTitle,date);
    }

    this.onChange(date);
  }

  onChange = item => {
    const {input} = this.props;
    input.onChange(item); 
  }
  render(){
    const {
      input, 
      type, 
      id, 
      meta, 
      material, 
      leftIcon = true, 
      shrink, 
      isNotMinDate, 
      label,
      placeholder,
      rightIcon
    } = this.props;
    const today = new Date();
    const Field = material ? MaterialTextField : InputField;
    return (
      <div>
        <Field 
          leftIcon={ leftIcon && <Icon color="#646464" lineHeight="inherit">invoice_calender</Icon> } 
          rightIcon={ rightIcon && <Icon lineHeight="inherit">{rightIcon}</Icon> }
          label={label}
          shrink={shrink}
          input={input}
          meta={meta}
          type={type}
          id={id}
          readOnly
          onClick={this.openModal}
          placeholder={placeholder}
        />

        <DatePickerModal 
          height={this.state.height}
          minDate={isNotMinDate ? null : today}
          onSelect={this.handleDateChange}
          toggle={this.toggleModal}
          handleCancel={this.closeModal}
          {...this.state}
        />
      </div>
    );
  }
}


export default DatePicker;