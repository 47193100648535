import React, { Component } from "react";
import { connect } from "react-redux";
import  { ChangeInJobAlert } from "../components/ChangeInJobAlert";
import { openChangesModal } from "../store/actions/jobChanges";

class ChangeInJobAlertContainer extends Component {
  openJobChangesModal = (obj) => () => {
    this.props.openChangesModal(obj)
  }
  render(){
    return (
      <ChangeInJobAlert 
        openJobChangesModal={this.openJobChangesModal} 
        {...this.props}
      />
    )
  }
}



const mapDispatchToProps = (dispatch) => ({
  openChangesModal: (obj) => dispatch(openChangesModal(obj))
})
export default connect(null, mapDispatchToProps)(ChangeInJobAlertContainer)