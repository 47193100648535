import React, { Component } from "react";
import { connect } from "react-redux";
import Card from "core/components/Card";
import Grid from "core/components/Grid";
import Tooltip from "core/components/Tooltip";
import { withStyles } from "core/components/Styles";
import CardContent from "core/components/CardContent";
import Typography from "core/components/Typography";
import Button from "@material-ui/core/Button";
// import SvgIcon from 'core/components/SvgIcon';
import momemt from "moment-timezone";
import cn from "classnames";
import { setAndShowAdditionalBookings } from "../../store/actions/jobChanges";
import Icon from "core/components/Icon";
import {capitalizeFirstLetterOnly} from "utils/capitalize";
import domesticRequest from "assets/images/svg/domestic_request.svg";
import commercialRequest from "assets/images/svg/commercial_request.svg";
import carpetRequest from "assets/images/svg/carpet_request.svg";
import rings from "assets/images/svg/rings.svg";
import purpleCircles from "assets/images/svg/purple_circles.svg";
import brownCircles from "assets/images/svg/brown_circles.svg";
import greenCircles from "assets/images/svg/green_circles.svg";
import checkOrderType from "../JobDetailsModal/checkOrderType";





const circles = {
  "domestic":greenCircles,
  "carpet":brownCircles,
  "commercial":purpleCircles
}
const styles = theme => ({
  jobOfferItem: {
    height: "18.7rem",
    width: "33rem",
    cursor: "pointer",
    padding : "0px !important",
    marginBottom : 0,
    marginTop : "1rem",
    marginLeft : "2.4rem",
    // boxShadow: "-3px 2px 2px 0 rgba(206, 206, 206, 0.16)"
    // [theme.breakpoints.down('sm')]: {
    //   minWidth: "100%"
    // }
  },
  rehiredJob:{
    backgroundColor:"#9fffea47"
  },
  jobOfferCard: {
    height: "inherit",
    width: "inherit",
    boxShadow:"0px 2px 6px rgba(0, 0, 0, 0.128223)",
    borderBottomLeftRadius: 12,
    borderTopLeftRadius: 12,
    borderBottomRightRadius: 12,
    background: "linear-gradient(#54cdb9 82.9%, #1097a7 102%)",
    overflow:"visible"
  },
  commercialOfferCardBG:{
    background: "linear-gradient(#8850c3 82.9%, #7147b9 102%)",
  },
  carpetOfferCardBG:{
    background: "linear-gradient(#C64D3C 82.9%, #703A31 102%)",
  },
  btn:{
    display:"flex",
    alignItems:"center",
    width:"11rem",
    height:"1.9rem",
    minHeight:0,
    bottomLeftBorderRadius: 7,
    fontSize: ".875rem",
    fontFamily:"Poppins",
    padding:0,
    color:"#fff",
    "&:hover":{
      background: "transparent"
    }
  },
  reject:{
    borderRadius :0,
    borderLeft: ".9px solid #80e3e3",
    borderRight: ".9px solid #80e3e3",
    color: "#50e3c2"
  },
  dangerBtn:{
    backgroundColor:"#fff",
    border:"1px solid #d0021b",
    color:" #d0021b",
    marginLeft: 30,
  },
  cardContentRoot :{ 
    width:"inherit",
    height:"15.56rem",
    padding : "0 0",
    backgroundColor : "#fff",
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderTopLeftRadius: 10
  },

  jobInfo: {
    width  : "inherit",
    paddingTop : "1.2rem"
  },
  jobDescStyle :{
    height: "3.95rem",
    backgroundColor:"#fff",
    marginBottom : 0,
    padding: 0,
    borderTopLeftRadius: 10
  },
  infoStyle:{
    fontSize : ".86rem",
    color:"#000000",
    paddingLeft : 5
  },
  iconStyle:{
    color: "#9b9b9b",
    paddingBottom: ".1rem"
  },
  iconBtnColor: {
    color: "#fff",
    marginRight: ".3rem"
  },
  typoStyle: {
    color: "#fff",
    fontSize : ".92rem",
  },
  jobSummaryHolder : {
    height: "11.6rem"
  },
  jobOptions :{
    height:"3.1rem"
  },
  titleStyle:{
    color : "#9B9B9B",
    fontSize : ".86rem",
    fontWeight : 400,
  },
  titlePadding:{
    paddingBottom : ".4rem"
  },
  priceAndIdContainer: {
    width: "28.3rem"
  },
  paddingTopPoint_4:{
    paddingTop : ".4rem",
  },
  headerLogoContainer:{
    paddingLeft: "1.5rem",
    width: "12.93rem",
    height: "3.95rem",
    color:"#fff",
    borderBottomRightRadius: "3.25rem",
    borderTopLeftRadius : "3.25rem",
    marginLeft : "-1.5rem",
    "&:before":{
      content:"''",
      width: 0,
      height: 0,
      borderLeft: "1.5rem solid transparent",
      borderTop: "1.68rem solid #01788e",
      backgroundColor: "transparent",
      marginLeft: "-1.5rem",
      marginTop: "3.9rem"
    }
  },
  headerLogo:{
    borderRadius: ".5rem",
    background : "linear-gradient(#FEA46E , #FA656B)",
    marginTop: "-3.6rem",
    marginLeft: ".5rem",
  },
  carpetHeader:{
    background : "linear-gradient(#FB472C , #FF573B)",
  },
  domesticTitleAndBG:{
    background: "linear-gradient(to bottom, #50e3c2 -14%, #008da7 114%)"
  },
  commercialTitleStyle:{
    background: "linear-gradient(to bottom, #9e59cc -14%, #492fb5 114%);",
    "&:before":{
      borderTop: "1.68rem solid #4c0ca0"
    }
  },
  carpetTitleStyle:{
    background: "linear-gradient(to bottom, #C64D3C -14%, #7C3C32 114%);",
    "&:before":{
      borderTop: "1.68rem solid #703127"
    }
  },
  headerIcon:{
    height: "4rem",
    width: "3.65rem",
    padding: "1.1rem .72rem .75rem .9rem",
    fontSize: "1.72rem"
  },
  commGradBorder:{
    borderLeft: ".9px solid #a674fa",
    borderRight: ".9px solid #a674fa"
  },
  carpetGradBorder:{
    borderLeft: ".9px solid #fff",
    borderRight: ".9px solid #fff"
  },
  headerTitle:{
    paddingLeft: ".62rem" ,
    color: "#fff",
    fontSize: ".933rem",
    marginTop: "-1.5rem",
    lineHeight: ".86rem"
  },
  headerSubTitle:{
    color: "#fff",
    fontSize : ".80rem"
  },
  noStyle:{},
  priceGrid:{
    marginLeft: ".9rem"
  },
  idGrid:{
    marginLeft: "4rem"
  },
  idGridUpdateMargin: {
    marginLeft: "2.2rem"
  },
  circlesGrid:{
    marginRight: "1.9rem"
  },
  circleGridUpdateMargin: {
    marginRight: "3.5rem"
  },
  sendQuoteBtn : {
    minHeight:0,
    minWidth:0,
    padding: ".7rem 1.4rem",
    border: "0px solid transparent",
    fontSize: ".9rem",
    color: "#fff",
    background: "linear-gradient( 90deg, #50E3C2 1.81%, #00949C 110.86%)",
    lineHeight: "1.125rem",
    borderRadius: "1.81rem",
    textTransform: "capitalize",
  },
  submitQuoteCont:{
    width: "80%",
    border: "1px solid #9b9b9b",
    padding: ".7rem .3rem .7rem 1rem",
    borderRadius: "2.81rem"
  },
  quoteInput:{
    color: "#9b9b9b",
    fontSize : ".92rem",
    border: "none",
    outline: "none",
    height: "2rem",
    width: "70%",
    padding: "0 .625rem"
  },
  quoteInputIcon:{
    color: "#9b9b9b"
  },
  jobRequestCont:{
    height: "4rem"
  },
  requestText:{
    color: "#fff",
    width: "4rem",
    fontSize: ".70rem",
    paddingTop: ".64rem",
    marginLeft: "9rem"
  },
  containerCell:{
    width: "9.75rem"
  },
  noPadding:{
    padding: 0
  },
  bottomPaddingPoint_3:{
    paddingBottom : ".3rem"
  },
  domesticBookingsButton:{
    padding: 0,
    fontSize : ".78rem",
    color : "#A059CE",
    "&:hover":{
      color : "#A059CE",
      textDecoration: "underline",
      backgroundColor: "#FFFFFF"
    }
  },
  commercialBookingsButton:{
    padding: 0,
    fontSize : ".78rem",
    color : "#16a6ae",
    "&:hover":{
      color : "#16a6ae",
      textDecoration: "underline",
      backgroundColor: "#FFFFFF"
    }
  },
  viewBookingsContainer: {
    width: "28.3rem",
    paddingBottom: ".3rem"
  },
  domesticRequestDiv:{
    backgroundImage: `url(${domesticRequest})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  commercialRequestDiv:{
    backgroundImage: `url(${commercialRequest})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  carpetRequestDiv:{
    backgroundImage: `url(${carpetRequest})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  extraPadding:{
    paddingRight: ".4rem"
  },
  additionalQuoteWarning:{
    color: "red",
    fontSize: ".93rem",
    paddingBottom: ".5rem"
  }
});



const orderIcons = {
  domestic:"house",
  commercial:"buildings",
  carpet:"carpet"
}

class JobOffer extends Component {

  state={
    quote: "",
    showQuoteForm: false
  }

  onQuoteChange = (e)=>{
    e.stopPropagation();
    this.setState({quote: e.target.value});
  }

  addQuote = ()=> (e)=>{
    e.stopPropagation();
    this.setState({  
      showQuoteForm: !this.state.showQuoteForm, 
      quote: "" 
    });
  }

  submitQuote= (quote,jobId,displayId)=> ()=> {
    quote = parseFloat(quote);
    if(quote && quote !== 0 && quote > 0){  
      this.props.submitQuoteCb(quote,jobId,displayId);
      this.setState({
        showQuoteForm: false, 
        quote: ""
      });
    }
  }

  showAdditionalBookings= (additionalBookings)=> ()=> {
    this.props.setAndShowAdditionalBookings(additionalBookings);
  }

  render(){
    const {
      classes,
      key,
      jobOfferObj = {},
      getJobDetails,
      getJobActions,
      // isCarpet
    } = this.props;

    const { _id: jobId, data, rehired, type, displayId, time, createdat, date, orderCost, additionalBookings } = jobOfferObj;
    const { 
      isDomestic,
      isCarpet,
      isCommercial,
    } = checkOrderType(type)
    let hasAdditionalBookings = false;
    let additionalBookingsCount = 0;
    let additionalBookingsList = [];
    if(additionalBookings && additionalBookings.length){
      hasAdditionalBookings = true;
      additionalBookingsCount = parseInt(additionalBookings.length);
      additionalBookingsList = additionalBookings;
    }

    let newJobsActions = getJobActions(type,orderCost);
    if(!isDomestic && !orderCost){
      newJobsActions.unshift({
        label: "Quote",
        onClick: this.addQuote,
        icon: "edollar",
        isIcon: true,
        btnClass: "quote",
        tooltip: "Add Price Quote"
      });
    }

    let contractorRehired = false;
    if(data && data.rehired){
      contractorRehired = true;
    } else if(rehired){
      contractorRehired = true;
    }

    const timed = momemt(time,"hh:mm:ss").format("hh:mm A");

    const { showQuoteForm, quote } = this.state;

    return (    
      <Grid key={key} item className={classes.jobOfferItem} id="jobItem">
        <Card className={ cn([classes.jobOfferCard, { 
          [classes.commercialOfferCardBG] :isCommercial,
          [classes.carpetOfferCardBG]:isCarpet,
        }])}>
          <CardContent classes={{root:classes.cardContentRoot}} > 
            <Grid
              container 
              justify="space-between"
              direction="row" 
              alignItems = "flex-start"
              className={classes.jobDescStyle}
            >
              <Grid item container alignItems="center" className={cn([classes.headerLogoContainer,{
                [classes.domesticTitleAndBG] : isDomestic,
                [classes.commercialTitleStyle] : isCommercial,
                [classes.carpetTitleStyle]: isCarpet,
              }])}>
                <Icon 
                  className={cn([classes.headerLogo,classes.headerIcon, isCarpet ? classes.carpetHeader : null])}
                  lineHeight="inherit"
                >
                  {orderIcons[type]}
                </Icon>
                <span className={classes.headerTitle}>
                  {capitalizeFirstLetterOnly(type)}
                  <Typography className={classes.headerSubTitle}>
                    Job Offer
                  </Typography>
                </span>
              </Grid>

              <Grid item>
                <img 
                  src={rings} 
                  style={{height: "3.6rem"}} 
                  alt="Not Found"
                />
              </Grid>

              <Grid item className={cn([classes.jobRequestCont,{
                [classes.domesticRequestDiv]: isDomestic,
                [classes.commercialRequestDiv]: isCommercial,
                [classes.carpetRequestDiv]:isCarpet,
                [classes.extraPadding]: contractorRehired
              }])}>
                <Typography  className={classes.requestText}>  
                  { contractorRehired ? "Rehire Job Request" : "New Job Request" }
                </Typography> 
              </Grid>
              
            </Grid>
            {
              (showQuoteForm && !orderCost)
                ? <Grid 
                  container 
                  alignItems="center" 
                  justify="center"
                  className = {classes.jobSummaryHolder}
                >
                  <Grid item container justify="center" alignItems="center" >
                    {
                      hasAdditionalBookings &&
                        <Grid>
                          <Typography className={classes.additionalQuoteWarning}>
                          [ Note : Amount should be per Booking. ]
                          </Typography>
                        </Grid>
                    }
                    <Grid className={classes.submitQuoteCont}>
                      <Icon className={classes.quoteInputIcon} lineHeight="inherit" fontSize=".92rem">
                        dollar
                      </Icon>
                      <input
                        type="number"
                        value={quote}
                        onChange={this.onQuoteChange}
                        className={classes.quoteInput}
                        placeholder= "Enter amount ..."
                      />
                      <Button
                        classes={{root:classes.sendQuoteBtn}}
                        onClick={this.submitQuote(quote,jobId,displayId)}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                : <Grid 
                  container 
                  alignItems = "center"
                  justify= "center"
                  className = {classes.jobSummaryHolder}
                >

                  <Grid 
                    className={cn([classes.priceAndIdContainer,{
                      [classes.paddingTopPoint_4]: hasAdditionalBookings
                    }])}
                    container
                    alignItems = "center"
                    justify = "space-between" 
                  >
                    <JobInfoCell 
                      title= "Order ID"
                      info = {displayId}
                      classes = {classes}
                    />
                    {
                      (orderCost > 0) &&
                      <JobInfoCell 
                        title= "Price"
                        info = {`$ ${orderCost}`}
                        classes = {{
                          infoContainer: classes.containerCell,
                          titleStyle: classes.titleStyle,
                          titlePadding: classes.titlePadding,
                          infoStyle: cn([classes.infoStyle,classes.noPadding]),
                          iconStyle: classes.iconStyle
                        }}
                      />
                    }
                    <Grid item className={classes.circlesGrid}>
                      <img 
                        src={circles[type]} 
                        alt="Art work"
                      />
                    </Grid>
                  </Grid> 

                  <Grid 
                    container
                    className= {cn([{
                      [classes.bottomPaddingPoint_3]: !hasAdditionalBookings
                    }])} 
                    justify = "space-around"
                    alignItems = "center"
                    direction="row"
                  >
                    <JobInfoCell 
                      icon = {"date"}
                      title="Created at"
                      info = {momemt(createdat).format("DD MMM, YYYY")}
                      classes = {classes}
                    />
                    <JobInfoCell 
                      icon = {"date"}
                      title="Cleaning Date"
                      info = {momemt(date).format("DD MMM, YYYY")}
                      classes = {classes}
                    />
                    <JobInfoCell
                      title="Cleaning Time"
                      icon = {"time"}
                      info = {timed}
                      classes = {classes}
                    /> 
                  </Grid>

                  {
                    hasAdditionalBookings &&
                    <Grid
                      container
                      className ={classes.viewBookingsContainer}
                      justify = "flex-start"
                      alignItems = "center"
                      direction="row"
                    >
                      <Button
                        disableFocusRipple={true}
                        disableRipple={true}
                        classes = {{
                          root: isDomestic ? classes.domesticBookingsButton : classes.commercialBookingsButton,
                          text: classes.noPadding
                        }}
                        onClick={this.showAdditionalBookings(additionalBookingsList)}
                      >
                        View {additionalBookingsCount} Additional Bookings  
                      </Button>
                    </Grid>
                  }

                </Grid>
            }
          </CardContent>
          <Grid 
            container 
            direction="row" 
            alignItems = "center"
            justify = "space-around"
            className = {classes.jobOptions}
          >
            {newJobsActions && newJobsActions.map((action, i) => {
              if(action.isIcon){
                return (
                  <Grid key={i} item >
                    <Tooltip title={action.tooltip}>
                      <Button 
                        disableFocusRipple={true}
                        disableRipple={true}
                        onClick = { action.onClick ? action.onClick(jobOfferObj) : getJobDetails(jobOfferObj) } 
                        classes={
                          {
                            root: cn([
                              classes.btn, 
                              classes[action.btnClass],
                              {
                                [classes.commGradBorder] : (isCommercial && action.btnClass === "reject" ),
                                [classes.carpetGradBorder] : (isCarpet && action.btnClass === "reject")
                  
                              }
                            ]),
                            focusVisible: classes.noStyle
                          }
                        }
                        {...action.btnProps}
                      >
                        <Icon className={classes.iconBtnColor} lineHeight="inherit" fontSize=".92rem">
                          {action.icon}
                        </Icon>
                        <Typography className={classes.typoStyle}>
                          {action.label}
                        </Typography>
                      </Button>
                    </Tooltip>
                  </Grid>
                );
              }
              return (
                <Grid key={i}  item>
                  <Button
                    disableFocusRipple={true}
                    disableRipple={true}
                    onClick={action.onClick ? action.onClick(jobOfferObj): getJobDetails(jobOfferObj)} 
                    className={cn(classes.btn, action.label === "Reject" && classes.dangerBtn)}
                    {...action.btnProps}
                  >
                    {action.label}
                  </Button>

                </Grid>
              );
            })}
          </Grid>
        </Card>
      </Grid>
    );
  }
}

const JobInfoCell = (props)=>{
  const {title, icon, prefix="", info, classes} = props;
  return (
    <div className={classes.infoContainer}>
      <Typography className={cn([classes.titleStyle,classes.titlePadding])}>
        {title}
      </Typography>
      <Grid container alignItems="center"> 
        {icon &&
          <Icon className={classes.iconStyle} lineHeight="inherit" fontSize=".86rem">
            {icon}
          </Icon> 
        }
        <span className={classes.infoStyle}>
          {prefix}{info} 
        </span>
      </Grid>  
    </div>
  );
};

const mapDispatchToProps = (dispatch)=> ({
  setAndShowAdditionalBookings: (additionalBookings)=> dispatch(setAndShowAdditionalBookings(additionalBookings))
});

const injectPropsToJobOffer = connect(undefined,mapDispatchToProps)(JobOffer);

export default withStyles(styles)(injectPropsToJobOffer);