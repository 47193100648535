import React from "react";
import Dialog from "core/components/Dialog";
// import cn from "classnames";

// import Button from "core/components/Button";
import InviteForm from "../InviteForm";
// import Grid from "core/components/Grid";
// import Typography from "core/components/Typography";
// import IconButton  from "core/components/IconButton";
// import { withStyles } from "core/components/Styles";
// import { Close } from "mdi-material-ui";



const InviteModel = (props) => {
  const { isModalOpen, handleModal } = props;
  return (
    <Dialog
      open={isModalOpen}
      onClose={handleModal}
      aria-labelledby="form-dialog-title"
      fullWidth
      scroll="paper"
    >
      <InviteForm onModalClose={handleModal}/>
    </Dialog>
  )
}


export default InviteModel;