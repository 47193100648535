import React, { Component, Fragment } from "react";
import { connect } from "react-redux"; 
import { reduxForm, reset } from "redux-form";
import _ from "lodash";
import axios from 'utils/http';
import http from "axios";
import EmployeeDashboard from "../components/EmployeeDashboard";
import AddEmployeeModal from "../components/AddEmployeeModal";
import { loadData } from "../store/actions/data";
import { setMsgAndShow } from "../store/actions/popup";
import { toggleLoader, toggleDialogLoader } from "../store/actions/app";
import { ImageDropZone, DropZoneDialog } from "../components/DropZone";
import socket from "socket";
import moment from "moment-timezone";
import { debounce } from "lodash";

import Storage from "../utils/Storage";
import { STATUS } from "react-joyride";

import { withMixpanel } from "../utils/react-mixpanel";
import getTotalPagesCount from "../utils/getTotalPagesCount";
 
class EmployeeDashboardContainer extends Component {
  state = {
    isModalOpen: false,
    editEmployee: false,
    showDropZone: false,
    busyEmployeeList:[],
    freeEmployeeList:[],
    numOfBusyEmployees:0,
    numOfFreeEmployees:0,
    employees: [],
    employeeCount: 0,
    filteredEmployees:[],
    rawFilteredEmployees:[],
    runGuide: false,
    employeeId: "",
    currPage:1,
    totalPages:0,
    q:"",
    perPage:10,
  };

  searchEmployees = () => {
    this.getDataByPage(1);
  }

  lazySearch = debounce(this.searchEmployees,250);
  busyEmployeesIds = [];
  handleSave = (obj, dispatch, props) => {
    console.error(obj); // eslint-disable-line
  };


  componentDidMount() {

    const { trackPageLoad } = this.props;
    trackPageLoad("Employee_List");

    if(!_.isEmpty(this.props.contractorInfo.businessDetails)){
      this.addData();
    }
    
    socket.on("newLocation", this.setEmployeeLocation)
  }

  componentDidUpdate(prevProps, nextProps){
    if(JSON.stringify(prevProps.contractorInfo) !== JSON.stringify(this.props.contractorInfo)){
      this.addData();
    }
  }
  addData = async () => {
    const { toggleLoader  } = this.props;
    const contractorId = this.props.contractorInfo.businessDetails._id
    let filteredEmployees = []

    toggleLoader();
    try {
      const employeeData = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_all_employees`,
        {
          params: {
            contractorId
          }
        }
      );


      const dayWiseEmployeeData = await axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/get_employees`,
      {
        params: {
          contractorId,
          date: moment().format("YYYY-MM-DD")
        }
      })

      toggleLoader();

  
      const { employees, total, perPage, page:receivedPage } = employeeData.data;
      const { busyEmployeeList, freeEmployeeList, numOfBusyEmployees, numOfFreeEmployees } = dayWiseEmployeeData.data
      const totalPages = getTotalPagesCount(total,perPage)
      const busyEmployeeIds = busyEmployeeList.map(e => e.userId);
      this.busyEmployeesIds = busyEmployeeIds;
      filteredEmployees = this.filterEmployeesBasedOnBusyList(employees, this.busyEmployeesIds);

      this.setState({
        employees,
        employeeCount:total,
        filteredEmployees,
        busyEmployeeList,
        freeEmployeeList,
        numOfBusyEmployees,
        numOfFreeEmployees,
        totalPages,
        currPage:receivedPage,
        perPage
      });

      const { isTourEnabled } = this.props;

      if( Storage.get("employeeListGuide") !== "completed" && isTourEnabled ){ 
        this.setState({
          runGuide: true
        })
      } 

      this.getLocationsOfEmployees(filteredEmployees);

    } catch (error) {
      toggleLoader();
      const { response } = error;
      this.props.setMsgAndShow( response ? response.data.msg : "Error");
    }
 
  }


  getHelpers = (StoreHelpers) => {
    this.helpers = StoreHelpers;
  };

  handleJoyrideCallback = (CallBackProps) => {
    const { status } = CallBackProps;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      Storage.set("employeeListGuide","completed");
      this.setState({ runGuide: false });
    }
    
  };


  submitData = (type) => async (obj, dispatch, props) => {
    const { toggleDialogLoader, trackApiEvent } = this.props;
    let formValues = _.cloneDeep(obj);

    const workDays = [];

    formValues.workDays.forEach(day => {
      workDays.push(day.value);
    });

    delete formValues.confirmPassword;

  
    if(type === "add"){
      formValues = {
        ...formValues,
        userType: "employee",
        contractorId: this.props.contractorInfo.businessDetails._id,
        workDays
      };

      
      toggleDialogLoader();
      try {
        const employeeData = await axios.post(
          `${process.env.REACT_APP_API_PREFIX}contractor/employees/add`,
          { ...formValues, currDate: moment().format("YYYY-MM-DD")}
        );



        const { status, message } = employeeData.data;
        toggleDialogLoader();
        if(status === "OK"){

          trackApiEvent("Add_Employee", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/employees/add`, "OK", window.location.pathname);

          this.clearForm();
          this.handleModal();
          this.updateListAndInfo();
          this.props.setMsgAndShow(message);
        } else {
          this.props.setMsgAndShow(message)
        }
        
  
      } catch (error) {
        toggleDialogLoader();
        const { response } = error;
        let msg = "Error Occurred!"; 
        
        if(response && response.data){
          msg = response.data.message ? response.data.message : "Error Occurred!";
        }
        
        trackApiEvent("Add_Employee", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/employees/add`, msg, window.location.pathname);
        this.props.setMsgAndShow(msg);
      }
    } else if(type === "update"){

      
      formValues = {
        ...formValues,
        workDays
      };



      toggleDialogLoader();
  
      try {
        await axios.put(
          `${process.env.REACT_APP_API_PREFIX}contractor/employees/update/${obj.userId}`,
          { ...formValues }
        );
  
        toggleDialogLoader();
        trackApiEvent("Update_Employee", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/employees/update`, "OK", window.location.pathname);

  
        this.clearForm();
        this.props.loadData(null);
        this.handleModal();
        this.updateListAndInfo();
        this.props.setMsgAndShow("Employee details were updated");
      } catch (error) {
        toggleDialogLoader();
        const { response } = error;
        let msg = "Error Occured!";
        
        if(response && response.data){
          msg = response.data.message ? response.data.message : response.data.msg ? response.data.msg : "Error Occurred!"
        }

        this.props.setMsgAndShow(msg);
      }
    }

  };
  handleModal = () => {

    const { isModalOpen } = this.state;

    if(isModalOpen) {
      this.setState({
        isModalOpen: false,
        employeeId: ""
      });
      this.props.loadData(null);
      this.clearForm();
    } else {
      this.setState({
        isModalOpen: true
      });
    }
  
    
  };
  updateEmployeeList = async (employeeInfo, type) => {
    const { toggleLoader } = this.props;
    let { employees, filteredEmployees, employeeCount } = this.state;

    toggleLoader();
    try {
      if (type === "add") {
        filteredEmployees.push({
          ...employeeInfo,
          employeeStatus:"available"
        });
  
        employeeCount++;
  
        const dayWiseEmployeeData = await axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/get_employees`,
        {
          params: {
            contractorId: this.props.contractorInfo.businessDetails._id,
            date: moment().format("YYYY-MM-DD")
          }
        })
  
        const { numOfBusyEmployees, numOfFreeEmployees } = dayWiseEmployeeData.data;
  
  
        this.setState({
          employees,
          filteredEmployees,
          employeeCount,
          numOfBusyEmployees,
          numOfFreeEmployees
        });
  
      } else if (type === "remove") {
        const employeeIndex = filteredEmployees.indexOf(employeeInfo);
        if (employeeIndex > -1) {
          filteredEmployees.splice(employeeIndex, 1);
          employeeCount--;
  
          const dayWiseEmployeeData = await axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/get_employees`,
          {
            params: {
              contractorId: this.props.contractorInfo.businessDetails._id,
              date: moment().format("YYYY-MM-DD")
            }
          })
    
          const { numOfBusyEmployees, numOfFreeEmployees } = dayWiseEmployeeData.data;
    
    
          this.setState({
            employees,
            filteredEmployees,
            employeeCount,
            numOfBusyEmployees,
            numOfFreeEmployees
          });
        }
      } else if(type === "update") {
        filteredEmployees = filteredEmployees.map((employee) => {
            if(employee.userId === employeeInfo.userId){
              return {
                ...employee,
                ...employeeInfo
              }
            } else {
              return employee
            }
          })
  
  
          const dayWiseEmployeeData = await axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/get_employees`,
          {
            params: {
              contractorId: this.props.contractorInfo.businessDetails._id,
              date: moment().format("YYYY-MM-DD")
            }
          })
    
          const { numOfBusyEmployees, numOfFreeEmployees } = dayWiseEmployeeData.data;
          this.setState({
            employees,
            filteredEmployees,
            employeeCount,
            numOfBusyEmployees,
            numOfFreeEmployees
          })
      }

      toggleLoader();
    } catch (error) {
      toggleLoader();
      const { response } = error;
      this.props.setMsgAndShow(
        response ? response.data.message ? response.data.message : response.data.msg : "Error"
        );
    }

  };
  removeEmployee = async (employeeObj) => {
    
    const { trackButtonEvent, trackApiEvent } = this.props;
    trackButtonEvent("Delete",window.location.pathname);

    const { toggleLoader } = this.props;
    const { userId } = employeeObj;
    toggleLoader();
    try {
      const res = await axios.delete(`${process.env.REACT_APP_API_PREFIX}contractor/employees/remove/${userId}`)
      toggleLoader()
      if(res.data.status === "OK"){

        trackApiEvent("Delete_Employee","DELETE",`${process.env.REACT_APP_API_PREFIX}contractor/employees/remove`,"OK",window.location.pathname);


        this.updateListAndInfo();
        this.props.setMsgAndShow(`Employee Id ${employeeObj.fullName} was removed`);

      } else {
        toggleLoader();

        trackApiEvent("Delete_Employee","DELETE",`${process.env.REACT_APP_API_PREFIX}contractor/employees/remove`,res.data.message,window.location.pathname);

        this.props.setMsgAndShow(res.data.message)
      }

    } catch (error) {
      const { response } = error;
      toggleLoader();

      let message = "Error Occurred!";

      if(response && response.data){
        message = response.data.message ? response.data.message : "Error Occurred!";
      }

      trackApiEvent( "Delete_Employee", "DELETE", `${process.env.REACT_APP_API_PREFIX}contractor/employees/remove`, message, window.location.pathname);
      this.props.setMsgAndShow(message);
    }

  }

  updateEmployee = (employeeObj) => async () => {

    const { trackButtonEvent } = this.props;
    trackButtonEvent("View_Profile",window.location.pathname);

    const { userId } = employeeObj;
    this.setState({
      isModalOpen: true,
      editEmployee: true,
      employeeId: userId
    })

    const workDays = employeeObj.workDays.map((day)=> {
      return {
        label: day,
        value: day
      }
    })

    const obj = {
      firstName: employeeObj.fname,
      lastName: employeeObj.lname,
      phone: employeeObj.phone,
      email: employeeObj.email,
      jobShiftStartTime: employeeObj.jobShiftStartTime,
      jobShiftEndTime: employeeObj.jobShiftEndTime,
      workDays,
      userId: userId,
      profileImage: employeeObj.profileImage
    };

    this.props.loadData(obj);
  }


  clearForm = () => {
    this.props.reset("EmployeeForm");
  }
  
  cancelForm = () => {
    this.props.loadData(null);
    this.clearForm();
    this.setState({
      editEmployee: false,
      isModalOpen: false,
      employeeId: ""
    });
  }

  trackInput = (e)=> {
    if(e.target.value){
      const { trackInputOnBlur } = this.props;
      trackInputOnBlur("Employee_Search", e.target.value, window.location.pathname);
    }
  }

  trackMapLink = ()=>{
    const { trackLinkEvent_inBlock } = this.props;
    trackLinkEvent_inBlock("View_On_Map",window.location.pathname);
  }

  openModal = ()=> {

    const { trackButtonEvent } = this.props;
    trackButtonEvent("Add_New_Employee",window.location.pathname);

    this.setState({
      isModalOpen:true,
      editEmployee: false,
    })
  }

  setEmployeeLocation = (empLocation) => {
      const { filteredEmployees: oldEmployeeLocations } = this.state;
      let updateState = false;
      const updatedLocations = oldEmployeeLocations.map((e) => {
        if(e.employeeId === empLocation.employeeId){
          updateState = true;
          return {
            ...e,
            location: empLocation.location,
            address: empLocation.address
          }
        }
        return e;
      })
    
      if(updateState){
        this.setState({
          filteredEmployees: updatedLocations
        })

      }
  }

  componentWillUnmount(){
    socket.off("newLocation", this.setEmployeeLocation)
  }


  handleEmployeeSearch = (e) => {
    const matchValue = e.target.value.toLowerCase();
    this.setState({
      q:matchValue
    }, () => this.lazySearch())
  };

  handleDropZone = ()=> this.setState({ showDropZone: !this.state.showDropZone });

  fileUploaded = async(response)=> {
    let toastMsg = "Error occured while uploading photo.";
    let updateObj = { showDropZone: false };
    const { toggleLoader, setMsgAndShow, change } = this.props;
    toggleLoader();
    try {
      if(response && response.data){
        if(response.data.status === "OK"){
          change( "profileImage", response.data.profileImage );
          toastMsg = "Photo uploaded successfully.";
          this.addData();  
        }
      }      
    } catch (error) {
      const { response } = error;
      if(response && response.data){
        toastMsg = response.data.message || toastMsg;
      }
    }
    toggleLoader();
    this.setState(updateObj);
    setMsgAndShow(toastMsg);
  }

  onPhotoUpload = ()=> this.setState({ showDropZone: true });


  filterEmployeesBasedOnBusyList = (employees, busyEmployeesIds) => {
    let filteredEmployees = [];

    if(busyEmployeesIds  && (employees && employees.length)){
      employees.forEach((e) => {
        if(busyEmployeesIds.indexOf(e.userId) > -1){
          e.employeeStatus = "ongoing"
        } else {
          e.employeeStatus = "available"
        }
  
        filteredEmployees.push(e);
      })
    }

    return filteredEmployees;
  }

  getLocationsOfEmployees = async (employees) => {
    const cEmploypees = _.cloneDeep(employees);
    cEmploypees.forEach(async (e) => {
      const { lastLocation } = e;
      if(lastLocation){
        const res = await http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lastLocation[1]},${lastLocation[0]}&key=${process.env.REACT_APP_GOOGLE_KEY}`);
        const { status, results } = res.data;
        if(status === "OK"){
          e.lastAddress = results[0].formatted_address;
          this.setState({
            filteredEmployees: cEmploypees
          });
        }
      }
    })
  }
  getDataByPage = async (pageNumber, isLoaderEnabled, getEmpStats) => {
    const { toggleLoader  } = this.props;
    const { perPage, q } = this.state;
    const contractorId = this.props.contractorInfo.businessDetails._id
    let filteredEmployees = []

    if(!isLoaderEnabled){
      toggleLoader();
    }

    try {
      const employeeData = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_all_employees`,
        {
          params: {
            contractorId,
            page:pageNumber,
            limit:perPage,
            q
          }
        }
      );


      const { employees, total, perPage:receivedPerPage, page:receivedPage } = employeeData.data;

      const totalPages = getTotalPagesCount(total,receivedPerPage);

      let stateObj = {
        employees,
        totalPages,
        currPage:receivedPage,
        perPage:receivedPerPage
      }
      if(getEmpStats){
        const dayWiseEmployeeData = await axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/get_employees`,
        {
          params: {
            contractorId,
            date: moment().format("YYYY-MM-DD")
          }
        })
        const { busyEmployeeList, freeEmployeeList, numOfBusyEmployees, numOfFreeEmployees } = dayWiseEmployeeData.data;
        const busyEmployeeIds = busyEmployeeList.map(e => e.userId);
        this.busyEmployeesIds = busyEmployeeIds;

        stateObj = {
          ...stateObj,
          busyEmployeeList,
          freeEmployeeList,
          numOfBusyEmployees,
          numOfFreeEmployees,
        }

      }

      filteredEmployees = this.filterEmployeesBasedOnBusyList(employees,this.busyEmployeesIds);

      stateObj.filteredEmployees = filteredEmployees

      this.setState({...stateObj});

      toggleLoader();

      this.getLocationsOfEmployees(filteredEmployees);

    } catch (error) {
      const { response  } = error;
      let errMsg = "Unable to get list!";
      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      toggleLoader();
      this.props.setMsgAndShow(errMsg);
    }
  }
  handlePageChange = (_, pageNumber) => {
    this.getDataByPage(pageNumber);
  }



  clearSearch = () => {
    this.setState({
      q:""
    }, () => {
      this.getDataByPage(1);
    })
  }


  updateListAndInfo = async () => {
    const { toggleLoader  } = this.props;
    const { currPage, q, perPage } = this.state;
    const contractorId = this.props.contractorInfo.businessDetails._id
    let filteredEmployees = []

    toggleLoader();
    try {
      const employeeData = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_all_employees`,
        {
          params: {
            contractorId,
            page:currPage,
            limit:perPage,
            q
          }
        }
      );
      const { employees, total, perPage:receivedPerPage, page:receivedPage } = employeeData.data;

      const totalPages = getTotalPagesCount(total,receivedPerPage)



      if(currPage > totalPages){
        this.getDataByPage(totalPages,true, true)
      } else {

        const dayWiseEmployeeData = await axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/get_employees`,
        {
          params: {
            contractorId,
            date: moment().format("YYYY-MM-DD")
          }
        })
        const { busyEmployeeList, freeEmployeeList, numOfBusyEmployees, numOfFreeEmployees } = dayWiseEmployeeData.data;

        const busyEmployeeIds = busyEmployeeList.map(e => e.userId);
        this.busyEmployeesIds = busyEmployeeIds;

        filteredEmployees = this.filterEmployeesBasedOnBusyList(employees, this.busyEmployeesIds);

        this.setState({
          employees,
          employeeCount:total,
          filteredEmployees,
          busyEmployeeList,
          freeEmployeeList,
          numOfBusyEmployees,
          numOfFreeEmployees,
          totalPages,
          currPage:receivedPage,
          perPage
        });


        toggleLoader();

        this.getLocationsOfEmployees(filteredEmployees);
      }


    } catch (error) {
      toggleLoader();
      const { response } = error;
      this.props.setMsgAndShow( response ? response.data.msg : "Error");
    }
 
  }

  render() {
    const { employeeId } = this.state;

    return ( 
      <Fragment>
        <EmployeeDashboard 
          {...this.state}
          openModal={this.openModal}
          handleModal={this.handleModal} 
          removeEmployee={this.removeEmployee}
          updateEmployee={this.updateEmployee}
          handleEmployeeSearch={this.handleEmployeeSearch}
          handleJoyrideCallback={this.handleJoyrideCallback}
          getHelpers={this.getHelpers}
          trackInput={this.trackInput}
          trackMapLink={this.trackMapLink}
          handlePageChange={this.handlePageChange}
          clearSearch={this.clearSearch}
        />
        <AddEmployeeModal
          handleModal={this.handleModal}
          submitData={this.submitData}
          handleSave={this.handleSave}
          cancelForm={this.cancelForm}
          onPhotoUpload={this.onPhotoUpload}
          {...this.state}
          {...this.props}
        />

        <DropZoneDialog
          isOpen={this.state.showDropZone}
          toggle={this.handleDropZone}
          title="Upload Employee Profile Picture"
        >
          <ImageDropZone
            onUploaded={this.fileUploaded}
            onCancel={this.handleDropZone}
            url={`${process.env.REACT_APP_API_PREFIX}contractor/upload_employee_photo`}
            accept=".jpg,.png,.gif"
            formData={{ employeeId }}
          />
        </DropZoneDialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  let formValues = {};

  if (state.form.EmployeeForm) {
    const { EmployeeForm } = state.form;
    if (EmployeeForm.values) {
      formValues = EmployeeForm.values;
    }
  }

  return {
    formValues,
    initialValues: state.employee.currentEmployee,
    contractorInfo: state.contractor,
    isTourEnabled: state.app.isTourEnabled
  }
};


const mapDispatchToProps = dispatch => ({
  loadData: (data) => dispatch(loadData(data)),
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg)),
  toggleLoader: () => dispatch(toggleLoader()),
  toggleDialogLoader: () => dispatch(toggleDialogLoader()),
  reset
})

const connectRedux = connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: "EmployeeForm",
    enableReinitialize: true
  })(EmployeeDashboardContainer)
);

export default withMixpanel(connectRedux);

