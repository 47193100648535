// JOBS
export const ADD_NEW_JOB = "ADD_NEW_JOB";
export const REMOVE_JOB = "REMOVE_JOB";
export const SET_JOBS = "SET_JOBS";
export const UPDATE_JOB = "UPDATE_JOB";
export const UPDATE_JOB_IN_ALL_TYPES = "UPDATE_JOB_IN_ALL_TYPES";


// Contractor 
export const SET_DETAILS = "SET_DETAILS";
export const  SET_USER_INFO = "SET_USER_INFO";
export const SET_ORDER_HISTORY = "SET_ORDER_HISTORY";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_DETAILS = "UPDATE_DETAILS";
export const UPDATE_INVOICE_LOGO = "UPDATE_INVOICE_LOGO";

// Todo
export const SET_CURRENT_TODO = "SET_CURRENT_TODO";


// Popup
export const SET_MSG_AND_SHOW = "SET_MSG_AND_SHOW";
export const RESET_MSG = "RESET_MSG";


// APP State
export const SET_ITEM = "SET_ITEM";
export const TOGGLE_BUSINESS_MODAL = "TOGGLE_BUSINESS_MODAL";
export const TOGGLE_LOADER = "TOGGLE_LOADER";
export const TOGGLE_DIALOG_LOADER = "TOGGLE_DIALOG_LOADER";
export const SET_FORM_DATA = "SET_FORM_DATA";
export const CLOSE_BUSINESS_MODAL = "CLOSE_BUSINESS_MODAL";
export const SET_ORDERS_WITH_CHANGES = "SET_ORDERS_WITH_CHANGES";
export const CLEAR_ORDERS_WITH_CHANGES = "CLEAR_ORDERS_WITH_CHANGES";


export const SHOW_TOUR_GUIDE = "SHOW_TOUR_GUIDE";

// Job Changes
export const SET_JOB_AND_OPEN_MODAL = "SET_JOB_AND_OPEN_MODAL"
export const REMOVE_ORDER_FROM_ORDERS_WITH_CHANGES = "REMOVE_ORDER_FROM_ORDERS_WITH_CHANGES"
export const CLEAR_JOB_AND_CLOSE_MODAL = "CLEAR_JOB_AND_CLOSE_MODAL";
export const ADD_ORDER_WITH_CHANGES = "ADD_ORDER_WITH_CHANGES";


// Alert Box
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

// Additional Bookings Modal
export const SET_AND_SHOW_ADDITIONAL_BOOKINGS = "SET_AND_SHOW_ADDITIONAL_BOOKINGS";
export const REMOVE_AND_HIDE_ADDITIONAL_BOOKINGS = "REMOVE_AND_HIDE_ADDITIONAL_BOOKINGS";

// Page Refresh
export const TOGGLE_PAGE_REFRESH = "TOGGLE_PAGE_REFRESH";