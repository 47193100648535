import React, { Component } from "react";
import BigCalendar from "react-big-calendar";
import moment from "moment-timezone";
import Event from "../Event";
import CalenderToolbar from "../CalenderToolbar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { withStyles } from "core/components/Styles";
// import { color } from "echarts/lib/export";
// import zIndex from "core/components/Styles/zIndex";
// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
BigCalendar.momentLocalizer(moment); // or globalizeLocalizer

const styles = {
  bigCalendarWrapper: {
    height: "100vh",
    "& .rbc-calendar":{ 
      minHeight: "100%",
      height:"auto"
    },
    "& .rbc-row-content":{
      height:"100%",
      flex : 1
    },
    "& .rbc-row-content > :first-child":{
      position : "relative",
      zIndex :1
    },
    // "& .rbc-row-content > .rbc-row:not(first-child)":{
    //   position : "absolute",
    //   top : 0,
    //   height : "100%",
    //   width : "100%"
    // },
    "& .rbc-row.rbc-row-segment":{// To target Every date that has a JOB to be displayed
      flexBasis : "14.2857%",
      maxWidth : "14.2857%",
    },
    "& .rbc-row .rbc-row-segment .rbc-event ":{// The Order container's "CELL" Style :-
      //height : "100%",
      display : "flex",
      alignItems : "center",
      justifyContent: "center",
      flexDirection : "column"
    },
    // '& div [class*="rbc-today"]':{// For Today's Full Grid 
    //   //background : "#3F77F2",
    //   color:"#ffffff"
    // },
    "& .rbc-time-view":{
      background: "#fff"
    },
    '& div [class*="rbc-now rbc-current"]' :{// For Today's Date String
      color : "#8a888a",
      fontFamily: "Montserrat",
      fontWeight : 600,
      fontSize : 17,
      lineHeight : "21px",
      textAlign : "center",
      letterSpacing : "-0.215833px"
    },
    // "& .rbc-show-more":{ // For "Show more" jobs option to display if more 2 jobs. 
    //   paddingTop: 110,
    //   paddingLeft: 80,
    //   color: "#8a888a",
    //   textDecoration : "none",
    //   backgroundColor:"rgba(255, 255, 255, -0.7)"
    // },
    
    "& .rbc-header": {// For "Day's" row Header String eg . Mon,Tue 
      height: 59,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // background: "#4a4a4a", 
      textTransform:"uppercase",
      background:"#fff",
      border: "none",
      fontFamily: "Montserrat",
      fontSize : 15,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight : "12px",
      letterSpacing : "0.21px",
      textAlign: "center",
      // color: "#cfd5da",
      borderBottom: "1px solid #efefef",
      "& a span": {
        whiteSpace: 'initial'
      },
      "&:first-of-type":{
        color: "#ff1f00"
      },
      "&:last-of-type":{
        color: "#ff1f00"
      }
    },
    "& .rbc-month-view": {
      background: "#fff",
      // boxShadow: "0px 2px 5px rgba(0,0,0,0.2)"
      border:"none"
    },
    "& .rbc-off-range-bg": {
      background: "#fff"
    },
    "& .rbc-date-cell": {
      fontFamily : "Montserrat",
      fontStyle : "normal",
      fontWeight : 600,
      fontSize : 14,
      lineHeight : "17px",
      textAlign : "center",
      letterSpacing : "-0.215833px",
      display : "flex",
      justifyContent : "flex-start",
      color: "#8a888a",
      padding: 10,
      "&.rbc-off-range": {
        color: "#e6e6e6"
      }
    }
  }
}

class EventCalender extends Component {
  render() {
    const { 
      classes,
      eventList,
      selectedDate,
      handleDateChange,
      eventStyleSetter
    } = this.props

    return (   
      <div className={classes.bigCalendarWrapper} id="eventCalendar">
        <BigCalendar
          events={eventList}
          selectable
          step={15}
          timeslots={8}
          onSelectEvent={selectedDate}
          onSelectSlot={selectedDate}
          components={{
            event: Event,
            toolbar: CalenderToolbar
          }}
          onNavigate={handleDateChange}
          eventPropGetter={eventStyleSetter}
        />
      </div>
    );
  }
}

export default withStyles(styles)(EventCalender);
