import React from "react";
import Grid from "core/components/Grid";
import Button from "core/components/Button";
import { withStyles } from "core/components/Styles";

// Custom Components

import MainContent from "../MainContent";
import DataList from "../DataList";
import earningObjItemProps from "./earningObjItemProps";
import { MaterialTextField } from "../FormField";


const totalEarningsObjItemProps = [
  {
    name:"Total Jobs",
    label:"totalJobs"
  },
  {},
  {},
  {},
  {},
  {
    name:"Total Earnings",
    label:"totalEarnings"
  },
  {
    name:"Total Zoklean Fee",
    label:"zokleanTotalFee"
  },
  {
    name:"Total Contractor Share",
    label:"contractorTotalShare"
  }
]

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex"
  },
  jobsBtnCont: {
    marginTop: 10
  },
  newJobBtnCont: {
    marginTop: 10
  },
  jobslistCont: {
    marginTop: 10
  },
  textCenter: {
    textAlign: "center"
  }
});


const Earnings = ({
  classes,
  startDate,
  endDate,
  ordersEarningList,
  ordersEarningObjIndex,
  handleDateChange,
  getData,
  totalsObjArray
}) => {
  return (
    <div className={classes.root}>
      <MainContent>
        <Grid container justify="space-between" alignContent="center" >
          <Grid item container spacing={24} xs alignItems="center">
            <Grid item>
              <MaterialTextField
                name="startDate"
                onChange={handleDateChange("startDate")}
                type="date"
                label="From"
                shrink
                value={startDate}
              />
            </Grid>

            <Grid item>
              <MaterialTextField
                name="endDate"
                onChange={handleDateChange("endDate")}
                type="date"
                label="To"
                shrink
                value={endDate}
              />
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={getData}> GET DATA</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12}>
            {ordersEarningList.length > 0 ? 
            <DataList
              activeIndex={ordersEarningObjIndex}
              // getDetails={getContractorDetails}
              dataList={ordersEarningList}
              dataItemObjProps={earningObjItemProps}
              // actions={contractorActions}
              marginTop={20}
              errMsg="No Data Found"
            /> : <p className={classes.textCenter}>No Data Available</p>
            
            }
          </Grid>
          <Grid item xs={12}>
            {ordersEarningList.length > 0 ?  <DataList
                // activeIndex={ordersEarningObjIndex}
                // getDetails={getContractorDetails}
                dataList={totalsObjArray}
                dataItemObjProps={totalEarningsObjItemProps}
                // actions={contractorActions}
                marginTop={20}
                errMsg="No Data Found"
              /> : null}
          </Grid>
        </Grid>
      </MainContent>
    </div>
  )
}


export default withStyles(styles)(Earnings);