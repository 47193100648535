import { 
  SET_JOB_AND_OPEN_MODAL,
  CLEAR_JOB_AND_CLOSE_MODAL,
  SET_AND_SHOW_ADDITIONAL_BOOKINGS,
  REMOVE_AND_HIDE_ADDITIONAL_BOOKINGS
} from "../types";
import  { cloneDeep } from "lodash";

const defaultState = {
  openJobChangesModal: false,
  job:{},
  showAdditionalBookings: false,
  additionalBookings: []
}


const reducer = (state = defaultState, action) => {
  let finalState = {}

  switch(action.type){
    
    case SET_JOB_AND_OPEN_MODAL:
      finalState = cloneDeep(state);
      finalState.job = cloneDeep(action.payload);
      finalState.openJobChangesModal = true
      break;

    case CLEAR_JOB_AND_CLOSE_MODAL:
      finalState = cloneDeep(state)
      finalState.job = {};
      finalState.openJobChangesModal = false
      break;

    case SET_AND_SHOW_ADDITIONAL_BOOKINGS:
      finalState = cloneDeep(state);
      finalState.showAdditionalBookings = true;
      finalState.additionalBookings = [ ...action.payload ];
      break;

    case REMOVE_AND_HIDE_ADDITIONAL_BOOKINGS: 
      finalState = cloneDeep(state);
      finalState.showAdditionalBookings = false;
      finalState.additionalBookings = [];
      break;

    default:
    finalState = state;
  }
  return finalState;
}


export default reducer;