function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 18,
    height: 18,
    viewBox: "0 0 18 18"
  }, props), React.createElement("g", {
    id: "prefix__Group_360",
    "data-name": "Group 360",
    transform: "translate(-499 -639)",
    style: {
      "fill": "none",
      "stroke": "#fff",
      "strokeLinecap": "round",
      "strokeLinejoin": "round",
      "strokeWidth": 2
    }
  }, React.createElement("circle", {
    id: "prefix__Ellipse_37",
    cx: 8,
    cy: 8,
    r: 8,
    className: "prefix__cls-1",
    "data-name": "Ellipse 37",
    transform: "translate(500 640)",
    style: {
      "fill": "none",
      "stroke": "#fff",
      "strokeLinecap": "round",
      "strokeLinejoin": "round",
      "strokeWidth": 2
    }
  }), React.createElement("path", {
    id: "prefix__Path_281",
    d: "M12 6v4.8l2.4 2.4",
    className: "prefix__cls-1",
    "data-name": "Path 281",
    transform: "translate(496 637.2)",
    style: {
      "fill": "none",
      "stroke": "#fff",
      "strokeLinecap": "round",
      "strokeLinejoin": "round",
      "strokeWidth": 2
    }
  })));
};

export default __webpack_public_path__ + "static/media/job_time.8208f504.svg";
export { SvgComponent as ReactComponent };