import React from "react";
import withStyles from "react-jss";
import cn from "classnames";


const styles = {
  root: {

  },
  hideButton: {
    position: "absolute",
    opacity:0,
    cursor:"pointer"
  },
  row:{
    display:"flex",
  },
  inputStyle:{
    border: "1px solid #4a4a4a",
    padding: "1.2rem 1rem",
    fontSize: "1.2rem",
    borderRadius: "0.6rem",
    background: "#fff",
    width: "100%",
    margin: "0.3rem 0",
    lineHeight: "normal",
    cursor:"pointer"
  },
  active:{
    background: "linear-gradient(180deg, #50E3C2 0%, #48CBD2 100%)",
    color: "#fff"
  },
  errors:{
    color:"red",
    fontSize:"0.75rem"
  }
};

const RadioField = ({
  classes,
  id,
  input,
  label,
  radioValue,
  isSelected,
  options,
  meta,
  showInRow,
  optionComponent:OptionComponent,
  ...other
})=>(
  <div className={cn(classes.root, {[classes.row] : showInRow})}>

    {meta && meta.touched &&
      ((meta.error && <span className={classes.errors}>{meta.error}</span>) ||
        (meta.warning && <span>{meta.warning}</span>))}

    { options && options.map((o, i) => {
      return (
        <OptionComponent  
          o={o}
          key={`option-item-${i}`}
          input={input}
          id={id}
          {...other}
        />
      )
    })}

  </div>
);





export default withStyles(styles)(RadioField);