import React, { PureComponent  } from 'react';
import cn from "classnames";
import { images, svgs } from "./images";
// const images = {
//   "assets/images/logo-final.png": require("assets/images/logo-final.png"),
//   "assets/images/svg/login_icon.svg": require("assets/images/svg/login_icon.svg"),
//   "assets/images/svg/banner_search_btn.svg": require("assets/images/svg/banner_search_btn.svg"),
//   "assets/images/svg/banner_svg.svg": require("assets/images/svg/banner_svg.svg"),
//   "assets/images/svg/property_analysis.svg": require("assets/images/svg/property_analysis.svg"),
//   "assets/images/svg/screened_pro.svg": require("assets/images/svg/screened_pro.svg"),
//   "assets/images/svg/service_guranteed_img.svg": require("assets/images/svg/service_guranteed_img.svg"),
//   "assets/images/appBooking_2.png" : require("assets/images/appBooking_2.png" ),
//   "assets/images/appBooking_1.png" : require("assets/images/appBooking_1.png" ),
//   "assets/images/appBooking_3.png" : require("assets/images/appBooking_3.png" ),
//   "assets/images/app_store_btn.png" : require("assets/images/app_store_btn.png" ),
//   "assets/images/g_play_btn.png" : require("assets/images/g_play_btn.png" ),
//   "assets/images/svg/quote_img.svg": require("assets/images/svg/quote_img.svg"),
//   "assets/images/svg/book_img.svg": require("assets/images/svg/book_img.svg"),
//   "assets/images/svg/clean_img.svg": require("assets/images/svg/clean_img.svg"),
//   "assets/images/svg/pay_img.svg": require("assets/images/svg/pay_img.svg"),
//   "assets/images/svg/features_img.svg": require("assets/images/svg/features_img.svg"),
//   "assets/images/svg/hand_shake.svg": require("assets/images/svg/hand_shake.svg"),
//   "assets/images/svg/customer_gain.svg": require("assets/images/svg/customer_gain.svg"),
//   "assets/images/svg/emp_management.svg": require("assets/images/svg/emp_management.svg"),
//   "assets/images/svg/scheduling_system.svg": require("assets/images/svg/scheduling_system.svg"),
//   "assets/images/svg/gps_tracking.svg": require("assets/images/svg/gps_tracking.svg"),
//   "assets/images/svg/emp_app.svg": require("assets/images/svg/emp_app.svg"),
//   "assets/images/avatar1.png" : require("assets/images/avatar1.png" ),
//   "assets/images/svg/star_ratings.svg": require("assets/images/svg/star_ratings.svg"),
//   "assets/images/avatar2.png" : require("assets/images/avatar2.png" ),
//   "assets/images/avatar3.png" : require("assets/images/avatar3.png" ),
//   "assets/images/zendesk.jpg" : require("assets/images/zendesk.jpg" ),
//   "assets/images/nationwide.jpg" : require("assets/images/nationwide.jpg" ),
//   "assets/images/water.org.jpg": require("assets/images/water.org.jpg"),
//   "assets/images/vrbo.jpg" : require("assets/images/vrbo.jpg" ),
//   "assets/images/amazon.jpg" : require("assets/images/amazon.jpg" ),
//   "assets/images/airbnb.jpg" : require("assets/images/airbnb.jpg" ),
//   "assets/images/logo_with_tagline.png" : require("assets/images/logo_with_tagline.png" ),
//   "assets/images/svg/facebook_icon.svg": require("assets/images/svg/facebook_icon.svg"),
//   "assets/images/svg/twitter_icon.svg": require("assets/images/svg/twitter_icon.svg"),
//   "assets/images/svg/insta_icon.svg": require("assets/images/svg/insta_icon.svg"),
//   "assets/images/svg/contact_banner.svg": require("assets/images/svg/contact_banner.svg"),
//   "assets/images/svg/whyZoklean_bg.svg": require("assets/images/svg/whyZoklean_bg.svg"),
//   "assets/images/svg/google_icon.svg": require("assets/images/svg/google_icon.svg"),
//   "assets/images/svg/facebook_icon_blue.svg": require("assets/images/svg/facebook_icon_blue.svg"),
//   "assets/images/svg/signup.svg": require("assets/images/svg/signup.svg"),
//   "assets/images/svg/home_icon.svg": require("assets/images/svg/home_icon.svg"),
//   "assets/images/svg/building_icon.svg": require("assets/images/svg/building_icon.svg"),
//   "assets/images/svg/privacy_banner.svg": require("assets/images/svg/privacy_banner.svg"),
//   "assets/images/svg/cleaning_info.svg": require("assets/images/svg/cleaning_info.svg"),
//   "assets/images/svg/deal.svg": require("assets/images/svg/deal.svg"),
//   "assets/images/svg/mobile_app.svg": require("assets/images/svg/mobile_app.svg"),
//   "assets/images/svg/wallet.svg": require("assets/images/svg/wallet.svg"),
//   "assets/images/svg/cookie_info.svg": require("assets/images/svg/cookie_info.svg"),
//   "assets/images/svg/email.svg": require("assets/images/svg/email.svg"),
//   "assets/images/svg/marketing.svg": require("assets/images/svg/marketing.svg"),
//   "assets/images/svg/affiliated_business.svg": require("assets/images/svg/affiliated_business.svg"),
//   "assets/images/svg/contractors.svg": require("assets/images/svg/contractors.svg"),
//   "assets/images/avatar.png": require("assets/images/avatar.png"),
//   "assets/images/pin-location.png": require("assets/images/pin-location.png"),
//   "assets/images/buildings.png": require("assets/images/buildings.png"),
//   "assets/images/calender.png": require("assets/images/calender.png"),
//   "assets/images/time.png": require("assets/images/time.png"),
//   "assets/images/svg/edit.svg": require("assets/images/svg/edit.svg"),
//   "assets/images/svg/cancel.svg": require("assets/images/svg/cancel.svg"),
//   "assets/images/svg/job_started.svg": require("assets/images/svg/job_started.svg"),

// }

class Images extends PureComponent {
  imageSrc = "";

  constructor(props) {
    super(props);
    const src = props.src.split("/").pop();
    this.imageSrc = images[src] || svgs[src];
    
    if(props.src.indexOf("http") > -1) {
      this.imageSrc = props.src;
    }
  }

  getSrc = () => {
    const { src } = this.props;
    const imageKey = src.split("/").pop();
    let imageSrc = images[imageKey] || svgs[imageKey];
    
    if(src.indexOf("http") > -1) {
      imageSrc = src;
    }
    return imageSrc;
  }

  render() {
    const { src, style = {}, className, width, height, spaceVertical, spaceTop, spaceBottom, spaceRight, spaceLeft, spaceHorizontal, xs, fluid, ...other } = this.props;
    
    const styles = {...style};
    if(fluid) styles.maxWidth = "100%";
    if(width) styles.width = width;
    if(height) styles.height = height;
    if(spaceTop) styles.marginTop = spaceTop;
    if(spaceBottom) styles.marginBottom = spaceBottom;
    if(spaceVertical) {
      styles.marginTop = spaceVertical;
      styles.marginBottom = spaceVertical;
    }
    if(spaceRight) styles.marginRight = spaceRight;
    if(spaceLeft) styles.marginLeft = spaceLeft;
    if(spaceHorizontal) {
      styles.marginRight = spaceHorizontal;
      styles.marginLeft = spaceHorizontal;
    }
    const classnames = cn(className, 'disable-select')
    return <img
      alt="loading"
      src={this.getSrc()}
      style={styles}
      className={classnames}
      {...other}
    />
      
  }
};

export default Images;
export {
  Images,
  images,
  svgs
}