import React from "react";
import cn from "classnames";
import { withStyles, WithStyles, createStyles, Theme} from "../../core/components/Styles";
import Grid from "../../core/components/Grid";

const styles = (theme:Theme)=> createStyles(({
	billAmountContainer:{
    boxShadow: "0 3px 12px 0 rgba(0, 0, 0, 0.16)",
    backgroundImage: "linear-gradient(to bottom, #fea46e, #fa656b)",
    borderRadius: "0 0 4px 4px",
		color: "#ffffff",
		height: "5.5rem",
		[theme.breakpoints.up('md')]:{
			marginLeft: "15rem"
		}
	},
	amountAndDiscountContainer:{
		textAlign: "center"
	},
	costAndDiscountText:{
		paddingLeft: "1rem",
		fontSize: ".71rem"
	},
	padBottom:{
		paddingBottom: ".3rem"
	},
	padTop:{
		paddingTop: ".3rem"
	},
	costAndDiscount: {
		fontSize: ".78rem",
		fontWeight: 600
	},
	totalAmountContainer:{
		backgroundImage: "linear-gradient(to top left, #fea46e, #fa656b)",
		padding: "1.2rem 1rem .69rem 2rem",
		textAlign: "center",
		height: "inherit",
		borderTopLeftRadius: "4rem"
	},
	totalAmountText:{
		margin: ".4rem 0",
		fontSize: ".71rem"
	},
	totalAmount: {
		fontSize: ".92rem",
		fontWeight: 600,
		margin: ".4rem 0",
	},
	addPadding:{
		[theme.breakpoints.down("sm")]:{
			paddingBottom: '4rem'
		}
	}
}));

export interface CostBreakDownProps extends WithStyles<typeof styles> {
	orderCost: number,
	discount: string,
	originalCost: number
} 

const CostBreakDown = (props:CostBreakDownProps)=>{
	const { classes, orderCost, discount, originalCost } = props;
	return(
		<Grid container item
			className = {classes.addPadding} 
			justify="center" 
			alignItems="flex-start" 
			xs = {12}  
			md = {6} 
		>
			{ orderCost &&
				<Grid item container 
					className={classes.billAmountContainer}
					xs = {6}
 				>
					<Grid item 
						container
						direction = "column"
						justify = "center"
						alignItems = "flex-start" 
						className = {classes.amountAndDiscountContainer}
						xs={6}
					>
						<Grid item className={cn([classes.costAndDiscountText,classes.padBottom])}>
							Billed Amt : 
							<span className={classes.costAndDiscount}>
							{ ` $${originalCost.toFixed(1)}` }
							</span>
						</Grid>
						<Grid item className={cn([classes.costAndDiscountText,classes.padTop])}>
							Discount : 
							<span className={classes.costAndDiscount}>
								{`  ${discount}`}
							</span>
						</Grid>
					</Grid>
					<Grid item 
						className = {classes.totalAmountContainer}
						xs={6}
					>
						<p className = {classes.totalAmountText}>
						  NET AMT
						</p>
						<p className = {classes.totalAmount}>
							{` $${orderCost}`}
						</p>
					</Grid>
				</Grid>
			}
		</Grid>
	)
};

export default withStyles(styles)(CostBreakDown);