import React, { Component } from "react";
import Grid from "core/components/Grid";
import Button from "core/components/Button";
import Card from "core/components/Card";
import CardContent from "core/components/CardContent";
import Select from "core/components/Select";
import Typography from "core/components/Typography";
import MenuItem from "core/components/MenuItem";
import { withStyles } from "core/components/Styles";
import {cardHeader, cardSubHeader} from "theme/colors";
import cn from "classnames";

// Custom Component;
import MainContent from "../components/MainContent";
import DataList from "../components/DataList";
import { Calendar } from "mdi-material-ui";

// dataList, dataItemObjProps
const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex"
  },
  invoiceDetailsCont: {
    marginTop: 10
  },
  infoCard: {
    borderRadius: 0
  },

  invoicesAdvanceDetailsCont: {
    marginTop: 10,
    padding: 10
  },
  // invoiceDetailsContBlock: {
  //   height: "100%"
  // },
  invoicesDetailsRow: {
    height: "100%"
  },
  textAlignRight: {
    textAlign: "right"
  },
  btnControlsCont: {
    marginTop: 20
  },
  calIcon: {
    fontSize: 30
  },
  btnNav: {
    border: "1px solid #9b9b9b",
    fontSize: 11,
    maxHeight: 32,
    fontWeight: "normal",
    borderRadius: 0,
    textTransform: "capitalize",
    minHeight: 26,
    padding: 0,
    minWidth: 70,
    "&:hover": {
      color: "inherit"
    }
  },
  btnNavigateBar: {
    marginLeft: 20,
    "& button:first-child": {
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20
    },
    "& button:last-child": {
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20
    }
  },
  fullHeight: {
    height: "100%"
  },
  inProgressJobs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  cardTopMargin_30: {
    marginTop: 30
  },
  cardHeader: {
    color: cardHeader,
    fontWeight: 400
  },
  cardSubHeader: {
    color: cardSubHeader,
    fontWeight: 300,
    marginBottom: 15
  },
  cardText: {
    color: "#6c6c6c",
    fontSize: 40,
    fontWeight: 300
  },
  cardSelect: {
    color: cardHeader,
    "&:before, &:after": {
      display: "none"
    }
  }
});

const jobsInvoices = [
  {
    orderId: 3423235,
    dateOfBooking: "13 Aug 2018",
    dateOfCleaning: "28 Aug 2018",
    time: "07:30AM",
    cost: "$117",
    paymentStatus: "Received"
  },
  {
    orderId: 3423235,
    dateOfBooking: "13 Aug 2018",
    dateOfCleaning: "28 Aug 2018",
    time: "07:30AM",
    cost: "$117",
    paymentStatus: "Pending"
  },
  {
    orderId: 3423235,
    dateOfBooking: "13 Aug 2018",
    dateOfCleaning: "28 Aug 2018",
    time: "07:30AM",
    cost: "$117",
    paymentStatus: "Invoice Rejected"
  },
  {
    orderId: 3423235,
    dateOfBooking: "13 Aug 2018",
    dateOfCleaning: "28 Aug 2018",
    time: "07:30AM",
    cost: "$117",
    paymentStatus: "Received"
  }
];

const jobInvoicesProps = [
  {
    name: "Order ID",
    label: "orderId"
  },
  {
    name: "Date of Booking",
    label: "dateOfBooking"
  },
  {
    name: "Date of Cleaning",
    label: "dateOfCleaning"
  },
  {
    name: "Time",
    label: "time"
  },
  {
    name: "Cost",
    label: "cost"
  },
  {
    name: "Payment",
    label: "paymentStatus"
  }
];

class InvoiceContainer extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <MainContent>
          <Grid container  className={classes.invoiceDetailsCont}>
            <Grid item xs={8}>
              <Card className={classes.infoCard}>
                <CardContent className={classes.invoiceDetailsContBlock}>
                  <Grid
                    container
                    justify="space-between"
                    alignContent="center"
                    className={classes.invoicesDetailsRow}
                  >
                    <Typography variant="title" gutterBottom className={classes.cardHeader}>
                      Invoice
                    </Typography>

                    <Select value="today" className={classes.cardSelect}>
                      <MenuItem value="today">Today</MenuItem>
                      <MenuItem value="week">This week</MenuItem>
                      <MenuItem value="month">This Month</MenuItem>
                    </Select>
                  </Grid>
                  <Grid
                    container
                    className={classes.invoicesAdvanceDetailsCont}
                    justify="space-between"
                  >
                    <Grid item>
                      <Typography variant="title" gutterBottom className={classes.cardSubHeader}>
                        Due
                      </Typography>
                      <span className={classes.cardText}>$2,652.07</span>
                    </Grid>
                    <Grid item>
                      <Typography variant="title" gutterBottom  className={classes.cardSubHeader}>
                        Paid
                      </Typography>
                      <span className={classes.cardText}>$2,034.08</span>
                    </Grid>
                    <Grid item>
                      <Typography variant="title" gutterBottom  className={classes.cardSubHeader}>
                        Un-Billed
                      </Typography>
                      <span className={classes.cardText}>$1,675.08</span>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                className={cn(
                  classes.infoCard,
                  classes.fullHeight,
                  classes.inProgressJobs
                )}
              >
                <CardContent
                  className={cn(
                    classes.invoiceDetailsContBlock,
                    classes.cardTopMargin_30
                  )}
                >
                  <Typography variant="title" gutterBottom align="center" className={classes.cardSubHeader}>
                    Inprogress Jobs
                  </Typography>
                  <span className={classes.cardText}>$2,034.08</span>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item className={classes.btnControlsCont}>
            <Grid container alignItems="center" justify="flex-end">
              <Calendar className={classes.calIcon} />
              <Grid className={classes.btnNavigateBar}>
                <Button className={cn(classes.btnNav)}>Day</Button>
                <Button className={cn(classes.btnNav)}>Week</Button>
                <Button className={cn(classes.btnNav)}>Month</Button>
              </Grid>
            </Grid>
          </Grid>
          {jobsInvoices && (
            <DataList
              dataList={jobsInvoices}
              dataItemObjProps={jobInvoicesProps}
              marginTop={20}
            />
          )}
        </MainContent>
      </div>
    );
  }
}


export default withStyles(styles)(InvoiceContainer);
