import React from "react";
import { Grid, 
  // IconButton, 
  Paper, 
  Typography, 
  // Divider, 
  List, 
  // ListItem
 } from "core/components";
import { withStyles } from "core/components/Styles";
// import { EyeOutline } from "mdi-material-ui";
import cn from 'classnames';
// import momentTz from "moment-timezone";
import JobAlertItem from "./JobAlertItem";

const styles = (theme) => ({
  root: {
    padding:24,
    backgroundColor:"#f34435"
  },
  attentionLabel:{
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold"
  },
  paperCont:{
    margin: 15
  },
  closeBtnIcon:{
    width: 20,
    height: 20
  },
  divider:{
    backgroundColor:"#fff"
  },
  infoBtn:{
    width: 18,
    height: 18,
    color:"#fff"
  },
  jobAlert:{
    padding: 10
  },
  listItemDivider:{
    borderTop: "1px solid #fff"
  },
  list:{
    padding:0
  },
  jobAlertItem:{
    padding:0,
    color: "#fff"
  },
  marginBottom_20:{
    marginBottom: 20
  }
})



// const list = [
//   {
//     serviceAddress:{
//       fname:"Chris",
//       lname:"Lanme",
//       address:"1515 St Woolsey St",
//       city:"Berkeley",
//       state:"CA"
//     },
//     time:"14:30",
//     date:"2019-06-03"
//   },
//   {
//     serviceAddress:{
//       fname:"Chris",
//       lname:"Lanme",
//       address:"1515 St Woolsey St",
//       unit:"15",
//       city:"Berkeley",
//       state:"CA"
//     },
//     time:"14:30",
//     date:"2019-06-03",
//     changes:{
//       time:"15:00",
//       date:"2019-06-10"
//     }
//   },
//   {
//     serviceAddress:{
//       fname:"Chris",
//       lname:"Lanme",
//       address:"1515 St Woolsey St",
//       city:"Berkeley",
//       state:"CA"
//     },
//     time:"14:30",
//     date:"2019-06-03"
//   },

// ]







const ChangeInJobAlert = (props) => {
  const {  classes, openJobChangesModal, ordersWithChanges } = props;
  return (
    ordersWithChanges && ordersWithChanges.length > 0 ? 

    <Grid className={classes.paperCont}>
        <Paper className={classes.root} elevation={1}>
          <Typography variant="subtitle1" className={cn(classes.attentionLabel, classes.marginBottom_20)}>
                  These Jobs requires immediate attention and action
          </Typography>
          <List className={classes.list}>
                  
            {
              ordersWithChanges.map((o, index) => {
                return (
                  <JobAlertItem l={o} itemIndex={index}  key={`job-alert-item-${index}`} openJobChangesModal={openJobChangesModal(o)}/>
                )
              })
            }

          </List>
        </Paper>
    </Grid> : null
  )
}



export default withStyles(styles)(ChangeInJobAlert)