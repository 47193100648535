import React from 'react';
import Grid from "../../core/components/Grid";
import Typography from "../../core/components/Typography";
import { withStyles } from "../../core/components/Styles";
import AssignedCleaner from "./AssignedCleaner";
import { muted } from "theme/colors";


const styles = {
  mutedHeading: {
      color: muted,
      fontWeight: 300,
      marginBottom:5,
    },
  cleanersInfo:{
    paddingLeft:"3.75rem",
    paddingRight:"3.75rem"
  },
  labelText:{
    color:'#6b6b6b',
    marginBottom:"1.25rem"
  }
}
const AssignedCleanersInfo = ({
  cleaners,
  classes
}) => {
  return (
    cleaners && cleaners ?
      <Grid className={classes.cleanersInfo} >
        <Typography
          className={classes.labelText}
        >
          Assigned Cleaners ----
        </Typography>

        <Grid container spacing={2}>
          {cleaners.map((cleaner, index) => (
            <Grid item key={`assigned-cleaner-item-${index}`}>
              <AssignedCleaner
                cleaner={cleaner}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      : null
  )
}


export default withStyles(styles)(AssignedCleanersInfo);