import axios from 'utils/http';
import { contractor } from "./servicesConf";

export async function getCustomers(value) {
  try {
    let url = `${process.env.REACT_APP_API_PREFIX}${contractor.base}${contractor.customers.base}`;
    if(value) url += `?q=${value}`;
    return await axios.get(url);
  } catch (error) {
    throw error; 
  }
}

export async function getServices() {
  try {
    return await axios.get(`${process.env.REACT_APP_API_PREFIX}${contractor.base}${contractor.services.base}${contractor.services.getAll}`);
  } catch (error) {
    throw error; 
  }
}

export async function addInvoice(body) {
  try {
    return await axios.post(`${process.env.REACT_APP_API_PREFIX}${contractor.base}${contractor.invoices.base}${contractor.invoices.add}`, body);
  } catch (error) {
    throw error; 
  }
}

export async function editInvoice(invoice_id,body) {
  try {
    return await axios.put(`${process.env.REACT_APP_API_PREFIX}${contractor.base}${contractor.invoices.base}/${invoice_id}`, body);
  } catch (error) {
    throw error;    
  }
}

export async function getInvoice(invoice_id) {
  try {
    return await axios.get(`${process.env.REACT_APP_API_PREFIX}${contractor.base}${contractor.invoices.base}/${invoice_id}`);
  } catch (error) {
    throw error;    
  }
}

export default {
  getCustomers,
  getServices,
  addInvoice,
  editInvoice,
  getInvoice
}