import React from "react";
// import withStyles from "react-jss";
import cn from "classnames";
import { withStyles } from "core/components/Styles";


const RadioField = ({classes, id, input, label, radioValue, checked, meta})=>{
  let inputValue;
  if(!isNaN(radioValue)) inputValue = JSON.stringify(radioValue);

  return (
    <label htmlFor={id} 
      className={cn(classes.inputStyle, inputValue === input.value ? classes.active : "")} >
      <input
        {...input}
        checked={checked}
        type="radio"
        className={cn("form-control", classes.radioBtn)}
        id={id}
        value={radioValue || ""}
      />
       <span>{label}</span>
    </label>
  );
};


const styles = theme => ({
  inputStyle:{
    lineHeight: "normal",
    padding: 10,
    cursor:"pointer",
    display: "flex",
    alignItems: "center"
  },
  radioBtn:{
    margin:0,
    marginRight: 8,
   
  },
  active:{
    background: "linear-gradient(135deg, #5d00b4 0%, #5d00b4 11%, #5d00b4 11%, #5d00b4 30%, #e570e7 100%)",
    color: "#fff"
  }
});


export default withStyles(styles)(RadioField);