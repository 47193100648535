import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import jobsReducer from "./jobsReducer";
import contractorReducer from "./contractorReducer";
import employeeReducer from "./employeeReducer";
import todosReducer from "./todosReducer";
import popupReducer from "./popupReducer";
import appReducer from "./appReducer";
import jobChangesReducer from "./jobChangesReducer";

const rootReducer = combineReducers({
  form,
  jobs: jobsReducer,
  contractor: contractorReducer,
  employee: employeeReducer,
  todos: todosReducer,
  popup: popupReducer,
  app: appReducer,
  jobChanges: jobChangesReducer
});

export default rootReducer;