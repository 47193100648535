
import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import AddOnTag from "../../assets/images/svg/job_add_on_tag.svg";

const styles = theme =>({
  addOnsItem:{
    borderRadius: "1.5rem",
    border: "1px solid #00949c",
    margin: ".62rem 1.8rem 1.8rem 0",
    padding: ".37rem .34rem .6rem .34rem",
    height: "5.22rem",
    width: "8.57rem"
  }, 
  addOnsTag: {
    backgroundImage: `url(${AddOnTag})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: ".43rem",
    textAlign: "center",
    width: "6.2rem",
    height: "2.84rem",
    position: "relative",
    top: "-1.25rem",
    left: "-.79rem",
    color: "#ffffff",
    fontSize: ".85rem"
  }, 
  addOnInner:{
    width: "7.79rem",
    height: "4.39rem",
    boxShadow: "1px 1px 3px 0 rgba(0, 0, 0, 0.2)",
    backgroundColor: "#ffffff",
    borderRadius: "1.2rem",
    marginTop: "-2.85rem"
  },
  displayFlex:{
    height:"inherit",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: ".6rem"
  },
  addOnText:{
    fontSize: '1rem',
    color: "#636363",
    paddingLeft: ".85rem"
  }
});

const AddOn = (props)=>{
  const {classes, icon, addOn, label} = props;
  return(
    <Grid className={classes.addOnsItem}>
      <div className={classes.addOnsTag}>
        <span>{label}</span>
      </div>
      <div className={classes.addOnInner}>
        <div className={classes.displayFlex}>
          {icon}
          <span className={classes.addOnText}>
            {addOn}
          </span>
        </div>
      </div>
    </Grid>
  )
}

export default withStyles(styles)(AddOn);