import React from "react";
import Grid from "core/components/Grid";
import Avatar from "core/components/Avatar";
import Checkbox from "core/components/Checkbox";
import { withStyles } from "core/components/Styles";
import cn from "classnames";
import employeePlaceholder from "../../images/utils/employee-placeholder.png";

const styles = theme => ({
  freeEmployeeList:{
    marginTop: 10,
  },
  employeeImg:{
    height: 40,
    width: 40,
    marginRight:10,
    "& .avatar-text":{
      textTransform: "uppercase",
      fontSize:"1rem"
    }
  }
})
const EmployeeList = ({
  employeeList,
  jobItemObj,
  classes,
  assignJob
}) => {

  let { assignedEmployeeIds = [] } = jobItemObj
  assignedEmployeeIds = assignedEmployeeIds && assignedEmployeeIds.length > 0 ? assignedEmployeeIds : []
  return (
    <Grid className={classes.freeEmployeeList}>
    {employeeList && employeeList.map((employee, key) => {

      const { fname, lname, profileImage, userType } = employee;
      let imageURL
      if(userType === "employee" || userType === "contractor"){
        if(profileImage){
          imageURL = `${process.env.REACT_APP_USER_FILES}${profileImage}`;
        } else {
          imageURL = employeePlaceholder;
        }
      }
      return (
        <Grid
          key={`employpee-list-${key}`}
          container
          alignContent="center"
          alignItems="center"
          justify="space-between"
          >
            <Avatar
              className={cn(classes.employeeImg,"profile-btn-avatar img-fluid mr-2")}
              image={imageURL}
              name={`${fname} ${lname}`}
              disableBorder
            />
            <p>{employee.fname + " " + employee.lname}</p>
          <Checkbox value={employee.userId} color="primary" onChange={assignJob} checked={assignedEmployeeIds.includes(employee.userId)} />
        </Grid> 
      );
    })}
  </Grid>
  )
}




export default withStyles(styles)(EmployeeList);