import React from "react";
import { withStyles } from "core/components/Styles";

const styles = theme => ({
	contentText:{
    fontSize : 20
	}
});

const Content = ({text,classes})=>(
	<div>  
		<p className={classes.contentText}>
			{text}
		</p>
	</div>
)
export default withStyles(styles)(Content);