import React from "react";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { MuiThemeProvider } from './core/components/Styles';
import "./index.css";
import "assets/icons/style.css"
import "assets/css/helpers.css";
import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";
import store from "./store/store";
import theme from "./theme/theme"


import mixpanel from "mixpanel-browser";
import { MixpanelProvider } from "./utils/react-mixpanel";

mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`);

const history = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <MixpanelProvider mixpanel={mixpanel}>
      <MuiThemeProvider theme={theme}>
        <Router history={history}>
          <Route path="/" component={App}/>
        </Router>
      </MuiThemeProvider>
    </MixpanelProvider>
  </Provider>,
  document.getElementById("root")
);
// registerServiceWorker();
