

type OrderType = "domestic" | "commercial" | "carpet";


const checkOrderType = (type:OrderType) => {
  return {
    isCarpet:type && type === "carpet",
    isCommercial:type && type === "commercial",
    isDomestic:type && type === "domestic"
  }
}


export default checkOrderType;