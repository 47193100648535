import React from 'react';
import PropTypes from 'prop-types';

const MixpanelContext = React.createContext();
export default MixpanelContext

MixpanelContext.Provider.propTypes = {
    value: PropTypes.shape({
        init: PropTypes.func.isRequired,
        track: PropTypes.func.isRequired,
    })
};

export class MixpanelProvider extends React.Component {
    render() {
        return <MixpanelContext.Provider value={this.props.mixpanel}>
            {this.props.children}
        </MixpanelContext.Provider>
    }
}

export const mixpanelShape = PropTypes.shape({
    init: PropTypes.func.isRequired,
    track: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    people: PropTypes.object.isRequired,
});

MixpanelProvider.propTypes = {
    children: PropTypes.node.isRequired,
    mixpanel: mixpanelShape
};

export const MixpanelConsumer = MixpanelContext.Consumer;


export function withMixpanel(WrappedComponent) {
    return class extends React.Component {

    onSite = "zoklean-contractor";

    isHome = (pathname) => (
      pathname === "/" ? "/home" : pathname
    );

    trackPageLoad = (mixpanel)=> (pageName)=> {
      mixpanel.track(`${pageName}_pageEnter`,{
        onSite: this.onSite
      });
    }

    trackLinkEvent = (mixpanel)=> (linkText, pathname)=> ()=> {
      mixpanel.track(`${linkText}_linkClick`,{
        onSite: this.onSite,
        onPage: this.isHome(pathname),
      });
    }

    trackButtonEvent = (mixpanel)=> (buttonName, pathname)=>{ 
      mixpanel.track(`${buttonName}_btnClick`,{
        onSite: this.onSite,
        onPage: this.isHome(pathname),
      });
    } 

    trackInputOnBlur = (mixpanel)=> (fieldName, value, pathname)=>{
      mixpanel.track(`${fieldName}_onBlur`,{
        onSite: this.onSite,
        onPage: this.isHome(pathname),
        fieldValue: value
      });
    }

    trackDropdownInput = (mixpanel) => (Itemlabel, value, header, isAQuestion)=> {
      if(isAQuestion){
        mixpanel.track(`Question_Related_To_${header}`,{
          question: Itemlabel,
          value,
          onSite: this.onSite
        });
      }else{
        mixpanel.track(`${header}_onDropSelect`,{
          item: Itemlabel,
          value,
          onSite: this.onSite,
        });
      }
    }

    trackPickerEvent = (mixpanel)=> (label, value)=>{
      mixpanel.track(`${label}_Pick`,{
        value,
        onSite: this.onSite,
      });
    }

    trackMultiPickEvent = (mixpanel)=> (label,listOfValues)=>{
      mixpanel.track(`${label}_multiPick`,{
        list: listOfValues,
        onSite: this.onSite,
      })
    }

    trackApiEvent = (mixpanel)=> (buttonName, type, apiRoute, response, pathname)=> {
			mixpanel.track(`${buttonName}_apiCall`,{
        requestType: type,  
        apiRoute,
        apiResponse: response,
        onPage: this.isHome(pathname),
        onSite: this.onSite,
      });
    }

    trackRadioInput = (mixpanel)=> (fieldName, defaultValue, selectedValue, pathname)=> {
      mixpanel.track(`${fieldName}_onSelect`,{
        onSite: this.onSite,
        onPage: this.isHome(pathname),
        defaultValue,
        selectedValue
      });
    }

    trackLinkEvent_inBlock = (mixpanel)=> (linkText, pathname)=> {
      mixpanel.track(`${linkText}_linkClick`,{
        onPage: this.isHome(pathname),
        onSite: this.onSite,
      });
    }

    render() {
      return <MixpanelConsumer> 
        {mixpanel => 
          <WrappedComponent 
            mixpanel={mixpanel}
            trackLinkEvent={this.trackLinkEvent(mixpanel)} 
            trackButtonEvent={this.trackButtonEvent(mixpanel)} 
            trackInputOnBlur={this.trackInputOnBlur(mixpanel)}
            trackApiEvent={this.trackApiEvent(mixpanel)}
            trackPageLoad={this.trackPageLoad(mixpanel)}
            trackDropdownInput={this.trackDropdownInput(mixpanel)}
            trackLinkEvent_inBlock={this.trackLinkEvent_inBlock(mixpanel)}
            trackPickerEvent={this.trackPickerEvent(mixpanel)}
            trackMultiPickEvent={this.trackMultiPickEvent(mixpanel)}
            trackRadioInput={this.trackRadioInput(mixpanel)}
            {...this.props}  
          />}
      </MixpanelConsumer>
    }
  };
}