import React, { Fragment } from "react";
import Grid from "core/components/Grid";
import Button from "core/components/Button";
import DialogActions from "core/components/DialogActions";
import DialogContent from "core/components/DialogContent";
import DialogTitle from "core/components/DialogTitle";
import {  FieldArray } from "redux-form";
import { GroupedMaterialInputField } from "../FormField";
import { required } from "../FormField/Validations";

const InviteForm = (props) => {
  const { handleSubmit, submitData, onModalClose, addInvites } = props;
  return (
      <Fragment>
          <DialogTitle id="form-dialog-title">Invite Users</DialogTitle>
          <DialogContent>
            <FieldArray
              name="emails"
              component={GroupedMaterialInputField}
              validate={[required]}
            />
          </DialogContent>
          <DialogActions>
            <Grid container alignItems="center" justify="space-between">
            <Grid item xs>
              <Button onClick={addInvites} color="primary">
                Add More
              </Button>
            </Grid>
            <Grid item xs container justify="flex-end" alignItems="center">
            <Button onClick={onModalClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit(submitData)} color="primary">
              Invite
            </Button>
            </Grid>
    
            </Grid>

          </DialogActions>
        </Fragment>
      // </Form>
  )
}


export default InviteForm;