import React from "react";
import { Grid, Typography } from "core/components";
import { withStyles } from "core/components/Styles";
import { ToolTipChart } from "../EChart";
import cn from "classnames";

const styles = () => ({
  graphInfoCont:{
    background:"#ffffff",
    borderRadius: 12,
    border:"1px solid #f6f5f5",
    boxShadow:"0 2px 48px 1px rgba(176, 192, 237, 0.42)",
    height:"100%",
    width: "100%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    overflow:"hidden"
  },
  graphTitle:{
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize:20,
    lineHeight: "30px",
    color: "#121958"
  },
  graphicSubHeading:{
    color:"#121958",
    marginTop: 1.8,
    fontSize : "34px",
    lineHeight : "51px",
    fontWeight : "600"
  },
  chartCont:{
    width: "100%",
  },
  graphDataCont:{
    paddingLeft: 28,
    paddingRight: 28,
    paddingTop: 24,
  } 
})

const GraphInfo = (props) => {
  const { classes, chartHeight, fontSize, title, subHeading, lineStyle, areaStyle, data , titleStyle, subHeadingStyle} = props;
  const styles = {};
  if(fontSize){
    styles.fontSize = fontSize;
  }
  return (
    <Grid className={classes.graphInfoCont}>
      <Grid className={classes.graphDataCont}>
        <Typography className={ cn(classes.graphTitle, titleStyle) }>
          {title}
        </Typography>
        <Typography className={ cn(classes.graphicSubHeading, subHeadingStyle)} style={styles}>
          {subHeading}
        </Typography>
      </Grid>
      <Grid className={classes.chartCont}>
        <ToolTipChart chartHeight={chartHeight} lineStyle={lineStyle} areaStyle={areaStyle} data={data}/>
      </Grid>
    </Grid>
  )
}


export default withStyles(styles)(GraphInfo);