import React from "react";

import JobsDashboardContainer from "containers/JobsDashboardContainer";
import { MainLayout } from "layouts";

const JobsPage = (props) => (
  <MainLayout {...props}>
    <JobsDashboardContainer {...props} />
  </MainLayout>
)

export default JobsPage;