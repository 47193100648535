function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 6.4,
    height: 8.76,
    viewBox: "0 0 6.4 8.76"
  }, props), React.createElement("g", {
    id: "prefix__Livello_110",
    transform: "translate(-19.989)",
    style: {
      "opacity": 0.94
    }
  }, React.createElement("path", {
    id: "prefix__Path_300",
    d: "M24.729 3.185a1.6 1.6 0 1 1-1.6-1.593 1.6 1.6 0 0 1 1.6 1.593m1.66 0a3.2 3.2 0 1 0-5.662 2.035l2.465 3.54 2.5-3.592a3.148 3.148 0 0 0 .246-.354l.025-.036a3.16 3.16 0 0 0 .429-1.593",
    "data-name": "Path 300",
    style: {
      "fill": "#fff"
    }
  })));
};

export default __webpack_public_path__ + "static/media/job_address_pin.0d63c990.svg";
export { SvgComponent as ReactComponent };