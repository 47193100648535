/* eslint-disable */
import React from "react";
import Grid from "core/components/Grid";
import { withStyles } from "core/components/Styles";
import { Typography, List, ListItem } from "core/components";
import SearchBar from "../SearchBar";
// import CustomerItem from "../CustomerItem";
import FabButton from "core/components/FabButton";
import CustomerListItem from "../ListItem"

import Pagination from "core/components/Pagination";
import Joyride from "react-joyride";
import { customerListSteps } from "../TourGuide/TourGuideSteps";
import {tooltipButtonColor} from "../../theme/colors";


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
  },
  jobsBtnCont: {
    marginTop: 10
  },
  newJobBtnCont: {
    marginTop: 10
  },
  jobslistCont: {
    marginTop: 10
  },
  textCenter: {
    textAlign: "center"
  },
  mainCont:{
    padding: "25px 85px 25px 85px",
    width:"100%",
    [theme.breakpoints.down('sm')]: {
      padding: 16
    }
  },
  pageTitle:{
    fontFamily : "Roboto",
    fontWeight : "normal",
    lineHeight : "25px",
    fontSize: 21,
    marginLeft: 6,
    color:"#697b8c"
  },
  searchBoxCont:{
    width: 440,
    height : 40,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: "100%"
    }
  },
  customersCont:{
    marginTop: 0
  },
  customerListItem: {
    marginTop: 25
  },
  fabBtn:{
    position:"fixed",
    right: 20,
    bottom: 20,
    zIndex: 99,
    height:40,
    width: 40,
    background:"#ed5454",
    color: "#fff",
    "&:hover":{
      background:"#ed5454",
      boxShadow:"0px 3px 5px -1px rgba(0, 0, 0, 0.4), 0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 1px 18px 0px rgba(0, 0, 0, 0.15)"
    }
  },
  addIcon:{
    height:12,
    width:12,
 
  },
  noCustomerText:{
    marginTop: 20,
    textAlign:"center"
  },
  customerContainer :{
    marginTop : 14,
    padding : 0
  },
  paginateWrapper:{
    marginTop:20,
  }
  // customersList:{
  //   overflow:"scroll"
  // }
});

const headers = [
  {
    name: "customerName",
    label: "Customer Name"
  },
  {
    name: "email",
    label: "Email"
  },
  {
    name: "phone",
    label: "Phone"
  },
  {
    name: "serviceAddress",
    label: "Address"
  },
  {
    name: "actions",
    label: "Actions"
  }
];

const columnProps = [
  {
    fnameProp: "fname",
    lnameProp: "lname",
    isName: true
  },
  {
    name: "email"
  },
  {
    name: "phone"
  },
  {
    cityProp: "city",
    stateProp: "state",
    zipcodeProp: "zipcode",
    addressProp: "address",
    name: "serviceAddress",
    isAddress: true
  }
];

const Customers = ({
  classes,
  customers,
  actions,
  openCustomerModal,
  openCustomerDetails,
  handleCustomerSearch,

  trackBlurInput,
  trackMapLink,

  runGuide,
  handleJoyrideCallback,
  getHelpers,
  handlePageChange,
  totalPages,
  currPage,
  q,
  clearSearch
}) => {

  // const customersArray = []
  // customers.forEach((customer, i)=>{
  //   const index = customersArray.length - 1;
  //   const nextIndex = customersArray.length;
  //   const curentIndex = i + 1;
  //   if(curentIndex % 2 === 0 && customersArray[index]){
  //     customersArray[index].push(customer)
  //   } else {
  //     customersArray[nextIndex] = [ customer ]
  //   }
  // });


  return (
    <div className={classes.root}>
      <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={runGuide}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={customerListSteps}
        styles={{
          options: {
            primaryColor: tooltipButtonColor  
          }
        }}
      />
      <Grid className={classes.mainCont} id="customersListCont"> 

        <FabButton onClick={openCustomerModal}/>
        <Grid item>
          <Typography className={classes.pageTitle}>Customer List</Typography>
        </Grid>
        <Grid item className={classes.searchBoxCont} id="customersSearch">
          <SearchBar 
            onChange={handleCustomerSearch} 
            placeholder="Search Customer" 
            onBlur={trackBlurInput} 
            reverse 
            value={q}
            onClear={clearSearch}
          />
        </Grid>

        <Grid className = {classes.customerContainer} >
          <List className={classes.customersCont} id="customersCont"> 
            {
              customers.length > 0 ? 
              customers.map((customer, index) => (
                <CustomerListItem
                  key={`customer-item-key-${index}`} 
                  user={customer}  
                  editUser = {openCustomerDetails}
                  className={classes.customerListItem}

                  trackLink={trackMapLink}

                /> 
              )) :<ListItem><Typography className={classes.noCustomerText}> No Customer </Typography></ListItem>
            }
          </List>
          <Grid className={classes.paginateWrapper}>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Pagination 
                  count={totalPages} 
                  page={currPage} 
                  onChange={handlePageChange} 
                  showFirstButton
                  showLastButton
                  color="primary"
                />
              </Grid>
            </Grid>

          </Grid>
        </Grid>

      </Grid>
    </div>

    
  );
};


export default withStyles(styles)(Customers);
