function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 20.3,
    height: 13.3,
    viewBox: "0 0 20.3 13.3"
  }, props), React.createElement("path", {
    id: "prefix__double_x5F_bed",
    d: "M48.067 125.362a.529.529 0 0 0 .049-.222v-.74a.892.892 0 0 0-.86-.919h-.567v-4.121a1.322 1.322 0 0 0-1.275-1.36H33.022a1.322 1.322 0 0 0-1.275 1.362v4.121h-.6a.892.892 0 0 0-.86.919v.738a.528.528 0 0 0 .049.222 1.626 1.626 0 0 0-1.132 1.58v2.2a.71.71 0 0 0 .685.732h1.422v.686a.428.428 0 0 0 .413.441h.37a.428.428 0 0 0 .413-.441v-.686h13.864v.686a.428.428 0 0 0 .413.441h.37a.428.428 0 0 0 .413-.441v-.686h.947a.71.71 0 0 0 .685-.732v-2.2a1.625 1.625 0 0 0-1.132-1.58zm-16-6a.993.993 0 0 1 .958-1.023h12.389a.993.993 0 0 1 .958 1.023v4.078h-1.88v-.45a.478.478 0 0 0-.461-.492h-3.312a.478.478 0 0 0-.461.492v.45h-2.081v-.45a.478.478 0 0 0-.461-.492h-3.311a.478.478 0 0 0-.461.492v.45h-1.88zm12.11 3.628v.45h-3.6v-.45a.149.149 0 0 1 .144-.153h3.309a.149.149 0 0 1 .144.153zm-6.314 0v.45h-3.6v-.45a.149.149 0 0 1 .144-.153h3.311a.149.149 0 0 1 .142.153zM30.6 124.4a.563.563 0 0 1 .543-.58h16.113a.563.563 0 0 1 .543.58v.738a.16.16 0 0 1-.154.165h-16.89a.16.16 0 0 1-.154-.165zm1.585 6.157a.1.1 0 0 1-.1.1h-.37a.1.1 0 0 1-.1-.1v-.657h.561zm15.065 0a.1.1 0 0 1-.1.1h-.37a.1.1 0 0 1-.1-.1v-.657h.561zm1.632-1.418a.381.381 0 0 1-.368.393H29.885a.381.381 0 0 1-.368-.393v-2.2a1.26 1.26 0 0 1 1.215-1.3h16.936a1.26 1.26 0 0 1 1.215 1.3v2.2z",
    transform: "translate(-29.05 -117.85)",
    style: {
      "fill": "#00949c",
      "stroke": "#00949c",
      "strokeWidth": ".3px"
    }
  }));
};

export default __webpack_public_path__ + "static/media/job_bedroom.bfe50f85.svg";
export { SvgComponent as ReactComponent };