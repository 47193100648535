import React from "react";
import { Switch } from "react-router-dom";
import PropsRoute from "./PropsRoute";

const SettingsRoutesSwitcher = ({ routes, ...restProps }) => (
  <Switch>
    {routes.map((route, i) => (
      <PropsRoute
        key={`settings-route-${i}`}
        path={route.path}
        component={route.component}
        exact={route.exact}
        {...restProps}
      />
    ))}
  </Switch>
);

export default SettingsRoutesSwitcher;
