import React from "react";
import DashboardContainer from "containers/DashboardContainer";
import { MainLayout } from "layouts";

const EmployeePage = (props) => (
  <MainLayout {...props}>
    <DashboardContainer {...props} />
  </MainLayout>
)

export default EmployeePage;
