import axios from "utils/http";



const getCustomersReviewsStats = async ({
  contractorId,
  mode,
  date,
  month,
  year
}) => {
  try {
    const employeeStatsRes = await axios.get(
      `${process.env.REACT_APP_API_PREFIX}contractor/get_customers_reviews_stats?`,
      {
        params: {
          contractorId,
          mode,
          date,
          month,
          year,
        }
      }
    )

    return employeeStatsRes.data;
  } catch (error) {
    throw error;
  }
}


export default getCustomersReviewsStats