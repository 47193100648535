import axios from "utils/http";



const getEmployeesStats = async ({
  contractorId,
  mode,
  date,
  weekStartDate
}) => {
  try {
    const employeeStatsRes = await axios.get(        
      `${process.env.REACT_APP_API_PREFIX}contractor/get_employee_stats?`,
      {
        params: {
          contractorId,
          mode,
          date,
          weekStartDate
        }
      }
    )

    return employeeStatsRes.data;
  } catch (error) {
    throw error;
  }
}

export default getEmployeesStats