import { ADD_NEW_JOB, REMOVE_JOB, SET_JOBS, UPDATE_JOB } from "../types";


export const addNewJob = (jobObj,jobsType)=> ({
  type:ADD_NEW_JOB,
  payload: jobObj,
  jobsType: jobsType
})

export const updateJob = (jobObj,jobsType) => ({
  type: UPDATE_JOB,
  payload: jobObj,
  jobsType,
})

export const removeJob = (jobId,jobsType ) => ({
  type: REMOVE_JOB,
  payload: jobId,
  jobsType: jobsType
})


export const setJobs = (jobs, jobsType) => ({
  type: SET_JOBS,
  payload: jobs,
  jobsType: jobsType
})
   
  