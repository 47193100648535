import React from "react";
import { withStyles } from "core/components/Styles";
import Grid from "core/components/Grid";
import AddJobModal from "../AddJobModal";
// import _ from "lodash";
// Custom Components
import EventCalender from "../EventCalender";
import FabButton from "core/components/FabButton";
import Joyride from "react-joyride";
import { calendarSteps } from "../TourGuide/TourGuideSteps";

import {tooltipButtonColor} from "../../theme/colors";
const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex"
  },
  jobsScroller: {
  },
  jobOffersCont:{
    overflowX:"auto"
  },
  jobDate: {
    textAlign: "right"
  },
  agendaListCont: {
    marginTop: 20
  },
  availEmployeeListCont: {
    marginTop: 12
  },
  toggleButtons: {
    textAlign: "right",
    marginTop: 20
  },
  mainContentCont: {
    paddingTop: 20,
    paddingLeft: 23,
    paddingRight: 23,
    paddingBottom: 20,
    position:'relative',
    background : "rgba(236, 240, 241, 0.58)"
  },
  mainContent: {
    marginTop: 64,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 15,
    width: window.innerWidth - 240
  },
  todaysCont: {
    borderTopColor: "#000000",
    borderTop: "3px solid",
    borderRadius: 0
  },
  availableEmployeeCont: {
    marginTop: 20,
    borderTopColor: "#4d73a8",
    borderTop: "3px solid",
    borderRadius: 0
  },
  todaysJobsCard: {
    width: 100
  },
  leftCardContent: {
    paddingLeft: 16,
    paddingRight: 16
  },
  todayInfo: {
    width: 240
  },
  fabBtn:{
    position:"absolute",
    right: 20,
    bottom: 20,
    zIndex: 99,
    height:40,
    width: 40,
    background:"#ed5454",
    color: "#fff",
    "&:hover":{
      background:"#ed5454",
      boxShadow:"0px 3px 5px -1px rgba(0, 0, 0, 0.4), 0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 1px 18px 0px rgba(0, 0, 0, 0.15)"
    }
  },
  addIcon:{
    height:12,
    width:12,
 
  }
});

const CalenderDashboard = props => {
  const {
    handleDateChange,
    // numOfFreeEmployees,
    classes,
    selectedDate,
    // newJobsList,
    // newJobsActions,
    // availableEmployeeList,
    // todaysJobs,
    jobsForMonth,
    eventStyleSetter,
    // getJobDetails,
    // todaysJobsList,
    customerOptions,
    handleModalClose,
    handleSubmit,
    handleSave,
    isAddJobModalOpen,
    formValues,
    increaseByOne,
    decreaseByOne,
    submitData,
    openNewUserform,
    addNewCustomer,
    openAddJobModal,
    runGuide,
    handleJoyrideCallback,
    getHelpers,
    searchCustomers
  } = props;

  return (
    <div className={classes.root} >
      <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={runGuide}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={calendarSteps}
        styles={{
          options: {
            primaryColor: tooltipButtonColor    
          }
        }}
      />
      <Grid
        container
        justify="flex-start"
        spacing={6}
        className={classes.mainContentCont}
        id="calenderRoot"
      >
        <FabButton onClick={openAddJobModal}/>
        <Grid item xs>
          <EventCalender 
            selectedDate={selectedDate} 
            eventList={jobsForMonth} 
            handleDateChange={handleDateChange} 
            eventStyleSetter={eventStyleSetter}
            card
          />
        </Grid>
      </Grid>
      <AddJobModal 
        customerOptions={customerOptions}
        handleClose={handleModalClose}
        handleSubmit={handleSubmit}
        handleSave={handleSave}
        isModalOpen={isAddJobModalOpen}
        formValues={formValues}
        increaseByOne={increaseByOne}
        decreaseByOne={decreaseByOne}
        submitData={submitData}
        openNewUserform={openNewUserform}
        addNewCustomer={addNewCustomer}
        searchCustomers={searchCustomers}
      />
    </div>
  );
};

export default withStyles(styles)(CalenderDashboard);
