import React from "react";
import ReactEchartsCore from "echarts-for-react/lib/core";
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/line";




const getDefaultChartOptions = (lineStyle, areaStyle) => {
//   var base = +new Date(2016, 9, 3);
//   var oneDay = 24 * 3600 * 1000;
//   var valueBase = Math.random() * 300;
//   var valueBase2 = Math.random() * 50;
//   var data = [];
//   var data2 = [];
    let data =  []; 
 const option = {
      animation: true,
      xAxis: {
        type: 'time',
        show: false,
    },
    yAxis: {
        type: 'value',
        show: false
    },
    series: [
        {
            name:'Chart',
            type:'line',
            smooth: true,
            showSymbol: false,
            symbolSize: 5,
            stack: 'a',
            itemStyle: {
                normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: lineStyle ? lineStyle.firstColor : '#76b1ff',
                  }, {
                      offset: 1,
                      color:  lineStyle ? lineStyle.secondColor :'#7676ff'
                  }]),
                  lineStyle: {
                    width: 5
                  }
                }
            },
            areaStyle: {
                normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: areaStyle ? areaStyle.firstColor : '#76b1ff',
                    }, {
                        offset: 1,
                        color: areaStyle ? areaStyle.secondColor : '#7676ff'
                    }])
                }
            },
            data: data
        }

    ],
    grid:{
        // top: 0,
        left:0,
        right: 0,
        bottom:0,
        height: "100%",
        containLabel: false
    }
  };


  return option;
}

const ToolTipChart = (props) => {
  const { chartOptions, chartHeight, lineStyle, areaStyle, data } = props;
  const styles = {};
  const defaultOptions = getDefaultChartOptions(lineStyle, areaStyle);
  const options = {
    ...defaultOptions,
    ...chartOptions
  }

  if(chartHeight){
    styles.height = chartHeight
  }
  if(data){
      options.series[0].data = data;
  }


  return (
    <div>
      <ReactEchartsCore
        echarts={echarts}
        option={options}
        notMerge={true}
        lazyUpdate={true}
        style={styles}
      />
    </div>
  )
}


export default ToolTipChart;