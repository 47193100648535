import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "core/components/Styles";
import Table from "core/components/Table";
import TableHead from "core/components/TableHead";
import TableBody from "core/components/TableBody";
import TableCell from "core/components/TableCell";
import TableFooter from "core/components/TableFooter";
import TablePagination from "core/components/TablePagination";
import TableRow from "core/components/TableRow";
import Paper from "core/components/Paper";
import IconButton from "core/components/IconButton";
import momentTz from "moment-timezone";
// import { PageFirst, PageLast, ArrowLeft, ArrowRight, DotsVertical } from "mdi-material-ui";
import { PageFirst, PageLast, ArrowLeft, ArrowRight } from "mdi-material-ui";
import PopUpMenu from "../PopUpMenu";
import Status from "../Status";

import cn from "classnames";
// import ArrowLeft from '@material-ui/icons/ArrowLeft';
// import ArrowRight from '@material-ui/icons/ArrowRight';
// import LastPageIcon from '@material-ui/icons/LastPage';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => { 
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => { 
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <PageLast /> : <PageFirst />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? <ArrowRight /> : <ArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? <ArrowLeft /> : <ArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <PageFirst /> : <PageLast />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true
})(TablePaginationActions);

// let counter = 0;
// function createData(name, calories, fat) {
//   counter += 1;
//   return { id: counter, name, calories, fat };
// }

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  },
  tableHeading:{
    fontSize: 11,
    padding: "16px 16px 16px 24px",
    // textAlign:'center',
    color:"#000000",
    fontWeight:"normal",
    textTransform : "uppercase",
    border:"none"
  },
  tableHead:{
    background:"#F9FAFB",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.128223)",
  },
  tableCell:{
    padding: "11px 10px 11px 24px",
    fontFamily: "Poppins",
    border:"none",
    // fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    // textAlign: "center",
    fontSize:13,
    color:" #52575a",
    "& button":{
      fontSize:13,
      fontFamily: "Poppins",
      // fontSize: 16,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      // textAlign: "center",
      color:" #52575a",
    }
  },
  actionBtn:{
    // border: "1px solid rgba(148, 151, 155, 0.6)"
  },
  alertIcon:{
    color:"#FFC300"
  },
  rowColor:{
    backgroundColor:"#EEEEEE"
  },
  row:{
    borderBottom:"0.5px solid #F2F2F2;"
  }
});
 
class CustomPaginationActionsTable extends React.Component {
  state = {
    rowsPerPage: 10
  };

  handleChangePage = (event, page) => {
    if(this.props.handleOnPageChange){
      this.props.handleOnPageChange(event, page);
    } 
  };

  handleChangeRowsPerPage = event => {
    if(this.props.handleOnLimitChange){
      this.props.handleOnLimitChange(event.target.value)
    };
  };

  render() {
    const { classes, headers, totalCount, columnProps, actions, changeRowColor, rowsLimit:rowsPerPage, pageNumber} = this.props;
    let { list } = this.props;
    // const { page } = this.state;
    const emptyRows = rowsPerPage - list.length;
    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {headers &&
                  headers.map((header, index) => {
                    if (header.isNumeric) {
                      return (
                        <TableCell className={classes.tableHeading} key={`table-header-${index}`} numeric>
                          {header.label}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell className={classes.tableHeading} key={`table-header-${index}`}>
                        {header.label}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {list 
                 .map((item, index) => {                    
                  return (
                    <TableRow key={item._id} className={cn(classes.row,changeRowColor(item) ? classes.rowColor : null )}>
                      {columnProps &&
                        columnProps.map((column, index) => {
                          if (column.isName) {
                            return (
                              <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                                {item[column.fnameProp] +
                                  " " +
                                  item[column.lnameProp]}
                              </TableCell>
                            );
                          } else if(column.isCost){ 
                            return (
                              <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                                {`$${item[column.name] ? item[column.name] : 0}`}
                              </TableCell>
                            );
                          } else if(column.isNumeric){
                            return (
                              <TableCell className={classes.tableCell} numeric key={`table-item-${index}`}>
                                {item[column.name]}
                              </TableCell>
                            );
                          } else if(column.isAddress && item[column.name]){
                            return (
                              <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                                {`${item[column.name][column.addressProp]}${item[column.name][column.unitProp] ? ` UNIT ${item[column.name][column.unitProp]}, ` : ", "}${
                                  item[column.name][column.cityProp]
                                }, 
                                      ${item[column.name][column.stateProp]}, ${
                                  item[column.name][column.zipcodeProp]
                                }`}
                              </TableCell>
                            );
                          } else if (column.isDate){
                            return( 
                              <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                                { momentTz(item[column.name]).format(column.dateFormat) }
                              </TableCell>
                            )
                          } else if(column.isTime){
                            return (
                              <TableCell className={classes.tableCell} key={`table-item-${index}`} >
                                { momentTz(item[column.name],column.originalFormat).format(column.timeFormat) }
                              </TableCell>
                            )  
                          } else {
                            if(column.isActionButton){
                              return (
                                <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                                  {/* <Button variant="outlined" className={classes.actionBtn}>
                                  {item[column.name]}
                                  </Button> */} 
                                  <PopUpMenu
                                    buttonProps={{ fontSize: "xs" }}
                                    actions={actions}
                                    item={item}
                                    itemIndex={index}
                                    text={item[column.name]}
                                  /> 
                                </TableCell>
                              );
                            }

                            if(column.isStatus){
                              return(
                                <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                                  <Status status={item[column.name]} />   
                                </TableCell>
                              )
                            } 

                            if(column.isPaid){
                              return(
                                <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                                  <Status status={item[column.name] ? column.prop1 : column.prop2 } />     
                                </TableCell>
                              )
                            }

                            if(column.isAlert){ 
                              if(column.displayAlert(item)){
                                return (
                                  <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                                    <column.Icon className={classes.alertIcon}/>
                                  </TableCell>
                                ); 
                              } else {
                                return <TableCell className={classes.tableCell} key={`table-item-${index}`}></TableCell> 
                              }
                            }

                            if(column.convertToUpperCase){ 
                              return (
                                <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                                  {column.convert(item[column.name])}
                                </TableCell>
                              )
                            }  
                            return (
                              <TableCell className={classes.tableCell} key={`table-item-${index}`}>
                                {item[column.name]}
                              </TableCell>
                            ); 
                          }  
                        })}

                      {/* <TableCell key={`action-col-${index}`}>
                      <PopUpMenu
                          icon={<DotsVertical />}
                          buttonProps={{
                            fontSize: "xs"
                          }}
                          options={actions}
                          item={item}
                        />
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={columnProps.length}
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={pageNumber}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

CustomPaginationActionsTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomPaginationActionsTable);
