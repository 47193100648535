import React from "react";
import cn from "classnames";
import { Grid } from "core/components/";
import employeePlaceholder from "../../images/utils/employee-placeholder.png";
import { withStyles } from "core/components/Styles";
import momentTz from "moment-timezone"
import formatPhone from "../../utils/formatPhone";

const styles = (theme) => ({
  profileImage:{
    height: 130,
  },
  employeeName:{
    // font-family: Poppins;
    margin: 0,
    fontSize: 16,
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#47495a"
  },
  textBody:{
    // fontFamily: "Poppins",
    margin:0,
    fontSize: 12,
    // marginTop: 5,
    // marginBottom: 5,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#959595"
  },
  addressLabel:{
    // font-family: BrandonGrotesque;
    textTransform: "uppercase",
    margin:0,
    // marginBottom: 10,
    fontSize: 12,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#47495a"
  },
  addressText:{

    margin:0,
    fontSize: 15,
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#47495a"
  },
  marginTop_5:{
    marginTop: 5
  },
  marginBottom_12:{
    marginBottom: 12,
  },
  mainWrapper:{
    fontFamily:"Poppins"
  }
});

const EmployeeInfo = (props) => {

  const { employee, classes } = props;

  const { profileAvator, fname, lname, empCreatedAt, phone, address } = employee;

  const profileLink =  profileAvator ? `${process.env.REACT_APP_USER_FILES}${profileAvator}`
  : employeePlaceholder

  return (
    <Grid
      container
      direction="row"
      spacing={6}
      className={classes.mainWrapper}
    >
      <Grid item className={classes.imgContainer}>
        <img src={profileLink} className={classes.profileImage} alt="profile"/>
      </Grid>
      <Grid item>

        <p className={classes.employeeName}>
          {`${fname} ${lname}`}
        </p>
        {/* <Typography variant="title" gutterBottom> */}

        {/* </Typography> */}
        <p className={cn(classes.textBody, classes.marginTop_5)}>
          Date of Joining: {momentTz(empCreatedAt).format("DD/MM/YY")}
        </p>

        <p className={cn(classes.textBody, classes.marginTop_5, classes.marginBottom_12)}>
          {formatPhone(phone)}
        </p>

        <p className={classes.addressLabel}>
          Address
        </p>
        <p className={cn(classes.addressText, classes.marginTop_5)}>
        {address}
        </p>

        {/* <Typography variant="subtitle1" gutterBottom>
          {`${fname} ${lname}`}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {`${fname} ${lname}`}
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          {`${fname} ${lname}`}
        </Typography> */}
        {/* <h5>
        {`${fname} ${lname}`}
        </h5> */}

      </Grid>
    </Grid>
  )
};


export default withStyles(styles)(EmployeeInfo);