import React, { Component, Fragment } from 'react';
import { connect } from "react-redux"
import { reduxForm, arrayPush } from 'redux-form'
import axios from 'utils/http';
import momentTz from "moment-timezone";
import {
  isEqual, 
  isEmpty, 
  // debounce
} from "lodash";
import InvoiceForm from '../components/InvoiceForm'
import { withMixpanel } from  "../utils/react-mixpanel";
// import { loadData } from '../store/actions/data'
// import { getFormValues } from 'redux-form';
import { setMsgAndShow } from '../store/actions/popup'
import { toggleLoader } from "../store/actions/app";
import DropZone, { DropZoneDialog } from "../components/DropZone";
import { updateInvoiceLogo } from "../store/actions/contractor";

// form container
class InvoiceFormContainer extends Component {
  state = {
    total: 0,
    customers: [],
    productsList: [{}],
    showDropzone: false,
    showSavePermanently: false,
    canEditCustomer: false
  }

  lastInvoiceNumber = 0;
  getCustomers = async inputValue => { 
    try {
      let customers = [];
      let url = `${process.env.REACT_APP_API_PREFIX}contractor/get_customers`;
      if(inputValue) url += `?q=${inputValue}`;
      let customersDataRes = await axios.get(url); 
      const { customers: customerList } = customersDataRes.data;
      if(customerList && customerList.length > 0){
        customers = customerList;
      }
      return customers.map((customer)=>{
        let { serviceAddress:{city,state,address,zipcode} } = customer;
        return {
          label: `${customer.fname} ${customer.lname}`,
          email: customer.email,
          phone: customer.phone,
          address: `${address}, ${city}, ${state} ${zipcode}`,
          value: customer._id
        }
      })
      
    } catch (error) {
      throw error;
    }
  }

  getData = async () => {

    const { setMsgAndShow , toggleLoader} = this.props;
    toggleLoader()
    try {
      let productsList = [{}];
      let customers = [];
      let invoiceNumber = null;
      let customersDataRes = axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/get_customers`);
      let productListRes = axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/services/get_all`);
      let lastInvoiceNumbeRes = axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/invoices/get_last_invoice_number`);
      customersDataRes = await customersDataRes;
      productListRes = await productListRes;
      lastInvoiceNumbeRes = await lastInvoiceNumbeRes;

      const { customers: customerList } = customersDataRes.data; 
      let { services  } = productListRes.data;
      const { status, number } = lastInvoiceNumbeRes.data;

      if(customerList && customerList.length > 0){
        customers = customerList;
      }

      if(services && services.length > 0){
        productsList = services.map((p) => ({
          id: p._id,
          label: p.name,
          value: p.value,
          description: p.description,
          quantity: p.quantity,
          rate: p.rate
        }))
      }


      if(status === "OK" && Number.isInteger(number)){
        invoiceNumber = number + 1;
        this.props.change("number",invoiceNumber);
      }

      this.setState({
        customers,
        productsList
      })

      toggleLoader()

    } catch (error) {
      const { response } = error;
      toggleLoader()
      setMsgAndShow(response ? response.data.message : "Error while getting data please try again!");
    }
  }

  componentDidMount() {
    this.getData();
  }



  onClientSelect = (event, newValue, previousValue={}, name) => {
    let showSavePermanently = this.state.showSavePermanently;
    const { value: previousCustomerId } = previousValue ;
    const { value: newCustomerId } = newValue;
    if(previousCustomerId !== newCustomerId){
      if(event && event.value) {
        this.props.change("customerEmail", event.email);
        this.props.change("billingAddress",event.address);
        this.props.change("customerPhone",event.phone); 
        this.setState({
          canEditCustomer: true
        })
      }
      showSavePermanently = false;
    }
    this.setState({ showSavePermanently });
  }

  onProductSelect = (event, newValue, previousValue, name) => {
    const productIndex =  parseInt(name.replace(/[^\d]/g, ''));
    if( productIndex === this.props.formValues.products.length-1 ){
      this.props.addLines();
    }
    const selecetdproduct = name.split(".");
    this.props.change(`${selecetdproduct[0]}.description`, newValue.description)
    this.props.change(`${selecetdproduct[0]}.rate`, newValue.rate)
    this.props.change(`${selecetdproduct[0]}.quantity`, newValue.quantity)
  }

  saveInvoice = ()=> { 
    this.props.change("logo",this.props.formValues.logo);
    this.props.change("onlySave",true);
    setTimeout(()=>{
      this.props.handleSubmit(this.invoiceSubmitAction)();
    },100)
  };

  // action performed when the form is submitted
  invoiceSubmitAction = async (object, dispatch, props) => { 
    this.props.change("logo",this.props.formValues.logo);
    let { number:invoiceNumber, products, onlySave, customerId, amount, ...restFormValues } = object; 
    const { toggleLoader, trackApiEvent } = this.props;
    products = products.filter( p => ( !isEmpty(p) && !isEqual(p.amount,"") ) && p );
     
    try {
      toggleLoader();
      let response = await axios.post(`${process.env.REACT_APP_API_PREFIX}contractor/invoices/add`, {
        amount: this.state.total,
        number: object.number,
        products: products,
        customerId: customerId.value, 
        onlySave,
        currDate: momentTz().format("YYYY-MM-DD"),
        ...restFormValues
      });
      if (response.data.status === "OK") {
        trackApiEvent( "Add_Invoice", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/invoices/add`, "OK", window.location.pathname);
        props.reset("InvoiceForm")
        props.change('number',invoiceNumber + 1);
        dispatch(setMsgAndShow(response.data.message))
        toggleLoader();
        this.props.history.push("/invoices");
      }
    } catch (error) {
      const { response  } = error;
      console.error("error during submission ",error); // eslint-disable-line
      let msg = "Error";
      if(response && response.data){
        msg = response.data.message ? response.data.message : "Error";
      }
      trackApiEvent( "Add_Invoice", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/invoices/add`, msg, window.location.pathname);
      dispatch(setMsgAndShow(msg));
      toggleLoader();
    }
  }

  //when rows are updated, this function gets called and updates the total
  componentDidUpdate(prevProps, prevState) {
    const { formValues } = prevProps
    let products=[]
    if(formValues && formValues.products) products = formValues.products
    let totalValue = 0, amount = 0, rate = 0, qty = 0;

    if (!isEqual(this.props.formValues,formValues)) {
      const allProducts = this.props.formValues.products && this.props.formValues.products;
      if (!isEqual(allProducts, products) ) {
        totalValue = amount = rate = qty = 0;

        for (let i = 0; i < allProducts.length; i++) {
          let num = allProducts[i];
          rate = isNaN(num.rate) ? 0 : parseInt(num.rate, 10);
          qty = isNaN(num.quantity) ? 0 : parseInt(num.quantity, 10);
          amount = rate * qty;

          this.props.change(`products[${i}].amount`, ((amount === 0) ? "" : amount));

          (allProducts[i].amount === 0) ? (totalValue = prevState.total) :
            (totalValue += isNaN(amount) ? 0 : parseInt(amount, 10))
        }
        this.setState({ total: totalValue })
      }
    }
  }



  // remove all the rows from the table
  clearLines = () => {
    this.props.change("products", [{ name: null }])
  }



  resetForm = () => {
    const { reset, change, invNo, companyAddress, companyMail, companyName, companyPhone } = this.props;
    reset();
    change("number", invNo);
    change("companyName",companyName);
    change("companyAddress", companyAddress);
    change("companyMail", companyMail);
    change("companyPhone", companyPhone);
  }
  cancelInvoice = () => this.props.history.goBack();
  
  
  trackInput = (fieldname)=> (e)=> {
    this.trackField(fieldname,e.target.value);
  }
  
  trackField = (fieldName,value)=>{
    const { trackInputOnBlur } = this.props;
    if(value){
      trackInputOnBlur(fieldName, value, window.location.pathname);
    }
  }

  trackRadio = (fieldName, defaultValue)=> (e)=>{
    const { trackRadioInput } = this.props;
    trackRadioInput(fieldName, defaultValue, e.target.value, window.location.pathname);
  }

  onLogoUpload = ()=> {
    const { trackButtonEvent } = this.props;
    trackButtonEvent("Upload_Company_Logo",window.location.pathname);
    this.setState({
      showDropzone : true
    })
  }

  handleDropZone = () => {
    // const { trackButtonEvent, location:{pathname} } = this.props;
    // trackButtonEvent("Cancel_Pic_Upload",pathname);
    this.setState({ showDropzone: !this.state.showDropzone });
  };

  fileUploaded = res => {

    const { setMsgAndShow, updateInvoiceLogo, trackButtonEvent, trackApiEvent } = this.props;
    trackButtonEvent("Upload_Company_Logo",window.location.pathname);
    
    try {
      const { data } = res; 
      if( data && data.status === "OK"){
        trackApiEvent("Upload_Company_Logo", "POST",`${process.env.REACT_APP_API_PREFIX}contractor/upload_logo`,"OK",window.location.pathname);
        if(data.logo){
          const { logo } = data;
          updateInvoiceLogo(logo);
          setMsgAndShow("Upload was successful");
          this.handleDropZone();
        }
      } else {
        trackApiEvent("Upload_Company_Logo", "POST",`${process.env.REACT_APP_API_PREFIX}contractor/upload_logo`,data.message,window.location.pathname);
        setMsgAndShow(data.message ? data.message : "Error Occured");
      }
    } catch (error) { 
      trackApiEvent("Upload_Company_Logo", "POST",`${process.env.REACT_APP_API_PREFIX}contractor/upload_logo`,error,window.location.pathname);
      setMsgAndShow("Error");
    }

  };

  checkCustomerDetails = (fieldname)=> (e)=>{
    const { value:inputValue } = e.target;
    if(!this.props.formValues.customerId) return;
    const { customerId:{value:cid} } = this.props.formValues;
    const filteredCustomer = this.state.customers.filter(customer => customer._id === cid); 
    const { serviceAddress: {city,state,address,zipcode}, phone, email } = filteredCustomer[0];
    let billingAddress = `${address}, ${city}, ${state} ${zipcode}`; 
    let flag = false; 
    switch(fieldname){
      case "billingAddress": 
        flag = billingAddress !== inputValue ;
        break;
      case "customerEmail":         
        flag = email !== inputValue ;
        break;
      case "customerPhone":  
        flag = phone !== inputValue ;
        break;
      default: break;
    }
    this.setState({
      showSavePermanently:flag
    });
  }

  saveNewCustomerDetails = async()=>{
    const { setMsgAndShow, trackApiEvent } = this.props;
    const { billingAddress, customerEmail, customerPhone, customerId:{value:customerId} } = this.props.formValues;
    try {
      if(customerPhone.length < 10 || customerPhone.length > 10 ){
        setMsgAndShow("Phone number must consist 10 characters");
        return;
      }
      if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(customerEmail)){
        setMsgAndShow("Invalid Email Address");
        return;
      }
      if(/(\d+) ((\w+[ ,])+ ){2}([a-zA-Z]{2}) (\d{5})/.test(billingAddress)){
        let newBillingAddress = billingAddress.match(/(\d+) ((\w+[ ,])+ ){2}([a-zA-Z]{2}) (\d{5})/); 
        let address =  newBillingAddress[0].split(",")[0].trim();
        let city = newBillingAddress[3].replace(",","").trim()
        let state = newBillingAddress[4].trim();
        let zipcode = newBillingAddress[5].trim();  
        const {data} = await axios.put(`${process.env.REACT_APP_API_PREFIX}contractor/update_customer`,{
          customerId,
          updateDetails:{
            email: customerEmail,
            phone: customerPhone,
            serviceAddress: { address, city, state, zipcode }
          }
        });
        let msg = "Customer info updated successfully";
        if(data && data.status === 'OK'){
          if(data.customer){
            let updateCustomers = this.state.customers.filter(customer => customer._id !== data.customer._id);
            const updatedCustomers = [ ...updateCustomers, data.customer ]; 
            this.setState({
              customers: updatedCustomers,
              showSavePermanently:false
            });
            msg = data.status ? data.status : "Customer info updated successfully" ;
          }
        } else {
          msg = data.message ? data.message : "Error Occured";
        }
        trackApiEvent("Save_Permanently","PUT",`${process.env.REACT_APP_API_PREFIX}user/update_details`,msg,window.location.pathname);
        setMsgAndShow(msg === "OK" ? "Customer info updated successfuly" : msg );
      }else{
        setMsgAndShow("Invalid Address (Format Accepted : Address, City, State ZipCode )"); 
      } 
    } catch (error) { 
      let errorMsg = "An error occurred please try again!";
      const { response } = error;
      if(response && response.data && response.data.message){
        errorMsg = response.data.message;
      } 
      setMsgAndShow(errorMsg);
      trackApiEvent("Save_Permanently","PUT",`${process.env.REACT_APP_API_PREFIX}user/update_details`,error,window.location.pathname);
    }
  }

  render() {  
    return (
      <Fragment>
        <InvoiceForm
          {...this.props}
          addLines={this.props.addLines}
          clearLines={this.clearLines}
          invoiceSubmitAction={this.invoiceSubmitAction}
          total={this.state.total}
          customers={this.state.customers}
          onClientSelect={this.onClientSelect}
          onPaymentMethodChange={this.onPaymentMethodChange}
          productsList={this.state.productsList} //products list get by axios
          onProductSelect={this.onProductSelect}
          resetForm={this.resetForm}
          cancelInvoice={this.cancelInvoice}
          getCustomers={this.getCustomers}
          saveInvoice={this.saveInvoice}
          onLogoUpload={this.onLogoUpload}

          canEditCustomer={this.state.canEditCustomer}
          showSavePermanently={this.state.showSavePermanently}
          checkCustomerDetails={this.checkCustomerDetails}
          saveNewCustomerDetails={this.saveNewCustomerDetails}

          trackInput={this.trackInput}
          trackField={this.trackField}
          trackRadio={this.trackRadio}
        />
        <DropZoneDialog
          isOpen={this.state.showDropzone}
          toggle={this.handleDropZone}
          title="Upload Company Logo"
        >
          <DropZone
            onUploaded={this.fileUploaded}
            onCancel={this.handleDropZone}
            url={`${process.env.REACT_APP_API_PREFIX}contractor/upload_logo`}
            accept=".jpg,.png,.gif"
          />
        </DropZoneDialog>
      </Fragment>
    )
  }
}



const mapStateToProps = (state, props) => {
  const { form, contractor } = state;
  let formValues = {};
  let companyDetails = {};

  if(form && form.InvoiceForm && form.InvoiceForm.values){
    formValues = {...form.InvoiceForm.values}
  }
  
  if (contractor && contractor.businessDetails) { 
    if(contractor.businessDetails.data){
      companyDetails.companyAddress = contractor.businessDetails.data.businessAddressString;
      companyDetails.companyPhone = contractor.businessDetails.data.phone;
    } 
    companyDetails.companyName = contractor.businessDetails.businessName;
    // if(contractor.businessDetails.businessEmail){
    companyDetails.companyMail = contractor.businessDetails.businessEmail;
    // }
    if(contractor.businessDetails.logo && contractor.businessDetails.logo.url ){
      companyDetails.logo = contractor.businessDetails.logo.url 
    }
  }

  return {
    formValues,
    initialValues: {
      ...companyDetails,
      number: formValues.number,
      products: [{}],
      paymentMethod: "offline",
      onlySave:false
    }
  };
}

// addLines function being provided to dispatch action to add rows to the table
const mapDispatchToProps = (dispatch) => {
  return {
    addLines: () => dispatch(arrayPush("InvoiceForm", "products", {})),
    toggleLoader: () => dispatch(toggleLoader()),
    setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg)),
    updateInvoiceLogo: (updateObj) => dispatch(updateInvoiceLogo(updateObj))
  }
}

const withReduxForm = reduxForm({
  form: "InvoiceForm",
  destroyOnUnmount: true,
  enableReinitialize: true
})(InvoiceFormContainer);

const connectRedux =  connect(mapStateToProps, mapDispatchToProps)(withReduxForm);

export default withMixpanel(connectRedux);