import axios from 'utils/http';


const getJobsByMonth = async ({
  contractorId,
  month,
  year,
}) => {
  try {
    const monthOrdersData = await axios.get(
      `${process.env.REACT_APP_API_PREFIX}cleaning_order/get_all_jobs`,
      {
        params: {
          month,
          contractorId,
          year,
        }
      }
    );

    return monthOrdersData.data;
  } catch (error) {
    throw error;
  }
}

export default getJobsByMonth;