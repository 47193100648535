import React, { Component } from 'react';
import Cookies from "js-cookie";
import * as Sentry from '@sentry/browser';
import socket, { createRoom } from "socket"
import { addNewJob, removeJob} from "store/actions/jobs";
import { setMsgAndShow, resetMsg} from "store/actions/popup";
import { setOrdersWithChanges, toggleBusinessModal, addOrderWithChanges, showAlert} from "store/actions/app";
import { AppRoutes } from "./routes";
import Storage from 'utils/Storage';
import { connect } from "react-redux";
import Snackbar from 'core/components/Snackbar';
import IconButton from 'core/components/IconButton';
import { Close } from "mdi-material-ui";
import axios from 'utils/http';
import { getCookie } from "utils/cookies";
import _ from "lodash";
import { setDetails, setUserInfo, setOrderHistory } from "./store/actions/contractor";
import { setJobs } from "./store/actions/jobs";
import { updateJob } from "./store/actions/jobs";
import { Grid, Button } from "core/components";
import JobChangesModal from "./components/JobChangesModal";
import errorImage from "./assets/images/error.png";
import allowedUserTypes from "./variables/allowedUserTypes";
import { withMixpanel } from "./utils/react-mixpanel";


if(process.env.NODE_ENV === "production"){
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DNS});
}




class App extends Component {
  state = {
    hasError: false
  }


  updateNewOffersList = async () => {
    try {
      const {_id:contractorId } = this.props.businessDetails;
      let messageToShow;
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/get_new_orders`,
        {
          params: {
            contractorId
          }
        }
      );

      const { status, orders, message } = data;
      
      if(status === "OK"){
        if(orders && orders.length){
          this.props.setJobs(orders, "newJobs");
          messageToShow = "New job offers";
        }
      } else {
        messageToShow = message;
      }

      this.props.setMsgAndShow(messageToShow);
    } catch (error) {
      const { response, message } = error;
      let errorMsg = "An error occurred while getting job offers!";
      if(response && response.data && response.data.message){
        errorMsg = response.data.message
      } else if(message){
        errorMsg = message;
      }

      this.props.setMsgAndShow(errorMsg);
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    Sentry.withScope(scope => {
      if(process.env.NODE_ENV === "production"){
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      } else {
        console.error(error); // eslint-disable-line
      }
    });
  }

  componentDidMount(){

    const { trackApiEvent } = this.props;

    axios.get(`${process.env.REACT_APP_API_PREFIX}user/is_logged_in`)
    .then(async (res)=>{

      try {
        let openModal = true;
        let showAddressAlert = true;
        const { isLoggedIn, userInfo, businessDetails, orderHistory, loginActivitiesCount, ordersWithChanges,isBusinessAddressExists } = res.data;
        if(isLoggedIn){
  
            trackApiEvent("Is_Logged_In", "GET", `${process.env.REACT_APP_API_PREFIX}user/is_logged_in`, "OK", window.location.pathname);

            if(allowedUserTypes.indexOf(userInfo.userType) === -1){
              return this.props.history.replace('/login')
            }
  
            if(!_.isEmpty(businessDetails)){
              this.props.setDetails(userInfo, businessDetails, orderHistory);
              this.props.setOrdersWithChanges(ordersWithChanges);
              createRoom(businessDetails._id)
              socket.on('roomcreated', data => {
                console.info('room created', data, businessDetails._id); // eslint-disable-line
              })
              if(businessDetails.serviceArea && businessDetails.serviceArea.radius){
                openModal = false;
              }
              if(isBusinessAddressExists){
                showAddressAlert = false
              }
  
              Storage.set("userInfo", JSON.stringify(userInfo))
    
              Storage.set("contractorInfo", JSON.stringify({
                businessDetails,
                orderHistory
              }))
      
            } else {
              this.props.setUserInfo(userInfo)
              this.props.setOrderHistory(orderHistory)
              Storage.set("userInfo",JSON.stringify(userInfo));
              Storage.set("orderHistory", JSON.stringify(orderHistory));
            }
  
            const storedToken = Storage.get("auth_token");
            const storedAuthCookie = getCookie("authToken");
            if(!storedToken && storedAuthCookie ){
              Storage.set("auth_token", storedAuthCookie);
            }
            

            if(showAddressAlert){
              this.props.showAlert("Please complete your company profile in settings");
            }
            if(loginActivitiesCount <= 1 && openModal){
              this.props.toggleBusinessModal();
            }
  
            if(window.location.pathname === "/login" || window.location.pathname === "/signup" ||window.location.pathname === "/" ) {
              this.props.history.push("/dashboard");
            } 
            // this.props.history.push("/");
           
        } else {   
          
          if(window.location.pathname !== "/login" && window.location.pathname !== "/signup" && window.location.pathname !== "/" ) {
            this.props.history.push("/login");

          } 
          Cookies.remove("authToken",{path:"/", domain:process.env.REACT_APP_HOME_SITE});
          Storage.remove("userInfo");
          Storage.remove("orderHistory");
          Storage.remove("contractorInfo");
          
        }
        
      } catch (error) {
        console.error(error); // eslint-disable-line
      }
    })
    .catch((e)=>{

      const { response } = e;

      let msg = "Error Occured During Login";

      if(response && response.data){
        msg = response.data.message ? response.data.message : "Error Occured During Login"
        this.props.setMsgAndShow(msg);
      }

      trackApiEvent("Is_Logged_In", "GET", `${process.env.REACT_APP_API_PREFIX}user/is_logged_in`, msg, window.location.pathname);


      if(window.location.pathname !== "/login" && window.location.pathname !== "/signup" && window.location.pathname !== "/" ) {
        this.props.history.push("/login");
      } 
      Cookies.remove("authToken",{path:"/", domain:process.env.REACT_APP_HOME_SITE});
      Storage.remove("userInfo");
      Storage.remove("orderHistory");
      Storage.remove("contractorInfo");
    });

    let disconnected = false;
    socket.on( 'disconnect', function () {
      disconnected = true
    });
    socket.on( 'connect', function () {
      const businessDetails = Storage.get('contractorInfo') && JSON.parse(Storage.get('contractorInfo')).businessDetails
      if(disconnected && businessDetails) {
        createRoom(businessDetails._id);
      }
    })
    socket.on('new job request',this.updateNewOffersList);

    socket.on("removeJobFromNewJobsList",(jobId) => {
      this.props.removeJob(jobId,"newJobs")
      // this.props.setMsgAndShow(`${jobId} is removed from job requests`,);
      //TODO show snackbar after job is removed 
    });

    socket.on("newChangeInJob", async () => {

      try {
        
        const {  setOrdersWithChanges } = this.props;
        const res = await axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/orders/change_requests`)

        if(res.data.status === "OK"){
          setOrdersWithChanges(res.data.orders);
        }
      } catch (error) {
        console.error(error); // eslint-disable-line
      }
    })

    socket.on("removeJobFromEveryWhere", (jobId) => {
      const jobTypeList = ["newJobs","acceptedJobs","todaysJobs"];
      jobTypeList.forEach((type) => {
        this.props.removeJob(jobId, type)
      });
    })
  }

  handlePopUp = () => {
     this.props.resetMsg()
  }





  render() {

    const { popupObj } = this.props;


    if(this.state.hasError){
      return (
        <Grid style={{ height: "100vh"}} container alignItems="center" justify="center" direction="column">
          <img alt="Error" src={errorImage} />

          <Button 
            variant="contained" 
            color="secondary" 
            style={{
              marginTop:10
            }}
            href={process.env.REACT_APP_HOME_SITE}
          >
            GO TO HOME
          </Button>
 
        </Grid>
      );
    }

    return (
      <div>
      <AppRoutes />
      <JobChangesModal />
      <Snackbar
        anchorOrigin={{ 
          vertical: popupObj.vertical,
          horizontal: popupObj.horizontal
        }}
        open={popupObj.isPopupOpen}
        onClose={this.handlePopUp}
        ContentProps={{
          'aria-describedby': 'popup-msg',
        }}
        autoHideDuration={6000}
        action={
        <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handlePopUp}
          >
            <Close />
          </IconButton>
        }
        message={<span id="popup-msg">{popupObj.msg}</span>}
        
      />
    </div>
    )
  }
}



const mapStateToProps = (state) => ({
  popupObj: state.popup,
  allJobs: state.jobs.allJobs,
  businessDetails: state.contractor.businessDetails,
})

const mapDispatchToProps = (dispatch) => ({
  setJobs: (jobs, jobsType) => dispatch(setJobs(jobs, jobsType)),
  setDetails: (userInfo, businessDetails, orderHistory) => dispatch(setDetails(userInfo, businessDetails, orderHistory)),
  setUserInfo: (userInfo) => dispatch(setUserInfo(userInfo)),
  setOrderHistory: (orderHistory) => dispatch(setOrderHistory(orderHistory)),
  addNewJob: (job, jobsType) => dispatch(addNewJob(job, jobsType)),
  removeJob: (jobId, jobsType) => dispatch(removeJob(jobId, jobsType)),
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg)),
  resetMsg: () => dispatch(resetMsg()),
  toggleBusinessModal: () => dispatch(toggleBusinessModal()),
  setOrdersWithChanges: (orders) => dispatch(setOrdersWithChanges(orders)),
  addOrderWithChanges: (order) => dispatch(addOrderWithChanges(order)),
  updateJob: (job, jobsType) => dispatch(updateJob(job,jobsType)),
  showAlert: (message) => dispatch(showAlert(message))
})


const connectRedux = connect(mapStateToProps, mapDispatchToProps)(App);
export default withMixpanel(connectRedux);