import { 
  SET_ITEM, 
  TOGGLE_BUSINESS_MODAL,
  CLOSE_BUSINESS_MODAL, 
  TOGGLE_LOADER, 
  TOGGLE_DIALOG_LOADER, 
  SET_FORM_DATA,
  SET_ORDERS_WITH_CHANGES,
  CLEAR_ORDERS_WITH_CHANGES,
  REMOVE_ORDER_FROM_ORDERS_WITH_CHANGES,
  ADD_ORDER_WITH_CHANGES,
  SHOW_ALERT,
  HIDE_ALERT,
	SHOW_TOUR_GUIDE,
  TOGGLE_PAGE_REFRESH
} from "../types";

export const showTourGuide = (flag) => ({
  type: SHOW_TOUR_GUIDE,
  payload: flag
});


export const setItem = (item, itemType) => ({
  type: SET_ITEM,
  item,
  itemType
})

export const toggleBusinessModal = () => ({
  type: TOGGLE_BUSINESS_MODAL,
})

export const toggleLoader = () => ({
  type: TOGGLE_LOADER
})

export const toggleDialogLoader = () => ({
  type: TOGGLE_DIALOG_LOADER
})

export const setFormData = (payload) => ({
  type: SET_FORM_DATA,
  payload
})

export const closeBusinessModal = () => ({
  type: CLOSE_BUSINESS_MODAL
})

export const setOrdersWithChanges = (orders) => ({
  type: SET_ORDERS_WITH_CHANGES,
  payload:orders
})

export const clearOrdersWithChanges = () => ({
  type: CLEAR_ORDERS_WITH_CHANGES
})


export const removeOrderFromOrdersWithChanges = (orderId) => ({
  type: REMOVE_ORDER_FROM_ORDERS_WITH_CHANGES,
  payload: orderId
})


export const addOrderWithChanges = (order) => ({
  type: ADD_ORDER_WITH_CHANGES,
  payload: order
})

export const showAlert = (message) => ({
  type: SHOW_ALERT,
  payload: message
})

export const hideAlert = () => ({
  type:HIDE_ALERT
})

export const togglePageRefresh = () => ({
  type:TOGGLE_PAGE_REFRESH
})