import { 
  SET_DETAILS, 
  SET_USER_INFO, 
  SET_ORDER_HISTORY, 
  UPDATE_USER_INFO, 
  UPDATE_DETAILS,
  UPDATE_INVOICE_LOGO
} from "../types";

export const setDetails = (userInfo, businessDetails, orderHistory) => ({
  type: SET_DETAILS,
  payload: [
    userInfo,
    businessDetails,
    orderHistory
  ]
});

export const setUserInfo = (userInfo) => ({
  type:SET_USER_INFO,
  payload:userInfo
});

export const updateUserInfo = (updateObj) => ({
  type: UPDATE_USER_INFO,
  payload: updateObj
});

export const updateDetails = (updateData) => ({
  type: UPDATE_DETAILS,
  payload: updateData
});


export const setOrderHistory = (orderHistory) => ({
  type: SET_ORDER_HISTORY,
  payload: orderHistory
});

export const updateInvoiceLogo = (logoObj)=> ({
  type: UPDATE_INVOICE_LOGO,
  payload: logoObj
});