import axios from "utils/http";

const getReports = async ({
  contractorId,
  mode,
  date,
  year,
  month
}) => {
  try {
    const reportsRes = await axios.get(`${process.env.REACT_APP_API_PREFIX}contractor/get_reports`,
      {
        params: {
          contractorId,
          mode,
          date,
          year,
          month
        }
      }
    );
    
    return reportsRes.data;

  } catch (error) {
    throw error;
  }
}


export default getReports;