function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 16,
    height: 19,
    viewBox: "0 0 16 19"
  }, props), React.createElement("g", {
    id: "prefix__dish_x5F_spoon",
    transform: "translate(-32.352 -86.723)"
  }, React.createElement("path", {
    id: "prefix__Path_17",
    d: "M407.657 89.736c0-1.662-1.657-3.013-3.693-3.013s-3.693 1.352-3.693 3.013a3.339 3.339 0 0 0 3.366 3v2.169h-.777v10.365h2.207V94.906h-.777v-2.169a3.339 3.339 0 0 0 3.367-3.001zm-3.243 5.57v9.565h-.9v-9.565zm-.45-2.957A2.643 2.643 0 1 1 407 89.736a2.854 2.854 0 0 1-3.036 2.614z",
    className: "prefix__cls-1",
    "data-name": "Path 17",
    transform: "translate(-359.305)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Path_18",
    d: "M36.424 101.906c0 .835-1.042 1.526-2.383 1.624v-5.164h-.653v5.164c-1.34-.1-2.383-.788-2.383-1.624v-3.54h-.653v3.54c0 1.053 1.335 1.921 3.036 2.021v2.136h-.777v10.22h2.207v-10.22h-.777v-2.136c1.7-.1 3.036-.968 3.036-2.021v-3.54h-.653v3.54zm-2.259 4.552v9.431h-.9v-9.431z",
    className: "prefix__cls-1",
    "data-name": "Path 18",
    transform: "translate(2 -10.56)",
    style: {
      "fill": "#00949c"
    }
  })));
};

export default __webpack_public_path__ + "static/media/job_property_type.edf83079.svg";
export { SvgComponent as ReactComponent };