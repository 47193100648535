import React, { PureComponent } from "react"
import JobsListModal from "./JobsListModal";
import JobDetailsModal from "../JobDetailsModal";
import GetOTPModal from "../GetOTPModal";
import axios from "utils/http";
import { toggleDialogLoader, toggleLoader } from "../../store/actions/app";
import { setMsgAndShow } from "../../store/actions/popup";

// import { isEmpty  } from "lodash";
import { withMixpanel } from "../../utils/react-mixpanel";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { cloneDeep } from "lodash";
import getTotalPagesCount from "../../utils/getTotalPagesCount";

class JobsListModalContainer extends PureComponent {


  state = {
    isAssignMenuOpen: false,
    isJobDetailsModalOpen: false,
    isOTPModalOpen: false,
    jobs: [],
    jobData: {},
    employeeList: [],
    btnRef: null,
    total: 0,
    page: 0,
    currPage:1,
    perPage: 10,
    gettingOrders: false,
    load: false
  }


  checkForListOpen = (prevProps, currProps) => {
    const { isOpen: prevIsOpen } = prevProps;
    const { isOpen: currIsOpen } = currProps;
    let fetchData = false;
    if (prevIsOpen !== currIsOpen && currIsOpen) {
      fetchData = true;
    }

    return fetchData;
  }


  getData = async () => {
    const { selectedDate } = this.props;
    const { currPage, perPage: limit } = this.state;
    const contractorId = this.props.contractorInfo.businessDetails._id;
    this.setState({
      gettingOrders: true
    })
    try {

      const ordersRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_orders`,
        {
          params: {
            contractorId,
            date: moment(selectedDate).format("YYYY-MM-DD"),
            limit,
            page: currPage
          }
        }
      );


      const {
        orders,
        total,
        perPage,
        page: receivedPage,
      } = ordersRes.data;


      this.setState({
        jobs: orders,
        total,
        perPage,
        page: receivedPage - 1,
        currPage:receivedPage,
        gettingOrders: false
      })
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";

      if (response && response.data && response.data.message) {
        errMsg = response.data.message;
      }

      this.setState({
        gettingOrders: false
      })

      this.props.setMsgAndShow(errMsg);
    }
  }

  enableLoader = () => {
    this.setState({
      load: true
    })
  }

  disableLoader = () => {
    this.setState({
      load: false
    })
  }

  updateList = async () => {
    const { selectedDate } = this.props;
    const { currPage, perPage: limit } = this.state;
    const contractorId = this.props.contractorInfo.businessDetails._id;
    this.setState({
      gettingOrders: true
    })
    try {

      const ordersRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_orders`,
        {
          params: {
            contractorId,
            date: moment(selectedDate).format("YYYY-MM-DD"),
            limit,
            page: currPage
          }
        }
      );


      const {
        orders,
        total,
        perPage,
        page: receivedPage,
      } = ordersRes.data;

      const totalPages = getTotalPagesCount(total, perPage);


      if (receivedPage > totalPages) {
        this.getDataByPage(totalPages, true);
      } else {
        this.setState({
          jobs: orders,
          total,
          perPage,
          page: receivedPage - 1,
          currPage:receivedPage,
          gettingOrders: false
        })
      }

    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";

      if (response && response.data && response.data.message) {
        errMsg = response.data.message;
      }

      this.setState({
        gettingOrders: false
      })

      this.props.setMsgAndShow(errMsg);
    }
  }


  getDataByPage = async (pageNumber, isLoaderEnabled) => {
    const { selectedDate } = this.props;
    const { perPage: limit } = this.state;
    const contractorId = this.props.contractorInfo.businessDetails._id;
    if (!isLoaderEnabled) {
      this.setState({
        gettingOrders: true
      })

    }
    try {

      const ordersRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_orders`,
        {
          params: {
            contractorId,
            date: moment(selectedDate).format("YYYY-MM-DD"),
            limit,
            page: pageNumber + 1
          }
        }
      );


      const {
        orders,
        total,
        perPage,
        page: receivedPage,
      } = ordersRes.data;



      this.setState({
        jobs: orders,
        total,
        perPage,
        page: receivedPage - 1,
        currPage:receivedPage,
        gettingOrders: false
      })
      
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";

      if (response && response.data && response.data.message) {
        errMsg = response.data.message;
      }

      this.setState({
        gettingOrders: false
      })

      this.props.setMsgAndShow(errMsg);
    }
  }


  getDataByPageAndLimit = async (pageNumber,newLimit, isLoaderEnabled) => {
    const { selectedDate } = this.props;
    const contractorId = this.props.contractorInfo.businessDetails._id;

    if(!isLoaderEnabled){
      this.setState({
        gettingOrders: true
      })
    }

    try {

      const ordersRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_orders`,
        {
          params: {
            contractorId,
            date: moment(selectedDate).format("YYYY-MM-DD"),
            limit:pageNumber,
            page: newLimit
          }
        }
      );


      const {
        orders,
        total,
        perPage,
        page: receivedPage,
      } = ordersRes.data;


      this.setState({
        jobs: orders,
        total,
        perPage,
        page: receivedPage - 1,
        currPage:receivedPage,
        gettingOrders: false
      })


    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";

      if (response && response.data && response.data.message) {
        errMsg = response.data.message;
      }

      this.setState({
        gettingOrders: false
      })

      this.props.setMsgAndShow(errMsg);
    }
  }

  getDataByLimit = async (limit) =>{
    const { selectedDate } = this.props;
    const { currPage } = this.state;
    const contractorId = this.props.contractorInfo.businessDetails._id;
    this.setState({
      gettingOrders: true
    })
    try {

      const ordersRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_orders`,
        {
          params: {
            contractorId,
            date: moment(selectedDate).format("YYYY-MM-DD"),
            limit,
            page: currPage
          }
        }
      );


      const {
        orders,
        total,
        perPage,
        page: receivedPage,
      } = ordersRes.data;

      const totalPages = getTotalPagesCount(total, perPage);


      if (receivedPage > totalPages) {
        this.getDataByPageAndLimit(totalPages,limit,true);
      } else {
        this.setState({
          jobs: orders,
          total,
          perPage,
          page: receivedPage - 1,
          currPage:receivedPage,
          gettingOrders: false
        })
      }

    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";

      if (response && response.data && response.data.message) {
        errMsg = response.data.message;
      }

      this.setState({
        gettingOrders: false
      })

      this.props.setMsgAndShow(errMsg);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.checkForListOpen(prevProps, this.props)) {
      this.getData();
    }
  }

  sendTrackEvent = (eventName, requestType, url, status, path) => {
    const { trackApiEvent } = this.props;
    trackApiEvent(eventName, requestType, url, status, path);
  }


  handlePageChange = (_, page) => {
    this.getDataByPage(page);
  }

  handlePerPageLimit = (limit) => {
    this.getDataByLimit(limit);
  }


  closeListModal = () => {

    this.props.closeModal()
  }



  getOTPForOrder = (job) => {
    this.setState({
      jobData: { ...job },
      isOTPModalOpen: true
    })
  }


  closeOTPModal = () => {
    this.setState({
      jobData: {},
      isOTPModalOpen: false
    })
  }

  getJobDetails = (jobItem) => {
    // const { show } = this.state;

    // let doNotShowJobBtns = false;

    // if (show === "jobsInCalender") {
    //   doNotShowJobBtns = true;
    // }
    this.setState({
      isJobDetailsModalOpen: true,
      // doNotShowJobBtns,
      jobData: { ...jobItem }
    });
  }


  jobCompleted = async (jobItem) => {

    try {
      this.enableLoader();
      const resData = await axios.post(
        `${process.env.REACT_APP_API_PREFIX}contractor/orders/complete/${jobItem._id
        }`
      );


      if (resData.data.status === "OK") {
        this.props.setMsgAndShow(`Job Id ${jobItem.displayId} is completed`);
      } else {
        this.props.setMsgAndShow(resData.data.message);

        this.sendTrackEvent("Complete_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/complete/`, resData.data.message, window.location.pathname);
      }
      this.disableLoader();
      this.updateList()
      this.callOnJobActionSuccess();
    } catch (error) {
      const { response } = error;
      let resMsg = "Error Occured!";

      if (response && response.data) {
        resMsg = response.data.message ? response.data.message : "Error Occured!"
        this.props.setMsgAndShow(response.data.message ? response.data.message : response.data.msg ? response.data.msg : "Error Occured!");
      }
      this.disableLoader();
      this.trackApiEvent("Complete_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/complete/`, resMsg, window.location.pathname);
    }
  };



  checkOTPRequirementAndComplete = (job) => {
    const { isOTPRequired } = job;

    if (isOTPRequired) {
      this.getOTPForOrder(job);
    } else {
      this.jobCompleted(job);
    }
  }

  openEmployeeMenu = async (item, _, itemRef) => {
    try {
      this.enableLoader();
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}employee/get_employee_data_by_job`,
        {
          params: {
            contractorId: this.props.contractorInfo.businessDetails._id,
            date: moment(item.date).format("YYYY-MM-DD"),
            time: item.time,
            jobId: item._id
          }
        }
      );

      this.sendTrackEvent("Get_Employee_By_Job", "GET", `${process.env.REACT_APP_API_PREFIX}employee/get_employee_data_by_job`, "OK", window.location.pathname);



      const { employeeList } = data;

      this.setState({
        employeeList,
        isAssignMenuOpen: true,
        btnRef: itemRef,
        jobData: item,
        load: false
      });
    } catch (error) {

      const { response } = error;
      let resMsg = "Error Occured!";

      if (response && response.data) {
        resMsg = response.data.message ? response.data.message : "Error Occured!";
      }

      this.sendTrackEvent("Get_Employee_By_Job", "GET", `${process.env.REACT_APP_API_PREFIX}employee/get_employee_data_by_job`, resMsg, window.location.pathname);
      this.disableLoader();
      this.props.setMsgAndShow(response.data.message ? response.data.message : response.data.msg);
    }
  }

  handleAssignMenuClose = () => {
    this.setState({
      btnRef: null,
      isAssignMenuOpen: false
    })
  }


  deleteJob = async jobItem => {

    let msgForPopUp = "Unable to process request!";
    let callSuccessFn = false;
    try {
      this.enableLoader();
      const jobUpdateObj = {
        orderId: jobItem._id,
        data: {
          deletedByContractor: true,
          isActive: false
        }
      };
      const resData = await axios.put(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/update`,
        { ...jobUpdateObj }
      );

      if (resData.data.status === "OK") {

        this.sendTrackEvent("Delete_Job", "PUT", `${process.env.REACT_APP_API_PREFIX}cleaning_order/update`, "OK", window.location.pathname);
        callSuccessFn = true;
        msgForPopUp = `Job Id ${jobItem.displayId} is deleted`;
      } else {
        msgForPopUp = resData.data.message;
      }

      this.handleJobDetailsModal();
      this.disableLoader();
      this.updateList()
      if (callSuccessFn) {
        this.callOnJobActionSuccess();
      }
      this.props.setMsgAndShow(msgForPopUp);
    } catch (error) {
      const { response } = error;
      let resMsg = "Error Occured!";

      if (response && response.data) {
        resMsg = response.data.msg ? response.data.msg : "Error Occured!"
      }
      this.disableLoader();
      this.sendTrackEvent("Delete_Job", "PUT", `${process.env.REACT_APP_API_PREFIX}cleaning_order/update`, resMsg, window.location.pathname);
      this.props.setMsgAndShow(resMsg);
    }
  };

  cancelJob = async (jobItem) => {

    let msgForPopUp = "Unable to cancel job!";
    try {
      this.enableLoader();
      const resData = await axios.post(
        `${process.env.REACT_APP_API_PREFIX}contractor/orders/cancel/${jobItem._id}`
      );


      if (resData.data.status === "OK") {

        this.sendTrackEvent("Cancel_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/cancel/`, "OK", window.location.pathname);

        msgForPopUp = `Job Id ${jobItem.displayId} is cancelled`;
      } else {
        msgForPopUp = resData.data.message;
        this.sendTrackEvent("Cancel_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/cancel/`, resData.data.message, window.location.pathname);
      }
      this.disableLoader();
      this.updateList()
      this.callOnJobActionSuccess();
      this.props.setMsgAndShow(msgForPopUp);
    } catch (error) {
      const { response } = error;
      let msg = "Error Occured!";

      if (response && response.data) {
        msg = response.data.message ? response.data.message : "Error Occured!";
      }
      this.disableLoader();
      this.sendTrackEvent("Cancel_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/cancel/`, msg, window.location.pathname);
      this.props.setMsgAndShow(msg);
    }
  };


  isAssigned = itemObj => {
    if (itemObj.assignedEmployeeIds) {
      return itemObj.assignedEmployeeIds.length > 0;
    } else if (itemObj.oldAssignedEmployeeIds) {
      return itemObj.oldAssignedEmployeeIds.length > 0
    }
  };

  disableDeleteButton = itemObj => {
    if (!itemObj.createdByContractor || itemObj.jobCompleted) {
      return true;
    } else {
      return false;
    }
  };
  disableCancelButton = (itemObj) => {
    if (itemObj.jobCompleted) {
      return true;
    } else {
      return false;
    }
  }


  assignJob = e => {
    const { target } = e;
    const { value, checked } = target;
    const jobData = cloneDeep(this.state.jobData);

    if (checked) {
      jobData.assignedEmployeeIds.push(value);

    } else {
      const employeeIndex = jobData.assignedEmployeeIds.indexOf(value);
      if (employeeIndex > -1) {
        jobData.assignedEmployeeIds.splice(employeeIndex, 1);
      }
    }

    this.setState({
      jobData
    });
  };
  confirmAssign = async () => {
    let {
      jobData
    } = this.state;
    let msgForPopUp;



    const jobId = jobData._id;
    const displayId = jobData.displayId;

    const updateObj = {
      orderId: jobId,
      assignedEmployeeIds: jobData.assignedEmployeeIds
    };

    if (
      jobData.assignedEmployeeIds.length > 0
    ) {

      msgForPopUp = `Job Id '${displayId}' is assigned`;
    } else {

      msgForPopUp = `Job Id '${displayId}' is unassigned`;
    }

    try {
      this.enableLoader();
      const updatedJobRes = await axios.post(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/assign`,
        {
          ...updateObj
        }
      );

      this.disableLoader();
      if (updatedJobRes.data.status === "Success") {
        this.sendTrackEvent("Assign_Job", "POST", `${process.env.REACT_APP_API_PREFIX}cleaning_order/assign`, "OK", window.location.pathname);
        this.handleAssignMenuClose();
        this.updateList();
        this.callOnJobActionSuccess();
      } else {
        msgForPopUp = updatedJobRes.data.message;
        this.sendTrackEvent("Assign_Job", "POST", `${process.env.REACT_APP_API_PREFIX}cleaning_order/assign`, msgForPopUp, window.location.pathname);
      }


      this.props.setMsgAndShow(msgForPopUp);

    } catch (error) {
      const { response } = error;

      let msg = "Error Occured!";
      if (response && response.data) {
        msg = response.data.message ? response.data.message : "Error Occured!";
      }

      this.sendTrackEvent("Assign_Job", "POST", `${process.env.REACT_APP_API_PREFIX}cleaning_order/assign`, msg, window.location.pathname);
      this.disableLoader();
      this.props.setMsgAndShow(msg);
    }
  };


  handleJobDetailsModal = () => {
    this.setState({
      isJobDetailsModalOpen: false,
      isAssignMenuOpen: false,
      btnRef: null,
      jobItem: {}
    });
  }

  handleCompleteSuccess = () => {
    this.sendTrackEvent("Complete_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/complete/`, "OK", window.location.pathname);

    this.updateList()
    this.callOnJobActionSuccess();
  }


  handleCompleteCallFailure = (errorRes) => {

    let errorMsg = "Error occurred while marking job complete!";
    const { response } = errorRes;
    if (response && response.data && response.data.message) {
      errorMsg = response.data.message;
    }

    this.sendTrackEvent("Complete_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/complete/`, errorMsg, window.location.pathname);
  }

  callOnJobActionSuccess = () => {
    if(this.props.onJobActionSuccess){
      this.props.onJobActionSuccess();
    }
  }

  actions = [
    {
      label: "Get Details",
      onClick: this.getJobDetails,
      isForJobModal: true
    },
    {
      label: "Mark as Complete",
      onClick: this.checkOTPRequirementAndComplete,
      checkForDisabled: true,
      propToCheck: "jobCompleted"
    },
    {
      label: "Yet to Assign",
      secondaryLabel: "Assigned",
      onClick: this.openEmployeeMenu,
      isSecondary: this.isAssigned,
      checkForDisabled: true,
      propToCheck: "jobCompleted",
      isRef: true
    },
    {
      label: "Delete Job",
      onClick: this.deleteJob,
      checkForDisabled: true,
      disableButton: true,
      checkDisableButton: this.disableDeleteButton
    },
    {
      label: "Cancel Job",
      checkForDisabled: true,
      disableButton: true,
      checkDisableButton: this.disableCancelButton,
      onClick: this.cancelJob
    }
  ];


  jobActions = [
    {
      label: "Assign",
      onClick: this.confirmAssign
    }
  ];

  render() {


    return (
      <>
        <JobsListModal
          isOpen={this.props.isOpen}
          handlePageChange={this.handlePageChange}
          handlePerPageLimit={this.handlePerPageLimit}
          actions={this.actions}
          handleClose={this.closeListModal}
          assignJob={this.assignJob}
          handleAssignMenuClose={this.handleAssignMenuClose}
          jobActions={this.jobActions}
          {...this.state}
        />

        <JobDetailsModal
          isJobDetailsModalOpen={this.state.isJobDetailsModalOpen}
          handleJobDetailsModal={this.handleJobDetailsModal}
          errMsg="No Data Available"
          assignJob={this.assignJob}
          jobActions={this.jobActions}
          jobItemObj={this.state.jobData}
          cleaningTypesObj={this.props.cleaningTypesObj}
        />
        <GetOTPModal
          closeModal={this.closeOTPModal}
          open={this.state.isOTPModalOpen}
          url={`${process.env.REACT_APP_API_PREFIX}contractor/orders/`}
          action="complete"
          jobId={this.state.jobData._id}
          onCallSuccess={this.handleCompleteSuccess}
          onCallFailure={this.handleCompleteCallFailure}
        />
      </>
    )
  }
}




const mapStateToProps = (state) => ({
  contractorInfo: state.contractor,
})

const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  toggleLoader: () => dispatch(toggleLoader()),
  toggleDialogLoader: () => dispatch(toggleDialogLoader()),
})


const ConnectedWithRedux = connect(mapStateToProps, mapDispatchToProps)(JobsListModalContainer);
export default withMixpanel(ConnectedWithRedux);