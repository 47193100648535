function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 24,
    height: 22.106,
    viewBox: "0 0 24 22.106"
  }, props), React.createElement("path", {
    id: "prefix__iconfinder_window_casement_frame_glass_furniture_4944896",
    d: "M3.1 5.928A1.094 1.094 0 0 0 4.154 5.1h.389v11.282a.273.273 0 0 0 0 .545v8.642a.274.274 0 0 0 .273.273h5.037a.273.273 0 0 0 .273-.259 15.612 15.612 0 0 0-1.221-6.933h10.19a15.621 15.621 0 0 0-1.22 6.933.272.272 0 0 0 .272.259h5.038a.273.273 0 0 0 .273-.273v-8.641a.273.273 0 0 0 0-.545V5.105h.389a1.1 1.1 0 1 0 0-.545H4.154A1.094 1.094 0 1 0 3.1 5.928zm1.992-.823h8.6a17.915 17.915 0 0 1-.67 3 .227.227 0 0 0-.009.027 17.82 17.82 0 0 1-5.543 8.251H5.088zm8.639 2.841h-.1c.032-.107.071-.212.1-.32zm-.275.545h.275v2.841h-1.482a18.326 18.326 0 0 0 1.203-2.84zm-1.516 3.386h1.791v2.841h-3.8a18.438 18.438 0 0 0 2.006-2.84zm2.336 0h1.783a18.419 18.419 0 0 0 2.017 2.841h-3.8zm0-.545v-2.84h.267a18.333 18.333 0 0 0 1.21 2.841zm0-3.386v-.333c.031.112.064.223.1.333zM5.088 25.3v-8.372h2.326A15.065 15.065 0 0 1 9.593 25.3zm2.956-8.369h.346a.273.273 0 0 0 0-.545h-.1q.6-.536 1.14-1.119h4.3V18.1H8.658c-.189-.394-.392-.787-.613-1.172zm6.229 1.169v-2.84h4.29c.362.387.742.761 1.141 1.119h-.094a.273.273 0 1 0 0 .545h.346c-.223.385-.425.778-.613 1.177h-5.07zm8.639 7.2h-4.5a15.066 15.066 0 0 1 2.18-8.369h2.325zm0-8.915H20.53a17.828 17.828 0 0 1-5.538-8.255.138.138 0 0 0-.007-.021 18.036 18.036 0 0 1-.67-3h8.6v11.273zM24.9 4.281a.551.551 0 1 1-.551.551.551.551 0 0 1 .551-.551zm-21.8 0a.551.551 0 1 1-.551.551.551.551 0 0 1 .551-.551z",
    transform: "translate(-2 -3.736)",
    style: {
      "fill": "#00949c"
    }
  }));
};

export default __webpack_public_path__ + "static/media/job_windows.a9443853.svg";
export { SvgComponent as ReactComponent };