import React from "react";
import cn from "classnames";
import Dialog from "core/components/Dialog";
import Avatar from "core/components/Avatar";
import Typography from "core/components/Typography";
import IconButton from "core/components/IconButton";
import Loader from "../ProgressCircle";
import Grid from "core/components/Grid";
import { Close } from "mdi-material-ui";
import { withStyles } from "core/components";
import formatPhone from "utils/formatPhone";
// import defaultCustomerImage from "../../assets/images/default.png";
import BookingsTable from "../BookingsTable";
import { ChevronRight, ChevronDown} from "mdi-material-ui";
  // import phoneIcon from "../../assets/images/phone.png";
  // import mailIcon from "../../assets/images/message.png";
  // import addressIcon from "../../assets/images/address.png";
import Icon from "core/components/Icon";


const styles = theme => ({  
  closeBtn: {
    position: "absolute",
    top: 10,
    right: 10
  },
  profileImgCont: {
    height: 232,
    width: 232,
    borderRadius:"50%",
    overflow:"hidden",
    border:"16px solid #fff",
    position: "fixed",
    "& .avatar-text":{
      fontSize: "4.8rem",
      textTransform:"uppercase"
    },
    [theme.breakpoints.down('xs')]: {
      position: "relative",
      height: 80,
      width: 80,
      border:"2px solid #fff",
      marginLeft: 18,
      marginTop: 18
    }
  },
  profileImgContSemi: {
    borderRadius: 0,
    borderTopLeftRadius: 116,
    borderTopRightRadius: 116,
    overflow: "hidden",
    height: 116,
  },
  profileName:{
    fontSize: 24,
    marginTop: 32
  },
  phoneNumber:{
    marginTop: 12
  },
  email:{

  },
  fadedGrey:{
    color:"#aeaeae"
  },
  addressLabel:{
    textTransform:"uppercase"
  },
  mutedGrey:{
    color:"#47495a"
  },
  addressCont:{
    marginTop: 14
  },
  link:{
    color:"#15959a",
    marginLeft: 3,
    textDecoration:"none"
  },
  bookingsCont:{
    marginTop:20,

  },
  bookingsHeading:{
    color:"#000",
    fontSize: 20
  },
  paddingLeft_20:{
    paddingLeft: 20
  },
  marginLeft_6:{
    marginLeft: 6
  },
  marginTop_10:{
    marginTop:10
  },
  customerDetailsRow:{
    marginLeft: 20
  },
  customerDetailsCont:{
    marginTop: 116,
    overflow: "auto",
    [theme.breakpoints.down('xs')]: {
      marginTop: 0
    }
  },
  customerDetailsProfileCont:{
    background:"#fff",
    [theme.breakpoints.down('xs')]: {
      flexWrap: "wrap"
    }
  },
  profileImgAvatar:{
    marginTop: -116,
    width: 232,
    minWidth: 232,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0
    }
  },
  dialog:{
    boxShadow:"none",
    background:"none",
    overflow:"hidden"
  },
  infoCont:{
    background:"#fff",
    position: "relative"
  },
  iconColor:{
    color: "#15959a"
  }
})


const headers = [
  {
    name: "displayId",
    label: "Order ID"
  },
  {
    name: "date",
    label: "Date Of Cleaning"
  },
  {
    name: "time",
    label: "Time"
  },
  {
    name: "orderCost",
    label: "Cost"
  },
  {
    name:"orderStatus",
    label:"Status"
  }
];


const columnProps = [
  {
    name:"displayId",
  },
  {
    name: "date",
    isDate: true,
    dateFormat:"DD MMM YYYY"
  },
  {
    name: "time",
    isTime: true,
    originalFormat:"hh:mm:ss",
    timeFormat:"hh:mm A"
  },
  {
    name:"orderCost",
    isCost: true
  },
  {
    name:"orderStatus",
    isStatus:true,
    isBadge: true,
    Icon: ChevronRight,
    SecondaryIcon: ChevronDown

    // Icon: ChevronRight,
    // SecondaryIcon: ChevronDown
  },
  
  
];


const CustomerDetailsModal = ({
  isCustomerDetailsModalOpen,
  handleCloseCustomerDetailsModal,
  customerDetails,
  classes,
  orders,
  gettingOrders,
  page,
  perPage,
  total,
  handlePageChange,
  handleLimitChange,
}) => {

  const { serviceAddress = {}, fname = "", lname = "", phone = "", email = "", profileImage = ""} = customerDetails;
  const { address = "", city = "", state = "", zipcode = "" } = serviceAddress;

  const mapLink = `http://maps.google.com/?q=${address},${city},${state},${zipcode}`;
  const displayAddress = `${address}, ${city}, ${state}, ${zipcode}`;
  const imageURL = profileImage ? 
  `url('${process.env.REACT_APP_USER_FILES}${profileImage}')`
  : null;

  return (
    <Dialog
      fullWidth
      open={isCustomerDetailsModalOpen}
      onClose={handleCloseCustomerDetailsModal}
      maxWidth="md"
      classes={{
        paper:classes.dialog
      }}
    >
      {gettingOrders && <Loader position="fixed"/>}
      <Grid className={classes.customerDetailsCont}>
        <Grid className={classes.infoCont}>
          <Grid container className={classes.customerDetailsProfileCont} wrap="nowrap">
            <Grid item className={classes.profileImgAvatar}>

              <Avatar
                className={cn(classes.profileImgCont,"profile-btn-avatar img-fluid mr-2")}
                image={imageURL}
                name={`${fname} ${lname}`}
                disableBorder
              />
            </Grid>

            <Grid container wrap="nowrap" className={classes.customerDetailsRow}>
              <Grid item>
                <Typography className={cn(classes.mutedGrey,classes.profileName)}>
                {`${fname} ${lname}`}
                </Typography>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item className={cn(classes.fadedGrey)}>
                    <Icon className={classes.iconColor} lineHeight="inherit" fontSize="1.1rem">
                      phone
                    </Icon>
                    <span className={classes.marginLeft_6}>
                      {formatPhone(phone)}
                    </span>
                  </Grid>

                  <Grid item className={cn(classes.fadedGrey)}>
                    <Icon className={classes.iconColor} lineHeight="inherit" fontSize="1.1rem">
                      message
                    </Icon>
                    <span className={cn(classes.marginLeft_6, 'break-email')}>
                      {email} 
                    </span>
                  </Grid>
                  <Grid item className={cn(classes.fadedGrey)}>
                    <Icon className={classes.iconColor} lineHeight="inherit" fontSize="1.1rem">
                      address
                    </Icon>
                    <span className={classes.marginLeft_6}>
                      <span>
                        {displayAddress} -
                      </span>
                      <a className={classes.link} href={mapLink} target="__blank">
                        View on map
                      </a>
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <IconButton onClick={handleCloseCustomerDetailsModal} className={classes.closeBtn}>
                <Close />
              </IconButton>

            </Grid>
          </Grid>
          <Grid className={classes.bookingsCont}>
              <Typography align="center" className={cn(classes.paddingLeft_20, classes.bookingsHeading)}>
                Booking Details
              </Typography>
              <Grid>
                <BookingsTable
                  list={orders}
                  headers={headers}
                  columnProps={columnProps}
                  actions={[]}
                  page={page}
                  total={total}
                  limit={perPage}
                  handlePageChange={handlePageChange}
                  handleLimitChange={handleLimitChange}
                />
              </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Dialog>
  )
}


export default withStyles(styles)(CustomerDetailsModal);