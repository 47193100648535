function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 16,
    height: 16,
    viewBox: "0 0 16 16"
  }, props), React.createElement("g", {
    id: "prefix__Group_359",
    "data-name": "Group 359",
    transform: "translate(-285 -639)"
  }, React.createElement("path", {
    id: "prefix__Path_280",
    d: "M5 5v16h16V5zm14.545 14.545H6.455v-9.454h13.09zM6.455 8.636V6.455h13.09v2.181z",
    className: "prefix__cls-1",
    "data-name": "Path 280",
    transform: "translate(280 634)",
    style: {
      "fill": "#fff"
    }
  }), React.createElement("path", {
    id: "prefix__Rectangle_23",
    d: "M0 0H1.455V3.847H0z",
    className: "prefix__cls-1",
    "data-name": "Rectangle 23",
    transform: "translate(288.913 647.058)",
    style: {
      "fill": "#fff"
    }
  }), React.createElement("path", {
    id: "prefix__Rectangle_24",
    d: "M0 0H1.455V3.847H0z",
    className: "prefix__cls-1",
    "data-name": "Rectangle 24",
    transform: "translate(292.273 647.029)",
    style: {
      "fill": "#fff"
    }
  }), React.createElement("path", {
    id: "prefix__Rectangle_25",
    d: "M0 0H1.455V3.847H0z",
    className: "prefix__cls-1",
    "data-name": "Rectangle 25",
    transform: "translate(295.64 647.029)",
    style: {
      "fill": "#fff"
    }
  })));
};

export default __webpack_public_path__ + "static/media/job_date.c6df9c7c.svg";
export { SvgComponent as ReactComponent };