import React from 'react'
import cn from "classnames";
import Image from "core/components/Image";
import styles from "./plan.module.css";
import { Grid } from "core/components";

const BasicPlanItem = (props) => {
  const { changePlan, plan } = props;
  const isSelected = plan === "basic";
  return (
    <Grid>
      <Grid className={cn(styles['plan-card'],isSelected ? styles['plan-selected'] : "")}>
        <Grid className={cn(styles['plan-card-header'], styles['basic-plan-header'])}>
          <Image src="assets/images/svg/basic_plan_icon.svg" alt="" />
          <p className="mt-1">basic plan</p>
        </Grid>
        <Grid 
          container 
          direction="column" 
          justify="space-between"  
        >
          <Grid className={styles['plan-card-body']}>
            <ul className={styles['margin-pad-0']}>
              <li className="mt-0">
                <Image src="assets/images/svg/red_circle_tick.svg" alt="" className="mr-2" /> 
                <span className={styles['ml-2']}>
                  15 Jobs
                </span>
              </li>
              <li>
                <Image src="assets/images/svg/red_circle_tick.svg" alt="" className="mr-2" /> 
                <span className={styles['ml-2']} >
                  15 Invoices
                </span>
              </li>
              <li>
                <Image src="assets/images/svg/red_circle_tick.svg" alt="" className="mr-2" /> 
                <span className={styles['ml-2']} >
                  1 Employee
                </span>
              </li>
              <li>
                <Image src="assets/images/svg/red_circle_tick.svg" alt="" className="mr-2" />
                <span className={styles['ml-2']} >
                  Also includes 90 days trail
                </span>
              </li>
            </ul>

          </Grid>
          <Grid className={styles["plan-card-footer"]}>
            <button type="button" className={cn(styles['bac-btn'], styles["plan-card-btn"])} onClick={changePlan("basic")}>
            {isSelected ? "Selected": "Select"}
            </button>
            {/* <a href="#signupform" className="bac-btn plan-card-btn">signup</a> */}
          </Grid>
        </Grid>
      </Grid>

    </Grid>

  )
}

export default BasicPlanItem
