import React from "react";
import Button from "core/components/Button";
import Dialog from 'core/components/Dialog';
import DialogActions from 'core/components/DialogActions';
import DialogContent from 'core/components/DialogContent';
import { withStyles } from "core/components/Styles";
import {primaryColor, primaryHoverColor} from "../../utils/colors";
import InfiniteCalendar from "react-infinite-calendar";
import "react-infinite-calendar/styles.css"; 

const year = new Date().getFullYear();

const startDate = new Date((year - 90), 1, 1);
const lastDate = new Date((year + 5), 1, 1);
const DatePickerModal = ({classes, isOpen, toggle, width, height, min, minDate, max, maxDate, onSelect, handleCancel }) => {
  const vWidth = window.innerWidth - 20;
  const vHeight = window.innerHeight;
  if(!min) min = startDate;
  if(!minDate) minDate = startDate;
  if(!max) max = lastDate;
  if(!maxDate) maxDate = lastDate;
  return (
    <Dialog open={isOpen} onClose={toggle} >
      <DialogContent className={classes.removePadding}>
        <InfiniteCalendar
          width={width || vWidth > 400 ? 400 : vWidth}
          height={height || vHeight/2}
          minDate={minDate}
          min={min}
          maxDate={maxDate}
          max={max}
          onSelect={onSelect}
          theme={{
            accentColor: "#448AFF",
            floatingNav: {
              background: "#8912f9",
              chevron: "#FFA726",
              color: "#FFF",
            },
            headerColor: "#5d00b4",
            selectionColor: "#5d00b4",
            textColor: {
              active: "#FFF",
              default: "#333",
            },
            todayColor: "#8912f9",
            weekdayColor: "#7402de",
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button className={classes.primaryButton} onClick={toggle}>Save</Button>
        <Button color="secondary" onClick={handleCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );};

const styles = theme => ({
  modalCentered:{
    textAlign:"center"
  },
  removePadding:{
    padding: "0 !important",
  },
  primaryButton: {
    background: primaryColor,
    border: "none",
    color:"white",
    "&:hover": {
      background: primaryHoverColor
    }
  }
});



export default withStyles(styles)(DatePickerModal);