import React from "react";
import { withStyles } from "core/components/Styles";

import Icon from "core/components/Icon";
// import cn from "classnames";
const styles = theme => ({
  textAreaInput:{
    border: 0,
    borderRadius: 3,
    padding: "0.85rem 1rem",
    backgroundColor:"#F6F7F9",
    width: "100%",
    fontSize:16,
    lineHeight:"normal",
    "&.right-icon" : {
      paddingRight: "4rem"
    },
    "&.left-icon" : {
      paddingLeft: "4rem"
    },
    "&::placeholder": {
      color: "#646464"
    },
    "&:focus": {
      boxShadow: 0,
      outline: "none"
    }
  },
  errors:{
    color:"red",
    fontSize:"0.75rem"
  },
  removeBottomMargin:{
    marginBottom: "0px"
  },

  relativePosition:{
    position:"relative"
  },
  iconStyle:{
    position:"absolute",
    fontSize:16,
    color: "#b7b7b7",
    top: 24,
    left: 20
  }
})

const TextAreaField = ({ 
  rows, 
  name, 
  placeholder, 
  onClick, 
  input, 
  classes, 
  meta = {}, 
  removeBottomMargin, 
  leftIcon,
  ...rem 
}) => {

  if(removeBottomMargin){
    rem.style = {
      marginBottom:"0px"
    }
  }
  
  
  return (
    <div className={classes.relativePosition} >
      { leftIcon && <Icon lineHeight="inherit" className={classes.iconStyle}>{leftIcon}</Icon> }
      <textarea
        className={classes.textAreaInput}
        onClick={onClick}
        rows={rows}
        name={name}
        placeholder={placeholder}
        {...input}
        {...rem}
      >
      </textarea>
      {meta && meta.touched &&
        ((meta.error && <span className={classes.errors} >{meta.error}</span>) ||
          (meta.warning && <span>{meta.warning}</span>))}
    </div>
  );
};


export default withStyles(styles)(TextAreaField)