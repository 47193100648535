import React, { Component } from "react";
import Grid from "core/components/Grid";
import Button from "core/components/Button";
import cn from "classnames";
import moment from "moment-timezone";
import { withStyles } from "core/components/Styles";
import { muted } from "theme/colors";

const styles = theme => ({
  jobItemCont: {
    backgroundColor: "rgb(243, 243, 243)",
    padding: 10,
    marginBottom: 10
  },
  textCenter: {
    textAlign: "center"
  },
  btnTopMargin_10: {
    marginTop: 6
  },
  btnTopMargin_5: {
    marginTop: 5
  },
  activeIndex: {
    position: "relative",
    zIndex: 9,
    backgroundColor: "#d8d8d8",
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)"
  },
  listTitle: {
    fontWeight: 300,
    color: muted
  }
});

const ItemHeader = ({ classes, text }) => (
  <h3 className={classes.listTitle}>{text}</h3>
);

class DataItem extends Component {
  anchorEl = {};

  render() {
    this.anchorEl = {};
    const {
      classes,
      dataItemObj,
      dataItemObjProps,
      actions,
      dataItemIndex,
      activeIndex,
      // getDetails,
      // showBtn
    } = this.props;
    let width = "";
    return (
      <Grid
        key={`item-obj-${dataItemIndex}`}
        item
        className={cn(
          classes.jobItemCont,
          activeIndex === dataItemIndex ? classes.activeIndex : ""
        )}
        xs={12}
      >
        <Grid container justify="flex-start" alignContent="center">
          {dataItemObjProps.map((itemObjProp, index) => {
            width =
              100 /
              (dataItemObjProps.length +
                (actions && actions.length > 0 ? 1 : 0));

            if (itemObjProp.isTimeStamp) {
              return (
                <Grid
                  key={`item-obj-data-${index}`}
                  className={classes.textCenter}
                  item
                  style={{
                    width: width + "%"
                  }}
                >
                  <ItemHeader text={itemObjProp.name} classes={classes} />
                  <p>
                    {moment(dataItemObj[itemObjProp.label]).format(
                      itemObjProp.formatRequired
                    )}
                  </p>
                </Grid>
              );
            } else if (itemObjProp.isTime) {
              return (
                <Grid
                  key={`item-obj-data-${index}`}
                  className={classes.textCenter}
                  item
                  style={{
                    width: width + "%"
                  }}
                >
                  <ItemHeader text={itemObjProp.name} classes={classes} />
                  <p>
                    {moment(
                      dataItemObj[itemObjProp.label],
                      itemObjProp.inFormat
                    ).format(itemObjProp.formatRequired)}
                  </p>
                </Grid>
              );
            } else if (itemObjProp.isCost) {
              const cost = dataItemObj[itemObjProp.label]
                ? dataItemObj[itemObjProp.label]
                : 0;
              return (
                <Grid
                  key={`item-obj-data-${index}`}
                  className={classes.textCenter}
                  item
                  style={{
                    width: width + "%"
                  }}
                >
                  <ItemHeader text={itemObjProp.name} classes={classes} />
                  <p>{`$${cost}`}</p>
                </Grid>
              );
            } else if (itemObjProp.isName) {
              return (
                <Grid
                  key={`item-obj-data-${index}`}
                  className={classes.textCenter}
                  item
                  style={{
                    width: width + "%"
                  }}
                >
                  <ItemHeader text={itemObjProp.name} classes={classes} />
                  <p>
                    {dataItemObj[itemObjProp.firstNameGetter] +
                      " " +
                      dataItemObj[itemObjProp.lastNameGetter]}
                  </p>
                </Grid>
              );
            } else if (itemObjProp.isPhone) {
              return (
                <Grid
                  key={`item-obj-data-${index}`}
                  className={classes.textCenter}
                  item
                  style={{
                    width: width + "%"
                  }}
                >
                  <ItemHeader text={itemObjProp.name} classes={classes} />
                  <p>{dataItemObj[itemObjProp.phoneLabel]}</p>
                </Grid>
              );
            } else if (itemObjProp.isAddress) {
              return (
                <Grid
                  key={`item-obj-data-${index}`}
                  className={classes.textCenter}
                  item
                  style={{
                    width: width + "%"
                  }}
                >
                  <ItemHeader text={itemObjProp.name} classes={classes} />
                  <p>
                    {`${dataItemObj[itemObjProp.streetGetter]}, ${
                      dataItemObj[itemObjProp.cityGetter]
                    }, ${dataItemObj[itemObjProp.stateGetter]}`}
                  </p>
                </Grid>
              );
            } else {
              return (
                <Grid
                  key={`item-obj-data-${index}`}
                  className={classes.textCenter}
                  item
                  style={{
                    width: width + "%"
                  }}
                >
                  <ItemHeader text={itemObjProp.name} classes={classes} />
                  <p>{dataItemObj[itemObjProp.label]}</p>
                </Grid>
              );
            }
          })}

          {actions && (
            <Grid
              container
              item
              className={classes.textCenter}
              alignItems="center"
              justify="center"
              style={{
                width: width + "%"
              }}
              direction="column"
            >
              {actions.map((action, index) => {
                if (action.isRef) {
                  if (action.checkForDisabled) {
                    return (
                      <Button
                        buttonRef={node => {
                          if (node) this.anchorEl[index] = node;
                        }}
                        key={`action-item-${index}`}
                        disabled={dataItemObj[action.propToCheck]}
                        className={classes.btnTopMargin_10}
                        fullWidth
                        onClick={action.onClick(
                          this.anchorEl,
                          dataItemIndex,
                          index,
                          dataItemObj
                        )}
                        variant="raised"
                      >
                        {action.isSecondary(dataItemObj)
                          ? action.secondaryLabel
                          : action.label}
                      </Button>
                    );
                  }
                  return (
                    <Button
                      buttonRef={node => {
                        if (node) this.anchorEl[index] = node;
                      }}
                      key={`action-item-${index}`}
                      className={classes.btnTopMargin_10}
                      fullWidth
                      onClick={action.onClick(
                        this.anchorEl,
                        dataItemIndex,
                        index,
                        dataItemObj
                      )}
                      variant="raised"
                    >
                      {action.isSecondary(dataItemObj)
                        ? action.secondaryLabel
                        : action.label}
                    </Button>
                  );
                } else if (action.isForJobModal) {
                  return (
                    <Button
                      key={`action-item-${index}`}
                      className={classes.btnTopMargin_10}
                      fullWidth
                      onClick={action.onClick(dataItemObj)}
                      variant="raised"
                    >
                      {action.label}
                    </Button>
                  );
                } else if (action.checkForDisabled) {
                  if (action.disableButton) {
                    if(!action.checkDisableButton(dataItemObj)){
                      return (
                      <Button
                        key={`action-item-${index}`}
                        className={classes.btnTopMargin_10}
                        fullWidth
                        onClick={action.onClick(dataItemObj)}
                        variant="raised"
                      >
                        {action.label}
                      </Button>
                    );
                    } else {
                      return ""
                    }
                  }
                  return (
                    <Button
                      key={`action-item-${index}`}
                      disabled={dataItemObj[action.propToCheck]}
                      className={classes.btnTopMargin_10}
                      fullWidth
                      onClick={action.onClick(dataItemObj)}
                      variant="raised"
                    >
                      {action.label}
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      key={`action-item-${index}`}
                      className={classes.btnTopMargin_10}
                      fullWidth
                      onClick={action.onClick(dataItemObj)}
                      variant="raised"
                    >
                      {action.label}
                    </Button>
                  );
                }
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(DataItem);
