import React, { Component, Fragment } from 'react'
import GoogleMap from "components/GoogleMap";
import {SliderField, InputField} from "components/FormField"
import { Field } from "redux-form";
import { required } from "components/FormField/Validations";
import {geoCode} from "utils/google";
import Grid from "core/components/Grid";
import { withStyles } from "core/components/Styles";
import { withMixpanel } from  "../../utils/react-mixpanel";
//import classes from '*.module.scss';
const styles = theme => ({
  mapDiv : {
    position: "relative", width: "100%", height: 300
  } 
})
class AddServiceArea extends Component {
  state = {
    center: null
  }
  componentDidMount(){
    const {area} = this.props
    geoCode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
    geoCode.fromAddress(area.address).then(
      response => {
        this.setState({ center: response.results[0].geometry.location});
        this.props.setGeo(response.results[0].geometry.location)
      },
      error => {
        console.error(error); // eslint-disable-line
      }
    );
  }

  trackInput = (fieldname)=> (e)=> {
    const { trackInputOnBlur } = this.props;
    if(e.target.value){
      trackInputOnBlur(fieldname, e.target.value, window.location.pathname);
    }
  }

  render() {
    const {area,classes} = this.props
    return <Fragment>
        <div className={classes.mapDiv}>
          <GoogleMap 
            circle={{
              radius: area.radius,
              center: this.state.center
            }}
            center={this.state.center}
          />
        </div>
        <Grid container spacing={6} alignItems="center" style={{marginTop : 20}}>
          <Grid item xs={10}>
            <Field 
              type="text"
              id="businessRadius"
              name="businessRadius"
              min={1}
              max={50}
              defaultValue={1}
              component={SliderField}
              validate={[required]}
            />
          </Grid>
          <Grid item xs={2}>
            <Field 
              placeholder="Enter Miles"
              type="number"
              id="businessRadius"
              name="businessRadius"
              component={InputField}
              validate={[required]}
              style={{color : "#874ec6"}}

              onBlur={this.trackInput("Business_Radius")}
            />
          </Grid>
        </Grid>
        
      </Fragment>
  }
}

const injectStyles = withStyles(styles)(AddServiceArea);
export default withMixpanel(injectStyles);