import { SET_JOB_AND_OPEN_MODAL, CLEAR_JOB_AND_CLOSE_MODAL, SET_AND_SHOW_ADDITIONAL_BOOKINGS, REMOVE_AND_HIDE_ADDITIONAL_BOOKINGS } from "../types";


export const openChangesModal = (obj) => ({
  type:SET_JOB_AND_OPEN_MODAL,
  payload:obj
})

export const closeChangesModal = () => ({
  type: CLEAR_JOB_AND_CLOSE_MODAL
})

export const setAndShowAdditionalBookings = (additionalBookings)=> ({
  type: SET_AND_SHOW_ADDITIONAL_BOOKINGS,
  payload: additionalBookings
});

export const removeAndHideAdditionalBookings = ()=> ({
  type: REMOVE_AND_HIDE_ADDITIONAL_BOOKINGS
});