import React from "react";
import {Grid, Typography} from "core/components";
import { withStyles } from "core/components/Styles";

const styles = theme => ({
  reviewInfoCard: {
    border: "1px solid #d8d8d8",
    width: "100%",
    minHeight: 211,
    height: 250,
    padding: "0 24px 10px 24px",
    marginBottom:20
  },
  textCenter: {
    textAlign: "center"
  },
  labelText: {
    color: "#5e3abb",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "12px",
    fontFamily: "Poppins"
  },
  labelValue: {
    color: "rgb(183, 183, 183)",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "12px",
    fontFamily: "Poppins",
    textAlign : "center"
  },
  fixedMargin: {
    marginLeft: 130,
    marginRight: 628
  },
  aligned: {
    paddingLeft: 65
  },
  fontStyle:{
    fontSize: 16,
    color: "#47495a"
  }
});


const InfoCell = ({classes, label, value , altText=""})=>{
  return(
    <div className={classes.aligned}>
      <span className={classes.labelText}> {`${label} :`} </span>
      <span className={classes.labelValue}>
        { value ? value : altText }
      </span> 
    </div>
  )
}

const ReviewInfo = ({ detailsObj, classes }) => {
  // const { email, fname, lname, phone } = detailsObj.userInfo;
  const {
    businessPhone,
    businessName,
    businessWebsite,
    // bankName,
    businessAddress,
    // accountType,
    businessEmail
  } = detailsObj.formValues;
  let displayBusinessAddress;

  if(businessAddress && businessAddress.state){
    displayBusinessAddress = `${businessAddress.street}${businessAddress.unit ? ` UNIT ${businessAddress.unit},`: ","} ${businessAddress.city}, ${businessAddress.state.value}`
  }

  return (
    <Grid container alignItems="center" justify="space-around" className={classes.reviewInfoCard}>
      <Typography className={classes.fontStyle} >
        You are almost done Please verify with details and click on Finish
      </Typography>
      
      <Grid container>
        <Grid item xs={12} md={4}>
          <InfoCell label="Address" value={displayBusinessAddress} altText="Not Provided" classes={classes} />
        </Grid>
        <Grid item xs={12} md={4}>
          <InfoCell label="Website" value={businessWebsite} altText="Not Provided" classes={classes} />  
        </Grid>
        <Grid item xs={12} md={4}>
          <InfoCell label="Email" value={businessEmail} altText="Not Provided" classes={classes}/>  
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={4}>
          <InfoCell label="Name" value={businessName} classes={classes}/> 
        </Grid>
        <Grid item xs={12} md={4}>
          <InfoCell label="Number" value={businessPhone} classes={classes}/>  
        </Grid>
        {/*
          <Grid item xs={12} md={4}>
            <InfoCell label="Payment Details" firstVal={accountType} classes={classes}/>  
          </Grid>
        */}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ReviewInfo);
