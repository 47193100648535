import React from "react";
import { Link } from "react-router-dom";
import Avatar from "core/components/Avatar";
import momentTz from "moment-timezone";
import { Grid, Typography, Button, 
  // IconButton 
} from "core/components";
import { 
  // TrashCanOutline,
  MenuDown 
} from "mdi-material-ui";
import { withStyles } from "core/components/Styles";
import PopUpMenu from "../PopUpMenu";
    // import imgAddr from "../../assets/images/address.png";
    // import phoneIcon from "../../assets/images/phone.png";
    // import dateIcon from "../../assets/images/calender.png";
    // import mailIcon from "../../assets/images/message.png";
    // import recty from "../../assets/images/recty.png";
import formatPhone from "../../utils/formatPhone";
import cn from "classnames";
import employeePlaceholder from "../../images/utils/employee-placeholder.png";
import Icon from "core/components/Icon";

const styles = theme => ({
  employeeInfoCont:{
    marginLeft : 0,
    padding: "12px 34px",
    background:"#fff",
    borderRadius : "8px",
    height : "100%",
    width : "100%", 
    transition : "all 0.3s",
    "&:hover":{
      transform : "scale(1.03)",
      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.158543)",
    }
  },
  employeeInfo:{
    marginLeft: 0,
  },
  textColor:{ 
    color:"#47495a"
  },
  profileImgCont:{
    height: 51,
    width: 51,
    backgroundSize: "cover",
    // marginRight : 34,
    borderRadius : 8,
    "& .avatar-text":{
      textTransform: "uppercase",
      fontSize: "1.5rem"
    }
  },
  phoneAndEmail:{
    textTransform : "uppercase",
    color :"#aeaeae",
    lineHeight: "18px",
    fontSize:12,
    fontWeight : 300,
    fontFamily:"Poppins",
    display: "flex"
  },
  dateOfJoining:{
    height : 15,
    fontSize : 12,
    fontFamily : "Poppins",
    color : "#aeaeae",
    fontWeight : 300,
    lineHeight : 1,
    display: "flex"
  },
  divider:{
    margin: "0 11.5px 0 11.5px",
    borderLeft:" 1px solid  rgba(151, 151, 151, 0.5)"
  },
  addressLabel:{
    fontSize: 12,
    textTransform:"uppercase",
    marginTop: 10,
    lineHeight: "14px"
  },
  

  editEmployeeBtn:{
    color:"rgba(36, 36, 36, 0.8)",
    padding:0,
    minHeight:28,
    minWidth : 127,
    borderRadius:23,
    border: "1px solid rgba(107, 124, 147, 0.4)",
    boxSizing : "border-box",
    background : "#ffffff",
    //lineHeight : "15px",
    //marginRight : 20,
    "&:hover":{
      background : "rgba(102, 61, 189, 0.8)",
      border : "1px solid rgba(107, 124, 147, 0.4)",
      color : "#ffffff"
    }
  },
  btnText :{
    paddingTop :2,
    fontSize: 11,
    textTransform:"uppercase",
    fontFamily:"Poppins",
    fontWeight : 500,
    letterSpacing : "1px",
  },
  moreOptionsBtn:{
    background : "#ffffff",
    color : "rgba(36, 36, 36, 0.8)",
    minHeight : 28,
    minWidth : 28,
    height : 28,
    width : 36,
    borderRadius:23,
    border : "1px solid rgba(107, 124, 147, 0.4)",
    padding : 0,
    "&:hover":{
      color : "#ffffff",
      border : "1px solid rgba(102, 61, 189, 0.8)",
      background : "rgba(102, 61, 189, 0.8)"
      //boxShadow : "0px 6px 12px rgba(102, 61, 189, 0.8)" 
    }
  },
  dropIconStyle : {
    height : 20,
    width : 20
  },

  addressText:{
    fontFamily : "Poppins",
    textTransform : "capitalize",
    lineHeight:"21px",
    fontWeight : 300,
    fontSize : 12
  },
  addressColor : {
    color : "#47495a"
  },
  addressLinkColor:{
    color : "#15959a",
    textDecoration:"none"
  },
  IconHW :{
    height : 15,
    width : 15
  },
  iconMarginRight : {
    marginRight : 6
  },
  // infoSpace:{
  //   width : 360
  // },
  // spaceLeft : {
  //   width : 282
  // },
  busyEmployee :{
    borderLeft : "9px solid #e7a02a"
  },
  freeEmployee :{
    borderLeft : "9px solid #4e96ea"
  },
  iconBtnColor: {
    color: "#14A3AE",
    fontSize: 15
  },
  // flexDisplay: {
  //   display: "flex"
  // }
  padTop: {
    paddingTop: 3
  }
});

const ListItem = ({ 
  className, 
  classes, 
  user, 
  editUser, 
  deleteUser,

  trackLink

}) =>{
	const { profileImage, fname, lname, phone,
    // lastLocation, 
    ...userObj 
    // address 
  } = user;

  let imageURL;
  
  const {
    serviceAddress,
    lastAddress = "", 
    createdat = "",
    employeeStatus = "", 
    email = "",
    userType,
  } = userObj
  //const { address="", city="", state="", zipcode="" } = serviceAddress;
  // const coords = lastLocation ?{
  //   lat: lastLocation[1],
  //   lng: lastLocation[0]
  // } : {};
  let userActions ;
  if(deleteUser){
    userActions = [{
      label : "Delete",
      onClick : deleteUser
    }]
  }


  if(userType === "customer" && profileImage){
    imageURL = `${process.env.REACT_APP_USER_FILES}${profileImage}`;
  } else if(userType === "employee" || userType === "contractor"){
    if(profileImage) {
      imageURL = `${process.env.REACT_APP_USER_FILES}${profileImage}`;
    } else {
      imageURL = employeePlaceholder;
    }

  }




  let mapLink,displayAddress;
  // let defaultImage;
  if(serviceAddress){
    // defaultImage = `url('${process.env.REACT_APP_USER_FILES}default.png')`
    const {address, city, state, zipcode} = serviceAddress;
    mapLink = `http://maps.google.com/?q=${address},${city},${state},${zipcode}`;
    if(address !== ""){ 
      displayAddress = `${address}, ${city}  ${state}, ${zipcode}`
      displayAddress = (displayAddress.length > 30) ? displayAddress.slice(0,26)+". . ." : displayAddress
    }else{ 
      displayAddress = lastAddress
    }
  } else {
    // defaultImage = `url('${process.env.REACT_APP_USER_FILES}defaultEmployee.png')`
  } 

  // const imageUrlLine = profileImage ? `url('${process.env.REACT_APP_USER_FILES}${profileImage}')` : `url('${recty}')`;
              
	return(
    <Grid container 
      alignItems="center" 
      justify="space-between"
      className={
        cn({
          [classes.employeeInfoCont]: classes.employeeInfoCont,
          [classes.freeEmployee]: employeeStatus && employeeStatus === "available",
          [classes.busyEmployee]: employeeStatus && employeeStatus !== "available",
          [className]: className
        })
      }
    >  
      <Grid item>
        <Grid container 
          justify="space-evenly" 
          alignItems="center" 
          spacing={8}
        > 
        

          <Grid item>
            <Avatar
              className={cn(classes.profileImgCont,"profile-btn-avatar img-fluid mr-2")}
              image={imageURL}
              name={`${fname} ${lname}`}
              disableBorder
            />
          </Grid>

          <Grid item>
            <Typography 
              component="div" 
              className={classes.textColor}
            >
              <Typography>
                {`${fname} ${lname}`}
              </Typography>
              { displayAddress 
                  ? <Typography> 
                      <span>
                        <Icon className={cn(classes.iconBtnColor, classes.iconMarginRight)} lineHeight="inherit" >
                          address
                        </Icon>
                      </span>
                      <span className={cn(classes.addressText, classes.addressColor)}>
                        {displayAddress+" - "}
                      </span>
                      {
                        mapLink
                        ? <a className={cn(classes.addressText, classes.addressLinkColor)} href={mapLink} onClick={trackLink} target="__blank">
                            View on map
                          </a>
                        : <Link className={cn(classes.addressText, classes.addressLinkColor)}  onClick={trackLink} to={{ pathname: '/track', state: { emp: user}}}>
                            View on map
                          </Link>
                      }
                    </Typography> 
                  : <Typography> 
                      <span>
                        <Icon className={cn(classes.iconBtnColor, classes.iconMarginRight)}   lineHeight="inherit" >
                          address
                        </Icon>
                        <span 
                          className={cn(classes.addressText, classes.addressColor)}
                        >
                          Adress Not Specified
                        </span> 
                      </span>
                      {/* <Link 
                        className={cn(classes.addressText, classes.addressLinkColor) } 
                        to={{ pathname: '/track', state: { emp: user }}}
                      >
                        View on map
                      </Link> */}
                    </Typography>
              }
            </Typography>
          </Grid>

        </Grid>
      </Grid>
      <Grid item xs={2}>
      { phone &&
          <Typography className={classes.phoneAndEmail}>
            <Icon className={cn(classes.iconBtnColor, classes.iconMarginRight)}  lineHeight="inherit" fontSize="1.2rem">
              phone
            </Icon>
            <span>{formatPhone(phone)}</span>
          </Typography>             
      }
      </Grid>
      <Grid item xs={2}>
        <Typography className={classes.dateOfJoining}>
          <Icon className={cn(classes.iconBtnColor, classes.iconMarginRight)}  lineHeight="inherit" fontSize="1.2rem">
            {createdat ? "calendar" : "message"}
          </Icon>
          <span className={classes.padTop}>
            {createdat ?  `Date of Joining: ${momentTz(createdat).format("DD/MM/YY")}` : email}
          </span>
        </Typography>  
      </Grid>
      <Grid item>
        <Grid container alignItems="center" justify="center" spacing={8}>
          <Grid item>
            <Button 
              className={classes.editEmployeeBtn} 
              onClick={editUser(user)} 
            >
              <span className={classes.btnText}>
                view Profile
              </span>
            </Button>
          </Grid>
          { 
            Array.isArray(userActions) &&
              <Grid item >
                <PopUpMenu 
                  item = {user}
                  icon = {<MenuDown/>}
                  buttonProps = {{className : classes.moreOptionsBtn}}
                  actions = {userActions}
                />
              </Grid>
          }
        </Grid>
      </Grid>
    </Grid> 
  )
}
export default withStyles(styles)(ListItem)