import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

const styles = theme =>({
  outerDiv:{
		border: "1px double #7dcdc7",
    borderRadius: "1.71rem",
    height: "2.84rem",
    width: "auto",
    padding: ".19rem .2rem", 
    margin: ".65rem .29rem", 
    display: "inline-block"
	},
  itemStyle: {
    height:"2.33rem",
    borderRadius: "1.71rem",
    fontSize:".85rem",
    color: "#676767",
		background: "#FFFFFF",
		border: "solid 1.4px #71cec6",
    padding: ".525rem .825rem",
    textAlign: "center",
		width:"auto",
    display: "inherit"
  },
});

const OptionItemText = ({item}) => {
  return (
    item
  )
}
const OptionListItem = (props)=>{
  const { classes, item, textComponent:TextComponent = OptionItemText } = props;
  return (
    <Grid className={classes.outerDiv}>
      <span className={classes.itemStyle}>
        <TextComponent item={item}/>

      </span>
    </Grid>
  )
}

export default withStyles(styles)(OptionListItem);