import React, { Component} from "react";
import Dropzone from "react-dropzone";
import axios from "utils/http";
import Button from "core/components/Button";
import cn from "classnames";
import Loader from "../Loader";
import { CloudUpload } from "mdi-material-ui";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { connect } from "react-redux";
import { setMsgAndShow } from "../../store/actions/popup";

import "./style.css";

class ImageDropZone extends Component {
  constructor() {
    super();
    this.state = {
      files: [],
      dropzoneActive: false,
      loading: false,
      openSnackbar: false,
      
      src:undefined,
      crop: {
        unit: 'px',
        width: 200,
        aspect:1,
      },
      imageDimensions: {
        x: "",
        y: "",
        height: "",
        width: ""
      }
    };
  }

  onDragEnter = () => this.setState({ dropzoneActive: true });

  onDragLeave = () => this.setState({ dropzoneActive: false });

  onDrop = (files) => {
    
    if (files && files.length > 0) {
      const { setMsgAndShow } = this.props;
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.addEventListener('load', (event)=>{

        const image = new Image(); 
        image.src = event.target.result;

        image.addEventListener("load", ()=> {
          const { naturalHeight, naturalWidth } = image;
          if (naturalHeight >= 200 || naturalWidth >= 200) {
            this.setState({ 
              src: reader.result,
              dropzoneActive: false,
              files,
            });
          }else{
            setMsgAndShow("Image size is too small.");
          }
        });

      });
    }
  }

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ 
      crop,
      dropzoneActive: false
    });
  };

  makeClientCrop = (imageDimensions) => {
    if (this.imageRef && imageDimensions.width && imageDimensions.height) {
      this.setState({ imageDimensions });
    }
  }

  getCroppedImg = (image, crop, fileType)=> {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Crop selection not made."); // eslint-disable-line
          return;
        }
        resolve(blob);
      }, fileType);
    });
  }


  handleFileUpload = async() => {
    const { files, imageDimensions } = this.state;
    const { onUploaded, url, noFileSelected, validate, formData } = this.props;
    
    if(validate && !validate(files)) return;

    if(!files || !files[0]) {
      if(noFileSelected) noFileSelected();
      return;
    }

    const data = new FormData();
    
    const croppedImageUrl = await this.getCroppedImg(
      this.imageRef,
      imageDimensions,
      files[0].type
    );
    files[0] = new File([croppedImageUrl], files[0].name, {type: files[0].type}); 
    data.append("file", files[0]);
    
    if(formData) {
      for(let key in formData) {
        data.append(key, formData[key]);
      }
    }
    
    this.setState({loading: true});

    axios.post(url, data)
      .then((res) => {
        this.setState({loading: false, files: []});
        onUploaded(res);
      }).catch(e => {
        console.log(e); // eslint-disable-line
        this.setState({ files: []});
        onUploaded();
      });
  }


  render() {
    const { files, dropzoneActive, loading, src, crop } = this.state;
    const { accept, onCancel } = this.props;
    return (
      <div>
        {src  
          ? <div className={cn("image-crop-area","drop-zone-area")}> 
              <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
                circularCrop={true}
                maxHeight={200}
                minHeight={120}
                maxWidth={200}
                minWidth={120}
              /> 
            </div>     
          : <Dropzone
              style={{position: "relative"}}
              accept={accept}
              onDrop={this.onDrop}
              onDragEnter={this.onDragEnter}
              onDragLeave={this.onDragLeave}
              disabled={loading}
            >
              { loading && <Loader /> }
              <div className={cn("drop-zone-area", dropzoneActive ? "drop-zone-success" : "")}>
                <h4>Click or Drag a File Here</h4>
                {<CloudUpload />}
              </div>                   
            </Dropzone>
        }
        <ul>
          { files.map((f, key) => <li key={key}>{f.name} - {f.size} bytes</li>) }
        </ul>
        <div className="drop-zone-footer">
          <Button  variant="contained" color="secondary" onClick={onCancel} disabled={loading} className="footer-action-button btn-danger">Cancel</Button>
          <Button variant="contained" color="primary" onClick={this.handleFileUpload} disabled={loading} className="footer-action-button btn-primary">Upload</Button>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: (msg) => dispatch(setMsgAndShow(msg))
});

export default connect(undefined, mapDispatchToProps)(ImageDropZone);