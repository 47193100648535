function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 20.4,
    height: 12.481,
    viewBox: "0 0 20.4 12.481"
  }, props), React.createElement("g", {
    id: "prefix__iconfinder_rotation_1814116",
    transform: "translate(-8.3 -17.5)"
  }, React.createElement("g", {
    id: "prefix__Icon-Retweet",
    transform: "translate(8.3 17.5)"
  }, React.createElement("path", {
    id: "prefix__Fill-160",
    d: "M-141.911-403.452h-1.076v-9.813a.57.57 0 0 0-.56-.56h-9.253v-1.075h9.253a1.635 1.635 0 0 1 1.635 1.635v9.813",
    className: "prefix__cls-1",
    transform: "translate(159.213 415.158)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Fill-161",
    d: "M-133.642-393.051l-3.658-4.261.775-.689 2.884 3.4 2.884-3.4.775.689-3.658 4.261",
    className: "prefix__cls-1",
    transform: "translate(150.384 405.532)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Fill-162",
    d: "M-149.511-402.252h-9.253a1.635 1.635 0 0 1-1.635-1.635v-9.813h1.076v9.813a.57.57 0 0 0 .559.559h9.253v1.076",
    className: "prefix__cls-1",
    transform: "translate(163.542 414.475)",
    style: {
      "fill": "#00949c"
    }
  }), React.createElement("path", {
    id: "prefix__Fill-163",
    d: "M-161.158-410.551l-2.884-3.4-2.884 3.4-.775-.689 3.658-4.261 3.658 4.261-.775.689",
    className: "prefix__cls-1",
    transform: "translate(167.7 415.5)",
    style: {
      "fill": "#00949c"
    }
  }))));
};

export default __webpack_public_path__ + "static/media/job_frequency.4ab9801d.svg";
export { SvgComponent as ReactComponent };