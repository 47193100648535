import React from "react";
import Dialog from "core/components/Dialog";
import Slide from "core/components/Slide";
import CustomerForm from "../CustomerForm";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" {...props} ref={ref} />);

const CustomerFormDialog = props => {
  const { isOpen, handleClose } = props;
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
    >
      <CustomerForm {...props}/>
    </Dialog>
  )
}

export default CustomerFormDialog;