import axios from "utils/http";


const getCleaningTypesList = async () => {
  try {
    const dataResult = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}cleaning/get_cleaning_types`
    );

    const cleaningTypes = {};
    const types = dataResult.data.types;
    types.forEach((type) => {cleaningTypes[type.value] = type.label;} );

    return cleaningTypes;
  } catch (error) {
    throw error;
  }
}


export default getCleaningTypesList;