import React, { Component } from "react";
import Cookies from "js-cookie";
import Storage from "utils/Storage";
import { debounce } from "lodash";
import { connect } from "react-redux";
import { setItem } from "../store/actions/app";
import Header from "../components/Header/Header";
import axios from 'utils/http';
import { withMixpanel } from "../utils/react-mixpanel"; 

class HeaderContainer extends Component {

  constructor(props){
    super(props)
    this.state = {
      showResults: false,
      results:[]
    }

    this.debounceFun = debounce(this.search, 1000)
  
  }


  handleSearch = (e) => {
    this.debounceFun(e.target.value)
  }

  search = async (q) => {
    const { trackApiEvent, trackInputOnBlur } = this.props;
    
    if(q){
      q = q.trim().toLowerCase();
      trackInputOnBlur("Search",q,window.location.pathname);
    }

    const contractorId = this.props.businessDetails._id

    try {

        const res = await axios.get(
          `${process.env.REACT_APP_API_PREFIX}contractor/search`,
          {
            params:{
              contractorId,
              q
            }
          }
        )
  
        const { status, message, results} = res.data;
  
        if(status === "OK"){
          trackApiEvent("Search_Term","GET", `${process.env.REACT_APP_API_PREFIX}contractor/search`,"OK",window.location.pathname);
          this.setState({
            results
          })
        } else {
          console.error(message); // eslint-disable-line
          trackApiEvent("Search_Term","GET", `${process.env.REACT_APP_API_PREFIX}contractor/search`,message,window.location.pathname);
        }

    } catch (error) {
      console.error(error); // eslint-disable-line
      trackApiEvent("Search_Term","GET", `${process.env.REACT_APP_API_PREFIX}contractor/search`,error,window.location.pathname);
    }
  }

  onSearchFocus = (e) => {
    this.setState({
      showResults: true
    })
  }
  onSearchBlur = (e) => {
    this.setState({
      showResults: false
    })
  }

  openPage = (routeName, item) => () => {
    const { history, setItem } = this.props;
    let itemType
    const url = "/" + routeName;

    if(routeName === "customers"){
      itemType = "customer"
    } else if(routeName === "jobs"){
      itemType = "job"
    } else {
      itemType = item.type
    }

    setItem(item, itemType);
    if(history){
      history.push(url)

    }
  }

  handleLogout = () => {
    const { trackApiEvent, trackButtonEvent } = this.props;
    trackButtonEvent("Logout",window.location.pathname);
    try {
      axios.get(`${process.env.REACT_APP_API_PREFIX}user/logout`);
      trackApiEvent("Logout","GET",`${process.env.REACT_APP_API_PREFIX}user/logout`,"OK",window.location.pathname);
      Cookies.remove("authToken",{path:"/", domain:process.env.REACT_APP_HOME_SITE});
      Storage.remove("userInfo");
      Storage.remove("orderHistory");
      Storage.remove("contractorInfo");
      Storage.remove("auth_token");
      window.location.href = process.env.REACT_APP_HOME_URL

    } catch (error) {
      console.error("Error in logout",error);
      trackApiEvent("Logout","GET",`${process.env.REACT_APP_API_PREFIX}user/logout`,"Error In logout",window.location.pathname);
    //   Cookies.remove("authToken",{path:"/", domain:process.env.REACT_APP_HOME_SITE});
    //   Storage.remove("userInfo");
    //   Storage.remove("orderHistory");
    //   Storage.remove("contractorInfo");
    //   Storage.remove("auth_token");
      // window.location.href = process.env.REACT_APP_HOME_URL
    }
  }
  render(){
    return (
      <Header 
        handleSearch={this.handleSearch}
        onSearchBlur={this.onSearchBlur}
        onSearchFocus={this.onSearchFocus}
        openPage={this.openPage}
        handleLogout={this.handleLogout}
        {...this.state}
        {...this.props} 
      />
    )
    
  }
}


const mapStateToProps = (state) => ({
  userInfo: state.contractor.userInfo,
  businessDetails: state.contractor.businessDetails
})

const mapDispatchToProps = (dispatch) => ({
  setItem: (item, itemType) => dispatch(setItem(item, itemType))
})

const connectRedux = connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);

export default withMixpanel(connectRedux);