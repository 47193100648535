import React from "react";
import { isEmpty } from "lodash";
import cn from "classnames";
import { Alert } from "mdi-material-ui"; 
import Joyride from "react-joyride";
import Grid from "core/components/Grid";
import Button from "core/components/Button";
import Table from "../TableWithPagination";
import { withStyles } from "core/components/Styles";
import JobOffer from "../JobOffer";
import AddJobModal from "../AddJobModal";
import FabButton from "core/components/FabButton";
import SearchBar from "../SearchBar";

import { jobsSteps } from "../TourGuide/TourGuideSteps";
import {tooltipButtonColor} from "../../theme/colors";
import Loader from "../ProgressCircle";

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex"
  },
  jobsBtnCont: {
    marginTop: 10
  },
  newJobBtnCont: {
    marginTop: 10
  },
  jobslistCont: {
    marginTop: 10
  },
  textCenter: {
    textAlign: "center"
  },
  mainCont: {
    paddingTop: 20,
    paddingLeft: 23,
    paddingRight: 23,
    paddingBottom: 20,
    width: "100%",
  },
  jobsScrollerCont: {
    width : "100%",
    height: "22rem",
    overflowY: "auto",
    margin: "-8px 0"
  },
  btnsCont: {
    margin: "25px 0",
    paddingLeft: 5,
    paddingRight: 5
  },
  jobBtn: {
    height: 32,
    borderRadius: 29,
    background: "#ffffff",
    fontFamily: "Poppins",
    fontWeight: "normal",
    fontSize: 12,
    lineHeight : "18px",
    // textAlign: "center",
    textTransform : "capitalize",
    letterSpacing: -0.1,
    color: "#51516b",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.128223)",
    border: "0px solid transparent",
    "&:hover": {
      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.428223)",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%"
    },
    padding: "7px 19px"
  },
  gradientJobBtn: {
    color :"#ffffff",
    background: "linear-gradient(97.58deg, #462EB4 2.88%, #B062D2 83.6%)",
    //borderRadius: 29
  },
  jobBtnsRow: {
    marginLeft: 40,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  fabBtn: {
    position: "absolute",
    right: 20,
    bottom: 20,
    zIndex: 99,
    height: 40,
    width: 40,
    background: "#ed5454",
    color: "#fff",
    "&:hover": {
      background: "#ed5454",
      boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.4), 0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 1px 18px 0px rgba(0, 0, 0, 0.15)"
    }
  },
  addIcon: {
    height: 12,
    width: 12,

  },
  searchBoxCont: {
    width: 436,
    height : 40,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginBottom: 20
    }
  },
  jobBtnsItem: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      padding: "4px 0 !important"
    }
  },
  noDataText: {
    marginLeft: 10,
  },
  newOffersCont:{
    width:"100%",
    position:"relative"
  }
});

const headers = [
  {
    name: "displayId",
    label: "ID"
  },
  {
    name: "date",
    label: "Date"
  },
  {
    name: "time",
    label: "Time"
  },
  {
    name: "serviceAddress",
    label: "Address"
  },
  {
    name: "orderCost",
    label: "Cost"
  },
  {
    name: "orderStatus",
    label: "Status"
  }
];

const columnProps = [
  {
    name: "displayId",
    isActionButton: true
  },
  {
    name: "date",
    isDate: true,
    dateFormat: "DD MMM YYYY"
  },
  {
    name: "time",
    isTime: true,
    originalFormat: "hh:mm:ss",
    timeFormat: "hh:mm A"
  },
  {
    cityProp: "city",
    stateProp: "state",
    zipcodeProp: "zipcode",
    addressProp: "address",
    unitProp:"unit",
    name: "serviceAddress",
    isAddress: true
  },
  {
    name: "orderCost",
    isCost: true
  },
  {
    name: "orderStatus",
    isStatus: true
  },
  {
    isAlert: true,
    displayAlert: (obj) => !isEmpty(obj.changes) && obj.changeReason,
    Icon: Alert
  }
];

const changeRowColor = (obj) => !isEmpty(obj.changes) && obj.changeReason

const JobOptionButton = (props)=>{
  const {label,text,checkJobList,changeList,classes} = props
  return(
    <Grid item className={classes.jobBtnsItem}>
      <Button
        className={cn({
          [classes.gradientJobBtn] : checkJobList(text),
          [classes.jobBtn]:true,
        })}
        onClick={changeList(text)}
      >
        {label}
      </Button>
    </Grid>
  )
}


const JobsDashboard = (props) => {

  const {
    jobsObj,
    // jobItemIndex,
    // open,
    // btnRef,
    // handleMenuClose,
    show,
    isModalOpen,
    classes,
    handleModalClose,
    changeList,

    // newJobOrders,
    getJobActions,
    // acceptedJobOrders,
    // acceptedJobActions,
    todaysJobActions,
    // todaysJobOrders,
    handleSubmit,
    handleSave,
    formValues,
    increaseByOne,
    decreaseByOne,
    submitData,
    getJobDetails,
    customerOptions,
    openNewUserform,
    addNewCustomer,
    // allJobsActions,
    // unassignedJobsActions,
    handleJobSearch,
    // load,
    isSearching,
  
    trackBlurInput,
  
    handleJoyrideCallback,
    getHelpers,
    runGuide,
    submitQuote,
    handleOnPageChange,
    page,
    total,
    perPage,
    handlePageLimitChange,
    orders,
    gettingOrders,
    q,
    clearSearch,
    gettingNewOrders,
    openModal,
    searchCustomers,
  } = props;
  let actions = todaysJobActions

  const checkJobList = (jobList)=>{
    return (jobList === show) ? true : false
  }

  return (
    <div className={classes.root}>
      <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={runGuide}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={jobsSteps}
        styles={{
          options: {
            primaryColor: tooltipButtonColor   
          }
        }}
      />
      <Grid className={classes.mainCont} id="jobsRoot">
        <FabButton onClick={openModal} />

        <Grid className={classes.newOffersCont}>
          {gettingNewOrders && <Loader/> }
          <Grid 
            container 
            alignItems="center" 
            justify="flex-start" 
            spacing={3} 
            wrap="nowrap" 
            className={cn([{
              [classes.jobsScrollerCont]: jobsObj && jobsObj.newJobs.length > 0
            }])} 
            id="jobScroller" 
          >
            {jobsObj && jobsObj.newJobs.length > 0 
              ? ( jobsObj.newJobs.map( (job, i) => 
                  <JobOffer
                    key={i}
                    jobOfferObj={job}
                    getJobActions={getJobActions}
                    getJobDetails={getJobDetails}
                    submitQuoteCb={submitQuote}
                  /> 
                )) 
              : <p className={classes.noDataText}>No New Offers</p>
            }
          </Grid>

        </Grid>
        <Grid container justify="space-between" alignContent="center" className={classes.btnsCont}>
          <Grid item container xs alignItems="center" >

            <Grid item className={classes.searchBoxCont} id="jobSearch">
              <SearchBar 
                placeholder="What are you looking for?" 
                onChange={handleJobSearch} 
                onBlur={trackBlurInput} 
                reverse
                value={q}
                onClear={clearSearch}

             />
            </Grid>

            <Grid item xs container justify="flex-end" spacing={3} className={classes.jobBtnsRow} id="jobFilters">
              <JobOptionButton
                label = {"All Jobs"}
                text = {"allJobs"}
                checkJobList = {checkJobList}
                changeList = {changeList}
                classes = {classes}
              />
              <JobOptionButton
                label = {"Today's Jobs"}
                text = {"todaysJobs"}
                checkJobList = {checkJobList}
                changeList = {changeList}
                classes = {classes}
              />
              <JobOptionButton
                label = {"Jobs Not Assigned"}
                text = {"notAssignedJobs"}
                checkJobList = {checkJobList}
                changeList = {changeList}
                classes = {classes}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid className={classes.tableCont} id="jobTable" >
            {gettingOrders && <Loader />}
          <Table
            list={orders}
            headers={headers}
            columnProps={columnProps}
            haveSearchQuery={isSearching}
            actions={actions}
            changeRowColor={changeRowColor}
            handleOnPageChange={handleOnPageChange}
            pageNumber={page}
            totalCount={total}
            rowsLimit={perPage}
            handleOnLimitChange={handlePageLimitChange}
          />
        </Grid>
      </Grid>

      <AddJobModal
        customerOptions={customerOptions}
        handleClose={handleModalClose}
        handleSubmit={handleSubmit}
        handleSave={handleSave}
        isModalOpen={isModalOpen}
        formValues={formValues}
        increaseByOne={increaseByOne}
        decreaseByOne={decreaseByOne}
        submitData={submitData}
        openNewUserform={openNewUserform}
        addNewCustomer={addNewCustomer}
        searchCustomers={searchCustomers}
      />

    </div>
  );
};

export default withStyles(styles)(JobsDashboard);