import React, { Component } from 'react';
import {Map, GoogleApiWrapper} from 'google-maps-react';
 
export class GoogleMap extends Component {
  googleMap = null

  defaults = {
    center: {lat: 34.052, lng: -118.243},
    zoom: 8 
  }
  circleDefaults = {
    strokeColor: 'rgba(152, 108, 210, 1)',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: 'rgba(152, 108, 210, 1)',
    fillOpacity: 0.35,
    center: this.defaults.center,
    radius: 10000
  }
  circle = []

  createCircle = opts => {
    const circle = new this.props.google.maps.Circle({
      ...this.circleDefaults,
      ...opts,
      map: this.googleMap.map
    })

    this.circle.push(circle);
  }

  onReady = () => {
    const {circle, center, zoom} = this.props
    if(center) this.updateMapCenter(center);
    if(zoom) this.updateZoom(zoom)
    if(circle) this.createCircle(circle);
  }

  updateCircleRadius = (c, r) => {
    if(c) c.setRadius(r);
  }
  updateCircleCenter = (c, center) => {
    if(c) c.setCenter(center);
  }

  updateMapCenter = (center) => {
    this.googleMap.map.setCenter(center)
  }
  updateZoom = z => {
    this.googleMap.map.setZoom(z)
  }

  componentDidUpdate (prevProps) {
    const {circle, center, zoom} = this.props
    if(center) this.updateMapCenter(center);
    if(zoom) this.updateZoom(zoom)
    if(circle) {
      const {radius, center} = circle
      if(radius !== prevProps.circle.radius) this.updateCircleRadius(this.circle[0], radius)
      if(center !== prevProps.circle.center) this.updateCircleCenter(this.circle[0], center)
    }

  }

  render() {
    const {center, zoom} = this.props
    return (
      <Map
        ref={ref => this.googleMap = ref}
        google={this.props.google}
        initialCenter={center ? center : this.defaults.center}
        zoom={zoom ? zoom : this.defaults.zoom}
        onReady={this.onReady}
      />
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: (process.env.REACT_APP_GOOGLE_KEY)
})(GoogleMap)