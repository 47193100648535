import openSocket from 'socket.io-client';
const socket = openSocket(process.env.REACT_APP_SOCKET_URL);

export function subscribeToTimer(cb) {
  socket.on('timer', timestamp => cb(null, timestamp));
  socket.emit('subscribeToTimer', 1000);
}

export function createRoom(id) {
  socket.emit('createroom', id);
}

export default socket;