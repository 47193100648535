import React from "react";

import CustomersContainer from "containers/CustomersContainer";
import { MainLayout } from "layouts";

const EmployeePage = (props) => (
  <MainLayout {...props}>
    <CustomersContainer {...props} />
  </MainLayout>
)

export default EmployeePage;