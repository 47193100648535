import React from "react";
import { Grid, ListItem } from "core/components";
import { withStyles } from "core/components/Styles"
import { EyeOutline } from "mdi-material-ui";
import cn from 'classnames';
import momentTz from "moment-timezone";

const listProps = [
  {
    isCustomerName: true,
    checkProp:"serviceAddress",
    firstName:"fname",
    lastName:"lname"
  },
  {
    isAddress: true,
    checkProp:"serviceAddress",
    addressProp:"address",
    unitProp:"unit",
    cityProp:"city",
    stateProp:"state"
  },
  {
    isTimeAndDate:true,
    checkTimeProp:"time",
    defaultTimeFormat:"HH:mm:ss",
    formatTimeRequired:"hh:mm A",
    checkDateProp:"date",
    defaultDateFormat:"YYYY-MM-DD",
    formatDateRequired:"Do MMMM, YYYY"
  },
]

const styles = () => ({

  closeBtnIcon:{
    width: 20,
    height: 20
  },

  infoBtn:{
    width: 18,
    height: 18,
    color:"#fff"
  },
  jobAlert:{
    padding: "10px 0 10px 0px"
  },
  listItemDivider:{
    borderTop: "1px solid #fff"
  },
  jobAlertItem:{
    padding:0,
    color: "#fff",
    fontSize: 14
  },
  marginBottom_20:{
    marginBottom: 20
  }
})




const JobAlertItem = (props) => {

  const { l, itemIndex, classes, openJobChangesModal } = props;
  return (
    <ListItem button onClick={openJobChangesModal} className={cn(classes.jobAlertItem, itemIndex !== 0 ? classes.listItemDivider : null)}>
    <Grid 
      className={classes.jobAlert} 
      alignItems="center" 
      justify="space-between"
      container 
    >
      <Grid 
        item
        xs
      >
        <Grid
          justify="flex-start" 
          alignContent="center"
          container
        >
        {
          listProps.map((propItem, k) => {
            if(propItem.isCustomerName){
              return (
                <Grid
                  key={`job-alert-item-data-${k}`}
                  className={classes.textCenter}
                  item
                  style={{
                    width: "20%"
                  }}
                >
                  {`${l[propItem.checkProp][propItem.firstName]} ${l[propItem.checkProp][propItem.lastName]}`}
                </Grid>
              )
            } else if(propItem.isAddress) {

              const text = `${l[propItem.checkProp][propItem.addressProp]}${l[propItem.checkProp][propItem.unitProp] ? ` UNIT ${l[propItem.checkProp][propItem.unitProp]}` : ""}, ${l[propItem.checkProp][propItem.cityProp]}, ${l[propItem.checkProp][propItem.stateProp]}`
              return (
                <Grid
                  key={`job-alert-item-data-${k}`}
                  className={classes.textCenter}
                  item
                  style={{
                    width: "40%"
                  }}
                >
                  {text}
                </Grid>
              )
            } else if(propItem.isTimeAndDate) {

              const text = `${momentTz(l[propItem.checkDateProp], propItem.defaultDateFormat).format(propItem.formatDateRequired)} ${momentTz(l[propItem.checkTimeProp], propItem.defaultTimeFormat).format(propItem.formatTimeRequired)}`
              return (
                <Grid
                  key={`job-alert-item-data-${k}`}
                  className={classes.textCenter}
                  item
                >
                  {text}
                </Grid>
              )
            } else {
              return null
            }
          })

        }

        </Grid>
      </Grid>

      <Grid item>
        <EyeOutline/>
      </Grid>
    </Grid>
  </ListItem>

  )
}



export default withStyles(styles)(JobAlertItem);