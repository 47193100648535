import React, { Component } from "react";
import socket from "socket";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import Track from "../components/Track";
// import ReactDOM from "react-dom";
import axios from "../utils/http";
import { setMsgAndShow } from "../store/actions/popup";
import { setItem, toggleLoader } from "../store/actions/app";
import moment from "moment-timezone";

import { withMixpanel } from "../utils/react-mixpanel";
class TrackContainer extends Component {

  state = {
    center: [-117.166,32.738],
    popupCoords: [0, 0],
    mapOffset: 0,
    employeeLocations:[],
    currEmployee:{},
    currEmpId:null,
    openPopup: false,
    employeeInactive:[]
  }

  popupRef =  React.createRef();

  mapRef = React.createRef();

  componentDidMount(){

    const { trackPageLoad } = this.props;
    trackPageLoad("GPS_Tracking");

    const { 
      contractorInfo: { businessDetails }, 
      // history: {
      //   location
      // },
      item:{
        itemType,
        item:itemObj
      }
    } = this.props;


    if(!isEmpty(businessDetails)){
      this.getData();
    }

    const mapOffset = this.mapRef.current.getBoundingClientRect().top;

    this.setState({
      mapOffset,
    })

    if(!isEmpty(itemObj)){
      if(itemType === "employee" || itemType === "contractor"){

          this.onEmpClick({...itemObj, location: itemObj.lastLocation})();

          // this.setState({
          //   center: itemObj.lastLocation,
          //   popupCoords: itemObj.lastLocation,
          //   openPopup: true,
          //   currEmployee: itemObj,
          //   currEmpId:itemObj.userId
          // })
        
      }
    }

    socket.on("newLocation", this.setEmployeeLocation)

  }


  setEmployeeLocation = (empLocation) => {
        const { employeeLocations: oldEmployeeLocations } = this.state;
        const updatedLocations = oldEmployeeLocations.map((e) => {
          if(e.employeeId === empLocation.employeeId){
            return {
              ...e,
              location: empLocation.location,
              address: empLocation.address
            }
          }
          return e;
        })
      
        this.setState({
          employeeLocations: updatedLocations
        })
  }


  componentDidUpdate(prevProps){
    const { contractorInfo:{ businessDetails: oldBusinessDetails}, item: oldItem } = prevProps;
    const { contractorInfo: { businessDetails }, item } = this.props;
    const { openPopup } = this.state;
    if(JSON.stringify(oldBusinessDetails) !== JSON.stringify(businessDetails)){
      this.getData()
    } 

    if(!openPopup){
      if(!isEmpty(item.item) && (JSON.stringify(oldItem) !== JSON.stringify(item))){
        if(item.itemType === "employee" || item.itemType === "contractor"){

            if(item.item.lastLocation){
              this.setState({
                center: item.item.lastLocation,
                popupCoords: item.item.lastLocation,
                openPopup: true,
                currEmployee: item.item,
                currEmpId:item.item.userId
              })

            }
        }
      }
    }

  }

  getData = async () => {
    const { contractorInfo, toggleLoader } = this.props;

    const employeeLocations = [];
    const employeeInactive = [];
    if(!isEmpty(contractorInfo.businessDetails)){
      const {_id: contractorId } = contractorInfo.businessDetails;
      toggleLoader();
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}contractor/get_employee_locations`, {contractorId, currDate: moment().format("YYYY-MM-DD") });
        const { status, message, employees } = res.data;
        toggleLoader();
        if(status === "OK"){
          employees.forEach((e) => {
            if(!e.lastLocation){
              employeeInactive.push(e);
            } else {
              employeeLocations.push({
                ...e,
                employeeId: e.userId,
                profileAvator: e.profileImage,
                location: e.lastLocation,  
              })
            }
          })

          this.setState({
            employeeLocations,
            employeeInactive
          })
        } else {
          this.props.setMsgAndShow(message);
        }
      } catch (error) {
        toggleLoader()
        const { response } = error;
        this.props.setMsgAndShow(response ? response.data.message : "Error");
      }


    }
  }

  onEmpClick = (emp) => (e) => {
    this.setState({
      center: emp.location,
      popupCoords: emp.location,
      openPopup: true,
      currEmployee: emp,
      currEmpId: emp.userId
    })
  }
  closePopup = () => {
    const { setItem } = this.props;
    this.setState({
      popupCoords:[0,0],
      openPopup: false,
      currEmployee: {},
      currEmpId:null
    })

    setItem(null,null)

    
  }

  componentWillUnmount(){
    socket.off("newLocation", this.setEmployeeLocation)
  }

  onLoad = () => {
    const { history: { location } } = this.props;

    if(!isEmpty(location.state)){
      const { emp } = location.state;   
      if(!isEmpty(emp) && emp.lastLocation) {
        this.setState({
          center: emp.lastLocation || [0,0],
          popupCoords: emp.lastLocation || [0,0],
          openPopup: true,
          currEmployee: emp,
          currEmpId: emp.userId
        })
      }
    }
  }

  onDragEnd = e => {
    const center = e.getCenter();
    this.setState({
      center: [center.lng, center.lat]
    })
  }

  render(){
    return (
      <Track 
        closePopup={this.closePopup}
        onEmpClick={this.onEmpClick}
        popupRef={this.popupRef}
        mapRef={this.mapRef}
        onLoad={this.onLoad}
        onDragEnd={this.onDragEnd}
        {...this.props}
        {...this.state}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  setItem: (item, itemType) => dispatch(setItem(item, itemType)),
  toggleLoader: () => dispatch(toggleLoader())
});

const mapStateToProps = (state) => ({
  contractorInfo: state.contractor,
  item: state.app.item
});

const connectRedux = connect(mapStateToProps, mapDispatchToProps)(TrackContainer);

export default withMixpanel(connectRedux);