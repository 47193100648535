import React from "react";
import Grid from "core/components/Grid";
import Card from "core/components/Card";
import CardContent from "core/components/CardContent";
import Typography from "core/components/Typography";

import ClickAwayListener from "core/components/ClickAwayListener";
import Grow from "core/components/Grow";
import Popper from "core/components/Popper";
import Paper from "core/components/Paper";
import Button from "core/components/Button";
import { withStyles } from "core/components/Styles";
import EmployeeList from "./EmployeeList";


const AssignJobMenu = ({
  open,
  btnRef,
  handleMenuClose,
  employeeList,
  jobItemObj = {},
  actions,
  title,
  classes,
  errMsg,
  assignJob,
  jobActions
}) => {


  const anchorEl = btnRef || document.getElementById('root')

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      transition
      disablePortal
      className={classes.listPopUp}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          id="menu-list-grow"
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom"
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleMenuClose}>
              <Card className={classes.popupCard}>
                <CardContent>
                  {title ? <Typography variant="subtitle1" gutterBottom>
                    {title} {`(${employeeList.length})`}
                  </Typography> : null }
                
                  {employeeList && <EmployeeList 
                    employeeList={employeeList}
                    assignJob={assignJob}
                    jobItemObj={jobItemObj}
                  />}

                  {!employeeList || employeeList.length === 0 ? <p className={classes.textCenter}>{errMsg}</p> : null}
                  
                  {jobActions && jobActions.map((action, key)=>
                    <Grid className={classes.actionBtn} key={`job-menu-${key}`}>
                      <Button onClick={action.onClick} fullWidth className={classes.textCaptalize}>{action.label}</Button>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}


const styles = theme => ({
  listPopUp: {
    marginTop: 20,
    marginBottom: 20,
    zIndex: 200
  },
  popupCard: {
    borderTopColor: "#4d73a8",
    borderTop: "2px solid",
    borderRadius: 0
  },
  freeEmployeeList: {
    marginTop: 10
  },
  actionBtn: {
    marginTop: 20
  },
  textCaptalize:{
    textTransform: "capitalize"
  },
  textCenter:{
    textAlign: "center",
  }
})

export default withStyles(styles)(AssignJobMenu);