import React, { Component, Fragment } from "react";
import { reset,reduxForm } from "redux-form";
import { connect } from "react-redux";
import socket from "socket";
import { setJobs, removeJob } from "../store/actions/jobs";
import { setMsgAndShow } from "../store/actions/popup";
import _ from "lodash";
import axios from "utils/http";
import removeProp from "utils/removeProp";
import moment from "moment-timezone";
import getCleaningTypesList from "../helpers/getCleaningTypesList";
import CalenderDashboard from "../components/CalenderDashboard";

import JobsListModal from "../components/JobsListModal";
import { capitalizeFirstLetterOnly } from "../utils/capitalize";
import { toggleDialogLoader, toggleLoader } from "../store/actions/app";
import { isEmpty  } from "lodash";
import Storage from "../utils/Storage";
import { STATUS } from "react-joyride";

import { withMixpanel } from "../utils/react-mixpanel";
import getJobsByMonth from "../helpers/getJobsByMonth";


class CalenderDashboardContainer extends Component {
  state = {
    date: "",
    show: "",
    isModalOpen: false,
    doNotShowJobBtns: false,
    isJobDetailsModalOpen: false,
    todaysJobs: [],
    jobsForMonth: [],
    allJobsOfTheMonth: [],
    availableEmployeeList: [],
    open: false,
    openAssignJobMenu: false,
    btnRef: "",
    jobItemIndex: null,
    jobItemObj: {},
    dateWiseJobs: [],
    numOfFreeEmployees: 0,
    cleaningTypesObj:{},
    isAddJobModalOpen: false,
    customerOptions: [],
    addNewCustomer: false,
    runGuide: false,
    isOTPModalOpen:false,
    page:0,
    total:0,
    perPage:10,
    currSelectedDate:""
  };

  cleaningTypes = {};

  assignedEmployeeIds = [];
  rawDateWiseJobs = [];
 
  componentDidMount() {
    if (!_.isEmpty(this.props.contractorInfo.businessDetails)) {
      this.addData();
    }

    const { trackPageLoad } = this.props;
    trackPageLoad("Calendar_Dashboard");
  }

  getHelpers = (StoreHelpers) => {
    this.helpers = StoreHelpers;
  };

  handleJoyrideCallback = (CallBackProps) => {
    
    const { status } = CallBackProps;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      Storage.set("calenderGuide","completed");
      this.setState({ runGuide: false });
    }
    
  };

  addData = async () => {
    const { toggleLoader  } = this.props;
    const contractorId = this.props.contractorInfo.businessDetails._id;
    let customerOptions = [];
    let allJobsOfTheMonth = [];
    const selectedDate = moment().format("YYYY-MM-DD");
    this.currSelectedDate = selectedDate;
    const selectedMonth = moment(selectedDate,"YYYY-MM-DD").format("MMM");
    toggleLoader();
    try {

      const customersRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_customers`,
        {
          params: {
            contractorId
          }
        }
      );

      const monthOrdersData = await getJobsByMonth({
        month: selectedMonth,
        contractorId,
        year: new Date().getFullYear()
      })

      toggleLoader();

      if (customersRes.data.status === "OK") {
        const { customers } = customersRes.data;

        customerOptions = customers.map(customer => {
          return {
            value: customer._id,
            label: `${customer.fname} ${customer.lname}`,
            fname: customer.fname,
            lname: customer.lname,
            email: customer.email,
            phone: customer.phone,
            serviceAddress: customer.serviceAddress
          };
        });
      }


      if(monthOrdersData.status === "OK"){
       allJobsOfTheMonth = monthOrdersData.orders;
      }



      this.cleaningTypes = await getCleaningTypesList()

 

      const jobsForMonth = allJobsOfTheMonth.map(job => {
        const startDate = moment(job.date).format("YYYY-MM-DD");
        const date = moment(`${startDate} ${job.time}`, "YYYY-MM-DD HH:mm:ss");

        if (!_.isEmpty(job.assignedEmployeeIds)) {
          return {
            id: job._id,
            title: job.displayId,
            desc: this.cleaningTypes[job.cleaningType],
            start: new Date(date),
            end: new Date(date.add(job.etc, "minutes")),
            isAssigned: true
          };
        } else {
          return {
            id: job._id,
            title: job.displayId,
            desc: this.cleaningTypes[job.cleaningType],
            start: new Date(date),
            end: new Date(date.add(job.etc, "minutes"))
          };
        }
      });

      // const availableEmployeeList = res.data.freeEmployeeList;
      // const numOfFreeEmployees = res.data.numOfFreeEmployees;

      // this.props.setJobs(newJobsList, "newJobs");
      this.setState({
        // availableEmployeeList,
        // numOfFreeEmployees,
        jobsForMonth,
        allJobsOfTheMonth,
        cleaningTypesObj: this.cleaningTypes,
        customerOptions,
      });

      const { isTourEnabled } = this.props;
      if( Storage.get("calenderGuide") !== "completed" && isTourEnabled){ 
        this.setState({ 
          runGuide: true 
        }) 
      } 

    } catch (error) {
      toggleLoader();
      const { response } = error;

      this.props.setMsgAndShow( 
        response ? response.data.message : "Error Occured"
      );
    }
  };

  checkForPageRefresh = (prevProps, nextProps) => {
    const { refreshPage:prevRefreshPage } = prevProps;
    const { refreshPage:newRefreshPage } = nextProps;
    let triggerRefresh = false;
    if(prevRefreshPage !== newRefreshPage && newRefreshPage){
      triggerRefresh = true;
    }

    return triggerRefresh;
  }
  componentDidUpdate(prevProps, nextProps) {
    if (
      JSON.stringify(prevProps.contractorInfo) !==
      JSON.stringify(this.props.contractorInfo)
    ) {
      this.addData();
    }


    if(this.checkForPageRefresh(prevProps, this.props)){
      this.getUpdatedJobsList(this.currSelectedDate)
    }
  }

  handleClose = () => {
    this.setState({
      isModalOpen: false,
      show: ""
    });
    this.props.resetForm("OrderForm");
  };

  selectedDate = event => {
    const matchDate = moment(event.start).format("YYYY-MM-DD");


    this.setState({
      show: "jobsInCalender",
      isModalOpen: true,
      currSelectedDate:matchDate,
    });
  };

  handleMenuClose = () => {
    this.setState({
      open: false,
      openAssignJobMenu: false,
      jobItemIndex: ""
    });
  };

  acceptJob = jobItem => async e => {
    e.stopPropagation();
    
    const { todaysJobsList, toggleLoader, trackApiEvent } = this.props;

    toggleLoader();
    try {
      await axios.post(`${process.env.REACT_APP_API_PREFIX}contractor/orders/accept/${jobItem._id}`);

      trackApiEvent("Accept_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/accept/`, "OK", window.location.pathname);

      socket.emit("removeJob", jobItem._id);
      toggleLoader();
      if (moment(jobItem.date).isSame(moment(), "day")) {
        const job = {
          _id: jobItem._id,
          cleaningType: this.cleaningTypes[jobItem.cleaningType],
          startTime: moment(jobItem.time, "hh:mm:ss").format("hh:mm A"),
          endTime: moment(jobItem.time, "hh:mm:ss")
            .add(jobItem.etc, "minutes")
            .format("hh:mm A")
        };
        todaysJobsList.push(job);
        this.props.setJobs(todaysJobsList, "todaysJobs");
      }
      this.adddJobsInCalender(jobItem);
      this.handleJobDetailsModal();
      this.props.setMsgAndShow(
        `Job Id '${jobItem.displayId}' is moved to accepted jobs`
      );
    } catch (error) {
      toggleLoader();
      console.error(error); // eslint-disable-line

      let resMsg = "Error Occured!";
      const { response } = error;

      if(response && response.data){
        resMsg = response.data.message ? response.data.message : "Error Occured!";
        this.props.setMsgAndShow(response.data.message ? response.data.message : response.data.msg ? response.data.msg : "Error Occured!");
      }

      trackApiEvent("Accept_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/accept/`, resMsg, window.location.pathname);
      this.props.setMsgAndShow("Error");
    }
  };

  rejectJob = jobItem => async e => {
    e.stopPropagation();

    const { toggleLoader, trackApiEvent } = this.props;

    const updateObj = {
      orderId: jobItem._id,
      rejectedBy: this.props.contractorInfo.businessDetails._id
    };

    toggleLoader();
    try {
      await axios.post(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/reject_order`,
        {
          ...updateObj
        }
      );
      
      trackApiEvent("Reject_Job", "POST", `${process.env.REACT_APP_API_PREFIX}cleaning_order/reject_order`, "OK", window.location.pathname );
      
      toggleLoader();
      this.props.removeJob(jobItem._id, "newJobs");
      this.handleJobDetailsModal();
      this.props.setMsgAndShow(`Job Id '${jobItem.displayId}' was rejected`);
    } catch (error) {
      const { response } = error;
      let resMsg = "Error";
      
      if(response && response.data){
        resMsg = response.data.message ? response.data.message : "Error";
        this.props.setMsgAndShow(response.data.message ? response.data.message : response.data.msg ? response.data.msg : "Error Occured!");
      }

      trackApiEvent("Reject_Job", "POST", `${process.env.REACT_APP_API_PREFIX}cleaning_order/reject_order`, resMsg, window.location.pathname );
    }
  };

  assignJob = e => {
    const { target } = e;
    const { value, checked } = target;
    const jobItemObj = _.cloneDeep(this.state.jobItemObj);

    if (checked) {
      jobItemObj.assignedEmployeeIds.push(value);
      this.setState({
        jobItemObj
      });
    } else {
      const employeeIndex = jobItemObj.assignedEmployeeIds.indexOf(value);
      if (employeeIndex > -1) {
        jobItemObj.assignedEmployeeIds.splice(employeeIndex, 1);
        this.setState({
          jobItemObj
        });
      }
    }
  };

  confirmAssign = async () => {
    let {
      jobItemObj,
    } = this.state;
    let msgForPopUp;

    const { toggleDialogLoader, trackApiEvent } = this.props;


    const jobId = jobItemObj._id;
    const displayId = jobItemObj.displayId;

    const updateObj = {
      orderId: jobId,
      assignedEmployeeIds: jobItemObj.assignedEmployeeIds
    };

    if (
      jobItemObj.assignedEmployeeIds.length > 0
    ) {

      msgForPopUp = `Job Id '${displayId}' is assigned`;
    } else {

      msgForPopUp = `Job Id '${displayId}' is unassigned`;
    }

    toggleDialogLoader();
    try {

      const updatedJobRes = await axios.post(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/assign`,
        {
          ...updateObj
        }
      );


      toggleDialogLoader();


      if(updatedJobRes.data.status === "Success"){

        trackApiEvent("Assign_Job", "POST", `${process.env.REACT_APP_API_PREFIX}cleaning_order/assign`, "OK", window.location.pathname );
        this.setState({
          isModalOpen: false,
          dateWiseJobs:[]
        });
        this.handleMenuClose();
        this.getUpdatedJobsList(this.currSelectedDate);
        this.props.setMsgAndShow(msgForPopUp);
      } else {
        msgForPopUp = updatedJobRes.data.message;
        this.props.setMsgAndShow(msgForPopUp);

        trackApiEvent("Assign_Job", "POST", `${process.env.REACT_APP_API_PREFIX}cleaning_order/assign`, msgForPopUp, window.location.pathname );
      }
  
    } catch (error) {
      const { response } = error;

      let msg = "Error Occured!";
      if(response && response.data){
        msg = response.data.message ? response.data.message : "Error Occured!";
      }

      trackApiEvent("Assign_Job", "POST", `${process.env.REACT_APP_API_PREFIX}cleaning_order/assign`, msg, window.location.pathname );

      toggleDialogLoader();
      this.props.setMsgAndShow(msg);
    }
  };

  handleDateChange = async e => {
    const { toggleLoader, trackApiEvent } = this.props;
    const selectedDate = moment(e).format("YYYY-MM-DD");
    const year = moment(selectedDate,"YYYY-MM-DD").format("YYYY");
    const month = moment(selectedDate,"YYYY-MM-DD").format("MMM");
    const contractorId = this.props.contractorInfo.businessDetails._id;
    let allJobsOfTheMonth = [];
    this.currSelectedDate = selectedDate;
    toggleLoader();
    try {
      const monthOrdersData = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/get_all_jobs`,
        {
          params: {
            month,
            contractorId,
            year
          }
        }
      );

      toggleLoader();
  
      if(monthOrdersData.data.status === "OK"){

        trackApiEvent("Date_Change", "GET", `${process.env.REACT_APP_API_PREFIX}cleaning_order/get_all_jobs`, "OK", window.location.pathname);

        allJobsOfTheMonth = monthOrdersData.data.orders;
      }else{
        let msg = "Error";

        if(monthOrdersData && monthOrdersData.data){
          msg = monthOrdersData.data.message ? monthOrdersData.data.message : "Error Occured!";
        }

        trackApiEvent("Date_Change", "GET", `${process.env.REACT_APP_API_PREFIX}cleaning_order/get_all_jobs`, msg, window.location.pathname);
      }
  

  
      const jobsForMonth = allJobsOfTheMonth.map(job => {
        const startDate = moment(job.date).format("YYYY-MM-DD");
        const date = moment(`${startDate} ${job.time}`, "YYYY-MM-DD HH:mm:ss");
  
        if (!_.isEmpty(job.assignedEmployeeIds)) {
          return {
            id: job._id,
            title: job.displayId,
            desc: this.cleaningTypes[job.cleaningType],
            start: new Date(date),
            end: new Date(date.add(job.etc, "minutes")),
            isAssigned: true
          };
        } else {
          return {
            id: job._id,
            title: job.displayId,
            desc: this.cleaningTypes[job.cleaningType],
            start: new Date(date),
            end: new Date(date.add(job.etc, "minutes"))
          };
        }
      });
  
      this.setState({
        allJobsOfTheMonth,
        jobsForMonth,
      });
    } catch (error) {
      toggleLoader();
      const { response } = error;
      let msg = "Error";

      if(response && response.data){
        msg = response.data.message ? response.data.message : "Error Occured!";
      }

      trackApiEvent("Date_Change", "GET", `${process.env.REACT_APP_API_PREFIX}cleaning_order/get_all_jobs`, msg, window.location.pathname);
      this.props.setMsgAndShow(msg);
    }

  };

  adddJobsInCalender = jobItem => {
    let { allJobsOfTheMonth } = this.state;

    const jobObj = {
      ...jobItem,
      contractorId: this.props.contractorInfo.businessDetails._id
    };
    allJobsOfTheMonth.push(jobObj);

    const jobsForMonth = allJobsOfTheMonth.map(job => {
      const startDate = moment(job.date).format("YYYY-MM-DD");
      const date = moment(`${startDate} ${job.time}`, "YYYY-MM-DD HH:mm:ss");

      if (!_.isEmpty(job.assignedEmployeeIds)) {
        return {
          id: job._id,
          title: job.displayId,
          desc: this.cleaningTypes[job.cleaningType],
          start: new Date(date),
          end: new Date(date.add(job.etc, "minutes")),
          isAssigned: true
        };
      } else {
        return {
          id: job._id,
          title: job.displayId,
          desc: this.cleaningTypes[job.cleaningType],
          start: new Date(date),
          end: new Date(date.add(job.etc, "minutes"))
        };
      }
    });

    this.setState({
      allJobsOfTheMonth,
      jobsForMonth
    });
  }; 

  eventStyleSetter = (event, start, end, isSelected) => {
    let newStyle = {
      color: "#346ff1",
      width : "100%",
      paddingTop: 5,
      fontSize: 8,
      border: "none",
      backgroundColor: "#eff4fe",
      borderLeft: "5px solid #4a90e2" 
    };

    if (event.isAssigned) {
      newStyle.backgroundColor = "#e9ffd0";
      newStyle.borderLeft = "5px solid #a2d271"
    }

    return {
      style: newStyle
    };
  };

  isAssigned = itemObj => {
    if(itemObj.assignedEmployeeIds){
      return  itemObj.assignedEmployeeIds.length > 0;
    } else if(itemObj.oldAssignedEmployeeIds) {
      return itemObj.oldAssignedEmployeeIds.length > 0
    }
  };

  setBtn = async (item, index, itemRef) => {
    const { toggleDialogLoader, trackApiEvent } = this.props;
    toggleDialogLoader();
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}employee/get_employee_data_by_job`,
        {
          params: {
            contractorId: this.props.contractorInfo.businessDetails._id,
            date: moment(item.date).format("YYYY-MM-DD"),
            time: item.time,
            jobId: item._id
          }
        }
      );

      trackApiEvent("Get_Employee_By_Job","GET",`${process.env.REACT_APP_API_PREFIX}employee/get_employee_data_by_job`,"OK", window.location.pathname);

      toggleDialogLoader();

      const { employeeList } = data;

      this.setState({
        employeeList,
        open: true,
        btnRef: itemRef, 
        // jobItemIndex: jobIndex,
        jobItemObj: item
      });
    } catch (error) {
      toggleDialogLoader();
      this.setState({
        open: true,
        btnRef: itemRef,
        // jobItemIndex: jobIndex
      });
      const { response } = error;
      let resMsg = "Error Occured!";
      
      if(response && response.data){
        resMsg = response.data.message ? response.data.message : "Error Occured!";
      }

      trackApiEvent("Get_Employee_By_Job","GET",`${process.env.REACT_APP_API_PREFIX}employee/get_employee_data_by_job`, resMsg, window.location.pathname);
      this.props.setMsgAndShow(response.data.message ? response.data.message : response.data.msg);
    }
  };

  getJobDetails = jobItemObj => {
    const { show } = this.state;

    let doNotShowJobBtns = false;

    if (show === "jobsInCalender") {
      doNotShowJobBtns = true;
    }
    this.setState({
      isJobDetailsModalOpen: true,
      doNotShowJobBtns,
      jobItemObj
    });
  };

  handleJobDetailsModal = () => {
    this.setState({
      isJobDetailsModalOpen: false,
      openAssignJobMenu:false,
      jobItemObj: {}
    });
  };

  sendTrackEvent = (eventName,requestType,url,status,path) => {
    const { trackApiEvent } = this.props;
    trackApiEvent(eventName, requestType, url,status,path);
  }

  updateDateWiseJobsAfterComplete = (job) => {
    if(!isEmpty(job)){
      let { dateWiseJobs, allJobsOfTheMonth } = this.state;
      dateWiseJobs = dateWiseJobs.map(j => j._id === job._id ? {...j,...job}  : j );
      allJobsOfTheMonth = allJobsOfTheMonth.map(aJob => job._id === aJob._id ? {...aJob, ...job} : aJob);
      this.handleJobDetailsModal();
      this.setState({
        dateWiseJobs,
        allJobsOfTheMonth
      });
      this.sendTrackEvent("Complete_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/complete/`,"OK", window.location.pathname);

    }
  }

  jobCompleted = async (jobItem) => {
    const { toggleDialogLoader, trackApiEvent } = this.props;
    // let { dateWiseJobs, allJobsOfTheMonth } = this.state;
    toggleDialogLoader();
    try {
      const resData = await axios.post(
        `${process.env.REACT_APP_API_PREFIX}contractor/orders/complete/${
          jobItem._id
        }`
      );

      toggleDialogLoader();

      if (resData.data.status === "OK") {
        const { job } = resData.data;
        this.updateDateWiseJobsAfterComplete(job);
        this.props.setMsgAndShow(`Job Id ${jobItem.displayId} is completed`);
      } else {
        this.props.setMsgAndShow(resData.data.message);

        trackApiEvent("Complete_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/complete/`, resData.data.message, window.location.pathname);
      }
    } catch (error) {
      toggleDialogLoader();
      const { response } = error;
      let resMsg = "Error Occured!";

      if(response && response.data){
        resMsg = response.data.message ? response.data.message : "Error Occured!"
        this.props.setMsgAndShow(response.data.message ? response.data.message : response.data.msg ? response.data.msg : "Error Occured!" );
      }

      trackApiEvent("Complete_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/complete/`, resMsg, window.location.pathname);
    }
  };

  cancelJob = async (jobItem) => {
    const { toggleDialogLoader , trackApiEvent } = this.props;
    let { dateWiseJobs, allJobsOfTheMonth, jobsForMonth } = this.state;
    let { todaysJobsList } = this.props;
    toggleDialogLoader();
    try {
      const resData = await axios.post(
        `${process.env.REACT_APP_API_PREFIX}contractor/orders/cancel/${jobItem._id}`
      );

      toggleDialogLoader();

      if (resData.data.status === "OK") {

        trackApiEvent("Cancel_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/cancel/`, "OK", window.location.pathname);

        if (moment(jobItem.date).isSame(moment(), "day")) {
          todaysJobsList = todaysJobsList.filter(tJ => tJ._id !== jobItem._id);
          this.props.setJobs(todaysJobsList, "todaysJobs");
        }
        dateWiseJobs = dateWiseJobs.filter(dwJ => dwJ._id !== jobItem._id);
        allJobsOfTheMonth = allJobsOfTheMonth.filter(
          aJFM => aJFM._id !== jobItem._id
        );
        jobsForMonth = jobsForMonth.filter(jsFM => jsFM.id !== jobItem._id);
        this.setState({
          allJobsOfTheMonth,
          jobsForMonth,
          dateWiseJobs
        });
        this.props.setMsgAndShow(`Job Id ${jobItem.displayId} is cancelled`);
      } else {
        this.props.setMsgAndShow(resData.data.message);
        trackApiEvent("Cancel_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/cancel/`, resData.data.message, window.location.pathname);
      }
    } catch (error) {
      toggleDialogLoader();
      const { response } = error;
      let msg = "Error Occured!";

      if(response && response.data){
        msg = response.data.message ? response.data.message : "Error Occured!";
      }

      trackApiEvent("Cancel_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/cancel/`, msg, window.location.pathname);
      this.props.setMsgAndShow(msg);
    }
  };

  disableDeleteButton = itemObj => {
    if (!itemObj.createdByContractor || itemObj.jobCompleted) {
      return true;
    } else {
      return false;
    }
  };

  deleteJob = async jobItem => {
    const { toggleDialogLoader, trackApiEvent } = this.props;
    let {
      dateWiseJobs,
      allJobsOfTheMonth,
      jobsForMonth,
      jobItemObj
    } = this.state;
    let { todaysJobsList } = this.props;
    const jobUpdateObj = {
      orderId: jobItem._id,
      data: {
        deletedByContractor: true,
        isActive: false
      }
    };

    toggleDialogLoader();
    try {
      const resData = await axios.put(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/update`,
        { ...jobUpdateObj }
      );

      toggleDialogLoader();

      if (resData.data.status === "OK") {

        trackApiEvent("Delete_Job", "PUT", `${process.env.REACT_APP_API_PREFIX}cleaning_order/update`, "OK", window.location.pathname);
        
        if (moment(jobItem.date).isSame(moment(), "day")) {
          todaysJobsList = todaysJobsList.filter(tJ => tJ._id !== jobItem._id);
          this.props.setJobs(todaysJobsList, "todaysJobs");
        }
        dateWiseJobs = dateWiseJobs.filter(dwJ => dwJ._id !== jobItem._id);
        allJobsOfTheMonth = allJobsOfTheMonth.filter(
          aJFM => aJFM._id !== jobItem._id
        );
        jobsForMonth = jobsForMonth.filter(jsFM => jsFM.id !== jobItem._id);
        this.props.setMsgAndShow(`Job Id ${jobItem.displayId} is cancelled`);
      } else {
        this.props.setMsgAndShow(resData.data.message);
      }
      jobItemObj = {
        ...jobItemObj,
        deletedByContractor: true,
        isActive: false
      };
      this.setState({
        allJobsOfTheMonth,
        jobsForMonth,
        dateWiseJobs,
        jobItemObj
      });

      this.handleJobDetailsModal();
      this.props.setMsgAndShow(`Job Id ${jobItem.displayId} is deleted`);
    } catch (error) {
      const { response } = error;
      let resMsg = "Error Occured!";

      if(response && response.data){
        resMsg = response.data.msg ? response.data.msg : "Error Occured!"
      }

      trackApiEvent("Delete_Job", "PUT", `${process.env.REACT_APP_API_PREFIX}cleaning_order/update`, resMsg, window.location.pathname);
      toggleDialogLoader();
      this.props.setMsgAndShow(resMsg);
    }
  };
  submitData = async (obj, dispatch, props) => {
    const { toggleDialogLoader, trackApiEvent } = this.props;
    let formValues = _.cloneDeep(obj);
    let detailsObj = {};
    let serviceAddress = {};
    const addOns = {};
    let userId = null;

    let {
      customer,
      addOnServices,
      city,
      state,
      email,
      zipcode,
      address,
      firstName,
      lastName,
      phone,
      cleaningType,
      propertyArea,
      propertyType,
      etc,
      type
    } = formValues;

    if (!_.isEmpty(customer)) {
      firstName = customer.fname;
      lastName = customer.lname;
      email = customer.email;
      phone = customer.phone;
      userId = customer.value;
    }

    if (Array.isArray(addOnServices) && type === "domestic") {
      addOnServices.forEach(
        service => (addOns[service.name] = service.quantity)
      );
    } else if(type === "commercial" && !_.isEmpty(addOnServices)) {
      addOns[addOnServices.nameLabel] = addOnServices.planType;
      addOns[addOnServices.valueName] = addOnServices.value;
    }

    
    formValues = removeProp(formValues, [
      "addOnServices",
      "city",
      "state",
      "email",
      "address",
      "firstName",
      "lastName",
      "phone",
      "cleaningType",
      "propertyArea",
      "propertyType",
      "etc",
      "customer"
    ]);

    if (formValues.type === "commercial") {
      formValues = removeProp(formValues, ["bedrooms", "bathrooms"]);
    }

    state  = state.value;

    serviceAddress = {
      city,
      state,
      zipcode,
      fname: firstName,
      lname: lastName,
      phone,
      email,
      address,
      propertyArea: propertyArea.value,
      propertyType: !_.isEmpty(propertyType) ? propertyType.value : undefined
    }

    detailsObj.orderDetails = {
      ...formValues,
      cleaningType: cleaningType.value,
      propertyArea: propertyArea.value,
      data: { etc: parseInt(etc, 10), isActive: true },
      serviceAddress,
      addOns,
      isOpen: false,
      contractorId: this.props.contractorInfo.businessDetails._id,
      userId
    };

    detailsObj.userInfo = {
      data: {
        fname: firstName,
        lname: lastName,
        phone,
        serviceAddress
      },
      userType: "customer",
      username: email,
      email
    };

    toggleDialogLoader();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/add_order`,
        { ...detailsObj, currDate: moment().format("YYYY-MM-DD") }
      );

      toggleDialogLoader();

      if (res.data.status === "OK") {

        trackApiEvent("Add_Job", "POST", `${process.env.REACT_APP_API_PREFIX}cleaning_order/add_order`, "OK", window.location.pathname);

        const { todaysJobsList } = this.props;
        const { order  } = res.data;

        order.customerName = `${capitalizeFirstLetterOnly(firstName.trim())} ${capitalizeFirstLetterOnly(lastName.trim())}`;
        if (moment(order.date).isSame(moment(), "day")) {
          const job = {
            _id: order.displayId,
            cleaningType: this.cleaningTypes[order.cleaningType],
            startTime: moment(order.time, "hh:mm:ss").format("hh:mm A"),
            endTime: moment(order.time, "hh:mm:ss")
              .add(order.etc, "minutes")
              .format("hh:mm A")
          };
          todaysJobsList.push(job);
          this.props.setJobs(todaysJobsList, "todaysJobs");
        }
        this.adddJobsInCalender(order);
        this.handleModalClose();
      } else {        
        this.props.setMsgAndShow(res.data.message);
      }
    } catch (error) {
      this.handleModalClose();

      const { response } = error;
      let msg = "Error Occured!";

      if(response && response.data){
        msg = response.data.message ? response.data.message : "Error Occured!";
      }
      
      trackApiEvent("Add_Job", "POST", `${process.env.REACT_APP_API_PREFIX}cleaning_order/add_order`, msg, window.location.pathname);
      this.props.setMsgAndShow(msg);
    }
  };


  decreaseByOne = type => () => {
    let value = this.props.formValues[type];
    value = value === 1 ? value : value - 1;
    this.props.change(type, value);
  };

  increaseByOne = type => () => {
    let value = this.props.formValues[type];
    value = value + 1;
    this.props.change(type, value);
  };

  openAddJobModal = () => {
    const { trackButtonEvent } = this.props;
    trackButtonEvent("Add_New_Job",window.location.pathname);

    this.setState({
      isAddJobModalOpen: true
    });
  }

  handleModalClose = () => {
    this.setState({
      isAddJobModalOpen: false,
      addNewCustomer: false
    });
    this.props.reset("OrderForm");
  }
  openNewUserform = () =>
  this.setState({ addNewCustomer: !this.state.addNewCustomer });
  openAssignMenuFromModal = (jobItem) => () =>{
    this.openAssignMenu(jobItem,undefined,this.assignEmployeeBtnRef);
  }

  openInnerAssignMenu = (jobItem) => () =>{
    this.openInnerAssignMenuInModal(jobItem,undefined,this.assignEmployeeBtnRef);
  }
  openInnerAssignMenuInModal =  async (item, index, itemRef) => {
    const { toggleDialogLoader } = this.props;
    toggleDialogLoader();
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}employee/get_employee_data_by_job`,
        {
          params: {
            contractorId: this.props.contractorInfo.businessDetails._id,
            time: item.time,
            date: moment(item.date).format("YYYY-MM-DD"),
            jobId: item._id
          }
        }
      );

      toggleDialogLoader();

      const { employeeList } = data;

      this.setState({
        employeeList,
        openAssignJobMenu:true,
        btnRef: itemRef,
        // jobItemIndex: jobIndex,
        jobItemObj: item
      });
    } catch (error) {
      toggleDialogLoader()
      const { response } = error;

      this.setState({
        open: true,
        btnRef: itemRef,
        // jobItemIndex: jobIndex
      });
      this.props.setMsgAndShow(response ? response.data.msg : "Error");
    }
  }

  handleAssignMenuClose = () => {
    this.setState({
     openAssignJobMenu: false,
      jobItemIndex: ""
    });
  };


  disableCancelButton = (itemObj) => {
    if (itemObj.jobCompleted) {
      return true;
    } else {
      return false;
    }
  }

  componentWillUnmount(){
    this.props.resetForm("OrderForm");
  }

  getOTPForOrder = (job) => {
    this.setState({
      jobItemObj:job,
      isOTPModalOpen:true
    })
  }

  closeOTPModal = () => {
    this.setState({
      jobItemObj:{},
      isOTPModalOpen:false
    })
  }



  checkOTPRequirementAndComplete = (job) => {
    const { isOTPRequired } = job;

    if(isOTPRequired){
      this.getOTPForOrder(job);
    } else {
      this.jobCompleted(job);
    }
  }


  handleCompleteCallFailure = (errorRes) => {
    const { trackApiEvent } = this.props;
    let errorMsg = "Error occurred while marking job complete!";
    const { response } = errorRes;
    if(response && response.data && response.data.message){
      errorMsg = response.data.message;
    }
    
    trackApiEvent("Complete_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/complete/`, errorMsg, window.location.pathname);
  }

  createJobsForMonth = (allJobs) => {
    const jobsForMonth = allJobs.map(job => {
      const startDate = moment(job.date).format("YYYY-MM-DD");
      const date = moment(`${startDate} ${job.time}`, "YYYY-MM-DD HH:mm:ss");
      if (!_.isEmpty(job.assignedEmployeeIds)) {
        return {
          id: job._id,
          title: job.displayId,
          desc: this.cleaningTypes[job.cleaningType],
          start: new Date(date),
          end: new Date(date.add(job.etc, "minutes")),
          isAssigned: true
        };
      } else {
        return {
          id: job._id,
          title: job.displayId,
          desc: this.cleaningTypes[job.cleaningType],
          start: new Date(date),
          end: new Date(date.add(job.etc, "minutes"))
        };
      }
    });

    return jobsForMonth;
  }

  getUpdatedJobsList = async (selectedDate) => {


    const { toggleLoader  } = this.props;
    try {
      const contractorId = this.props.contractorInfo.businessDetails._id;
      let allJobsOfTheMonth = [];
      const selectedMonth = moment(selectedDate,"YYYY-MM-DD").format("MMM");
      const selectedYear = moment(selectedDate,"YYYY-MM-DD").format("YYYY");
      toggleLoader();

      const monthOrdersData = await getJobsByMonth({
        month: selectedMonth,
        contractorId,
        year: selectedYear
      })
      
      
    

      if(monthOrdersData.status === "OK"){
        allJobsOfTheMonth = monthOrdersData.orders;


        const jobsForMonth = this.createJobsForMonth(allJobsOfTheMonth);

        this.setState({
          jobsForMonth,
          allJobsOfTheMonth,
        })

  
       }
 

       toggleLoader();
    } catch (error) {
      toggleLoader();
      const { response } = error;

      this.props.setMsgAndShow( 
        response && response.data && response.data.message ? response.data.message : "Error Occurred"
      );
    }
  }

  searchCustomers = async (q) => {

    try {
      let customerOptions = [];
      const contractorId = this.props.contractorInfo.businessDetails._id;
      const customersRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_customers`,
        {
          params: {
            contractorId,
            q
          }
        }
      );
  
      if (customersRes.data.status === "OK") {
        const { customers } = customersRes.data;
  
        customerOptions = customers.map(customer => {
          return {
            value: customer._id,
            label: `${customer.fname} ${customer.lname}`,
            fname: customer.fname,
            lname: customer.lname,
            email: customer.email,
            phone: customer.phone,
            serviceAddress: customer.serviceAddress
          };
        });
      }


      return customerOptions;
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get customers!";
      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.props.setMsgAndShow(errMsg);
    }

  }


  actions = [
    {
      label: "Get Details",
      onClick: this.getJobDetails,
      isForJobModal: true
    },
    {
      label: "Mark as Complete",
      onClick: this.checkOTPRequirementAndComplete,
      checkForDisabled: true,
      propToCheck: "jobCompleted"
    },
    {
      label: "Yet to Assign",
      secondaryLabel: "Assigned",
      onClick: this.setBtn,
      isSecondary: this.isAssigned,
      checkForDisabled: true,
      propToCheck: "jobCompleted",
      isRef: true
    },
    {
      label: "Delete Job",
      onClick: this.deleteJob,
      checkForDisabled: true,
      disableButton: true,
      checkDisableButton: this.disableDeleteButton
    },
    {
      label: "Cancel Job",
      checkForDisabled: true,
      disableButton: true,
      checkDisableButton: this.disableCancelButton,
      onClick: this.cancelJob
    }
  ];

  jobActions = [
    {
      label: "Assign",
      onClick: this.confirmAssign
    }
  ];

  newJobActions = [
    {
      label: "Get Details",
      btnProps: {
        variant: "contained",
        color: "secondary",
        style: { marginRight: 16 }
      },
      onClick: this.getJobDetails,
      isForJobModal: true 
    },
    {
      label: "Accept",
      btnProps: {
        variant: "contained",
        color: "primary",
        style: { marginRight: 16 }
      },
      onClick: this.acceptJob
    },
    {
      label: "Reject",
      btnProps: {
        variant: "outlined",
        color: "secondary",
        style: { marginRight: 16 }
      },
      onClick: this.rejectJob
    }
  ];


  handlePageChange = (_, page) => {
    const rawDateWiseJobs = this.rawDateWiseJobs;
    const { perPage } = this.state;


    const dateWiseJobs = rawDateWiseJobs.slice(page * perPage, page * perPage + perPage);

    this.setState({
      dateWiseJobs
    })
  }

  handlePerPageLimit = (limit) => {
    console.log(":IMI", limit);
  }

  closeJobsListModal = () => {
    this.setState({
      isModalOpen:false,
      currSelectedDate:""
    })
  }

  onJobActionSuccess = () => {
    this.getUpdatedJobsList(this.currSelectedDate)
  }
  render() {


    return (
      <Fragment>
        <CalenderDashboard
          getJobDetails={this.getJobDetails}
          handleDateChange={this.handleDateChange}
          newJobsList={this.props.newJobsList}
          newJobsActions={this.newJobActions}
          jobActions={this.jobActions}
          handleClose={this.handleClose}
          handleMenuClose={this.handleMenuClose}
          selectedDate={this.selectedDate}
          eventStyleSetter={this.eventStyleSetter}
          submitData={this.submitData}
          increaseByOne={this.increaseByOne}
          decreaseByOne={this.decreaseByOne}
          openAddJobModal={this.openAddJobModal}
          handleModalClose={this.handleModalClose}
          openNewUserform={this.openNewUserform}
          getHelpers={this.getHelpers}
          handleJoyrideCallback={this.handleJoyrideCallback}
          searchCustomers={this.searchCustomers}
          {...this.props}
          {...this.state}
        />


        <JobsListModal 
          isOpen={this.state.isModalOpen}
          closeModal={this.closeJobsListModal}
          cleaningTypesObj={this.state.cleaningTypesObj}
          selectedDate={this.state.currSelectedDate}
          onJobActionSuccess={this.onJobActionSuccess}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  let formValues = {};

  if (state.form.OrderForm) {
    const { OrderForm } = state.form;
    if (OrderForm.values) {
      formValues = OrderForm.values;
    }
  }
  return {
    newJobsList: state.jobs.newJobs,
    todaysJobsList: state.jobs.todaysJobs,
    contractorInfo: state.contractor,
    initialValues: {
      bedrooms: 1,
      bathrooms: 1,
      type: "domestic"
    },
    formValues, 
    refreshPage:state.app.refreshPage,
    isTourEnabled: state.app.isTourEnabled  
  }
};

const mapDispatchToProps = dispatch => ({
  setJobs: (jobs, jobsType) => dispatch(setJobs(jobs, jobsType)),
  removeJob: (jobId, jobsType) => dispatch(removeJob(jobId, jobsType)),
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  toggleLoader: () => dispatch(toggleLoader()),
  toggleDialogLoader: () => dispatch(toggleDialogLoader()),
  resetForm: formName => dispatch(reset(formName)),
  reset
});

const withReduxForm = reduxForm({
  form: "OrderForm",
  destroyOnUnmount: false
})(CalenderDashboardContainer);

const connectRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(withReduxForm);

export default withMixpanel(connectRedux);
