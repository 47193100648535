import React from "react";
// import cn from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "core/components/Styles";
// import Typography from "core/components/Typography";
import Table from "core/components/Table";
import TableHead from "core/components/TableHead";
import TableBody from "core/components/TableBody";
import TableCell from "core/components/TableCell";
import TableFooter from "core/components/TableFooter";
import TablePagination from "core/components/TablePagination";
import TableRow from "core/components/TableRow";
import Paper from "core/components/Paper";
import IconButton from "core/components/IconButton";
// import momentTz from "moment-timezone";
import { PageFirst, PageLast, ArrowLeft, ArrowRight } from "mdi-material-ui";
// import PopUpMenu from "../PopUpMenu";
// import Status from "../Status";

import BookingItemRow from "./BookingItemRow"
// import ArrowLeft from '@material-ui/icons/ArrowLeft';
// import ArrowRight from '@material-ui/icons/ArrowRight';
// import LastPageIcon from '@material-ui/icons/LastPage';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
    boxShadow: "none"
  }
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <PageLast /> : <PageFirst />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? <ArrowRight /> : <ArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? <ArrowLeft /> : <ArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <PageFirst /> : <PageLast />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true
})(TablePaginationActions);

// let counter = 0;
// function createData(name, calories, fat) {
//   counter += 1;
//   return { id: counter, name, calories, fat };
// }

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    borderRadius: 8,
    position: "relative"
    // border:"1px solid #979797"
  },
  table: {
    minWidth: 500,
    background:"#F7F7F7"
  },
  tableWrapper: {
    overflowX: "auto"
  },
  tableHeading:{
    fontSize: 11,
    // textAlign:'center',
    color:"#2f2f2f",
    textTransform : "uppercase"
  },
  tableCell:{
    fontFamily: "Poppins",
    fontSize: 13,
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "19px",
    letterSpacing: "normal",
    // textAlign: "center",
    color:" rgba(0, 0, 0, 0.69)",
    textTransform : "capitalize",
    // height: 106,
    verticalAlign: "middle",
    "& button":{
      fontFamily: "Poppins",
      // fontSize: 16,
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      // textAlign: "center",
      color:" #52575a",
    },

  },
  tdHeading:{
    // fontWeight: "bold",
    color:"#000"
  },
  actionBtn:{
    // border: "1px solid rgba(148, 151, 155, 0.6)"
  },
  iconCont:{
    marginLeft: 15
  },
  icon:{
    // marginTop:2,
  },
  thead:{
  }
});

class CustomPaginationActionsTable extends React.Component {
  state = {
    page: 0,
    rowsPerPage: 5
  };
 
  handleChangePage = (_, page) => {
    if(this.props.handlePageChange){
      this.props.handlePageChange(page);
    } 
  };

  handleChangeRowsPerPage = event => {
    if(this.props.handleLimitChange){
      this.props.handleLimitChange(event.target.value)
    };

  }

  render() {
    const { classes, list, headers, columnProps, actions, limit, page, total } = this.props;
    const emptyRows = limit - list.length;
    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead className={classes.thead}>
              <TableRow>
                {headers &&
                  headers.map((header, index) => {
                    if (header.isNumeric) {
                      return (
                        <TableCell className={classes.tableHeading} key={`table-header-${index}`} numeric>
                          {header.label}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell className={classes.tableHeading} key={`table-header-${index}`}>
                        {header.label}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {list
                .map((item, index) => {
                  return (
                    <BookingItemRow key={`booking-item-${index}`} item={item}  columnProps={columnProps} actions={actions}/>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={columnProps}>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={columnProps.length}
                  count={total}
                  rowsPerPage={limit}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

CustomPaginationActionsTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomPaginationActionsTable);
