import React, { PureComponent } from "react";
import CustomerDetailsModal from "./CustomerDetailsModal";
import { setMsgAndShow } from "../../store/actions/popup";
import { connect } from "react-redux";
import axios from "utils/http";
import { withMixpanel } from "../../utils/react-mixpanel";
import getTotalPagesCount from "../../utils/getTotalPagesCount";


class CustomerDetailsModalContainer extends PureComponent {

  state = {
    orders:[],
    gettingOrders:false,
    perPage:5,
    currPage:1,
    page:0,
    total:0,
  }


  fetchOrders = async () => {

    const { trackApiEvent } = this.props;
    try {
      this.setState({
        gettingOrders:true
      })
      const contractorId = this.props.businessDetails._id;
      const customerId = this.props.customerDetails._id;
      const res = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_customer_orders`, {
        params: {
          contractorId,
          customerId,
          page:this.state.currPage,
          limit:this.state.perPage
        }
      })

      const {
         orders,
         total,
         page,
        } = res.data;
      trackApiEvent("View_Booking_Details", "GET", `${process.env.REACT_APP_API_PREFIX}contractor/get_customer_orders`, "OK", window.location.pathname);
      this.setState({
        orders,
        gettingOrders:false,
        total,
        currPage:page,
        page: page - 1
      })

    } catch (error) {

      const { response } = error;
      let errMsg = "Unable to get orders!";

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }

      trackApiEvent("View_Booking_Details", "GET", `${process.env.REACT_APP_API_PREFIX}contractor/get_customer_orders`, errMsg, window.location.pathname);

      this.setState({
        gettingOrders:false
      })

      this.props.setMsgAndShow(errMsg);
    }
  }

  resetState = () => {
    this.setState({
      orders:[],
      perPage:5,
      currPage:1,
      page:0,
      total:0,
    })
  }
  componentDidUpdate(prevProps){
    const { isCustomerDetailsModalOpen:prevIsModalOpen} = prevProps;
    const { isCustomerDetailsModalOpen:currIsModalOpen} = this.props;
    if(prevIsModalOpen !== currIsModalOpen){
      if(currIsModalOpen){
        this.fetchOrders()
      } else {
        this.resetState()
      }

    }
  }



  getListByPage = async (pageNumber, isLoaderEnabled) => {
    try {

      if(!isLoaderEnabled){
        this.setState({
          gettingOrders:true
        })

      }
      const contractorId = this.props.businessDetails._id;
      const customerId = this.props.customerDetails._id;
      const { perPage:storedPerPage } = this.state;
      const res = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_customer_orders`, {
        params: {
          contractorId,
          customerId,
          page:pageNumber,
          limit:storedPerPage
        }
      })

      const {
         orders,
         total,
         page,
         perPage
        } = res.data;


      const totalPages = getTotalPagesCount(total, perPage);


      if(page > totalPages){
        this.getListByPageAndLimit(totalPages, perPage, true);
      } else {
        this.setState({
          orders,
          gettingOrders:false,
          total,
          currPage:page,
          page: page - 1,
          perPage
        })

      }
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get orders!";

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }

      this.setState({
        gettingOrders:false
      })

      this.props.setMsgAndShow(errMsg);
    }
  }

  getListByPageAndLimit = async (pageNumber, newLimit, isLoaderEnabled) => {
    try {

      if(!isLoaderEnabled){
        this.setState({
          gettingOrders:true
        })

      }
      const contractorId = this.props.businessDetails._id;
      const customerId = this.props.customerDetails._id;
      const { currPage, perPage:storedPerPage } = this.state;
      const pageNumberToSend = pageNumber ? pageNumber : currPage;
      const limitToSend = newLimit ? newLimit : storedPerPage;
      const res = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_customer_orders`, {
        params: {
          contractorId,
          customerId,
          page:pageNumberToSend,
          limit:limitToSend
        }
      })

      const {
         orders,
         total,
         page,
         perPage
        } = res.data;


      // const totalPages = getTotalPagesCount(total, perPage);
      // if(page > totalPages){
      //   this.getListByPageAndLimit(totalPages, perPage, true);
      // } else {
        this.setState({
          orders,
          gettingOrders:false,
          total,
          currPage:page,
          page: page - 1,
          perPage
        })

      // }
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get orders!";

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }

      this.setState({
        gettingOrders:false
      })

      this.props.setMsgAndShow(errMsg);
    }
  }


  handlePageChange = (page) => {
    this.getListByPageAndLimit(page + 1);
  }

  handleLimitChange = async (limit) => {
    try {
        this.setState({
          gettingOrders:true
        })
      const contractorId = this.props.businessDetails._id;
      const customerId = this.props.customerDetails._id;
      const { currPage } = this.state;
      const res = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_customer_orders`, {
        params: {
          contractorId,
          customerId,
          page:currPage,
          limit
        }
      })

      const {
         orders,
         total,
         page,
         perPage
        } = res.data;


      const totalPages = getTotalPagesCount(total, perPage);
      if(page > totalPages){
        this.getListByPageAndLimit(totalPages, perPage, true);
      } else {
        this.setState({
          orders,
          gettingOrders:false,
          total,
          currPage:page,
          page: page - 1,
          perPage
        })

      }
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get orders!";

      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }

      this.setState({
        gettingOrders:false
      })

      this.props.setMsgAndShow(errMsg);
    }
  }

  render(){
    return (
      <CustomerDetailsModal 
        {...this.props}
        {...this.state}
        handlePageChange={this.handlePageChange}
        handleLimitChange={this.handleLimitChange}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  businessDetails: state.contractor.businessDetails,
})
const mapDispatchToProps = (dispatch) => ({
  setMsgAndShow:(msg) => dispatch(setMsgAndShow(msg))
})

const ConnectedWithRedux = connect(mapStateToProps, mapDispatchToProps)(CustomerDetailsModalContainer);


export default withMixpanel(ConnectedWithRedux);