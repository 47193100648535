function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 23,
    height: 15,
    viewBox: "0 0 23 15"
  }, props), React.createElement("path", {
    id: "prefix__iconfinder_bathtub_2023112",
    d: "M33.354 32.722a1.232 1.232 0 0 0-1.114-1.269h-18.8v-3.994a1.419 1.419 0 0 1 1.369-1.432 1.235 1.235 0 0 1 .982.381 1.409 1.409 0 0 1 .387 1.033h.58a2.065 2.065 0 0 0-.562-1.464 1.815 1.815 0 0 0-1.386-.57 2.019 2.019 0 0 0-1.949 2.053v3.994h-1.4a1.212 1.212 0 0 0-1.1 1.15 1.242 1.242 0 0 0 .92 1.319c.133 1.235 1 2.593 3.323 3.451v3.031h1.288l.6-2.516a28.078 28.078 0 0 0 5.365.445 31.848 31.848 0 0 0 5.379-.4l.58 2.469H29.1v-2.915c2.03-.7 3.19-1.9 3.329-3.565a1.24 1.24 0 0 0 .925-1.201zm-17.918 7.066h-.255v-2.179l.754.188zm13.084 0h-.255l-.487-1.951c.267-.056.452-.108.742-.173zm.58-2.988l-1.943.515a30.915 30.915 0 0 1-5.307.361c-6.226 0-9.668-1.287-10.01-3.757h20.029c-.107.93-.669 2.137-2.769 2.881zm3.14-3.5H11.46a.619.619 0 0 1 0-1.235h20.78a.619.619 0 0 1 0 1.235zm-15.453-4.825a.334.334 0 1 1-.333-.355.345.345 0 0 1 .333.355zm0 1.207a.334.334 0 1 1-.333-.355.324.324 0 0 1 .237.105.367.367 0 0 1 .1.253zm0 1.235a.334.334 0 1 1-.333-.355.324.324 0 0 1 .241.109.368.368 0 0 1 .092.261z",
    transform: "translate(-10.354 -25.405)",
    style: {
      "fill": "#00949c"
    }
  }));
};

export default __webpack_public_path__ + "static/media/job_bathroom.11235ad3.svg";
export { SvgComponent as ReactComponent };