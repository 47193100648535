import React, { Component} from "react";
import { connect } from "react-redux";
import { reset, reduxForm } from "redux-form";
import socket from "socket";
import axios from "utils/http";
import { cloneDeep, isEmpty, debounce } from "lodash";
import moment from "moment-timezone";
import { STATUS } from "react-joyride";
import removeProp from "../utils/removeProp";
import JobsDashboard from "../components/JobsDashboard";
import AssignJobMenu from "../components/AssignJobMenu";
import JobDetailsModal from "../components/JobDetailsModal";
import AdditionalBookingsModal from "../components/AdditionalBookingsModal";
import GetOTPModal from "../components/GetOTPModal";
import { setJobs, removeJob } from "../store/actions/jobs";
import { setMsgAndShow } from "../store/actions/popup";
import { setItem, toggleLoader, toggleDialogLoader } from "../store/actions/app";
import { openChangesModal } from "../store/actions/jobChanges";
import Storage from "utils/Storage";
import { withMixpanel} from "../utils/react-mixpanel";
import getTotalPagesCount from "../utils/getTotalPagesCount";
import getCleaningTypesList from "../helpers/getCleaningTypesList";

class JobsDashboardContainer extends Component {
  dropDownAnchor = null;
  assignedEmployees = [];
  state = {
    show: "allJobs",
    isJobDetailsModalOpen: false,
    doNotShowJobBtns: false,
    isModalOpen: false,
    newJobOrders: [],
    employeeList: [],
    jobItemIndex: "",
    jobItemObj: {},
    open: false,
    openAssignJobMenu: false,
    btnRef: null,
    customerOptions: [],
    addNewCustomer: false,
    cleaningTypesObj:{},
    rawAllJobs:[],
    rawTodaysJobs:[],
    rawNotAssignedJobs:[],
    load: false,
    isSearching: false,
    runGuide: false,
    isOTPModalOpen:false,
    currPage:1,
    page:0,
    total:0,
    perPage:10,
    orders:[],
    gettingOrders:false,
    q:"",
    gettingNewOrders:true
  };


  searchOrders = () => {
    this.getListByPage(1);
  }

  lazySearch = debounce(this.searchOrders,250);
  componentDidMount() {

    const {  
      item:{
        itemType,
        item:itemObj
      },
      trackPageLoad
    } = this.props;
 
    trackPageLoad("Jobs_Dashboard");
    
    if (!isEmpty(this.props.contractorInfo.businessDetails)) {
      this.addData();
    }

    if(!isEmpty(itemObj)){
      if(itemType === "job"){
        this.getJobDetails(itemObj)
      }
    }

  }

  componentDidUpdate = async (prevProps, nextProps) => {
    const { formValues, contractorInfo, 
      // history :{ location:prevLocation}, 
      item: oldItem } = prevProps;
    const { item } = this.props;
    const { isJobDetailsModalOpen } = this.state
    if (formValues.type !== this.props.formValues.type) {
      this.props.change("addOnServices", null);
    }
    if (
      JSON.stringify(contractorInfo.businessDetails) !==
      JSON.stringify(this.props.contractorInfo.businessDetails)
    ) {
      this.addData();
    }


    if(!isJobDetailsModalOpen){
      if(!isEmpty(item) && (JSON.stringify(oldItem) !== JSON.stringify(item))){
        if(item.itemType === "job"){
          this.getJobDetails(item.item)
        }
      }
    }


    if(this.checkForPageRefresh(prevProps, this.props)){
      this.updateLists();
    }

  };



  submitData = async (obj, dispatch, props) => {
    let formValues = cloneDeep(obj);
    let detailsObj = {};
    let serviceAddress = {};
    const addOns = {};
    let userId = null;

    let {
      customer,
      addOnServices,
      city,
      state,
      email,
      zipcode,
      address,
      firstName,
      lastName,
      phone,
      cleaningType,
      propertyArea,
      propertyType,
      etc,
      type
    } = formValues;

    if (!isEmpty(customer)) {
      firstName = customer.fname;
      lastName = customer.lname;
      email = customer.email;
      phone = customer.phone;
      userId = customer.value;
    }


    if (Array.isArray(addOnServices) && type === "domestic") {
      addOnServices.forEach(
        service => (addOns[service.name] = service.quantity)
      );
    } else if(type === "commercial" && !isEmpty(addOnServices)) {
      addOns[addOnServices.nameLabel] = addOnServices.planType;
      addOns[addOnServices.valueName] = addOnServices.value;
    }

    formValues = removeProp(formValues, [
      "addOnServices",
      "city",
      "state",
      "email",
      "address",
      "firstName",
      "lastName",
      "phone",
      "cleaningType",
      "propertyArea",
      "propertyType",
      "etc",
      "customer"
    ]);

    if (formValues.type === "commercial") {
      formValues = removeProp(formValues, ["bedrooms", "bathrooms"]);
    }

    state  = state.value;


    serviceAddress = {
      city,
      state,
      zipcode,
      fname: firstName,
      lname: lastName,
      phone,
      email,
      address,
      propertyArea: propertyArea.value,
      propertyType: !isEmpty(propertyType) ? propertyType.value : undefined
    }
    detailsObj.orderDetails = {
      ...formValues,
      cleaningType: cleaningType.value,
      propertyArea: propertyArea.value,
      data: { etc: parseInt(etc, 10), isActive: true },
      serviceAddress,
      addOns,
      isOpen: false,
      contractorId: this.props.contractorInfo.businessDetails._id,
      userId
    };




    detailsObj.userInfo = {
      data: {
        fname: firstName,
        lname: lastName,
        phone,
        serviceAddress
      },
      userType: "customer",
      username: email,
      email
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/add_order`,
        { ...detailsObj, currDate: moment().format("YYYY-MM-DD") }
      );

      if (res.data.status === "OK") {
        this.updateLists();
        this.handleClose();
        this.props.reset("OrderForm");
      } else {
        this.props.setMsgAndShow(res.data.message);
      }
    } catch (error) {
      const { response } = error;
      this.props.setMsgAndShow(response.data.message);
    }
  };

  decreaseByOne = type => () => {
    let value = this.props.formValues[type];
    value = value === 1 ? value : value - 1;
    this.props.change(type, value);
  };

  increaseByOne = type => () => {
    let value = this.props.formValues[type];
    value = value + 1;
    this.props.change(type, value);
  };

  getHelpers = (StoreHelpers) => {
    this.helpers = StoreHelpers;
  };

  handleJoyrideCallback = (CallBackProps) => {
    
    const { status } = CallBackProps;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      Storage.set("jobsGuide","completed");
      this.setState({ runGuide: false });
    }
    
  };


  getContractorId = () => {
    const contractorId = this.props.contractorInfo.businessDetails._id;
    return contractorId;
  }   


  getCleaningTypes = async () => {
    try {
      
      const cleaningTypesObj = await getCleaningTypesList();


      this.setState({
        cleaningTypesObj: cleaningTypesObj,
      })

    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get new orders!";
      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.props.setMsgAndShow(errMsg);
    }
  }

  getNewOrders = async () => {
    try {
      const contractorId = this.getContractorId();
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/get_new_orders`,
        {
          params: {
            contractorId
          }
        }
      );

      const orders = data.orders;

      if(orders && orders.length){
        this.props.setJobs(orders, "newJobs");
      } 
      this.setState({
        gettingNewOrders:false
      })
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get new orders!";
      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        gettingNewOrders:false
      })
      this.props.setMsgAndShow(errMsg);
    }
  }


  getAllJobs = async () => {
    try {

      this.setState({
        gettingOrders:true
      })
      let allJobs = [];
      const contractorId = this.getContractorId();
      const allOrdersRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_all_jobs`,
        {
          params: {
            contractorId
          }
        } 
      )


      if(allOrdersRes.data.status === "OK"){

        const total = allOrdersRes.data.total;
        allJobs = allOrdersRes.data.orders;
        this.setState({
          orders:allJobs,
          total,
          gettingOrders:false
        })
      }

    

    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get new orders!";
      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        gettingOrders:false
      })
      this.props.setMsgAndShow(errMsg);
    }
  }


  getCustomers = async () => {
    try {
      let customerOptions = [];
      const contractorId = this.getContractorId();
      const customersRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_customers`,
        {
          params: {
            contractorId,
          }
        }
      );
      if (customersRes.data.status === "OK") {
        const { customers } = customersRes.data;

        customerOptions = customers.map(customer => {
          return {
            value: customer._id,
            label: `${customer.fname} ${customer.lname}`,
            fname: customer.fname,
            lname: customer.lname,
            email: customer.email,
            phone: customer.phone,
            serviceAddress: customer.serviceAddress
          };
        });
  
      }

      this.setState({ 
        customerOptions,
      });

    
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get new orders!";
      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.props.setMsgAndShow(errMsg);
    }
  }

  lazyCustomerSearch = debounce(this.getCustomers, 250);


  
  addData = async () => {

    try {
      this.getCleaningTypes();
      this.getNewOrders();
      this.getAllJobs();
      this.getCustomers();
      const { isTourEnabled } = this.props;
      if( Storage.get("jobsGuide") !== "completed" && isTourEnabled){ 
        this.setState({ 
          runGuide: true 
        }); 
      }

    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  };


  checkForPageRefresh = (prevProps, nextProps) => {
    const { refreshPage:prevRefreshPage } = prevProps;
    const { refreshPage:newRefreshPage } = nextProps;
    let triggerRefresh = false;
    if(prevRefreshPage !== newRefreshPage && newRefreshPage){
      triggerRefresh = true;
    }

    return triggerRefresh;
  }
  updateLists = async () => {
    this.getList();
  };

  recordListChangeEvent = (listName) => {
    let url;
    let listButtonName;
    const {trackApiEvent, trackButtonEvent } = this.props;
    switch(listName){
      case "allJobs":
        url = "contractor/get_all_jobs";
        listButtonName = "All_Jobs";
        break;
      case "todaysJobs":
        url = "contractor/get_orders";
        break;
      case "notAssignedJobs":
        url = "contractor/get_unassigned_jobs";
        break;
      default:
        break;
    }
    trackApiEvent(listButtonName,"GET",`${process.env.REACT_APP_API_PREFIX}${url}`,"OK",window.location.pathname);
    trackButtonEvent(listButtonName,window.location.pathname);
    
  }
  changeList = listName => async () => {

    this.setState({
      show:listName,
    }, () => {
      this.getListByPage(1);
      this.recordListChangeEvent(listName);
    });

  };

  openModal = () => {

    const { trackButtonEvent } = this.props;
    trackButtonEvent("Add_New_Job",window.location.pathname);

    this.setState({
      isModalOpen: true
    });
  };

  handleClose = () => {
    this.setState({
      isModalOpen: false,
      addNewCustomer: false
    });
    this.props.resetForm("OrderForm");
  };

  handleMenuClose = () => {
    this.setState({
      open: false,
      jobItemIndex: "",
      openAssignJobMenu: false
    });
  };
  handleAssignMenuClose = () => {
    this.setState({
     openAssignJobMenu: false,
      jobItemIndex: ""
    });
  };

  acceptJob = jobItem => async (e) => {
    e.stopPropagation();

    const { toggleLoader, trackButtonEvent, trackApiEvent } = this.props;
    trackButtonEvent("Accept_Job",window.location.pathname);

    // const { show } = this.state;
    // let { allJobs, unassignedJobs, todaysJobs } = this.props.jobsObj;
    toggleLoader();
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}contractor/orders/accept/${jobItem._id}`);
      if(res.data.status === "OK"){
        this.updateLists();
        toggleLoader();
        trackApiEvent("Accept_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/accept/`, "OK", window.location.pathname);
        // const { order } = res.data;
        // // if(show === "allJobs"){
        // //   allJobs.push(order);
        // //   setJobs(allJobs,"allJobs");
        // // } else if(show === "todaysJobs"){
        // //   if (moment(order.date).isSame(moment(), "day")) {
        // //     todaysJobs.push(order);
        // //     setJobs(todaysJobs,"todaysJobs");
        // //   }
        // // } else if(show === "notAssignedJobs"){
        // //   unassignedJobs.push(order);
        // //   setJobs(unassignedJobs,"unassignedJobs");
        // // }
        socket.emit("removeJob", jobItem._id);
        this.props.setMsgAndShow(
          `Job Id '${jobItem.displayId}' is moved to accepted jobs`
        );
      } else {
        toggleLoader();
        this.props.setMsgAndShow("Error occured please try again later");
      }

    } catch (error) {
      const { response } = error;
      toggleLoader();
      
      let message = "Error";

      if(response && response.data){
        message = response.data.message ? response.data.message : "Error";
      }

      trackApiEvent("Accept_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/accept/`, message, window.location.pathname);
      this.props.setMsgAndShow(message);
    }
  };

  rejectJob = jobItem => async (e) => {
    e.stopPropagation();

    const { toggleLoader, trackButtonEvent, trackApiEvent }= this.props;
    trackButtonEvent("Reject_Job",window.location.pathname);


    const updateObj = {
      orderId: jobItem._id,
      rejectedBy: this.props.contractorInfo.businessDetails._id
    };

    toggleLoader();
    try {
      await axios.post(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/reject_order`,
        {
          ...updateObj
        }
      );

      trackApiEvent("Reject_Job", "POST", `${process.env.REACT_APP_API_PREFIX}cleaning_order/reject_order`, "OK", window.location.pathname);

      toggleLoader();
      this.props.removeJob(jobItem._id, "newJobs");
      this.props.setMsgAndShow(`Job Id '${jobItem.displayId}' was rejected`);
    } catch (error) {
      toggleLoader();

      const { response } = this.props;
      let message = "Error";

      if(response && response.data){
        message = response.data.msg ? response.data.msg : "Error";
      }

      trackApiEvent("Reject_Job", "POST", `${process.env.REACT_APP_API_PREFIX}cleaning_order/reject_order`, message, window.location.pathname);

      this.props.setMsgAndShow(error.response.data.msg);
    }
  };

  assignJob = e => {
    const { target } = e;
    const { value, checked } = target;
    const jobItemObj = cloneDeep(this.state.jobItemObj);

    if (checked) {
      jobItemObj.assignedEmployeeIds.push(value);
      this.setState({
        jobItemObj
      });
    } else {
      const employeeIndex = jobItemObj.assignedEmployeeIds.indexOf(value);
      if (employeeIndex > -1) {
        jobItemObj.assignedEmployeeIds.splice(employeeIndex, 1);
        this.setState({
          jobItemObj
        });
      }
    }
  };

  confirmAssign = async () => {
    const { toggleLoader, toggleDialogLoader } = this.props;
    let { jobItemObj } = this.state;

    let msgForPopUp;
    const jobId = jobItemObj._id;
    const displayId = jobItemObj.displayId;
    const updateObj = {
      orderId: jobId,
      assignedEmployeeIds: jobItemObj.assignedEmployeeIds
    };


    if (
      jobItemObj.assignedEmployeeIds.length > 0
    ) {
      msgForPopUp = `Job Id '${displayId}' is assigned`;
    } else {
      updateObj.jobTimeline = {
        assigned: null
      };

      msgForPopUp = `Job Id '${displayId}' is unassigned`;
    }

    toggleLoader();
    toggleDialogLoader();
    try {
     const updateJobRes = await axios.post(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/assign`,
        {
          ...updateObj
        }
      );
      toggleLoader();
      toggleDialogLoader();

      if(updateJobRes.data.status === "Success"){

        const updatedJob = updateJobRes.data.job;

        const newUpdatedJob = {
          ...jobItemObj,
          ...updatedJob
        }
        this.setState({
          jobItemObj: newUpdatedJob,
          open: false,
          jobItemIndex: "",
          openAssignJobMenu: false
        })

        this.updateLists();
        this.props.setMsgAndShow(msgForPopUp);
      }

    } catch (error) {
      toggleLoader();
      toggleDialogLoader();
      this.props.setMsgAndShow(error.response.data.message);
    }
  };

  setBtn = (nodes, jobIndex, i, itemObj) => async () => {
    const { toggleLoader } = this.props;
    toggleLoader();
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}employee/get_employee_data_by_job`,
        {
          params: {
            contractorId: this.props.contractorInfo.businessDetails._id,
            time: itemObj.time,
            date: moment(itemObj.date).format("YYYY-MM-DD"),
            jobId: itemObj._id
          }
        }
      );
      toggleLoader();

      const { employeeList } = data;

      this.setState({
        employeeList,
        open: true,
        btnRef: nodes[i],
        jobItemIndex: jobIndex,
        jobItemObj: itemObj
      });
    } catch (error) {
      toggleLoader();
      const { response } = error;

      this.setState({
        open: true,
        btnRef: nodes[i],
        jobItemIndex: jobIndex
      });
      this.props.setMsgAndShow(response ? response.data.msg : "Error");
    }
  };
  openAssignMenu =  async (item, index, itemRef) => {

    const { toggleLoader } = this.props;
    toggleLoader();
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}employee/get_employee_data_by_job`,
        {
          params: {
            contractorId: this.props.contractorInfo.businessDetails._id,
            time: item.time,
            date: moment(item.date).format("YYYY-MM-DD"),
            jobId: item._id
          }
        }
      );

      toggleLoader()

      const { employeeList } = data;

      this.setState({
        employeeList,
        open:true,
        btnRef: itemRef,
        jobItemObj: item
      });
    } catch (error) {
      toggleLoader();
      const { response } = error;

      this.setState({
        open: true,
        btnRef: itemRef,
        // jobItemIndex: jobIndex
      });
      this.props.setMsgAndShow(response ? response.data.msg : "Error");
    }
  }


  openInnerAssignMenuInModal =  async (item, index, itemRef) => {
    const { toggleDialogLoader } = this.props;
    toggleDialogLoader();
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}employee/get_employee_data_by_job`,
        {
          params: {
            contractorId: this.props.contractorInfo.businessDetails._id,
            time: item.time,
            date: moment(item.date).format("YYYY-MM-DD"),
            jobId: item._id
          }
        }
      );

      toggleDialogLoader();

      const { employeeList } = data;

      this.setState({
        employeeList,
        openAssignJobMenu:true,
        btnRef: itemRef,
        // jobItemIndex: jobIndex,
        jobItemObj: item
      });
    } catch (error) {
      toggleDialogLoader();
      const { response } = error;

      this.setState({
        open: true,
        btnRef: itemRef,
        // jobItemIndex: jobIndex
      });
      this.props.setMsgAndShow(response ? response.data.msg : "Error");
    }
  }

  getJobDetailsOnItem = jobItemObj => async () => {

    const { trackLinkEvent_inBlock } = this.props;
    trackLinkEvent_inBlock("Job_Description_Card",window.location.pathname);
    
    const { show } = this.state;
    let doNotShowJobBtns = false;
    if (show === "acceptedJobs" || show === "todaysJobs") {
      doNotShowJobBtns = true;
    }

    this.setState({
      isJobDetailsModalOpen: true,
      doNotShowJobBtns,
      jobItemObj
    });
  };

  getJobDetails = jobItemObj => {
    const { show } = this.state;
    // let doNotShowJobBtns = false;
    if (show === "acceptedJobs" || show === "todaysJobs") {
      // doNotShowJobBtns = true;
    }

    this.setState({
      isJobDetailsModalOpen: true,
      jobItemObj
    });
  };

  handleJobDetailsModal = () => {
    this.setState({
      isJobDetailsModalOpen: false,
      jobItemObj: {}
    });
    this.props.setItem(null, null)
  };

  isAssigned = itemObj => {
    if(itemObj.assignedEmployeeIds){
      return  itemObj.assignedEmployeeIds.length > 0;
    } else if(itemObj.oldAssignedEmployeeIds) {
      return itemObj.oldAssignedEmployeeIds.length > 0
    }
  };

  
  jobCompleted = async (jobItem) => {
    // const { show } = this.state;
    const { toggleLoader } = this.props;
    // let { acceptedJobs, todaysJobs, allJobs, unassignedJobs } = this.props.jobsObj;

    toggleLoader();
    try {
      const resData = await axios.post(
        `${process.env.REACT_APP_API_PREFIX}contractor/orders/complete/${
          jobItem._id
        }`
      );
      toggleLoader();

      if (resData.data.status === "OK") {
        this.updateLists()
        // const { job } = resData.data;
        // const newUpdatedJob = {
        //   ...jobItem,
        //   ...job
        // }
        // if (show === "acceptedJobs") {
        //   acceptedJobs = acceptedJobs.map(acJ => acJ._id === job._id ? newUpdatedJob : acJ);
        //   this.props.setJobs(acceptedJobs, "acceptedJobs");
        // } else if(show === "allJobs") {
        //     allJobs = allJobs.map((j) => j._id === job._id ? newUpdatedJob : j );
        //     this.props.setJobs(allJobs,"allJobs");
        // } else if(show === "notAssignedJobs"){
        //   unassignedJobs = unassignedJobs.map((j) => j._id === job._id ? newUpdatedJob : j );
        //   this.props.setJobs(unassignedJobs,"unassignedJobs");
        // } else if (show === "todaysJobs") {
        //   todaysJobs = todaysJobs.map(tJ => (tJ._id === job._id ? newUpdatedJob : tJ));
        //   this.props.setJobs(todaysJobs, "todaysJobs");
        // }
        // this.setState({
        //   jobItemObj: newUpdatedJob,
        //   load: false
        // });
        this.handleJobDetailsModal();
        this.props.setMsgAndShow(`Job Id ${jobItem.displayId} is completed`);
      } else {
        this.props.setMsgAndShow(resData.data.message);
      }
    } catch (error) {
      toggleLoader();
      const { response } = error;
      this.handleCompleteCallFailure(error);
      this.props.setMsgAndShow(response ? response.data.message : "Error");
    }
  };



  deleteJob = async (jobItem) => {
    let {jobItemObj } = this.state;
    const { toggleLoader } = this.props;
    const jobUpdateObj = {
      orderId: jobItem._id,
      data: {
        deletedByContractor: true,
        isActive: false
      }
    };

    toggleLoader();

    try {
      await axios.put(
        `${process.env.REACT_APP_API_PREFIX}cleaning_order/update`,
        { ...jobUpdateObj }
      );

      toggleLoader();

      jobItemObj = {
        ...jobItemObj,
        deletedByContractor: true,
        isActive: false
      };

      this.setState({
        jobItemObj
      });
      this.handleJobDetailsModal();
      this.updateLists();
      this.props.setMsgAndShow(`Job Id ${jobItem.displayId} is deleted`);
    } catch (error) {
      toggleLoader();
      const { response } = error;
      this.props.setMsgAndShow(response ? response.data.msg : "Error");
    }
  };

  disableDeleteButton = (itemObj) => {
    if(!itemObj.createdByContractor){
      return true
    } else {
      return false
    }
  } 
  openNewUserform = () => this.setState({ addNewCustomer: !this.state.addNewCustomer });

  cancelJob = async (jobItem) =>{
    // const { show } = this.state;
    const { toggleLoader } = this.props;
    // let { 
    //   acceptedJobs, todaysJobs, allJobs, 
    //   // unassignedJobs
    // } = this.props.jobsObj;

    toggleLoader();
    try {
      const resData = await axios.post(`${process.env.REACT_APP_API_PREFIX}contractor/orders/cancel/${jobItem._id}`);

      if(resData.data.status === "OK"){
        const { job } = resData.data;
        this.updateLists();
        toggleLoader();
        this.setState({
          jobItemObj: job
        })
        this.props.setMsgAndShow(`Job Id ${jobItem.displayId} is cancelled`);
      } else {
        this.props.setMsgAndShow(resData.data.message);
      }
    } catch (error) {
      toggleLoader();
      const { response  } = error;
      this.props.setMsgAndShow(response ?  response.data.message : "Error");
    }
  }

  assignEmployeeBtnRef = null;

  addRef = (ref) => this.assignEmployeeBtnRef = ref;

  openAssignMenuFromModal = (jobItem) => () =>{
      this.openAssignMenu(jobItem,undefined,this.assignEmployeeBtnRef);
  }
  openInnerAssignMenu = (jobItem) => () =>{
    this.openInnerAssignMenuInModal(jobItem,undefined,this.assignEmployeeBtnRef);
  }

  trackBlurInput = (e)=>{
    const matchValue = e.target.value;
    const { trackInputOnBlur } = this.props;
    if(matchValue){
      trackInputOnBlur("Job_Search",matchValue,window.location.pathname);
    }
  }

  handleJobSearch = (e) => {
    this.setState({
      q:e.target.value
    }, () => {
      this.lazySearch();
    })
    // this.lazySearch(e.target.value);
  }

  clearSearch = () => {
    this.setState({
      q:""
    }, () => {
      this.getListByPage(1);
    })
  }

  viewChanges = (obj) =>  this.props.openChangesModal(obj);

  submitQuote= async(quote,jobId,displayId)=> {  
    const { removeJob, setMsgAndShow, toggleLoader } = this.props;
    toggleLoader();
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_PREFIX}cleaning_order/add_price_quote`,{ quote, jobId });
      toggleLoader();
      if(res && res.data && res.data.status === "OK"){
        removeJob(jobId, "newJobs");
        setMsgAndShow(`Quote for Job ID : ${displayId} was submitted. You will be notified if your quote is accepted by the customer.`);
      }
    } catch (error) {
      toggleLoader();
      setMsgAndShow(`Error while submitting price quote.`);
    }
  } 

  componentWillUnmount(){
    this.props.resetForm("OrderForm");
  }

  getJobActions = (jobType, orderCost)=>{
    const commonActions = [
      {
        label: "Ignore",
        onClick: this.rejectJob,
        icon: "ignore",
        isIcon: true,
        btnClass:"reject",
        tooltip: "Reject job"
      },
      {
        label:"Info",
        icon: "details",
        isIcon: true,
        btnClass:"details",
        tooltip: "View details"
      }
    ];

    if(jobType === "domestic" || orderCost){
      commonActions.unshift({ 
        label: "Accept",
        onClick: this.acceptJob,
        icon: "tick1",  
        isIcon: true,
        btnClass:"accept",
        tooltip: "Accept Job"
      });
    }
    
    return commonActions;
  }


  getOTPForOrder = (job) => {
    this.setState({
      jobItemObj:job,
      isOTPModalOpen:true
    })
  }

  closeOTPModal = () => {
    this.setState({
      jobItemObj:{},
      isOTPModalOpen:false
    })
  }



  checkOTPRequirementAndComplete = (job) => {
    const { isOTPRequired } = job;

    if(isOTPRequired){
      this.getOTPForOrder(job);
    } else {
      this.jobCompleted(job);
    }
  }


  handleCompleteCallFailure = (errorRes) => {
    const { trackApiEvent } = this.props;
    let errorMsg = "Error occurred while marking job complete!";
    const { response } = errorRes;
    if(response && response.data && response.data.message){
      errorMsg = response.data.message;
    }
    trackApiEvent("Complete_Job", "POST", `${process.env.REACT_APP_API_PREFIX}contractor/orders/complete/`, errorMsg, window.location.pathname);
  }

  getListUrlAndParams = (listName) => {
    let url, params = {
      contractorId:this.getContractorId()
    };
    switch(listName){
      case "allJobs":
        url = "contractor/get_all_jobs";
        break;
      case "todaysJobs":
        url = "contractor/get_orders";
        params = {
          ...params,
          date: moment().format("YYYY-MM-DD"),
        }
        break;
      case "notAssignedJobs":
        url = "contractor/get_unassigned_jobs";
        break;
      default:
        break;
    }


    return {
      url,
      params
    }
  }


  getListOrders = async (url, params) => {
    try {
      const res = await axios.get(url,{params});
      return res.data;
    } catch (error) {
      throw error;
    }
  }
  setOrdersInList = (ordersRes) => {
    const { total, orders, page, perPage } = ordersRes;
    this.setState({
      orders,
      total,
      currPage:page,
      page: page - 1,
      perPage,
      gettingOrders:false
    })
  }

  createListUrlAndParamsToSend = (pageNumber, limit) => {
      let {q, perPage, show, currPage } = this.state;
      q = q && q.trim();
      q = q ? q.toLowerCase() : undefined;
      const { url, params } = this.getListUrlAndParams(show);
      const paramsToSend = {
        ...params,
        limit: limit ? limit : perPage,
        page:pageNumber ? pageNumber :currPage,
        q
      }

      return {
        url,
        params:paramsToSend
      }
  }


  getListByPageAndLimit = async (pageNumber,newLimit, isLoaderEnabled) => {
    try {
      if(!isLoaderEnabled){
        this.setState({
          gettingOrders:true
        })
      }
      const { url, params } = this.createListUrlAndParamsToSend(pageNumber, newLimit);
      const res = await this.getListOrders(`${process.env.REACT_APP_API_PREFIX}${url}`,params);
      this.setOrdersInList(res);
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";
      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        gettingOrders:false
      })
      this.props.setMsgAndShow(errMsg);
    }
  }

  getListByPage = async (pageNumber, isLoaderActive) => {
    try {

      if(!isLoaderActive){
        this.setState({
          gettingOrders:true
        })
      }
      const { url, params } = this.createListUrlAndParamsToSend(pageNumber);
      const res = await this.getListOrders(`${process.env.REACT_APP_API_PREFIX}${url}`,params);
      this.setOrdersInList(res);
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";
      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        gettingOrders:false
      })
      this.props.setMsgAndShow(errMsg);
    }
  }

  getListByLimit = async (limit) => {
    try {
      this.setState({
        gettingOrders:true
      })

      const { currPage } = this.state;
  
      const { url, params } = this.createListUrlAndParamsToSend(undefined,limit);
      const res = await this.getListOrders(`${process.env.REACT_APP_API_PREFIX}${url}`,params);

      const { total } = res;
      const totalPages = getTotalPagesCount(total,limit);
      if(currPage > totalPages){
        this.getListByPageAndLimit(totalPages, limit,true);
      } else {
        this.setOrdersInList(res);
      }
   
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get list!";
      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.setState({
        gettingOrders:false
      })
      this.props.setMsgAndShow(errMsg);
    }
  }



  getList = async () => {
    try {
      this.setState({
        gettingOrders:true
      })
      const { currPage } = this.state;
      const { url, params } = this.createListUrlAndParamsToSend();
      const res = await this.getListOrders(`${process.env.REACT_APP_API_PREFIX}${url}`,params);
      const { total, perPage } = res;
      const totalPages = getTotalPagesCount(total, perPage);
      if(currPage > totalPages){
        this.getListByPage(totalPages, true);
      } else {
        this.setOrdersInList(res);
      }
    } catch (error) {

      const { response } = error;
      let errMsg = "Unable to get list!";
      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }

      this.setState({
        gettingOrders:false
      })
      this.props.setMsgAndShow(errMsg);
    }
  }
  handleOnPageChange = (_, page) => {
    this.getListByPage(page + 1);
  }

  handlePageLimitChange = (limit) => {
    this.getListByLimit(limit);
  }


  searchCustomers = async (q) => {
    try {
      let customerOptions = [];
      const contractorId = this.getContractorId();
      const customersRes = await axios.get(
        `${process.env.REACT_APP_API_PREFIX}contractor/get_customers`,
        {
          params: {
            contractorId,
            q
          }
        }
      );
      if (customersRes.data.status === "OK") {
        const { customers } = customersRes.data;

        customerOptions = customers.map(customer => {
          return {
            value: customer._id,
            label: `${customer.fname} ${customer.lname}`,
            fname: customer.fname,
            lname: customer.lname,
            email: customer.email,
            phone: customer.phone,
            serviceAddress: customer.serviceAddress
          };
        });
  
      }

      return customerOptions;

    
    } catch (error) {
      const { response } = error;
      let errMsg = "Unable to get customers!";
      if(response && response.data && response.data.message){
        errMsg = response.data.message;
      }
      this.props.setMsgAndShow(errMsg);
    }
  }


  acceptedJobActions = [
    {
      label: "Get Details",
      onClick: this.getJobDetails,
      isForJobModal: true
    },
    {
      label: "Job Compeleted",
      onClick: this.checkOTPRequirementAndComplete,
      checkForDisabled: true,
      propToCheck: "jobCompleted"
    },
    {
      label: "Yet To Assign",
      secondaryLabel: "Assigned",
      onClick: this.setBtn,
      isSecondary: this.isAssigned,
      checkForDisabled: true,
      propToCheck: "jobCompleted",
      isRef: true
    },
    {
      label: "Delete Job",
      onClick: this.deleteJob,
      checkForDisabled: true,
      disableButton: true,
      checkDisableButton: this.disableDeleteButton
    },
    {
      label: "Cancel Job",
      onClick: this.cancelJob,
    }
  ];

  actions = [
    {
      label: "Confirm",
      onClick: this.confirmAssign
    }
  ];

  todaysJobActions = [
    {
      label: "Get Details",
      onClick: this.getJobDetails,
      isForJobModal: true
    },
    {
      label: "Mark as Completed",
      onClick: this.checkOTPRequirementAndComplete,
      checkForDisabled: true,
      propToCheck: "jobCompleted"
    },
    {
      label: "Assign this Job",
      secondaryLabel: "Assigned",
      onClick: this.openAssignMenu,
      isSecondary: this.isAssigned,
      checkForDisabled: true,
      propToCheck: "jobCompleted",
      isRef: true
    },
    {
      label: "Delete this Job",
      onClick: this.deleteJob,
      checkForDisabled: true,
      disableButton: true,
      checkDisableButton: this.disableDeleteButton
    },
    {
      checkForDisabled: true,
      propToCheck: "jobCompleted",
      label: "Cancel Job",
      onClick: this.cancelJob,
    },
    {
      checkForRender:true,
      renderAction: (obj) => !isEmpty(obj.changes) && obj.changeReason,
      label:"View Changes",
      onClick: this.viewChanges
    }
  ];


  render() {

    return (
      <>
        <JobsDashboard
          getJobDetails={this.getJobDetailsOnItem}
          getJobActions={this.getJobActions}
          // acceptedJobOrders={this.state.orders}
          acceptedJobActions={this.acceptedJobActions}
          todaysJobActions={this.todaysJobActions}
          // todaysJobOrders={this.state.orders}
          handleModalClose={this.handleClose}
          changeList={this.changeList}
          openModal={this.openModal}
          handleSave={this.handleSave}
          increaseByOne={this.increaseByOne}
          decreaseByOne={this.decreaseByOne}     
          submitData={this.submitData}
          openNewUserform={this.openNewUserform}
          handleJobSearch={this.handleJobSearch}
          trackBlurInput={this.trackBlurInput}
          handleJoyrideCallback={this.handleJoyrideCallback}
          getHelpers={this.getHelpers}
          submitQuote={this.submitQuote}
          handleOnPageChange={this.handleOnPageChange}
          handlePageLimitChange={this.handlePageLimitChange}
          clearSearch={this.clearSearch}
          searchCustomers={this.searchCustomers}
          {...this.state}
          {...this.props}
        />
        <AssignJobMenu
          title="Employee List"
          handleMenuClose={this.handleMenuClose}
          errMsg="No Data Available"
          assignJob={this.assignJob}
          jobActions={this.actions}
          {...this.state}
        />

        <JobDetailsModal
          addRef={this.addRef}
          handleJobCompleted={this.jobCompleted}
          acceptJob={this.acceptJob}
          rejectJob={this.rejectJob}
          handleJobDetailsModal={this.handleJobDetailsModal}
          openAssignMenuFromModal={this.openInnerAssignMenu}
          handleMenuClose={this.handleAssignMenuClose}
          errMsg="No Data Available"
          assignJob={this.assignJob}
          jobActions={this.actions}
          {...this.state}
        />

        <AdditionalBookingsModal />

        <GetOTPModal 
          closeModal={this.closeOTPModal}
          open={this.state.isOTPModalOpen}
          url={`${process.env.REACT_APP_API_PREFIX}contractor/orders/`}
          action="complete"
          jobId={this.state.jobItemObj._id}
          onCallSuccess={this.updateLists}
        />
      </>
    );
  }
}




const mapStateToProps = state => {
  let formValues = {};

  if (state.form.OrderForm) {
    const { OrderForm } = state.form;
    if (OrderForm.values) {
      formValues = OrderForm.values;
    }
  }
  return {
    jobsObj: state.jobs,
    contractorInfo: state.contractor,
    formValues,
    item: state.app.item,
    initialValues: {
      bedrooms: 1,
      bathrooms: 1,
      type: "domestic"
    },
    refreshPage:state.app.refreshPage,
    isTourEnabled: state.app.isTourEnabled
  };
};

const mapDispatchToProps = dispatch => ({
  setJobs: (jobs, jobsType) => dispatch(setJobs(jobs, jobsType)),
  removeJob: (jobId, jobsType) => dispatch(removeJob(jobId, jobsType)),
  resetForm: name => dispatch(reset(name)),
  setMsgAndShow: msg => dispatch(setMsgAndShow(msg)),
  setItem: (item, itemType) => dispatch(setItem(item, itemType)),
  toggleLoader: () => dispatch(toggleLoader()),
  toggleDialogLoader: () => dispatch(toggleDialogLoader()),
  openChangesModal:(obj) => dispatch(openChangesModal(obj)),
  reset
});

const withReduxForm = reduxForm({
  form: "OrderForm",
  destroyOnUnmount: false
})(JobsDashboardContainer);

const connectRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(withReduxForm);

export default withMixpanel(connectRedux);
