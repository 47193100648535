import React from "react";
import { withStyles } from "core/components/Styles"
import cn from "classnames";


const styles = {
  hideButton: {
    position: "absolute",
    opacity:0,
    cursor:"pointer"
  },
  inputStyle:{
    border: "1px solid #4a4a4a",
    padding: "1.2rem 1rem",
    fontSize: "1.2rem",
    borderRadius: "0.6rem",
    background: "#fff",
    width: "100%",
    margin: "0.3rem 0",
    lineHeight: "normal",
    cursor:"pointer"
  },
  active:{
    background: "linear-gradient(135deg, #5d00b4 0%, #5d00b4 11%, #5d00b4 11%, #5d00b4 30%, #e570e7 100%)",
    color: "#fff"
  },
  errors:{
    color:"red",
    fontSize:"0.75rem"
  },
  inList:{
    display:"flex",
    flexDirection:"column"
  }
};

const RadioField = ({classes, id, input, label, radioValue, isSelected, options, meta, displayInList})=>{
  // let inputValue;
  // if(!isNaN(radioValue)) inputValue = JSON.stringify(radioValue);
  // inputValue = radioValue;

  return (
    <div className={displayInList && classes.inList }>
      {meta && meta.touched &&
        ((meta.error && <span className={classes.errors}>{meta.error}</span>) ||
          (meta.warning && <span>{meta.warning}</span>))}

      { options && options.map((o, i) => {
        return (
          <label htmlFor={id} 
            className={cn(classes.inputStyle, o.value === input.value ? classes.active : "", isSelected ? classes.active : "" )} 
            key={`radio-${input.name}-${i}`}
          >
            <input
              {...input}
              type="radio"
              className={cn("form-control", classes.hideButton)}
              id={id}
              value={o.value || ""}
            />
            {/* {label} */}
            {o.label}
          </label>
        )
      })}
  
    </div>
  );
};





export default withStyles(styles)(RadioField);