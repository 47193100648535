import React from 'react';
import cn from "classnames";

export const getInitials = (name) => {
  let initals = name ? name.trim() : '';
  if (initals) {
    const splitName = initals.split(" ");
    const splitedInitials = splitName.map(name => name !== 'undefined' ? name.toUpperCase().charAt(0) : '');
    initals = splitedInitials.join("");
  }
  return initals;
}

export default function Avatar(props) {
  const { className, image, name, gradient, disableBorder } = props;
  // <Image src={image} alt="" className="img-fluid" />
  return <div className={cn("avatar", { 'avatar-gradient-bg': gradient, 'avatar-no-border': disableBorder }, className)}>
    <div className={cn('avatar-holder')}>
      {image ? <div className={cn('avatar-image')} style={{backgroundImage: `url(${image})`}}></div> : <span className="avatar-text">{getInitials(name)}</span>}
    </div>
  </div>
};