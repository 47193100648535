import _ from "lodash";


const defaultState = {
  currentEmployee: null
}


const reducer = (state = defaultState, action) => {
  let finalState = {}
  switch(action.type){
    case "LOAD":
    finalState = _.cloneDeep(state);
    finalState.currentEmployee = action.payload
    break
    default:
    finalState = state
  }

  return finalState
}

export default reducer;