import React from "react";
import Dialog from "core/components/Dialog";
import DialogContent from "core/components/DialogContent";
// import Button from "core/components/Button";
import Grid from "core/components/Grid";
// import Typography from "core/components/Typography";
import IconButton from "core/components/IconButton";
import { Close } from "mdi-material-ui";
// import { withStyles } from "core/components/Styles";
// import AddBusinessDetails from "../../containers/AddBusinessDetailsContainer";
import AddBusinessDetails from "../../containers/AddDetailsSettingsContainer";



const AddDetailsModal = (props) => {
  const { showBusinessDetailsModal, onBusinessModelClose, ...restProps } = props;
  return (
    <Dialog
      fullWidth
      open={showBusinessDetailsModal}
      onClose={onBusinessModelClose}
      maxWidth="md"
    >
      <DialogContent>
        <Grid container alignItems="center" justify="flex-end">
          <Grid item>
            <IconButton onClick={onBusinessModelClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid>
          <AddBusinessDetails onBusinessModelClose={onBusinessModelClose} {...restProps} />
        </Grid>
      </DialogContent>
    </Dialog>
  )
}


export default AddDetailsModal;