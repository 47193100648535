function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    width: 10,
    height: 13.332,
    viewBox: "0 0 10 13.332"
  }, props), React.createElement("g", {
    id: "prefix__location",
    transform: "translate(-4)"
  }, React.createElement("path", {
    id: "prefix__Path_316",
    d: "M12.5 10.746a2.5 2.5 0 1 0-2.5-2.5 2.5 2.5 0 0 0 2.5 2.5zm0-4.583a2.083 2.083 0 1 1-2.083 2.083A2.085 2.085 0 0 1 12.5 6.163z",
    className: "prefix__cls-1",
    "data-name": "Path 316",
    transform: "translate(-3.501 -3.352)",
    style: {
      "fill": "#00ae87",
      "fillRule": "evenodd"
    }
  }), React.createElement("path", {
    id: "prefix__Path_317",
    d: "M9 0a4.987 4.987 0 0 0-5 5c0 2.916 2.5 5.9 4.323 8a1.018 1.018 0 0 0 .661.335h.032A1.019 1.019 0 0 0 9.677 13C11.5 10.9 14 7.917 14 5a4.988 4.988 0 0 0-5-5zm.049 12.45a.506.506 0 0 1-.056.041.416.416 0 0 0-.054-.055C7.411 10.679 4.833 7.714 4.833 5a4.167 4.167 0 1 1 8.333 0c.001 2.714-2.577 5.679-4.118 7.45z",
    className: "prefix__cls-1",
    "data-name": "Path 317",
    style: {
      "fill": "#00ae87",
      "fillRule": "evenodd"
    }
  })));
};

export default __webpack_public_path__ + "static/media/job_location.0b632ffb.svg";
export { SvgComponent as ReactComponent };