export default [
  {
    name: "Order ID",
    label: "jobId"
  },
  {
    name: "Order Type",
    label: "jobType",
  },
  {
    name: "Cleaning Date",
    label: "jobDate",
    isTimeStamp: true,
    formatRequired:"DD MMM YYYY"
  },
  {
    name:"Cleaning Address",
    isAddress: true,
    streetGetter:"address",
    cityGetter:"city",
    stateGetter:"state"
  },
  {
    name:"Zipcode",
    label:"zipcode"
  },
  {
    name:"Order Cost",
    label:"jobCost"
  },
  {
    name: "Zoklean Fee",
    label: "zokleanFee"
  },
  {
    name: "Contractor Share",
    label: "contractorShare"
  },
]