import React from "react";
import Grid from "core/components/Grid";
import Typography from "core/components/Typography";
import List from "core/components/List";
import ListItem from "core/components/ListItem";
import ListItemText from "core/components/ListItemText";
import { withStyles } from "core/components/Styles";

const styles = theme => ({
  listItem: {
    padding: 0,
    color: "#4a4a4a",
    marginBottom: 16,
  },
  time: {
    fontSize: 12,
    color: "#cdcecf",
    lineHeight: 1,
    display: "flex",
    "& .dot": {
      width: 15,
      height: 15,
      display: 'inline-block',
      borderRadius: "100%",
      background: "#49d3b9",
      marginRight: 8
    }
  },
  noJobText:{
    textAlign:"center"
  }
})

const TodaysJobsList = ({
  todaysJobs,
  classes
}) => {
  return (
    <Grid>
    {
      todaysJobs && todaysJobs.length > 0 ?
      <List>
      {todaysJobs.map((job, key)=> {
        return (
          <ListItem key={`agenda-list-${key}`} className={classes.listItem}>
            <ListItemText>
              <div className={classes.time}> <span className="dot"></span> {job.startTime} --- {job.endTime}</div>
               <Typography> {job.cleaningType} </Typography>
            </ListItemText>
          </ListItem>
        )
      })}
     </List>
     : 
     <Typography className={classes.noJobText}>
       No Jobs
     </Typography>
    }

    </Grid>
  )
}

export default withStyles(styles)(TodaysJobsList);