import React from "react";
// import withStyles from "react-jss";
import cn from "classnames";
import { withStyles } from "core/components/Styles";
import Grid from "core/components/Grid";
import Icon from "core/components/Icon";
// import selected from "../../assets/images/svg/selected.svg"
import tick from "../../assets/images/tick.png";
const styles = () => ({
  optionsCont:{
    display:"flex",
    paddingTop :26,
    maxWidth: 340,
    width: "100%"
  },
  inputStyle:{
    margin:10,
    width : "auto",
    display : "flex",
    alignItems : 'center'
  },
  titleOfBtn :{
    fontWeight : 500,
    fontSize : 15,
    color: "rgba(36, 42, 55, 0.5)"
  },
  radioBtn:{
    margin:0,
    height : 18,
    minHeight : 18,
    width : 18,
    minWidth : 18,
    marginRight: 11,
    border :"1px solid #AEAEAE",
    borderRadius: "50%",
    outline : "none",
    "-webkit-appearance":"none",
    "&::before":{
      content : `url(${tick})`,
      display:"flex",
      justifyContent:"center",
      alignItems :"center",
      height:"100%",
      opacity :0
    },
    "&:checked":{
      background: "#4FE2C2",
      border: 0,
      "&::before":{
        opacity:1
      },
      "& ~ span" : {
        color: "#000000"
      }
    }
  },
  defaultRadioBtn:{
    height : 28,
    width : 34,
    border :"1px solid #D8D8D8",
    background : "#fff"
  },
  error: {
    color: "red",
    fontSize: 12,
  },
  warning: {
    color: "orange",
    fontSize: 12
  },
  widthAuto: {
    width: "auto"
  },

  iconStyle:{
		fontSize: 20,
  },

  setBackground:{
    color:"inherit"
  }

})
const RadioGroupField = ({ input = {}, meta = {}, options, align="center", justify="center", width, classes, inRow }) => {
  const hasError = meta && meta.touched && meta.error;
  return (
    <Grid
      container 
      alignItems={align}
      alignContent="space-between" 
      justify={justify}
      className={cn({
        [classes.optionsCont]: inRow, 
        [classes.widthAuto]: width === "auto"
      })}
    >
      {options.map((o,i) => (
        <label 
          className={cn(
            classes.inputStyle,{
              [classes.setBackground]: o.value === input.value  
            })}
          key={`radio-options-item-${i}`}
        >
          <input
            type="radio"
            {...input} 
            value={o.value}
            checked={o.value === input.value}
            className={cn("form-control", classes.radioBtn)}
          />{" "}

          {
            o.icon && <Icon lineHeight="inherit" className={classes.iconStyle}>{o.icon}</Icon> 
          }

          <span className={classes.titleOfBtn}>{o.title}</span>
        </label>
      ))}
      {hasError && (
        <span
         className={classes.error}
        >
          {meta.error}
        </span>
      )}
    </Grid>
  );
};


export default withStyles(styles)(RadioGroupField)