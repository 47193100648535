import React from "react";
import { 
    Grid, 
    List, 
    ListItem, 
    ListItemText,
    Typography 
} from "core/components";
import { withStyles } from "core/components/Styles";
import Avatar from "core/components/Avatar";
import employeePlaceholder from "../../images/utils/employee-placeholder.png";
import cn from "classnames";

const styles = {
  activeEmployeeListWrapper:{
    position:"absolute",
    background:"#fff",
    height:"50vh",
    width:280,
    boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.127868)",
    borderRadius: 15,
    top:24,
    right:20,
    overflowY:"auto"
  },
  employeeListLabel:{
    textAlign:"center",
    padding:20,
    color: "#4A4A4A"
  },
  employeeItem:{
  },
  employeeName:{
    fontSize:16,
    fontWeight:500,
    "& span":{
      fontSize:"inherit",
      fontWeight:"inherit",
    }
  },
  employeeProfile:{
    height:40,
    width:40,
  },
  selectedEmployee:{
    border:"2px solid #47CAB6"
  }
}

const ActiveEmployeeList = (props) => {
  const { classes,employeeList, onSelect, currEmpId } = props;
  return (
    <Grid className={classes.activeEmployeeListWrapper}>
      <Typography className={classes.employeeListLabel} variant="title">
        Active Employee List
      </Typography>
      <List>
        {
          employeeList && employeeList.map((e,index) => {
            const { fname, lname, profileAvator } = e;
            const profileImgLink = profileAvator ? `${process.env.REACT_APP_USER_FILES}${profileAvator}`
            : employeePlaceholder;
            const isSelected = e.userId === currEmpId;
            return (
              e.location &&
              <ListItem 
                key={`employee-item-${index}`}
                button  
                divider
                className={cn(classes.employeeItem,isSelected? classes.selectedEmployee : "")}
                onClick={onSelect(e)}
              >

                <Avatar
                  className={cn(classes.employeeProfile,"profile-btn-avatar img-fluid mr-2")}
                  image={profileImgLink}
                  name={`${fname} ${lname}`}
                  disableBorder
                />
                <ListItemText className={classes.employeeName}>
                  {e.fullName}
                </ListItemText>
              </ListItem>
            )
          })
        }
      </List>
    </Grid>
  )
}


export default withStyles(styles)(ActiveEmployeeList);