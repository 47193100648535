function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';

var SvgComponent = function SvgComponent(props) {
  return React.createElement("svg", _extends({
    id: "prefix___45-Area",
    width: 16.4,
    height: 16.4,
    "data-name": "45-Area",
    viewBox: "0 0 16.4 16.4"
  }, props), React.createElement("path", {
    id: "prefix__Path_279",
    d: "M15.717 3.417a.683.683 0 0 0 .683-.683V.683A.683.683 0 0 0 15.717 0h-2.05a.683.683 0 0 0-.683.683v.683H3.417V.683A.683.683 0 0 0 2.733 0H.683A.683.683 0 0 0 0 .683v2.05a.683.683 0 0 0 .683.683h.683v9.567H.683a.683.683 0 0 0-.683.684v2.05a.683.683 0 0 0 .683.683h2.05a.683.683 0 0 0 .683-.683v-.683h9.567v.683a.683.683 0 0 0 .683.683h2.05a.683.683 0 0 0 .683-.683v-2.05a.683.683 0 0 0-.683-.683h-.683V3.417zM.683 2.733V.683h2.05v2.05zm2.05 12.983H.683v-2.05h2.05zm10.25-2.05v.683H3.417v-.683a.683.683 0 0 0-.683-.683H2.05V3.417h.683a.683.683 0 0 0 .683-.683V2.05h9.567v.683a.683.683 0 0 0 .683.683h.683v9.567h-.683a.683.683 0 0 0-.683.684zm2.733 0v2.05h-2.05v-2.05zM13.667 2.733V.683h2.05v2.05z",
    "data-name": "Path 279",
    style: {
      "fill": "#00949c"
    }
  }));
};

export default __webpack_public_path__ + "static/media/job_property_area.e20dcbf0.svg";
export { SvgComponent as ReactComponent };