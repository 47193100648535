import React, { Component } from 'react'
import Plan from "../components/Plan";

import { connect } from "react-redux";
import { STATUS } from "react-joyride";
import Storage from "../utils/Storage";

import { withMixpanel } from "../utils/react-mixpanel";
export class PlanContainer extends Component {

  state={
    runGuide: false
  };

  componentDidMount(){
    const { isTourEnabled, trackPageLoad } = this.props;
    if(Storage.get("paymentPlanGuide") !== "completed" && isTourEnabled){
      this.setState({
        runGuide: true
      });
    };
    trackPageLoad("Plan_Settings");
  }

  getHelpers = (StoreHelpers) => {
    this.helpers = StoreHelpers;
  };

  handleJoyrideCallback = (CallBackProps) => {
    
    const { status } = CallBackProps;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      Storage.set("paymentPlanGuide","completed");
      this.setState({ runGuide: false });
    }
  };

  render() {
    return (
      <Plan
        getHelpers={this.getHelpers}
        handleJoyrideCallback={this.handleJoyrideCallback}
        {...this.state}
      />
    )
  }
}

const mapStateToProps = (state)=> ({
  isTourEnabled: state.app.isTourEnabled
});

const connectRedux =  connect(mapStateToProps)(PlanContainer);

export default withMixpanel(connectRedux);

