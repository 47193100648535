import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "utils/http";
import _ from "lodash";
import { connect } from "react-redux";
import socket, { createRoom } from "socket"
import { setDetails, setUserInfo, setOrderHistory } from "../store/actions/contractor";
import { setOrdersWithChanges } from "../store/actions/app"
import Storage from "../utils/Storage";
import Login from "../components/Login";
import { setMsgAndShow } from "../store/actions/popup";
import { toggleBusinessModal, showAlert, showTourGuide } from "../store/actions/app";
import deviceInfo from "utils/detectDevice";
import allowedUserTypes from "../variables/allowedUserTypes";

class LoginContainer extends Component {
  state = {
    user: {},
  }

  handleChange = (prop) => (e) => {
    const user = _.cloneDeep(this.state.user);

    user[prop] = e.target.value;
    
    this.setState({
      user
    })
  }

  handleLogin = async ()=> {
    const { user } = this.state;
    if(_.isEmpty(user)){
      return alert("Please enter your credentials");
    }


    user.deviceInfo = deviceInfo;
    try {
      let openModal = true;
      let showAddressAlert = true;
      const { data } = await axios.post("user/login",{...user});


      let { userInfo, businessDetails, orderHistory, authToken, loginActivitiesCount, ordersWithChanges, isBusinessAddressExists } = data;

      if(allowedUserTypes.indexOf(userInfo.userType) === -1){
        this.props.setMsgAndShow("UNAUTHORIZED")
        return this.props.history.replace('/login');
      }
  
      Storage.set('userInfo',JSON.stringify(userInfo))
      Storage.set('auth_token', authToken);
      Cookies.set("authToken", authToken,{path:"/", domain:process.env.REACT_APP_HOME_SITE})
      if(!_.isEmpty(businessDetails)){
        this.props.setDetails(userInfo, businessDetails, orderHistory);
        this.props.setOrdersWithChanges(ordersWithChanges);
        Storage.set("contractorInfo",JSON.stringify({
          userInfo,
          businessDetails,
          orderHistory
        }))
        createRoom(businessDetails._id)
        socket.on('roomcreated', data => {
          console.info('room created', data, businessDetails._id); // eslint-disable-line
        })

        if(isBusinessAddressExists){
          showAddressAlert = false
        }

        if(businessDetails.serviceArea && businessDetails.serviceArea.radius){
          openModal = false;
        }
      } else {
        this.props.setUserInfo(userInfo);
        this.props.setOrderHistory(orderHistory);

      }


      if(showAddressAlert){
        this.props.showAlert("Please complete your company profile in settings");
      }
      

      if(loginActivitiesCount <= 1 && openModal){
        this.props.toggleBusinessModal();
        this.props.showTourGuide(true); 
      }

      this.props.history.replace("/dashboard");
      
    } catch (error) {

      const { response } = error; 
      this.props.setMsgAndShow(response ? response.data.message : "Error")
    }
  }

  render(){
    return (
      <Login 
        handleChange={this.handleChange}
        handleLogin={this.handleLogin}
        {...this.state}
      />
    )
  }
}


const mapDispatchToProps = (dispatch) => ({
  setDetails: (userInfo, businessDetails, orderHistory)=> dispatch(setDetails(userInfo, businessDetails, orderHistory)),
  setUserInfo: (userInfo)=> dispatch(setUserInfo(userInfo)),
  setOrderHistory: (orderHistory)=> dispatch(setOrderHistory(orderHistory)),
  setMsgAndShow: (msg)=> dispatch(setMsgAndShow(msg)),
  toggleBusinessModal: ()=> dispatch(toggleBusinessModal()),
  setOrdersWithChanges: (orders)=> dispatch(setOrdersWithChanges(orders)),
  showAlert:  (message)=> dispatch(showAlert(message)),


  showTourGuide:  (flag)=> dispatch(showTourGuide(flag))
})

export default connect(null, mapDispatchToProps)(LoginContainer);