import React, { Component } from 'react';
import Settings from "../components/Settings";

import { connect } from "react-redux";
import { STATUS } from "react-joyride";
import Storage from "../utils/Storage";
class SettingsContainer extends Component {
  state = {
    isSettingsbarOpen: false,

    runGuide: false,
  }

  componentDidMount(){
    const { isTourEnabled} = this.props;
    if(Storage.get("companyProfileGuide") !== "completed" && isTourEnabled){
      this.setState({
        runGuide: true
      });
    }
  }

  getHelpers = (StoreHelpers) => {
    this.helpers = StoreHelpers;
  };

  handleJoyrideCallback = (CallBackProps) => {
    
    const { status } = CallBackProps;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      Storage.set("companyProfileGuide","completed");
      this.setState({ runGuide: false });
    }
   
  };

  toggleSidebar = (isVisible) => e => {
    if(isVisible !== false && isVisible !== true) {
      this.setState({isSettingsbarOpen: !this.state.isSettingsbarOpen});
    } else {
      this.setState({isSettingsbarOpen: isVisible});
    }
  }
  render(){
    return (
      <Settings 
        {...this.state} 
        toggleSidebar={this.toggleSidebar}
        getHelpers={this.getHelpers}
        handleJoyrideCallback={this.handleJoyrideCallback}
      />
    )
  }
}

const mapStateToProps = (state)=> ({
  isTourEnabled: state.app.isTourEnabled
});

export default connect(mapStateToProps)(SettingsContainer);
