import React, { Component } from "react";
import Grid from "core/components/Grid";
import Drawer from "core/components/Drawer";
import Button from "core/components/Button";
import { withStyles } from 'core/components/Styles';
import List from 'core/components/List';
import ListItem from 'core/components/ListItem';
import { NavLink } from "react-router-dom";
import {drawerWidth, appBar} from "theme/vars"
import Avatar from "core/components/Avatar";
import { Telegram, Pencil } from "mdi-material-ui";
import { IconButton, Typography } from "core/components";
import Icon from "core/components/Icon";

import cn from "classnames";
import dd from 'utils/detectDevice';

import { withMixpanel } from "../../utils/react-mixpanel";

const styles = theme => ({
  drawerPaper: {
    height:"100%",
    paddingTop: appBar,
    position: 'relative',
    width: drawerWidth,
    backgroundColor:"#ffffff",
    boxShadow:"-8px 0 42px 0 rgba(42, 34, 64, 0.14)",
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0 
    },
    zIndex:0
  },
  sideDrawer:{
    top: 92,
    display:"flex",
    flexDirection: 'column',
    justifyContent:"space-between",
    width: drawerWidth,
    position: "fixed",
    height: "calc(100vh - 100px)",
    overflowY: "auto",
    [theme.breakpoints.down('sm')]: {
      position: "relative",
      top: 0,
      height: "100vh",
    }
    
  },
  navLink: {
    textDecoration: "none",
    fontFamily:"Montserrat",
    fontSize: 12,
    textTransform:"uppercase",
    color:"#663DBD",
    "&.active": {
      color: "#50e3c2",
      fontWeight: 'bold',
    }
  },
  // navIcon:{
  //   marginRight: 24,
  //   marginLeft : 30
  // },
  bellCont:{
    height: 30,
    width: 30,
    boxShadow: "4px 7px 14px 0 rgba(59, 39, 95, 0.21)",
    background:"#fff",
    borderRadius: "50%",
    position:"absolute",
    bottom: 10,
    right: -10,
    "&:hover":{
      background:"#fff"
    }
  },
  editPhotoBtn:{
    height: 30,
    width: 30,
    boxShadow: "4px 7px 14px 0 rgba(59, 39, 95, 0.21)",
    background:"#fff",
    borderRadius: "50%",
    position:"absolute",
    bottom: 60,
    right: -10,
    "&:hover":{
      background:"#fff"
    }
  },
  bellIcon:{
    color: "#fd9b5b",
    fontSize: 14
  },
  pencilIcon:{
    color: "#fd9b5b",
    fontSize: 14
  },
  avator:{
    height : 120,
    width : 120,
    "& .avatar-text":{
      fontSize:"2.8rem"
    }
  },
  avatorAndBellCont:{
    position:"relative"
  },
  profileName:{
    fontFamily : "poppins",
    fontSize : 19,
    marginTop: 10,
    marginBottom: 10,
    color:" #383838"
  },
  inviteBtn:{
    display: 'flex',
    background : "#FFFFFF",
    height: 29,
    width: 85,
    borderRadius : 20,
    minHeight:"auto",
    minWidth:"auto",
    color: "rgba(77, 161, 255, 0.74)",
    border: "1px solid rgba(77, 161, 255, 0.74)",
    padding: "0 0",
    alignItems:"center",
    justifyContent:'center'
  },
  teleIcon:{
    fontSize: 14
  },
  inviteText:{
    fontSize: 11,
    fontHeight : 29,
    color: "rgba(77, 161, 255, 0.74)",
    marginLeft: 8
  },
  menuItem:{
    height : 63,
    width : "100%",
    display:"flex",
    padding: 0,
    alignItems: 'center',
    fontFamily : "Poppins",
    fontSize : 16,
    borderLeft : 0,
    color : "rgb(183, 183, 183)"
  },
  menuItemText : {
    //background : "-webkit-linear-gradient(93.31deg, #462EB42.88%, #B062D2 83.6%)",
    backgroundImage: "linear-gradient(93.31deg, #462EB4 2.88%, #B062D2 83.6%, #fff)",
    color: "transparent",
    "-webkit-background-clip" : "text"
  },
  activePath:{
    // color: "transparent",
    // "-webkit-background-clip": "text",
    // backgroundImage: "linear-gradient(93.31deg, #462EB4 2.88%, #B062D2 83.6%)",
    fontFamily : "Poppins",
    fontWeight : "bold",
    //borderLeft:"7px solid #663DBD",
    "&  span ":{
      color : "transparent",
      "-webkit-background-clip": "text",
      backgroundImage: "linear-gradient(93.31deg, #462EB4 2.88%, #B062D2 83.6%)",
      "&::before":{
        color : "transparent",
        "-webkit-background-clip": "text",
        backgroundImage: "linear-gradient(93.31deg, #462EB4 2.88%, #B062D2 83.6%)"
      }
    },
    "&  div":{
      background : "linear-gradient(207.09deg, #8A4FC7 12.5%, #482FB5 78.51%)"
    }
  },
  // activePathText:{
  //   background: "#462eb4",
  //   // color : "transparent",
  //   "-webkit-text-fill-color": "transparent",
  //   "-webkit-background-clip": "text",
  //   backgroundImage: "linear-gradient(207.09deg, #8A4FC7 12.5%, #482FB5 78.51%)"
  // },
  activePathText:{
    color : "transparent",
    "-webkit-background-clip": "text",
    backgroundImage: "linear-gradient(93.31deg, #462EB4 2.88%, #B062D2 83.6%)",
    "&::before":{
      color : "transparent",
      "-webkit-background-clip": "text",
      backgroundImage: "linear-gradient(93.31deg, #462EB4 2.88%, #B062D2 83.6%)"
    }
  },
  inActivePath:{
    color: "#a8b2b9" 
  },
  borderDiv :{
    height : 63,
    width : 10,
    background : "transparent"
  },
  activeBorderDiv: {
    backgroundImage: "linear-gradient(207.09deg, #8A4FC7 12.5%, #482FB5 78.51%)"
  },
  sideBarContainer :{
    padding : 0
  },
  copyrightText:{
    fontSize: 12,
    fontFamily:"Montserrat",
    color:"rgba(41, 37, 41, 0.23)"
  },
  logoutBtn:{
    fontSize: 12,
    marginTop:15,
    fontFamily:"Montserrat",
    color:"rgba(41, 37, 41, 0.23)",
    "&:hover":{
      color:"rgba(41, 37, 41, 0.5)"
    }
  },
  profileAvatarAndInfoContainer : {
    boxShadow: "2px -33px 33px rgba(0, 0, 0, 0.128223)",
    background : "#F4F8F9",
    height : 239
  },
  linkIcon:{
    paddingLeft:28,
    display: "flex",
    justifyContent: "center"
  },
  linkText:{
    paddingLeft: 24,
    justifyContent: "center"
  }
})

const routes = [
 {
   name:"Overview",
   link:"/dashboard",
   icon: "overview"
 },
 {
   name:"Calendar",
   link:"/calender",
   icon: "calendar"
 },
 {
   name:"Jobs",
   link:"/jobs",
   icon:"jobs"
 },
 {
  name:"Customer List",
  link:"/customers",
  icon:"customer"
},
{
  name:"GPS Tracking",
  link:"/track",
  icon:"customer_listgps"
},
 {
   name:"Employee List",
   link:"/employee",
   icon:"employee_list"
 },
//  {
//   name:"Earnings",
//   link:"/earnings"
//  },
 {
   name:"Invoice",
   link:"/invoices",
   icon:"px"
 },
 {
  name:"Settings",
  link:"/settings/company_details",
  icon:"settings"
  }
]

const isActive = (routeName) => window.location.pathname.indexOf(routeName) > -1 ? true : false

const SideBarLinks = ({classes, routes, handleEvent}) => (
  routes.map((route, key)=> (
      <NavLink
        to={route.link}
        key={`side-drawer-item-${key}`}
        className={classes.navLink}
        onClick = {handleEvent(route.name,route.link)}
      >
        <ListItem button component="div" id={route.icon} className={cn({
          [classes.menuItem]: true,
          // [classes.activePath]: isActive(route.link)
        })}>
          <div className={cn(classes.borderDiv, {
            [classes.activeBorderDiv]: isActive(route.link)
          })}/>
          {
            route && route.icon &&   
              <span 
                className={cn(classes.linkIcon,{
                  [classes.activePath]: isActive(route.link)
                })}
              >
                <Icon lineHeight="inherit" fontSize="1.2rem">
                  {route.icon}
                </Icon> 
              </span>
          }
           {/* {route.icon && <route.icon className={classes.navicon}/>} */}
          <span 
            className={cn(classes.linkText,{
              [classes.activePathText]: isActive(route.link)
          })}>
            {route.name}
            
          </span>
        </ListItem>
      </NavLink>
    )
  )
)

class SideDrawer extends Component {

  handleEvent =(name,link)=> ()=>{
    const { trackLinkEvent_inBlock } = this.props;
    trackLinkEvent_inBlock(name,link);
  }

  render() {
    const { 
      classes, 
      userInfo:{ fname, lname, profileImage}, 
      // handleLogout, 
      openImageUpload, 
      handleInviteModal, 
      isSidebarOpen, 
      toggleSidebar 
    } = this.props;

    let imageURI = null;

    if(profileImage){
      imageURI = `${process.env.REACT_APP_USER_FILES}${profileImage}`
    }
    return (
      <Drawer
        variant={dd.isPhone ? "temporary" : "permanent"}
        open={isSidebarOpen}
        classes={{
          paper: classes.drawerPaper
        }}
        anchor="left"
        className={classes.sideDrawerCont}
        id="sideDrawer"
        onClose={toggleSidebar(false)}
      >
        <Grid className={classes.sideDrawer}>
          <Grid item>
            <Grid container alignItems="center" justify="center" direction="column" id="avatar" className={classes.profileAvatarAndInfoContainer} >
              <Grid className={classes.avatorAndBellCont}>

                <Avatar
                  className={cn(classes.avator,"profile-btn-avatar img-fluid mr-2")}
                  image={imageURI}
                  name={`${fname} ${lname}`}
                  disableBorder
                />

                <IconButton onClick={openImageUpload} size="small" className={classes.editPhotoBtn}>
                  <Pencil className={classes.pencilIcon}/>
                </IconButton>
                {/*<IconButton  className={classes.bellCont}>
                  <Bell className={classes.bellIcon}/>
                </IconButton>*/}
              </Grid>
              <Typography className={classes.profileName}>
              {`${fname} ${lname}`}
              </Typography>
              <Button variant="outlined" color="primary" className={classes.inviteBtn} onClick={handleInviteModal}>
                <Telegram className={classes.teleIcon}/>
                <Typography className={classes.inviteText}>INVITE</Typography>
              </Button>
            </Grid>
            <List component="div" className={classes.sideBarContainer} tabIndex={0}>
              <SideBarLinks routes={routes} classes={classes} handleEvent={this.handleEvent} {...this.props}/>
            </List>
          </Grid>


         {/*
          <Grid item container alignItems="center" justify="center" direction="column">
            <Typography className={classes.copyrightText}>
              ®Zoklean, 2019
            </Typography>
            <Button className={classes.logoutBtn} onClick={handleLogout}>
              Logout
            </Button>
          </Grid>
         */}

        </Grid>
      </Drawer>
    );
  }
}

const injectStyles = withStyles(styles)(SideDrawer)

export default withMixpanel(injectStyles) ;