import React from "react";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import withStyles from "react-jss";
import "rc-slider/assets/index.css";

const Handle = Slider.Handle;

const styles = {
  sliderWrapper: {
    width: "99%"
  },
  slider: {
    "& .rc-slider-track" : {
      // transition: "0.15s width"
    },
    "& .rc-slider-handle" : {
      border: "none",
      height: 30,
      width: 30,
      background: "linear-gradient(180deg, #50E3C2 0%, #008DA7 100%)",
      boxShadow: "0px 11px 12px rgba(37, 92, 98, 0.31)",
      borderRadius: 30,
      top: -3
      // transition: "0.15s left"
    },
    "& .rc-slider-handle:active" : {
      borderColor: "#b952d7",
      boxShadow: "0 0 5px #b952d7"
    }
  },
  handler: {
    zIndex: 1050
  }
};


const SliderHandler = ({ classes, value, dragging, index, ...restProps }) => (
  <Tooltip
    prefixCls="rc-slider-tooltip"
    overlayClassName={classes.handler}
    overlay={value}
    visible={dragging}
    placement="top"
    key={index}
    style={{
      zIndex: 1050
    }}
  >
    <Handle value={value} {...restProps} />
  </Tooltip>
);

const SliderHandlerWithStyles = withStyles(styles)(SliderHandler);

const Handler = props => <SliderHandlerWithStyles {...props} />;


const SliderField = ({classes, onChange, min, max, defaultValue, input, type, id, value}) => {
  let inputValue = input && input.value ? input.value : value ? value : defaultValue;

  inputValue = parseInt(inputValue, 10);

  return (
    <div className={classes.sliderWrapper}>
      <Slider
        className={classes.slider}
        onChange={input ? input.onChange : onChange}
        min={min} 
        max={max} 
        defaultValue={defaultValue}
        handle={Handler}
        value={inputValue}
      />
      {input && <input 
        {...input}
        type={type} 
        id={id}
        style={{display: "none"}}
      /> }
      
    </div>
    
  );
};

export default withStyles(styles)(SliderField);