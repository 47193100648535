import React, {FC} from "react";
import cn from "classnames";
import { withStyles, WithStyles, createStyles, Theme } from "../../core/components/Styles";
import Grid from "@material-ui/core/Grid";

const styles = (theme: Theme) => createStyles(({
  jobLabelCont: {
		minHeight: "4.55rem",
		borderRadius: "6.8px",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.19)",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("sm")]:{
      margin: "1rem .3rem"
    }
  },
  jobLabelIcon:{
		height: "2.62rem",
    width: "2.62rem",
    borderRadius: "3.8px",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.19)",
    backgroundImage: "linear-gradient(to bottom, #50e3c2, #00949c)",
    display:"flex",
    alignItems:"center",
		justifyContent:"center",
	},
	infoTagIconPosition:{
    margin: "-1.5rem 0 0 -.5rem"
  },
  jobLabelTitle:{
    color: "#00949c",
    fontSize: ".78rem",
    display: "block"
  },
  jobLabelInfo:{
    color: "#7c7c7c",
    fontSize: ".857rem"
  },
  jobLabelSubtext:{
    fontSize: ".65rem"
	},
	inlineFlexColumn: {
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start"
  },
}));

export interface JobInfoLabelProps extends WithStyles<typeof styles> {
	icon: string,
	title: string,
	info: string | number,
  subText?: string
};

const JobInfoLabel:FC<JobInfoLabelProps> = (props)=>{ 
  const { classes, icon, title, info, subText } = props;
	return(
		<Grid container item 
      className={classes.jobLabelCont} 
			xs={5}
			md={3}
      justify="center"
      alignItems="center"
    >
			<Grid item container justify="space-between" xs={12} sm={12}>
				<Grid item xs={2} sm={3}>
					<span className={cn([classes.infoTagIconPosition,classes.jobLabelIcon])}>{icon}</span>
				</Grid> 
				<Grid item xs={8} sm={8} className={classes.inlineFlexColumn}>
					<span className={classes.jobLabelTitle}>
						{title}
					</span>
					<span className={classes.jobLabelInfo}>
						{info}
						{ subText && 
							<span className={classes.jobLabelSubtext}>{subText}</span> 
						}
					</span>
				</Grid>
			</Grid>
    </Grid>
  )
}

export default withStyles(styles)(JobInfoLabel);