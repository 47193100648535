import axios from "utils/http";

const getOrdersByDate = async ({
  contractorId,
  date
}) => {
  try {
    const ordersRes = await axios.get(
      `${process.env.REACT_APP_API_PREFIX}contractor/get_orders?`,
      {
        params: {
          contractorId,
          date
        }
      }
    );
    
    return ordersRes.data;

  } catch (error) {
    throw error;
  }
}


export default getOrdersByDate;