import Payments from "../containers/PaymentsContainer";
import AddDetails from "../containers/AddDetailsSettingsContainer";
import Plan from "../containers/PlanContainer";

const routes = [
  {
    path:"/settings/payment_methods",
    component: Payments
  },
  {
    path:"/settings/company_details",
    component: AddDetails
  },
  {
    path:"/settings/plan_settings",
    component: Plan
  }
]



export default routes;