import React from 'react';
import { Grid, Typography, 
  // Button 
} from "core/components";
import { Form, Field } from "redux-form";
import { AdvanceMultiRadioField } from "../FormField";
import { required  } from "../FormField/Validations";
import { withStyles } from "core/components/Styles";
import {  CardForm } from "../Stripe";
import Loader from "../ProgressCircle";
import BasicPlan from "./BasicPlanItem";
import PremiumPlan from "./PremiumPlanItem";
import PaymentCardItem from "./PaymentCardItem";
import ZButton from "core/components/Button";



const createCardOptions = (userInfo) => {
  const newCardO = {
    label:"Add a new card",
    value:"new"
  }
  const cardOptions = [];
  if(userInfo && Array.isArray(userInfo.cards)){
    const { cards } = userInfo;
    cards.forEach((c) => cardOptions.push({label: `${c.last4} ${c.brand}`, value: c._id, card:c}));
  }
  cardOptions.push(newCardO);
  return cardOptions;

}


const styles = () => ({
  root:{
    position:"relative",
  },
  cardsList:{
    marginTop: 20
  },
  cardFormCont:{
    marginTop: 20
  },
  doneBtnContainer:{
    marginTop: 20
  },
  termsText:{
    color: "#aaa",
    fontSize: "0.8rem",
    marginTop: 0,
    marginBottom: 0
  },
  heading: {
    letterSpacing: "-0.491481px",
    fontSize: "1.1428rem",
    lineHeight: "1.3125",
    color: "#000000"
  },
})


const PlanChangeForm = ({
  handleSubmit,
  submitPlanData,
  classes,
  formValues,
  userInfo,
  errorCodes,
  showLoader,
  changePlan
}) => {
  const plan = formValues && formValues.plan;
  const cardsOptions = createCardOptions(userInfo);
  const cardSource = formValues && formValues.cardSource;
  return (
    <Form onSubmit={handleSubmit(submitPlanData)}>
      <Grid className={classes.root}>
        {showLoader && <Loader />}
        <Grid>
          <Typography variant="subtitle1" gutterBottom>
            Plan Settings
          </Typography>
          <Grid
            container
            alignContent="center"
            justify="center"
            spacing={6}
            id="selectPlan"
          >
            <Grid item xs={4}>
              <BasicPlan plan={plan} changePlan={changePlan}/>
            </Grid>
            <Grid item xs={4}>
              <PremiumPlan plan={plan} changePlan={changePlan}/>
            </Grid>
          
          </Grid>
        </Grid>
        {
          plan && plan === "premium" && 
          <Grid className={classes.cardsList}>
            <Typography >Preferred Card</Typography>
            <Field 
              name="cardSource"
              options={cardsOptions}
              component={AdvanceMultiRadioField}
              optionComponent={PaymentCardItem}
              validate={[required]}
            />
            {/* <Field 
              name="cardSource"
              component={MutliRadioField}
              options={cardsOptions}
              validate={[required]}
              displayInList
            /> */}
            {
              cardSource && cardSource === "new" && plan === "premium" && 
              <Grid className={classes.cardFormCont}>
                <CardForm error={errorCodes}/>
              </Grid>
            }
          </Grid>
        }

        {/* <Grid>
          <Field
            id="acceptedPlanPaymentTerms"
            name="acceptedPlanPaymentTerms"
            component={MaterialCheckBox}
            label={ 
            <p className={classes.termsText} style={{ marginBottom: 0 }}>
              I accept {" "}
              <a target="_blank" href="https://www.zoklean.com/privacy-policy">Terms and Conditions</a>
            </p>
            }
            validate={[required]}
          />
        </Grid> */}

        <Grid
          className={classes.doneBtnContainer}
          container
          alignItems="center"
        >
          <ZButton color="primary" gradient type="submit">
            Done
          </ZButton>
        </Grid>
      </Grid>
    </Form>
  )
}

export default withStyles(styles)(PlanChangeForm)
